$headings-margin                 : 0 0 0.5em 0 !default;
$headings-margin--page           : $spacer--medium 0 !default;

$headings-font-family            : $font-family-base !default;
$headings-font-weight            : 700 !default;
$headings-line-height            : $font-line-height !default;
$headings-color                  : $color-secondary !default;

$headings-text-transform         : none !default;
$headings-text-transform--page   : uppercase !default;

$heading-font-size--page         : 24px !default;
$heading-font-size--first-level  : 18px !default;
$heading-font-size--second-level : 18px !default;
$heading-font-size--third-level  : 14px !default;
$heading-font-size--fourth-level : 14px !default;
$heading-font-size--fifth-level  : 12px !default;
$heading-font-size--sixth-level  : 12px !default;

$catalog-heading-font-size--page         : 24px !default;
$catalog-heading-font-size--first-level  : 18px !default;
$catalog-heading-font-size--second-level : 18px !default;
$catalog-heading-font-size--third-level  : 14px !default;
$catalog-heading-font-size--fourth-level : 14px !default;
$catalog-heading-font-size--fifth-level  : 12px !default;
$catalog-heading-font-size--sixth-level  : 12px !default;




.H3-Black-Semi-Bold {
    font-size: 24px;
    font-weight: 600;
    line-height: 1.33;
    color: $thunder;
}

.H3-Black-Reg {
    font-size: 24px;
    line-height: 1.33;
    color: $thunder;
}

.H3-White {
    font-size: 24px;
    line-height: 1.33;
    color: $white;
}

.H4-White {
    font-size: 20px;
    line-height: 1.4;
    color: $white;
}

.H4-Black {
    font-size: 20px;
    line-height: 1.4;
    color: $thunder;
}

.H5-Black-34-line {
    font-size: 18px;
    line-height: 1.78;
    color: $thunder;
}

.H5-White-34-line {
    font-size: 18px;
    line-height: 1.78;
    color: $white;
}

.heading {

    margin: $headings-margin;
    font-family: $headings-font-family;
    font-weight: $headings-font-weight;
    line-height: $headings-line-height;
    color: $headings-color;
    text-transform: $headings-text-transform;

    &--first-level {

        font-size: $heading-font-size--first-level;
        line-height: 1.2;

        &.hero {

            font-size: $heading-font-size--first-level-hero;
            line-height: 1.11;

        }

    }

    &--second-level {

        font-size: $heading-font-size--second-level;
        line-height: 1.25;

    }

    &--third-level {

        font-size: $heading-font-size--third-level;
        line-height: 1.33;

    }

    &--fourth-level {

        font-size: $heading-font-size--fourth-level;
        line-height: 1.4;

    }

    &--fifth-level {

        font-size: $heading-font-size--fifth-level;
        line-height: 1.44;

    }

    &--sixth-level {

        font-size: $heading-font-size--sixth-level;
        line-height: 1.78;

    }

    &--page {

        margin: $headings-margin--page;
        font-size: $heading-font-size--page;
        text-transform: $headings-text-transform--page;

    }

}

h1,
h2,
h3,
h4,
h5,
h6 {
    @extend .heading;
}

h1 {
    @extend .heading--first-level;
}

h2 {
    @extend .heading--second-level;
}

h3 {
    @extend .heading--third-level;
}

h4 {
    @extend .heading--fourth-level;
}

h5 {
    @extend .heading--fifth-level;
}

h6 {
    @extend .heading--sixth-level;
}

.catalog-typography {

    .heading {

        margin: $headings-margin;
        font-family: $headings-font-family;
        font-weight: $headings-font-weight;
        line-height: $headings-line-height;
        color: $headings-color;
        text-transform: $headings-text-transform;

        &--first-level {

            font-size: $catalog-heading-font-size--first-level;
            line-height: 1.2;

            &.hero {

                font-size: $catalog-heading-font-size--first-level-hero;
                line-height: 1.11;

            }

        }

        &--second-level {

            font-size: $catalog-heading-font-size--second-level;
            line-height: 1.25;

        }

        &--third-level {

            font-size: $catalog-heading-font-size--third-level;
            line-height: 1.33;

        }

        &--fourth-level {

            font-size: $catalog-heading-font-size--fourth-level;
            line-height: 1.4;

        }

        &--fifth-level {

            font-size: $catalog-heading-font-size--fifth-level;
            line-height: 1.44;

        }

        &--sixth-level {

            font-size: $catalog-heading-font-size--sixth-level;
            line-height: 1.78;

        }

        &--page {

            margin: $headings-margin--page;
            font-size: $catalog-heading-font-size--page;
            text-transform: $headings-text-transform--page;

        }

    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        @extend .heading;
    }

    h1 {
        @extend .heading--first-level;
    }

    h2 {
        @extend .heading--second-level;
    }

    h3 {
        @extend .heading--third-level;
    }

    h4 {
        @extend .heading--fourth-level;
    }

    h5 {
        @extend .heading--fifth-level;
    }

    h6 {
        @extend .heading--sixth-level;
    }

}
