.solutions-view-index {

    .heading--first-level.hero, h1.hero .custom-slider-item-title.hero {
        font-size: 25px;
        line-height: 37px;
        font-weight: bold;
        text-align: center;
        margin: 0 auto;
        max-width: 100% !important;

        @media all and (min-width: 1024px) {
            font-size: 36px;
            margin: 0 0 20px 7% !important;
            text-align: left;
            max-width: 500px !important;
        }
    }

    .custom-slider-item {

        .banner-overlay {

            display: none;
            width: 100%;
            position: absolute;
            height: 100%;
            opacity: 0.75;
            background: linear-gradient(270deg, rgba(20, 36, 45, 0) 0%, rgba(45, 74, 89, 1) 100%);
            filter: opacity(0.5);

            &[data-service-name="Enterprise Networking"],
            &[data-service-name="Small & Medium Business Networking"],
            &[data-service-name="Circular Economy & Environmental Sustainability"],
            &[data-service-name="Service & Support"],
            &[data-service-name="Financial Services"],
            &[data-service-name="Internet of Things"],
            &[data-service-name="Pick By Light"] {
                top: 0;
            }

        }

    }

    // Banner Text
    .custom-slider-item-strapline {
        margin-left: 7% !important;
        max-width: 40% !important;
        margin-bottom: 0 !important;
        padding-right: 20px;
        font-weight: 500;
        font-size: 20px;

        @media all and (max-width: 1023px) {
            margin: 0 auto !important;
            max-width: 100% !important;
            padding: 0;
        }

        p {
            font-weight: 500;
            line-height: 24px;
            color: #fefefe;
            margin: 0 auto;
            max-width: 100% !important;
            text-align: center;
            font-size: 20px;

            &:first-of-type {
                margin: 30px 0 22px;
            }

            @media all and (min-width: 1024px) {
                max-width: 76%;
                line-height: 26px;
                text-align: left;
            }
        }

        a {

            button {

                padding: 15px 45px;
                height: auto;
                margin-top: 35px;

            }

        }
    }

    // Services Banners Cut out
    .banner-cut-rectangle-container {
        filter: drop-shadow(7px 14px 13px rgba(0, 0, 0, 0.14)); // Drop shadow will follow clip path below
        position: relative;
        top: 20px;

        @media all and (max-width: 1023px) {
            top: -20px;
        }

        .banner-cut-rectangle {
            background-color: #eee;
            padding: 40px;
            color: white;
            font-size: 19px;
            line-height: 29px;
            transform: translateY(-16px);
            text-align: center;
            margin: 0;
            min-width: 40%;

            @media all and (min-width: 1440px) {
                margin: 0 -0.05%;
            }

            @media all and (min-width: 1024px) {
                position: absolute;
                right: 0;
                left: auto;
                bottom: 100%;
                transform: translateY(0);
                max-width: 550px;
                text-align: left;
                box-sizing: content-box;
                clip-path: polygon(0 0, 0 0%, 100% 0%, 100% 0, 100% 100%, calc(100% - 35px) 100%, 35px 100%, 0 calc(100% - 35px));
            }

            .note-heading {
                font-size: 21px;
                font-weight: 500;
                line-height: 37px;

                @media all and (max-width: 1023px) {
                    padding-right: 0 !important;
                }
            }

            @media (min-width: 320px) and (max-width: 480px) {
                padding: 18px 30px 0 27px;

            }

            .note-note {

                font-size: 17px;
                line-height: 29px;
                font-weight: 500;

            }

            p {
                margin-bottom: 0;
                color: white;
            }

        }
    }

    // Page Content
    .solution {
        &--title {
            padding: 40px 20px;
            margin: 20px 0;

            h2 {
                font-size: 18px;
                font-weight: 500;
                letter-spacing: 0;
                line-height: 26px;
                text-align: center;
            }
        }

        .banner-footer-text {
            display: flex;
            justify-content: center;
            gap: 0 50px;
            padding: 60px 50px;
            margin-top: -40px;
            flex-wrap: wrap;
            background: #f1f8fb;

            .banner-footer-header-text {
                width: 100%;
                margin-bottom: 50px;
                text-align: center;

                h2 {
                    margin: 0;
                    font-weight: 500;
                }
            }

            p {
                font-size: 17px;
                line-height: 26px;
            }

            .text-block-left {
                flex: 100%;

                @media all and (min-width: 1039px) {
                    flex: 1%;
                }

            }

            .text-block-right {
                flex: 1;
            }
        }

        .features {
            &--logos {
                display: flex;
                justify-content: center;
                flex-wrap: wrap;
                margin-bottom: 80px;

                @media all and (max-width: 450px) {
                    margin: 0;
                }

                .features-group {
                    flex: 1;
                    min-width: 320px;
                    max-width: 350px;
                    margin-bottom: 20px;

                    .feature-logo {
                        margin-bottom: 20px;

                        img {
                            max-width: 104px;
                            margin: 0 auto;
                            @media all and (max-width: 450px) {
                                max-width: 84px;
                                max-height: 84px;
                            }
                        }
                    }

                    .feature-title {
                        font-size: 18px;
                        text-align: center;
                        margin-bottom: 14px;
                        font-weight: 500;
                    }

                    .feature-text {
                        font-size: 14px;
                        text-align: center;
                        padding: 0 35px;
                        line-height: 23px;
                    }
                }
            }

            &--image-blocks {
                display: flex;
                justify-content: center;
                flex-wrap: wrap;

                .feature-image-group {
                    flex-basis: 100%;
                    max-width: 405px;
                    box-shadow: 0 4px 13px 0 rgba(0, 0, 0, 0.09);
                    margin: 0 20px 40px 20px;

                    &.four-columns {
                        max-width: 315px;
                    }

                    .feature-content {
                        padding: 40px 24px;

                        .feature-title {
                            color: #303030;
                            font-size: 22px;
                            line-height: 32px;
                            margin-bottom: 20px;
                            font-weight: 500;
                        }

                        .feature-text {
                            color: #686868;
                            font-size: 15px;
                            line-height: 23px;
                        }
                    }
                }
            }
        }

        .testimonials-container {

            max-width: 1440px;
            margin-bottom: 100px;

            .testimonials-image {

                width: 100%;
                height: auto;
                padding-top: 30.69%; // 1440x442
                background-repeat: no-repeat;
                position: relative;

                @media all and (max-width: 767px) {

                    padding-top: 61.38%;

                }

                @media all and (max-width: 500px) {

                    padding-top: 90%;

                }

                .testimonials-content {

                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    color: white;
                    text-align: center;
                    width: 100%;
                    max-width: 60%;

                    @media all and (max-width: 991px) {

                        max-width: 80%;

                    }

                    @media all and (max-width: 500px) {

                        max-width: 90%;

                    }

                    .testimonials-text {

                        font-style: italic;
                        font-size: 25px;
                        line-height: 35px;

                        @media all and (max-width: 991px) {

                            font-size: 20px;

                        }

                        @media all and (max-width: 500px) {

                            font-size: 18px;
                            line-height: 25px;

                        }

                    }

                    .testimonials-author {

                        font-size: 15px;
                        font-weight: 500;
                        margin-top: 25px;

                        @media all and (max-width: 991px) {

                            font-size: 13px;

                        }

                    }

                }

            }

        }

    }

    /* Desktop */

    @media all and (min-width: 1024px) {
        .solution {
            &--title {

                h2 {
                    font-size: 25px;
                    font-weight: 500;
                    letter-spacing: 0;
                    line-height: 32px;
                    text-align: center;
                }
            }

            .features {
                &--logos {
                    display: flex;
                    justify-content: center;
                    flex-wrap: wrap;

                    .features-group {

                        .feature-title {
                            font-size: 20px;
                            line-height: 28px;
                        }

                        .feature-text {
                            font-size: 14px;
                            line-height: 28px;
                        }
                    }
                }

                &--image-blocks {
                    flex: 1;

                    .feature-title {
                        font-size: 22px;
                    }

                    .feature-text {
                        font-size: 15px;
                        line-height: 26px;
                    }
                }
            }
        }
    }

    .our-solutions-container {

        .container {

            margin: 0 auto;

        }

        &[data-solution-service-name="eProcurement"] {
            margin-bottom: -80px; // Have blue bg section span to footer, expecting p tag polluting content after save
        }

    }

    /* ----- Transforming with Cisco Start ----- */
    .transforming-with-cisco {
        display: flex;
        flex-direction: column-reverse;
        margin-bottom: 40px;

        .block {
            &--left {
                flex: 1;
                background-color: #F1F8FB;
                padding-bottom: 30px;

                .block-content {
                    padding: 20px;
                    max-width: 100%;
                }

                .block-title {
                    color: #303030;
                    font-size: 18px;
                    font-weight: 500;
                    letter-spacing: 0;
                    line-height: 26px;
                    margin-bottom: 20px;
                }

                .block-text {
                    color: #303030;
                    font-size: 14px;
                    letter-spacing: 0;
                    line-height: 26px;
                    margin-bottom: 20px;
                }

                .block-button {
                    font-size: 14px;
                    letter-spacing: 0;
                    line-height: 17px;
                }
            }

            &--right {
                flex: 1;
                padding: 20px;
                background-color: #F1F8FB;

                .block-video {
                    height: 100% !important;

                    #cisco-video {
                        width: 100% !important;
                        margin: 0 auto;
                        display: block;
                        min-height: 250px;
                        border: 0;
                    }
                }
            }
        }
    }

    /* Desktop */
    @media all and (min-width: 1024px) {
        .transforming-with-cisco {
            display: flex;
            flex-direction: row;

            .block {
                &--left {
                    flex: 1;
                    background-color: #F1F8FB;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    min-height: 430px;
                    padding-bottom: 0;

                    .block-content {
                        padding: 0;
                        margin: 20px 80px;
                    }

                    .block-title {
                        font-size: 22px;
                        font-weight: 600;
                    }

                    .block-text {
                        font-size: 15px;
                    }

                    .block-button {
                    }
                }

                &--right {
                    flex: 1;
                    padding: 0;

                    .block-video {
                        height: 100%;

                        #cisco-video {
                            width: 100% !important;
                            height: 100% !important;
                        }
                    }
                }
            }
        }
    }
    /* ----- Transforming with Cisco End ----- */

    .speak-to-an-expert {

        &__container {

            margin-bottom: -40px;
            max-width: 1440px;
            margin: 0 auto -40px;

        }

        &__background {

            position: relative;
            height: 418px;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;

        }

        &__content {

            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            text-align: center;
            color: white;
            padding: 0 5%;
            width: 100%;
            max-width: 1000px;

            &-title {

                font-size: 27px;
                font-weight: bold;
                margin-bottom: 20px;

            }

            &-text {

                color: white;
                font-size: 25px;
                margin-bottom: 40px;

                @media all and (max-width: 400px) {

                    font-size: 22px;

                }

            }

            &-button {

                width: 200px;

            }

        }

    }

    .info-blocks {
        margin: 80px 0;

        .info-block {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            flex-direction: column;

            &.reverse {
                @media all and (min-width: 1024px) {
                    flex-direction: row-reverse;
                }
            }

            &.partners {
                justify-content: left;
                @media all and (min-width: 1024px) {
                    padding-top: 103px;

                }
                @media all and (max-width: 768px) {
                    padding-top: 30px;
                }
            }

            .block-partners-container {

                @media (min-width: 768px) and (max-width: 1024px) {
                    padding: 30px 40px 0 40px;
                }

                .section-title {
                    padding-bottom: 20px;

                    @media all and (max-width: 767px) {
                        margin-left: 15px;
                        padding: 0;
                    }
                }

                @media all and (min-width: 1025px) {
                    margin-left: 77px;

                }

                .block-partners-brand {
                    display: flex;

                    @media all and (min-width: 1024px) {
                        padding-top: 20px;
                        padding-bottom: 59px;
                    }
                    @media (min-width: 768px) and (max-width: 1024px) {


                    }

                    .partners-brand-image {
                        display: flex;
                        @media all and (max-width: 767px) {
                            flex-wrap: wrap;
                        }

                        .partners-brand-image-item {

                            @media all and (min-width: 1024px) {
                                padding: 0 121px 0 2px;
                            }

                            @media all and (max-width: 767px) {
                                flex-basis: 50%;
                                padding: 7px 23px 9px 27px;
                            }
                            @media (min-width: 768px) and (max-width: 1024px) {
                                padding: 7px 23px 9px 27px;
                            }

                        }
                    }


                }
            }

            .block-video {
                height: 100%;

                iframe {
                    height: 100%;
                }
            }

            .left-block {
                flex-basis: 50%;
                background: #F1F8FB;
                display: flex;
                align-items: center;
                padding: 10%;

                .block-content {

                    h3 {

                        font-weight: 500;

                    }

                    p {
                        font-size: 15px;
                        line-height: 26px;
                    }
                }

                img {
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                }
            }

            .right-block {
                width: 100%;

                img {
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                }
            }
        }
    }

    /* Info Blocks Desktop */
    @media all and (min-width: 1024px) {
        .info-blocks {
            .info-block {
                flex-direction: row;

                .left-block {
                    padding: 3%;
                }

                .right-block {
                    width: 50%;
                }
            }
        }
    }

    @media all and (min-width: 1250px) {
        .info-blocks {
            .info-block {
                .left-block {
                    padding: 5% 82px;
                }
            }
        }
    }

    .custom-slider-item.custom-image {
        .custom-solution-logo {
            position: absolute;
            bottom: 50px;
            color: white;
            font-size: 9px;
            left: 5%;
            text-align: center;

            @media all and (min-width: 1024px) {
                left: 7%;
            }

            @media all and (max-width: 1023px) {
                left: 50%;
                transform: translateX(-50%);
                bottom: 20px;
            }

            img {
                max-width: 70px;
                margin: 0 auto 0.5rem auto;
            }

            &[data-src-service-name="Internet of Things"] {
                left: unset;
                right: 5%;

                @media all and (max-width: 1023px) {
                    left: 50%;
                    right: unset;
                    transform: translateX(-50%);
                    bottom: 20px;
                }

                img {
                    width: auto;
                    max-width: 140px;
                }
            }
        }
    }


    .shop-certified-wrapper {
        background-color: rgba(116, 217, 148, 0.19);
        padding: 30px;
        width: 100%;

        &--content {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 1rem 2rem;
            max-width: 1000px;
            margin: 0 auto;

            @media all and (max-width: 1024px) {
                flex-wrap: wrap;
                justify-content: center;
            }

            img {
                width: auto;
                height: 100%;
                max-width: 125px;
            }

            h3 {
                font-weight: 500;
                margin: 0;
                text-align: center;
            }

            .button-wrapper {
                a.button.success {
                    background-color: #32C026 !important;
                    color: white !important;
                    min-width: 205px;

                    &:hover {
                        border: none !important;
                    }
                }

                @media all and (max-width: 1024px) {
                    flex-basis: 100%;
                    text-align: center;
                }
            }
        }
    }


    .enhanced-by-dte-wrapper {
        background-color: #F1F8FB;
        padding: 80px;
        margin: 0 auto;

        @media all and (max-width: 767px) {
            padding: 0;
        }

        &--content {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: baseline;
            margin: 0 auto;
            gap: 4rem 2rem;

            h2 {
                flex-basis: 100%;
                text-align: center;
                font-weight: 500;
            }

            .enhanced-item {
                text-align: center;
                color: #2E3687;
                flex-basis: calc(20% - 2rem);

                @media all and (max-width: 1024px) {
                    flex-basis: calc(33% - 2rem);
                }

                @media all and (max-width: 767px) {
                    flex-basis: calc(50% - 2rem);
                }

                img {
                    width: auto;
                    margin: 0 auto 2rem auto;
                }

                > div {
                    font-size: 20px;
                    font-weight: 550;
                }
            }
        }
    }

}

// Banner Title

.solutions-view-index {

    .solutions-sub-menu {
        display: none;
    }
}
