$tab__border-width                    : 1px !default;
$tab__border-width--large             : 8px !default;

// title
$tab__title-padding                   : $spacer--medium !default;
$tab__title-border-width              : 1px !default;
$tab__title-border-width-active       : 1px !default;
$tab__title-border-color              : $gray-dark !default;
$tab__title-border-color-active       : $gray-lighter !default;
$tab__title-color                     : $gray !default;
$tab__title-color--active             : $gray-darker !default;
$tab__title-font-size                 : $font-size-base !default;
$tab__title-font-weight--large        : $font-weight-normal !default;
$tab__title-font-weight--large-active : $font-weight-bold !default;
$tab__title-font-weight               : $font-weight-bold !default;
$tab__title-background                : $bg-color-base !default;
$tab__title-background--active        : $gray-lightest !default;
$tab__title-background--large-active  : $gray-lightest !default;

// content
$tab__content-padding                 : $spacer--medium !default;
$tab__content-padding--large          : $spacer--large $spacer--extra-large !default;

// icon
$tab__icon-color                      : $gray !default;
$tab__icon-color--active              : $gray-darker !default;
$tab__icon-padding                    : 16px !default;
$tab__icon-size                       : 48px !default;

.tab {

    //@include mq($screen-l) {

        display: flex;
        flex-flow: row wrap;

    //}

    &__title {

        position: relative;
        width: 100%;
        border: $tab__title-border-width $tab__title-border-color solid;
        border-width: $tab__border-width 0 0 0;
        background: $tab__title-background;
        text-align: center;
        font-weight: 500;
        font-size: $tab__title-font-size;
        color: $dte-bright-blue;
        cursor: pointer;
        padding: 0 16px;
        margin: 16px 0;
        display: flex;
        align-items: center;
        justify-content: center;

        &:not(:first-of-type) {

            border-left: 1px solid #e0e0e0;

        }

        &:nth-last-child(2) {


            //@include mq($screen-l) {
                width: auto;
            //}

        }

        //@include mq($screen-l) {

            flex: 1;
            order: -1;
            border-top: none;

        //}

        .tab__icon {

            fill: $tab__icon-color;
            pointer-events: none;

        }

        &:after {

            content: '';
            max-height: 0;
            transition: transform 0.35s;
            transform: scale(0);
            overflow: hidden;
            pointer-events: none;
            position: absolute;
            bottom: -2px;
            left: 0;
            right: 0;
            border-radius: $tab__title-border-width-active;

        }

        &--active {

            background-color: $tab__title-background--large-active;
            font-weight: $tab__title-font-weight--large-active;
            color: $dark-grey;
            z-index: 2;

            //@include mq($screen-l) {
                border-top: none;
            //}

            //&:after {
            //
            //    background-color: $tab__title-border-color-active;
            //    height: $tab__title-border-width-active;
            //    max-height: $tab__title-border-width-active;
            //    transform: scale(1);
            //
            //}


            .tab__icon {

                transform: rotateX(180deg);
                fill: $tab__icon-color--active;

            }

        }

        .counter {

            &:before {
                content: "(";
            }

            &:after {
                content: ")";
            }

        }

    }

    &__icon {

        position: absolute;
        right: 15px;
        top: 0;
        bottom: 0;
        margin: auto;
        width: $tab__icon-size;
        height: $tab__icon-size;
        padding: $tab__icon-padding;

        //@include mq($screen-l) {
            display: none;
        //}

    }

    &__content {

        position: relative;
        display: block;
        width: 100%;
        z-index: -1;
        opacity: 0;
        overflow: hidden;
        height: 0;
        transition: all 0.35s;

        &--active {

            height: auto;
            opacity: 1;
            z-index: 1;
            padding: $tab__content-padding;

            //@include mq($screen-l) {

                padding: $tab__content-padding--large;
                flex-basis: 100%;
                z-index: 1;

            //}

            &:nth-last-child(1) {

                border-bottom: $tab__title-border-width $tab__title-border-color solid;

                //@include mq($screen-l) {
                    border-bottom: none;
                //}

            }

        }

    }

    &--inline {

        .tab__title {

            flex: none;
            width: auto;
            display: inline-block;

        }

        .tab__title--end-spacer {

            flex: 1;

        }

        &:before {

            content: '';
            position: relative;
            display: block;
            width: 100%;
            border-top: $tab__title-border-width $tab__title-border-color solid;
            top: -$tab__title-border-width;
            pointer-events: none;

        }

    }

}
