.solutions-modal-container {

    position: relative;
    width: 0;
    height: 0;
    overflow: hidden;
    opacity: 0;
    z-index: -100;
    transition: all 0.35s;
    transition-delay: 0.5s;

    &:before {

        content: '';
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        pointer-events: none;
        background-color: rgba(0,0,0,0.35);
        z-index: 99;

    }

    .solutions-modal {

        position: fixed;
        top: 50%;
        transform: translateY(-50%);
        height: 90%;
        z-index: 99;
        max-height: 530px;
        max-width: 750px;
        left: 0;
        right: 0;
        margin: 0 auto;
        width: 90%;
        border-radius: 3px;

        > * {

            max-width: 460px;
            text-align: center;
            margin-left: auto;
            margin-right: auto;

        }

    }

    .solutions-modal-inner {

        background: $white;
        padding: 30px 90px;
        width: 100%;
        min-height: 100%;
        max-width: none;
        -webkit-animation: scale-out-center 0.45s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
        animation: scale-out-center 0.45s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;

    }

    .solutions-modal--icon-link {
        display: block;
    }

    .solutions-modal--icon {

        width: 96px;
        height: 96px;
        margin: 0 auto 15px;

    }

    .solutions-modal--title {
        margin-bottom: 30px;
    }

    .solutions-modal--buttons-container {
        margin: 45px auto 0;
    }

    .solutions-modal--close {

        max-width: none;
        display: block;
        text-align: right;
        margin: 0 -50px;

    }

    .solutions-modal--arrow {

        display: inline-block;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);

    }

    .solutions-modal--prev {
        left: 40px;
    }

    .solutions-modal--next {
        right: 40px;
    }

}

body.solutions-modal-active {

    overflow: hidden;

    .solutions-modal-container {

        width: auto;
        height: auto;
        overflow: visible;
        opacity: 1;
        z-index: 99;
        transition-delay: 0s;

        &:before {
            pointer-events: all;
        }

        .solutions-modal {
            overflow-y: auto;
        }

        .solutions-modal-inner {

            -webkit-animation: none;
            animation: none;

        }

    }

}

@media all and (max-width: 767px) {

    .solutions-modal-container {

        .solutions-modal--prev {
            left: 5px;
        }

        .solutions-modal--next {
            right: 5px;
        }

        .solutions-modal--close {
            margin: 10px -35px 0;
        }

        .solutions-modal-inner {

            overflow-x: hidden;
            padding: 0 45px 45px;

            h3 {
                font-size: 18px;
            }

        }

    }

}

@-webkit-keyframes scale-out-center {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1;
    }
    25% {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
        opacity: 0.8;
    }
    100% {
        -webkit-transform: scale(0);
        transform: scale(0);
        opacity: 0;
    }
}
@keyframes scale-out-center {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1;
    }
    25% {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
        opacity: 0.8;
    }
    100% {
        -webkit-transform: scale(0);
        transform: scale(0);
        opacity: 0;
    }
}