$label-margin        : 0 0 $spacer !default;
$label-margin--inline: 0 $spacer 0 0 !default;
$label-font-size     : $font-size-base !default;
$label-color         : $color-secondary !default;
$label-font-family   : $font-family-proxima-nova !default;
$label-font-weight   : $font-weight-normal !default;
$label-line-height   : $font-line-height !default;
$label-transition    : none !default;
$label-text-transform: none !default;
$label-focus-color   : $label-color !default;

.label {

    margin: $label-margin;
    font-size: $label-font-size;
    color: $label-color;
    font-family: $label-font-family;
    font-weight: $label-font-weight;
    line-height: $label-line-height;
    transition: $label-transition;
    text-transform: $label-text-transform;

    &--inline {
        margin: $label-margin--inline;
    }

    &--hidden {
        @include visually-hidden();
    }

}

.input {

    &:focus-within {

        label,
        .label {
            color: $label-focus-color;
        }

    }

}

label {
    @extend .label;
}

label {
    ~ .tooltip {
        &.wrapper {
            display: block;
            margin-top: 10px;
        }
    }
}
