$radio__size              : $spacer--medium !default;
$radio__dot-size          : 6px !default;
$radio__label-font-size   : $font-size-small !default;
$radio__label-color       : $black !default;
$radio__label-color-active: $color-primary !default;
$radio__icon-border       : 1px solid $black !default;
$radio__icon-outer-background: $white !default;
$radio__icon-active-inner-background: $color-primary !default;
$radio__icon-active-outer-background: $white !default;
$radio__icon-hover-inner-background: $color-primary !default;
$radio__icon-hover-outer-background: $white !default;
$radio__icon-hover-border-color : $black !default;
$radio__icon-transition   : $transition-base !default;
$radio__icon-text-transform: none !default;
$radio__icon-disabled-border-color: $gray-lighter !default;
$radio__icon-disabled-color: $gray-lighter !default;
$radio__icon-disabled-outer-background: $white !default;
$radio__icon-disabled-inner-background: $white !default;

.radio {

    position: relative;
    margin-bottom: $spacer--medium;
    text-transform: $radio__icon-text-transform;

    &__fieldset {

        border: 0;
        padding: 0;
        margin: 0;

    }

    &__legend {
        padding-bottom: $spacer--medium;
    }

    &__handler {
        position: relative;
    }

    &__field {

        position: absolute;
        left: 0;
        height: $radio__size;
        width: $radio__size;
        opacity: 0;
        z-index: -1;

        &:hover + .radio__label {

            .radio__icon {

                background-color: $radio__icon-hover-outer-background;
                border-color: $radio__icon-hover-border-color;

                &:before {

                    opacity: 1;
                    transform: scale(1);
                    background-color: $radio__icon-hover-inner-background;

                }

            }

        }

        &:checked + .radio__label {

            color: $radio__label-color-active;

            .radio__icon {

                background-color: $radio__icon-active-outer-background;
                border: 1px solid $radio__icon-hover-border-color;

                &:before {

                    opacity: 1;
                    transform: scale(1);

                }

            }

        }


        &:disabled,
        &[readonly] {

            opacity: 1;

            + .radio__label {

                color: $radio__icon-disabled-color;
                cursor: default;

                .radio__icon {

                    background-color: $radio__icon-disabled-outer-background;
                    border-color: $radio__icon-disabled-border-color;

                    &:before {

                        opacity: 1;
                        transform: scale(1);
                        background-color: $radio__icon-disabled-inner-background;

                    }

                }

            }

        }

    }

    &__label {

        position: relative;
        height: $radio__size;
        width: 100%;
        vertical-align: top;
        line-height: $radio__size;
        cursor: pointer;
        font-size: $radio__label-font-size;
        color: $radio__label-color;

    }

    &__icon {

        position: relative;
        display: inline-block;
        border: $radio__icon-border;
        border-radius: $radio__size;
        width: $radio__size;
        height: $radio__size;
        margin-right: $spacer;
        vertical-align: top;
        background-color: $radio__icon-outer-background;
        transition: $radio__icon-transition;

        &::before {

            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            content: '';
            width: $radio__dot-size;
            height: $radio__dot-size;
            border-radius: $radio__dot-size;
            margin: auto;
            opacity: 0;
            transform: scale(0);
            background-color: $radio__icon-active-inner-background;
            transition: $radio__icon-transition;

        }

    }

}
