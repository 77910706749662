$dashboard__margin        : $spacer--large !default;
$dashboard__padding       : $spacer--medium !default;
$dashboard__title--margin : 0 0 $spacer--medium 0 !default;
$dashboard__title--padding: 0 0 $spacer 0 !default;

.dashboard {
    margin-top: $spacer--medium;
    margin-bottom: $spacer--medium;

    &__actions {
        justify-content: space-between;
    }

    &__handler {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        margin-bottom: $dashboard__margin;
    }

    &__title {
        margin: $dashboard__title--margin;
        padding: $dashboard__title--padding;
        border-bottom: 1px solid $gray;

        &--wrapped {
            margin-bottom: 0;
            margin-right: $dashboard__margin;
        }
    }

    &__status {
        border: 1px solid $gray;
        border-radius: $border-radius;
        padding: 5px 15px;
        font-size: $font-size-small;
    }

    &__item {
        margin-bottom: $dashboard__margin;
    }

    &__form {
        padding: 0 15px;

        &:first-child {
            padding-left: 5px;
        }

        &:last-child {
            padding-right: 5px;
        }
    }

    &__product {
        border: 0;
        padding: 0;
    }

    &__form {
        &--hidden {
            display: none;
        }
    }

    &__field-qty {
        max-width: 100px;
        text-align: right;
    }

    &__input {
        margin-bottom: $dashboard__margin;
    }

    &__label {
        margin: 0 20px 0 0;
    }

    &__link {
        display: inline-flex;
    }

    &__list {
        .nav.item {
            @extend .list__item;
            @extend .dashboard__list-item;
        }

        .nav.item.current {
            @extend .dashboard__list-item--current;
        }

        .nav.item > a {
            @extend .link--invert;
            @extend .dashboard__list-link;
        }
    }

    &__list-item {
        display: flex;
        align-items: center;
        position: relative;
        padding: 0;
        margin-right: 5px;
        background-color: $gray-lighter;
        border: 1px solid $gray;
        border-bottom: 0;

        &--current {
            padding: $dashboard__padding;
            background-color: $white;

            &:after {
                content: "";
                position: absolute;
                bottom: -1px;
                left: 0;
                width: 100%;
                height: 1px;
                background-color: $white;
            }
        }
    }

    &__list-link {
        padding: $dashboard__padding;
    }
}
