.brands-page-view {
    nav.breadcrumbs {
        display: none;
    }
    &.attributepages-page-view {
        .hero-slider-container {
            .custom-slider-item {
                min-height: 703px;
                padding-top: 103px;
            }
        }
    }

    #maincontent {

        .title-wrapper {
            display: none;
        }
        .columns {
            a.button {
                padding: 15px 30px;
                margin-top: 10px;
            }
        }


    }

    #contact-form-popup {

        display: none;

        .contact-us-header-section {

            text-align: center;
            margin-top: 32px;

            h2 {
                font-size: 33px;
            }

            h3 {
                font-size: 19px;
            }

        }

        &.active {

            position: fixed;
            display: block;
            top: 50%;
            right: 0;
            left: 0;
            background: #fff;
            z-index: 99;
            width: 535px;
            margin: 0 auto;
            transform: translateY(-50%);
            box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.25);
            border: 1px #e8e8e8 solid;
            max-width: 100%;
            max-height: 85%;
            overflow-y: auto;
            padding: 25px 45px;

        }

        .webforms {

            margin-bottom: 0;
            text-align: left;

            form {

                .recaptcha {

                    margin-top: 13px;
                    margin-left: 27px;

                }

                .input__field {

                    height: 42px;
                    padding: 5px 16px;

                }

                .select__field-list:not(.choices__list--dropdown) {

                    height: 42px;
                    padding: 5px 15px;

                    .select__field-item {

                        min-height: 42px;
                        height: 42px;
                        padding: 6px 0;
                        font-size: 16px;
                        margin: 0;
                        color: $input__placeholder-color;

                    }

                }

                .choices__list--dropdown {

                    .select__field-list {

                        height: auto;

                    }

                }

                > .fieldset {

                    &:nth-child(2) {
                        padding-right: 0;
                    }

                    &:last-of-type {

                        width: 100%;

                        br {

                            display: none;

                        }

                        textarea {

                            margin-top: 7px;

                        }

                        .webforms-fields-file-upload {

                            display: block;

                            span.file-upload__button {

                                width: 100%;

                                &:not(:hover) {

                                    color: #666;
                                    border-color: #888;

                                    .button__icon {

                                        use {

                                            stroke: #333;

                                        }

                                    }

                                }

                                text-transform: none;

                            }

                        }
                    }

                }

                button.submit {

                    width: 100%;

                }

                max-width: 900px;
                margin: 0 auto;

                > .fieldset {

                    width: 100%;

                    .legend {

                        display: none;

                    }

                    textarea {

                        min-height: 120px;
                        resize: vertical;

                    }

                    > .field {

                        max-width: none;

                        &.type-select-checkbox {

                            label {

                                color: #fff;

                            }

                        }

                    }

                    .input:last-of-type {

                        margin-bottom: 0;

                    }

                }

                .file-upload {

                    margin: 13px 0 6px;

                    .file-upload__wrapper {

                        > div {

                            width: 100%;

                        }

                    }

                    .file-upload__label {

                        margin: 5px 0 3px;
                        color: #fcfcfc;

                    }

                    .file-upload__name {

                        display: none;

                    }

                    label span.file-upload__label {

                        display: none;

                    }

                }

                @media all and (max-width: 767px) {

                    padding: 0 10px;

                }

                .webforms-gdpr {

                    margin: 0;

                }

            }

        }

        #contact-form-popup-close {

            border-radius: 50%;
            padding: 5px 5px 2px 5px;
            float: right;
            border: none;
            cursor: pointer;

            svg {

                width: 21px;
                height: 21px;

            }

        }

    }

    .our-certifications-container {

        background-color: $nearly-white;
        padding: 40px 0 60px;

        h2 {

            text-align: center;
            margin: 0 0 45px;

        }

    }

    .brand-view-intro-title {

        max-width: 920px;
        text-align: center;
        margin: 30px auto 45px;

    }

    .brands-view-shop-categories-slider-container {
        margin-bottom: 30px;
    }

    .brand-view-hero-thumbnail-container {

        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        background: $light-navy-blue; // fallback color.
        z-index: 1;
        padding: 40px 80px;

        .brand-thumbnail-image {

            width: 155px;
            height: auto;
            position: relative;
            top: 50%;
            transform: translateY(-50%);
            filter: brightness(0) invert(1);

        }

        &--apple {
            background-color: #1EC3ED;
        }

    }


    .hero-slider-container {

        .custom-slider {
            padding-bottom: 0;
        }

        .custom-slider-item {

            .custom-slider-item-inner {
                > .container {
                    .custom-slider-item-title,
                    .custom-slider-item-strapline {
                        color: $white;
                    }
                    .button {
                        margin-top: 24px;
                        padding: 15px 30px;
                    }
                }


            }
        }

    }


    .take-a-closer-look {
        .container {
            .take-a-closer-look-content {

                .tab-content-right {
                    padding-top: 20px;
                    padding-bottom: 20px;
                }
            }
        }
    }


    @media (min-width: 768px) {

        .hero-slider-container {

            .custom-slider-item {

                .custom-slider-item-inner {

                    > .container {

                        padding-right: 250px;

                    }

                    .custom-slider-item-title,
                    .custom-slider-item-strapline {
                        max-width: 550px;
                    }

                }

            }

        }

    }

    @media (max-width: 767px) {

        .hero-slider-container {

            .custom-slider-item {

                padding-top: 0;
                min-height: 1px;

                .custom-slider-item-inner {
                    min-height: 1px;
                }

            }

        }

        .brand-view-hero-thumbnail-container {

            position: relative;
            top: auto;
            bottom: auto;
            left: auto;
            right: auto;
            padding: 20px;
            margin-bottom: 45px;

            .brand-thumbnail-image {

                width: 60px;
                top: auto;
                transform: none;
                margin: 0 auto;

            }

        }

    }

    .footer {

        margin-top: 40px;

    }

    .brand-view-menu-container {

        background-color: #f6f6f6;
        border-bottom: 1px solid #d3d3d3;

        .brand-menu {

            .brand-menu__list {

                list-style: none;
                position: relative;
                margin: 0 auto;
                width: 100%;
                max-width: 1453px;
                padding: 12px 0 0 0;

                @media all and (max-width: 550px) {

                    display: flex;
                    justify-content: space-between;

                }

                > .brand-menu__item {

                    display: inline-block;
                    padding: 0 40px 12px 40px !important;
                    border-bottom: 3px solid #f6f6f6;
                    font-size: 18px;
                    transition: border-color 200ms ease-in-out;
                    cursor: pointer;

                    @media all and (max-width: 768px) {

                        font-size: 14px;
                        padding: 10px 13px 7px;

                    }

                    a {

                        color: #333;

                        &:hover {

                            text-decoration: none;

                        }

                    }

                    &--parent {

                        // Add pseudo dropdown icon here

                        > a {

                            &::after {

                                content: '';
                                background-image: url("data:image/svg+xml,%3Csvg height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m7.92285156 6.96830426 7.49903224 7.49903224-7.49903224 7.4983052' fill='none' stroke='%23231f20' stroke-linecap='round' stroke-linejoin='round' transform='matrix(0 1 -1 0 26.524727 .866875)'/%3E%3C/svg%3E");
                                width: 16px;
                                height: 16px;
                                background-size: 100% 100%;
                                display: inline-block;
                                margin: 2px 0 0 8px;
                                transform: translate(2px, 3px);

                            }

                        }

                        &.active {

                            > a {

                                &::after {

                                    transform: translate(2px, 3px) rotate(180deg);

                                }

                            }

                        }

                    }

                    ul.brand-menu__inner-list {

                        display: none;
                        list-style: none;
                        background-color: rgba(255, 255, 255, 0.9);
                        font-size: 15px;
                        position: absolute;
                        top: 100%;
                        left: 0;
                        right: 0;
                        width: 100%;
                        padding: 15px 15px 30px;
                        z-index: 1;
                        margin: 1px auto 0;

                        .brand-menu__inner-item {

                            padding: 0;

                            > a {

                                padding: 7px 20px;
                                display: block;

                                &:hover {

                                    background-color: #f6f6f6;

                                }

                            }

                            &.brand-menu__inner-item--all {

                                display: none;

                            }

                        }

                    }

                    &:hover {

                        border-color: #2ac4f3;

                    }


                    &.active {

                        border-color: #2ac4f3;

                        > ul {

                            display: block;

                        }

                    }

                }

            }

        }

    }

    .hero-slider-container {

        .custom-slider-item {

            .custom-slider-item-inner {

							.back-to-partners {

								display: flex !important;

							}

                > .container {

                    text-align: center;
                    padding: 0 15px;

                    .custom-slider-item-title {

                        margin: 0 auto;
                        font-size: 55px;

                    }

                    .custom-slider-item-strapline {

                        margin: 30px auto;
                        font-size: 17px;
                        font-weight: 500;
                        padding-bottom: 60px;

												.button {

													margin-top: 60px;

												}

                    }

                }

            }

        }

    }

    // Phase 2 - New Design

    .custom-slider {

        .custom-slider-item-strapline {

            p {

                color: white;
								font-size: 20px !important;

            }

        }

    }

    .ways-you-can-benefit {

        &__container {

            background: #efefef;
            padding: 80px 62px 75px 62px;
            text-align: center;
            margin-bottom: 100px;

            @media all and (max-width: 991px) {

                padding: 35px;

            }

            h2 {

                font-weight: bold;
                margin-bottom: 75px;
                text-transform: capitalize;

                @media all and (max-width: 991px) {

                    margin-bottom: 35px;

                }

            }

        }

        &__items-container {

            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

        }

        &__item {

            flex-basis: 23%;
            max-width: 23%;
            background: white;
            box-shadow: 0 3px 11px 0 rgba(0, 0, 0, 0.13);
            border-radius: 3px;
            padding: 25px 25px 50px;

            @media all and (max-width: 991px) {

                flex-basis: 48%;
                min-width: 48%;
                margin-bottom: 20px;

            }

            @media all and (max-width: 600px) {

                flex-basis: 100%;
                min-width: 100%;

            }

        }

        &__image-container {

            margin-bottom: 20px;
            min-height: 110px;
            display: flex;
            align-items: center;

            img {

                max-width: 110px;
                width: 100%;
                height: auto;
                margin: 0 auto;

            }

        }

        &__title {

            margin-bottom: 20px;
            text-align: center;
            font-size: 20px;
            line-height: 32px;
            font-weight: bold;

        }

        &__content {

            text-align: center;
            font-size: 14px;
            line-height: 26px;

        }

    }

    .product {

        &__container {

            padding: 0 97px;
            margin-bottom: 207px;

            @media all and (max-width: 991px) {

                padding: 0 35px;

            }

            &__title {

                h2 {

                    font-weight: bold;
                    margin-bottom: 30px;

                }

            }

        }

        &__content__container {

            display: flex;
            justify-content: space-between;
            align-items: center;

            @media all and (max-width: 991px) {

                flex-wrap: wrap;

            }

        }

        &__content {

            &__list {

                width: 45%;

                @media all and (max-width: 991px) {

                    width: 100%;

                }

                ul {

                    padding: 0;
                    margin-bottom: 30px;
                    margin-left: 35px;

                    li {

                        list-style: none;
                        font-size: 15px;
                        font-weight: 500;
                        margin-bottom: 20px;
                        position: relative;

                        &:before {

                            content: '';
                            display: inline-block;
                            vertical-align: middle;
                            background-image: url("data:image/svg+xml,%3Csvg height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m18.7357432 7.12093254-9.15691975 9.15619256-4.57882345-4.5773691' fill='none' stroke='%2364B145' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
                            width: 20px;
                            height: 20px;
                            background-size: 100% 100%;
                            position: absolute;
                            left: -35px;

                        }

                    }

                }

            }

            &__text {

                margin-bottom: 30px;

                p {

                    font-weight: 500;

                }

            }

            &__slider {

                width: 45%;
                padding-top: 25px;

                @media all and (max-width: 991px) {

                    width: 60%;
                    margin: 0 auto;

                }

                .brand-product-carousel {

                    .owl-stage-outer {

                        padding: 0 !important;

                    }

                    .owl-controls {

                        .owl-nav {

                            .owl-prev,
                            .owl-next {

                                &:hover {

                                    background: none;

                                }

                                &:before,
                                &:after {

                                    border-top: 2px solid $light-grey !important;

                                }

                            }

                        }

                    }

                }

                &__item {

                    max-width: 300px;
                    margin: 0 auto;

                }

                &__image-container {

                    width: 300px;
                    height: 300px;
                    margin: 0 auto;
                    display: flex;
                    align-items: center;

                    img {

                        width: 100%;
                        height: auto;

                    }

                }

                &__name {

                    text-align: center;

                    p {

                        font-size: 15px;
                        font-weight: 500;
                        margin: 10px 0 0 0;

                    }

                    a {

                        font-size: 14px;
                        font-weight: 500;
                        color: $dte-bright-blue;
                        text-align: center;

                    }

                }

                // Nav changes
                .owl-theme .owl-controls .owl-nav [class*=owl-] {

                    height: 50px;

                    &:before,
                    &:after {

                        border-top: 1px solid #C7C7C7 !important;

                    }

                }

                .owl-theme .owl-controls .owl-nav {

                    .owl-prev {

                        top: 150px;
                        transform: translateY(-50%);
                        margin: 0;

                        &:before {

                            top: 33px;
                            left: 10px;
                            width: 25px;
                            transform: rotate(50deg);

                        }

                        &:after {

                            top: 14px;
                            left: 10px;
                            width: 25px;
                            transform: rotate(-50deg);

                        }

                    }

                    .owl-next {

                        top: 150px;
                        transform: translateY(-50%);
                        margin: 0;

                        &:before {

                            top: 14px;
                            left: 10px;
                            width: 25px;
                            transform: rotate(50deg);

                        }

                        &:after {

                            top: 33px;
                            left: 10px;
                            width: 25px;
                            transform: rotate(-50deg);

                        }

                    }

                }

            }

        }

    }

		// Specific brand page styles
		&.brand-view {

			&-fitbit {

				h2 ~ .hero-slider-categories {

					padding-top: 0 !important;

				}

				.hero-slider-categories {

					> .container {

						&::before {

							content: 'Shop the Fitbit Product Range';
							display: block;
							width: 100%;
							text-align: center;
							font-size: 25px;
							font-weight: bold;
							margin-bottom: 60px;

						}

					}

				}

				.category-description .container {

					margin: 0 auto !important;

				}

				.brand-view-menu-container {

					background: #008EAA;
					border: none;

					.brand-menu {

						border: none;

						&__list {

							display: flex;
							flex-wrap: wrap;
							align-items: center;
							justify-content: flex-start;

						}

						&__item {

							border: none !important;
							position: relative;

							.brand-menu__link {

									color: white !important;

									&::after {

										background-image: url("data:image/svg+xml,%3Csvg height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m7.92285156 6.96830426 7.49903224 7.49903224-7.49903224 7.4983052' fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' transform='matrix(0 1 -1 0 26.524727 .866875)'/%3E%3C/svg%3E") !important;
									}

							}

							.brand-menu__inner-list {

								background: white !important;
								box-shadow: 34px 26px 48px 0 rgba(0,0,0,0.35);
								padding: 15px 25px!important;

									.brand-menu__inner-item {

										a {

											font-size: 14px;
											font-weight: 550;
											color: $dte-blue;
											padding: 10px !important;

										}

									}

							}

						}

					}

				}

				.take-a-closer-look {

					.container > h2 {

						font-size: 25px;
						text-align: center;
						color: #002a3a;
						font-weight: bold;

					}

					.take-a-closer-look-tabs-container {

						.take-a-closer-look-tabs {

							.tab-item {

								color: #008EAA;
                background: rgba(185, 217, 235, 0.3);

								&.active,
								&:hover {

									background: #002A3A;
									color: white;

								}

							}

						}

					}

					.take-a-closer-look-content {

						background: #002A3A;

					}

				}

				table img[alt="comparison-table"] {

					max-width: 1250px;
					margin-top: 30px;
					margin-bottom: 50px;

				}

				table + .container {

					.info-block .row > div:first-of-type{

						@media all and (min-width: 768px) {

							order: 1;

						}

					}

				}

				.info-block {

					margin-bottom: 0;

					.col-img {

						transform:scaleX(-1);

					}

				}

				footer {

					margin-top: 0;

				}

				// Why fitbit Changes

				.our-solutions-container[data-solution-service-name="Why Fitbit?"] {

					> .container {

						> .container {

							// First block half-video specific changes
							> .container:first-of-type {

								padding: 70px;

								.col-info {

									background-color: transparent;

									.col-info-inner {

										padding: 50px 50px 50px 0;

									}

								}

							}

						}

					}

					.ways-you-can-benefit__container {

						margin: 0;
						padding: 75px 62px 112px 62px;

					}

				}

				// Get Motivated fitbit Changes
				.our-solutions-container[data-solution-service-name="Get Motivated"] {

					.info-block-video-right,
					.info-block-video-left {

						.col-video {

							background-color: transparent;

						}

						.col-info {

							background-color: transparent;

						}

					}

					// First Video
					>.container >.container:first-of-type .container .info-block-video-right {

						background: white;

					}

					>.container .container:nth-of-type(2) .info-block {

						background-color: rgba(185, 217, 235, 0.17);

					}


					>.container .container:nth-of-type(3) .info-block {

						background-color: rgba(185, 217, 235, 0.17);

					}

					>.container .container:nth-of-type(4) .info-block {

						.col-img {

							order: 1;

						}

						.col-info {

							background-color: white;

						}

					}




				}

				.ways-you-can-benefit {

					&__container {

                            background: rgba(185, 217, 235, 0.17);

							h2 {

								font-size: 0;
								text-transform: none;

								&::before {

									content: 'Experience Fitbit';
									display: block;
									width: 100%;
									text-align: center;
									font-size: 25px;
									font-weight: bold;
									margin-bottom: 12px;

								}

								&::after {

									content: 'Only Fitbit gives you the freedom to get fit your way.';
									display: block;
									width: 100%;
									text-align: center;
									font-size: 25px;
									font-weight: 400;

								}

							}

					}

				}

                // Root page specific changes

                &.brand-root-page {

									.hero-slider-container .custom-slider-item .custom-slider-item-inner {

										position: relative;

									}

                    .category-description {

                        > .container:last-of-type {

                            >.container:last-of-type {

                                .col-info  {

                                    background-color: white;

                                }

                            }

                        }

                    }

                }

			}

	}

    .take-a-closer-look {

        margin-bottom: 100px;

        .container {

            h2 {

                font-weight: bold;
                padding: 0 97px;
                margin-bottom: 55px;

                @media all and (max-width: 991px) {

                    padding: 0 35px;

                }

            }

        }

        &-tabs-container {

            position: relative;

            &:after {

                content: "";
                position: absolute;
                z-index: 1;
                width: 20%;
                top: 0;
                right: 0;
                height: 100%;
                background: linear-gradient(to left, white, rgba(0, 0, 0, 0));
                pointer-events: none;

            }

        }

        &-tabs {

            display: flex;
            padding: 0 97px;
            overflow-x: scroll;

            &::-webkit-scrollbar {
                display: none;
            }

            @media all and (max-width: 991px) {

                padding: 0 35px;

            }

            .tab-item {

                padding: 20px 50px;
                background: #f7f7f7;
                color: $dte-bright-blue;
                font-weight: bold;
                margin-right: 3px;
                border-top-left-radius: 3px;
                border-top-right-radius: 3px;
                font-size: 16px;
                cursor: pointer;
                min-width: 180px;
                transition: all ease-in-out 0.3s;

                &:not(.active) {

                    font-weight: 500;

                }

                &.active,
                &:focus,
                &:hover {

                    background: $dark-blue;
                    color: white;
                    z-index: 2;

                }

            }

        }

        &-content {

            background: $dark-blue;

            > div {

                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 40px 97px;


                @media all and (max-width: 991px) {

                    padding: 40px 35px;
                    flex-wrap: wrap;

                }

                .tab-content-left {

                    flex: 1;

                    @media all and (max-width: 991px) {

                        flex-basis: 100%;
                        margin-bottom: 30px;
                        text-align: center;

                    }

                    a {

                        &.button.button--transparent {

                            background: transparent;

                        }

                    }


                }

                .tab-content-right {

                    flex: 1;

                    @media all and (max-width: 991px) {

                        flex-basis: 100%;

                    }

                    iframe {

                        min-height: 400px;

                    }

                }

                h3, p {

                    color: white;
                    margin-bottom: 40px;

                }

                h3 {

                    font-weight: bold;

                }

                p {

                    max-width: 400px;
                    margin-right: 10px;

                    @media all and (max-width: 991px) {

                        margin-right: 0;
                        max-width: unset;

                    }

                }

            }

        }

    }

    .white-paper {

        &__downloads {

            padding: 0 97px;

            @media all and (max-width: 991px) {

                padding: 0;
            }

        }

        &-downloads__container {

            &:last-of-type {

                .white-paper__item {

                    border-bottom: 1px solid $border-input-grey;

                }

            }

        }

        &__title {

            font-size: 25px;
            font-weight: bold;
            margin-bottom: 30px

        }

        &__text {

            font-size: 15px;
            margin-bottom: 30px;
            max-width: 575px;

        }

        &__item {

            display: flex;
            padding: 30px 0;
            border-top: 1px solid $border-input-grey;
            line-height: 27px;

            @media all and (max-width: 991px) {

                flex-wrap: wrap;

            }


            &:not(:first-of-type) {

                border-top: none;

            }


            &__left-image {

                width: 30px;
                margin-right: 30px;

                @media all and (max-width: 991px) {

                    margin-right: 15px;

                }

            }

            &__title {

                width: 35%;
                margin-right: 10px;


                h3 {

                    font-size: 18px;
                    font-weight: 500;
                    margin: 0;

                }

                p {

                    font-style: 23px;
                    font-weight: bold;
                    color: $mid-grey;
                    margin: 0;

                }

            }

            &__summary {

                width: 40%;
                font-size: 15px;
                margin-right: 10px;

                @media all and (max-width: 991px) {

                    width: 45%;
                    margin: 0;

                }

            }

            &__download {

                width: 15%;
                margin: 0 auto;

                @media all and (max-width: 991px) {

                    width: 100%;
                    margin: 10px auto;

                }

            }

        }

    }

    .white-paper-downloads__container {

        ~ .white-paper-downloads__container {

            .white-paper__downloads {

                > .white-paper__title,
                > .white-paper__text {

                    display: none;

                }

            }

        }

    }

  // Removing P tag that is added from wysiwyg

  .category-description {

    > p {

      display: none;

    }

  }

  .hero-slider-container {

    .custom-slider-item {

      @media all and (max-width: 991px) {

        padding-top: 65px !important;
        padding-bottom: 65px !important;

      }

      .brand-thumbnail,
      .custom-slider-item-icon {

        position: absolute;
        right: 0;
        background: white;
        padding: 30px;
        width: 330px !important;
        max-height: unset !important;
        max-width: unset !important;
        min-height: 180px;
        display: flex;
        align-items: center;
        z-index: 2;
        top: 103px;

        img {

        	width: auto;
          // Fixes gulp err of mixing unit types
          max-width: calc(min(100%, 250px));

        }

        @media all and (max-width: 991px) {

          width: 150px !important;
          padding: 20px;
          bottom: 0;
					top: unset;
					min-height: 100px !important;

        }

      }

      .custom-slider-item-inner {

          max-width: 650px;

        > .container {

          text-align: left;
          padding-left: 107px;

          @media all and (max-width: 991px) {

            padding-left: 35px;

          }

          .back-to-partners {

            color: white;
            display: flex;
            align-items: center;
            text-decoration: none;
            margin-bottom: 16px;

            &:hover,
            &:focus {

              color: white;

            }

            &:before {


              display: flex;
              content: '\203A';
              align-items: center;
              color: white;
              font-size: 23px;
              transform: rotateY(180deg);
              margin-right: 10px;
              position: relative;
              top: -2px;

            }

          }

          .custom-slider-item-title {

            margin: 0 0 30px 0;
            text-align: left;
            font-weight: bold;
						margin-left: 0 !important;

          }

          .custom-slider-item-strapline {

            margin: 0 !important;
            text-align: left;
						max-width: 650px !important;

						p {

							text-align: left;

						}

          }

          .button {

            margin: 0;

          }

        }

      }

    }

  }

	.brand-view-menu-container {

		max-width: 1440px;
		padding: 0 80px;
		margin: 0 auto;

		@media all and (max-width: 991px) {

			padding: 0 60px;

		}

			.brand-menu {

					&__item {

							border-bottom: none;
							padding: 12px 40px;

							a {

									font-size: 18px;
									font-weight: 550;

							}

					}

			}

	}

	.category-description > .section-title:first-child {

		margin-top: 80px;
		margin-bottom: 50px;

	}

	.section-title {

		font-size: 25px;
		text-align: center;
		color: #002a3a;
		font-weight: bold;

	}

	.hero-slider-categories {

		max-width: 1440px;
		margin: 0 auto;
		padding: 80px 165px;


		.banner {

            &-item-image-link {

                margin-top: 40px;

            }

			&-title {

				color: $dte-blue;
				font-size: 16px;
				font-weight: 550;

			}

		}

		.owl-theme .owl-controls {

			.owl-nav {

				@media all and (max-width: 767px) {

					display: none;

				}

				.owl-prev,
				.owl-next {

					display: block !important;
					margin: 0;
					height: 50px;
					width: 50px;
					background: none;
					top: 33%;

					img {

						display: none;

					}

				}

				.owl-prev {

					left: -70px;

					&::before {

						top: 33px;
						left: 8px;
						border-top: 1px solid #c7c7c7 !important;
						position: absolute;
						z-index: 1;
						width: 30px;
						height: 1px;
						content: "";
						display: block;
						transform: rotate(50deg);
						border-radius: 20px;
						text-indent: 0;
						filter: none !important;

					}

					&::after {

						position: absolute;
						z-index: 1;
						height: 1px;
						content: "";
						display: block;
						border-radius: 20px;
						text-indent: 0;
						border-top: 1px solid #C7C7C7 !important;
						top: 10px;
						left: 8px;
						width: 30px;
						transform: rotate(-50deg);
						filter: none !important;

					}

				}

				.owl-next {

					right: -70px;

					&::before {

						top: 33px;
						border-top: 1px solid #c7c7c7 !important;
						position: absolute;
						z-index: 1;
						width: 30px;
						height: 1px;
						content: "";
						display: block;
						transform: rotate(-50deg);
						border-radius: 20px;
						text-indent: 0;

					}

					&::after {

						position: absolute;
						z-index: 1;
						height: 1px;
						content: "";
						display: block;
						border-radius: 20px;
						text-indent: 0;
						border-top: 1px solid #C7C7C7 !important;
						top: 10px;
						width: 30px;
						transform: rotate(50deg);

					}

				}

			}

		}

	}

	.custom-slider-item .banner-overlay {

		display: block !important;
		width: 100%;
    height: 100%;
    position: absolute;
		top: 0;
    filter: opacity(0.59);
		background: linear-gradient(100deg, #14242D 0%, rgba(20,36,45,0) 100%) !important;

	}

    .catalog-list__products {

        float: unset;
        width: 100%;

    }

}

