.solution-box-container {
    background-size: cover;
    background-position: center;
    text-align: center;
    width: 100%;
    padding-top: 100%;
    position: relative;
    margin-bottom: 30px;

    &.solution-box-container--service {

        background-image: url( '../images/backgrounds/services-bg-2x.png' );

    }

    &.solution-box-container--solution {

        background-image: url( '../images/backgrounds/solutions-bg-2x.png' );

    }

    .solution-box-inner {

        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;

        .solution-box-inner-vertical-holder {

            transform: translateY(-50%);
            position: relative;
            top: 50%;

        }

        a {
            display: block;
        }

        .solution-box--icon {

            width: 70px;
            height: auto;
            margin: 0 auto;

        }

        .solution-box--title {

            color: $white;
            margin: 12px auto 0;
            max-width: 80%;

        }

    }

}

@media all and (max-width: 767px) {

    .solution-box-container {

        background: #fff!important;
        padding: 15px 20px;
        margin-bottom: 15px;
        border: 1px $light-peach solid;
        border-radius: 5px;

        .solution-box-inner {

            text-align: left;
            position: static;

            .solution-box-inner-vertical-holder {

                position: static;
                transform: none;

            }

            .solution-box--title {

                color: $thunder;
                margin: 0;
                font-size: 14px;
                max-width: none;

            }

            .solution-box--icon {
                width: 42px;
            }

            .solution-box--icon-container,
            .solution-box--title-container
            {

                display: inline-block;
                margin: 0 auto;
                vertical-align: middle;

            }

            .solution-box--icon-container {
                margin-right: 15px;
            }

        }

    }

}