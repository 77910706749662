.owl-carousel {

    &.owl-theme {

        .owl-dots {

            position: relative;
            bottom: auto;
            margin: 20px 0 0;

            .owl-dot {

                span {

                    background-color: $light-grey-background;
                    border: none;

                }

                &.active {

                    span {
                        background-color: $mid-grey;
                    }

                }

                &:hover {
                    span {
                        background-color: $mid-grey;
                    }
                }

            }

        }

    }

    .owl-stage-outer {
        padding: 0;
    }

}

.hero-slider-container {
    max-width: 1440px;
    margin: 0 auto;

    .owl-carousel {

        &.owl-theme {

            .owl-dots {

                position: absolute;
                right: 15px;
                bottom: 50%;
                width: auto;
                transform: rotate(90deg) translate(0, -100%);
                margin: 0;

                .owl-dot {

                    span {

                        background-color: transparent;
                        border: 1px #fff solid;

                    }

                    &.active {

                        span {
                            background-color: #fff;
                        }

                    }

                    &:hover {

                        span {
                            background-color: #fff;
                        }

                    }

                }

            }

        }

    }
}

[class*=owl-carousel-products-].owl-theme .owl-dots {
    bottom: 0;
}

.hero-slider-container {

    .owl-carousel {

        .owl-stage-outer {
            padding: 0;
        }

    }

    .custom-slider-item {

        position: relative;
        padding-top: 60px;
        padding-bottom: 60px;
        background-position: center;
        background-size: cover;

        &.banner-type-2 {
            padding-top: 0;
            padding-bottom: 0;

            .banner-video {

                iframe {
                    height: 100%;
                    position: absolute;
                    width: 100%;
                    top: 0;
                    left: 0;
                }

            }
        }

        &.banner-type-1 {

            .banner-title {

                font-size: 33px;

            }

            .banner-description {

                font-size: 31px;
                font-weight: 400;
                margin: 0 0 40px;

            }

            .button {

                font-size: 13px;
                padding: 12px 30px;
                height: auto;

            }

        }

        .custom-slider-item-inner {

            z-index: 1;

        }

        .custom-slider-item-icon {
            margin: 0 auto;

            @media all and (min-width: 1024px) {

                margin-left: 6.5%;

            }
        }

        .custom-slider-item-title {

            color: $white;
            max-width: 725px;
            word-break: break-word;

        }


        .custom-slider-item-strapline {

            @media all and (max-width: 1024px) {

                text-align: center;

            }

        }


        .custom-slider-item-description {
            color: #f0f0f0;
            font-size: 18px;
        }

    }

}

@media all and (min-width: 768px) {

    .hero-slider-container {

        .custom-slider-item {

            .custom-slider-item-inner {

                .custom-slider-item-title,
                .custom-slider-item-strapline {

                    max-width: 725px;
                    margin-bottom: 50px;

                }

            }

        }

    }

}

@media all and (max-width: 1099px) {

    .hero-slider-container {

        .custom-slider-item {

            .custom-slider-item-inner {

                .container {
                    margin: 0 30px;
                }

            }

        }

    }

}

@media all and (max-width: 767px) {

    .hero-slider-container {

        .custom-slider-item {

            .custom-slider-item-inner {

                text-align: center;

                > .container {

                    > * {

                        &:last-child {
                            margin-bottom: 0;
                        }

                    }

                }

            }

            .custom-slider-item-icon {

                margin-left: auto;
                margin-right: auto;

            }

            .custom-slider-item-title {
                max-width: none;
            }

        }

        .owl-carousel {

            .owl-dots {

                position: static;
                margin: 15px 0 0

            }

        }

    }

}


.tab--services-and-solutions {

    .homepage-solutions-slider {

        padding: 0;

        @media all and (min-width: 768px) {
            padding: 0 130px;
        }

        .owl-item {

            text-align: center;

            .solutions-slider-item {

                padding: 15px 10px;

                @media all and (max-width: 768px) {
                    padding: 15px 50px;
                }

                .solutions-icon-wrapper {

                    img {

                        max-width: 100px;
                        margin: 0 auto;

                    }

                }

                .solutions-title-wrapper {

                    margin: 10px 0 20px;

                }

                .solutions-description-wrapper {

                    p {

                        font-size: 14px;

                    }

                }

                .solutions-actions-wrapper {

                    a {

                        height: 35px;
                        font-size: 13px;
                        text-transform: uppercase;
                        padding: 0;
                        width: auto;
                        background-color: transparent;
                        min-width: 88px;
                        margin-top: 10px;

                        &:hover {

                            background-color: $lipstick;

                        }

                    }

                }

            }

        }

        .owl-controls .owl-nav [class*=owl-] {

            &.owl-prev,
            &.owl-next {

                background-color: transparent;
                color: transparent;

                &:before,
                &:after {

                    border-color: #ccc;

                }

                &:hover {

                    background-color: #ebebeb;
                    color: transparent;

                    &:before,
                    &:after {

                        border-color: #2ac4f3;

                    }

                }

            }

        }

    }

}
