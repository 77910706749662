#footer-compare-container {

  $compare-darker-blue: #181d5b;

  .footer-compare-toggle {

    button {

      position: fixed;
      bottom: 18px;
      left: 15px;
      background-color: $color-primary;
      padding: 0;
      border-radius: 50%;
      color: #fff;
      font-family: "Montserrat", sans-serif;
      font-size: 15px;
      cursor: pointer;
      width: 60px;
      height: 60px;
      min-width: 0;
      z-index: 1;
      @extend %shadow--l1;

      .button__icon {

        margin-right: 0;
        font-weight: bold;

      }

      .compare-toggle-wrapper {

        position: relative;

        .compare-toggle-tooltip {

          display: none;
          opacity: 0;
          transition: 200ms opacity ease-in-out;
          position: absolute;
          right: auto;
          left: 100%;
          top: -50px;
          background-color: #fff;
          padding: 10px 20px;
          color: #000;
          border-radius: 25px 25px 25px 0;

        }

      }

    }

    &:hover {

      button {

        .compare-toggle-wrapper {

          .compare-toggle-tooltip {

            display: block;
            opacity: 1;

          }

        }

      }

    }

  }

  .footer-compare-inner {

    display: none;
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: $color-primary;
    padding: 15px 30px;

    // Apologies for this - needed to override tawk widget z-index
    z-index: 2000000001;

    h2 {

      color: #fff;
      display: none;

    }

  }

  #compare-items {

    display: block;
    width: 85%;
    float: left;
    margin: 0;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;

    > li {

      background-color: #fff;
      padding: 15px 40px 15px 15px;
      min-height: 113px;
      width: calc( 23% - 15px );
      margin: 15px 15px 15px 0;
      display: inline-block;
      box-sizing: border-box;
      position: relative;
      vertical-align: top;
      white-space: normal;
      min-width: 260px;
      border-radius: 3px;

      &.sidebar-block__item:before {

        content: none;

      }

      button {

        position: absolute;
        top: 0;
        right: 0;
        width: 40px;
        height: 40px;
        min-width: 0;
        padding: 0;
        margin-right: 0;

      }

      a.link {

        font-size: 14px;
        color: black;

      }

    }

    + .compare-actions {

      width: 15%;
      float: left;
      padding-left: 15px;

      > .button {

        width: 100%;
        max-width: 155px;
        margin: 15px 0 0 0;

      }

      .footer-compare-toggle-button {

        background-color: transparent;
        border: none;
        color: #fff;
        position: absolute;
        top: -10px;
        right: -25px;
        cursor: pointer;

      }

      @media all and ( max-width: 767px ) {

        display: block;
        float: none;
        width: 100%;
        padding: 0;

        > .button {

          max-width: none;

        }

      }

    }

    @media all and ( max-width: 767px ) {

      width: 100%;
      margin-top: 10px;

    }

  }

}