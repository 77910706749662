$header-button__size                : 48px !default;
$header-button__margin              : 0 !default;
$header-button__margin--extra-large : 0 0 0 $spacer--medium !default;
$header-button__padding             : 0 !default;
$header-button__label-display       : inline-block !default;
$header-button__background-color    : $white !default;

$header-button__icon-margin         : 0 0 2px 0 !default;
$header-button__icon-margin--medium : 0 0 4px 0 !default;
$header-button__icon-padding        : 0 0 4px 0 !default;
$header-button__icon-color          : $gray-darker !default;
$header-button__icon-color--hover   : $color-primary !default;
$header-button__transition          : $transition-base !default;

$header-button__text-font-family    : $font-family-base !default;
$header-button__text-font-color     : $gray !default;
$header-button__text-font-size      : 10px !default;
$header-button__text-line-height    : 10px !default;

$header-button__counter-top         : 0 !default;
$header-button__counter-top--medium : -2px !default;
$header-button__counter-right       : 14px !default;
$header-button__counter-font-size   : $font-size-extra-small !default;
$header-button__counter-background  : $color-primary !default;
$header-button__counter-color       : $white !default;
$header-button__counter-size        : 16px !default;
$header-button__counter-border      : none !default;
$header-button__counter-border-radius : 15px !default;
$header-button__counter-height      : 14px;
$header-button__counter-dot-size    : 2px !default;
$header-button__counter-dot-bg-color: $white !default;

.header-button {
    position: relative;
    height: $header-button__size;
    min-width: $header-button__size;
    display: flex;
    margin: $header-button__margin;
    padding: $header-button__padding;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: none;
    cursor: pointer;
    text-decoration: none;
    background-color: $header-button__background-color;
    font-family: $header-button__text-font-family;

    &:focus,
    &:hover {
        text-decoration: none;
        .header-button__icon {
            fill: $header-button__icon-color--hover;
        }
    }

    &--reset-margin {
        margin: 0;
    }

    &__icon {
        margin: $header-button__icon-margin;
        padding: $header-button__icon-padding;
        fill: $header-button__icon-color;
        transition: $header-button__transition;
        @include mq($screen-m) {
            margin: $header-button__icon-margin--medium;
        }
    }

    &__text {
        font-size: $header-button__text-font-size;
        line-height: $header-button__text-line-height;
        text-align: center;
        color: $header-button__text-font-color;
        display: $header-button__label-display;
    }

    &__counter {
        position: absolute;
        width: auto;
        border: $header-button__counter-border;
        border-radius: $header-button__counter-border-radius;
        text-align: center;
        color: $header-button__counter-color;
        background-color: $color-secondary;
        &--hidden {
            display: none;
        }
        border-radius: 15px;
        border: none;
        top: 12px;
        font-size: 12px;
        line-height: 18px;
        right : $header-button__counter-right;
        font-weight: bold;
        padding: 0 5px;
        min-width: 19px;
    }
}
