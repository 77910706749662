.amazon-welcome {

    background: #fff;
    padding: 30px;

    img {
        mix-blend-mode: multiply;
    }

    .welcome-sections {

        a {
            margin-right: 10px;
        }

    }

}