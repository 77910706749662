$pager__list-padding                        : $spacer !default;
$pager__item-margin                         : 0 $spacer!default;
$pager__item-after-height--current          : $spacer!default;
$pager__item-after-background-color--current: $color-primary!default;
$pager__item-after-transition--current      : $transition-base  !default;
$pager__item-size                           : 48px !default;
$pager__icon-width                          : 15px !default;
$pager__icon-height                         : 15px !default;
$pager__link-fill-disabled                  : $gray !default;
$pager__link-fill                           : $green !default;

.pager {

    width: 100%;
    text-align: center;

    &__list {

        display: flex;
        align-items: center;
        justify-content: center;
        list-style-type: none;
        padding: $pager__list-padding;
        margin: 0;

    }

    &__item {

        display: flex;
        align-items: center;
        justify-content: center;
        flex-basis: $pager__item-size;
        height: $pager__item-size;
        margin: $pager__item-margin;
        padding: 0;
        border: 1px $light-peach solid;

        &:first-child {

            margin-left: 0;
            border-top-left-radius: 3px;
            border-bottom-left-radius: 3px;

        }

        &:last-child {

            margin-right: 0;
            border-top-right-radius: 3px;
            border-bottom-right-radius: 3px;

        }

        &--current {

            position: relative;
            color: $dark-sky-blue;

        }

    }

    &__link {

        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: $pager__item-size;
        text-decoration: none;
        color: $dusty-grey;

        &[disabled] {

            fill: $pager__link-fill-disabled;
            pointer-events: none;
            cursor: default;

        }

        &--prev,
        &--next {

            fill: $pager__link-fill;

        }
    }

    &__icon {

        width: $pager__icon-width;
        height: $pager__icon-height;

    }

}
