.hero-slider-categories {

    text-align: center;

    .hero-slider-title {

        font-size: 36px;
        font-weight: 400;
        margin: 30px 0;

    }

    .banner-item {

        .banner-item-image-link {
            display: block;
        }

        .banner-item-content {

            margin: 15px 0;

        }

        .banner-image {

            width: 96px;
            height: 96px;
            text-align: center;
            margin: 0 auto;
            position: relative;

            img {

                max-width:100%;
                max-height:100%;
                height: auto;
                width: auto;
                transform: translate( -50%, -50% );
                position: absolute;
                top: 50%;
                left: 50%;

            }

        }

    }

    .owl-theme {

        .owl-dots {

            margin: 10px auto;
            position: static;

        }

    }

}

@media all and (min-width: 768px) {

    .hero-slider-categories {

        .owl-theme {

            .owl-controls {

                .owl-nav {

                    [class*=owl-] {

                        display: block;
                        top: 36%;
                        width: 48px;
                        height: 48px;
                        transition: filter 0.35s, background 0.35s;
                        background: none;

                        &:after,
                        &:before {

                            content: normal;
                            display: none;

                        }

                        &.owl-prev {
                            left: -70px;
                        }

                        &.owl-next {
                            right: -70px;
                        }

                        img {

                            height: 40px;
                            width: auto;
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);

                        }

                        &:hover {

                            background: none;
                            filter: brightness(10%);

                        }

                    }

                }

            }

        }

    }

}
@media all and (max-width: 1450px) {

    .hero-slider-categories {

        .owl-theme {

            .owl-controls {

                .owl-nav {

                    [class*=owl-] {

                        background: rgba( 0, 0, 0, 0.25 );
                        width: 30px;
                        height: 40px;

                        &.owl-prev {
                            left: -5px;
                        }

                        &.owl-next {
                            right: -5px;
                        }

                        img {
                            height: 25px;
                        }

                        &:hover {

                            background: rgba( 0, 0, 0, 0.5 );
                            filter: none;

                        }

                    }

                }

            }

        }

    }

}
@media all and ( max-width: 767px ) {

    .hero-slider-categories {

        .owl-theme {

            .owl-controls {

                .owl-nav {

                    [class*=owl-] {

                        &.owl-prev {

                            &:before,
                            &:after {
                                content:none;
                            }

                        }

                        &.owl-next {

                            &:before,
                            &:after {
                                content:none;
                            }

                        }

                        img {
                            width: auto;
                            position: absolute;
                            right: 50%;
                            top: 50%;
                            transform: translate(50%, -50%);
                        }

                    }

                }

            }

        }

    }

}

.hero-slider-container {
    max-width: 1440px;
    margin: 0 auto;
}
