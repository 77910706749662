// Add Category button specific styling here
.subcategory-links-wrapper {
    margin-bottom: 60px;

    .subcategory-links {
        display: flex;
        flex-wrap: wrap;
        gap: 1.5rem;
        justify-content: center;
        padding-left: 0;

        li {
            a {
                background-color: $blog-grey-light;
                padding: 1rem 3rem;
                color: $blog-body;
                font-weight: bold;
                text-decoration: none;
                transition: ease-in-out 0.2s;
                border-radius: 6px;
                display: inline-block;

                &:hover {
                    background-color: $blog-body;
                    color: white;
                }
            }
        }
    }
}
