.aw_rma-guest-index {



}

@media all and (max-width: 767px) {

    .aw_rma-guest-index {

        .aw-rma-new-guest {

            .block-guest-order,
            .block-customer-login
            {

                float: none;
                width: auto;

            }

            .fieldset {

                .field {

                    .control {

                        width: auto;

                    }

                }

            }

            .form {

                .actions-toolbar {

                    text-align: center;

                    .primary {

                        button {

                            display: block;
                            margin: 0;
                            width: 100%;
                            text-align: center;

                        }

                    }

                    .action.remind {

                        display: block;
                        margin: 10px 0 35px;

                    }

                }

            }

        }

    }

}
