$max-content-width: 1440px;
$container__x-padding: 15px;

$inner_container_width: 1312px;

$container__max-width: $max-content-width;
$container__margin   : 8px !default;

.container {
  max-width: $container__max-width;

  @media all and ( min-width: 768px ) {

    padding-left: 0px;
    padding-right: 0px;
  }

}

.row {
  margin-right: 0;
  margin-left: 0;
  width:100%;
}

.row-inner{
  max-width: $inner_container_width;
  margin-right:15px;
  margin-left:15px;
}
