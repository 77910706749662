.dte_bom-index-index {
    .title-wrapper {
        display:none
    }
    .breadcrumbs-container {
       display:flex;

      .breadcrumbs {

        &.dte-amazon {

          flex: 1 !important;

        }

      }

    }
    #maincontent {
        .row {
            .col-xs-12.col-md-8.col-lg-9 {
                flex-basis: 100%;
                max-width: 100%;
            }
        }


    }

    .dte-bom-container{

      margin-top:4rem;
      padding: 0 65px;


      @media all and (max-width: 534px) {
        text-align: center;
        margin: 0;
        background: white;
        padding: 0;
      }

      .dte-bom-inner {
        justify-content: space-between;

        @media all and (max-width: 992px) {
          justify-content: space-evenly;
        }

        .mobile-upload {
          display: none;

          @media all and (max-width: 534px) {
            display: block;
          }

          a {
            width: 190px;
            margin: 20px 0;
          }
        }

        .desktop-upload {
          @media all and (max-width: 534px) {
            display: none;
          }
        }
      }

    }

  .dte-bom-block{
    margin-bottom: 4em;

    @media all and (max-width: 534px) {
      margin-bottom: 0;
      width: 300px;
        p {
            margin-bottom: 28px;
        }
    }
    h3{
        font-size:18px;
        margin-bottom:20px;
        font-weight:bold;
    }

    p{
      font-size:17px;
      &.smaller{
        font-size:15px;
        padding-bottom: 20px;
      }
    }

  }

  #dte-bom-uploader-container {

      min-height: 349px;
      width: auto;
      border: 1px dashed #E0E0E0;
      border-radius: 22px;
      background-color: #FFFFFF;
      box-shadow: 0 7px 10px 0 rgba(0,0,0,0.12);

      @media all and (max-width: 767px) {
          height: auto;
          width: auto;

      }

    .dz-error-message{
      color:red;
    }

    #dte-bom-uploader {
      //position center all
      align-items: center;
      justify-content: center;
      text-align: center;
      height:100%;


      h4 {
        width:100%;
        font-size:20px;
        pointer-events:none;
      }

      p {
        width:100%;
        pointer-events:none;
      }

      a{
        &.button{
          min-width:143px;
        }
      }

      svg{
        max-height: 39px;
        margin: 60px 0 20px;

        cursor:pointer;
      }

    }

  }

}

.dte-bom-button-container{
  display:inline-block;
  @media all and (min-width: 768px) {
    float:right;
  }
  a.button{
    width:130px;
    height: 44px;
    margin-top: 8px;
  }
}
