.fotorama__nav__frame {
    @extend .fotorama-auto-margin;
    @extend .fotorama-focus;
    @extend .fotorama-inline-block;
    @extend .fotorama-no-tap;
    position: relative;
    padding: 0 !important; // sass-lint:disable-line no-important
    box-sizing: border-box;

    .fotorama__nav--dots & {
        width: 18px;
        height: 30px;
        &--thumb {
            display: none;
        }
    }
    .fotorama__nav--thumbs & {
        padding-left: 0 !important; // sass-lint:disable-line no-important

        &:last-child {
            padding-right: 0 !important; // sass-lint:disable-line no-important
        }

        &--dot {
            display: none;
        }
    }

    &.fotorama__active & {
        width: 0;
        height: 0;
        border-width: 3px;

        &:after {
            top: -3px;
            left: -3px;
            padding: 3px;
        }
    }

    &:focus {
        @extend .fotorama-focus-overlay;
        box-shadow: none;

        // TODO check this in magento/blank css
        :after {
            top: -1px;
            left: -1px;
            padding: 1px;
        }
    }

    .fotorama__nav-wrap--vertical .fotorama__nav__shaft &--thumb {
        display: block;
        padding-bottom: inherit !important; // sass-lint:disable-line no-important
    }
}
