$button__height: 48px !default;
$button__height-small: 40px !default;
$button__width: 48px !default;
$button__border-radius: 100px !default;
$button__outline: none !default;
$button__font-family: $font-family-base !default;
$button__line-height: 1.25 !default;
$button__font-weight: $font-weight-bold !default;
$button__font-size: $font-size-base !default;
$button__font-size-small: 12px !default;
$button__transition: $transition-base !default;

// Icon
$button__size--icon: 15px !default;

// Default
$button__padding: 0 $spacer--medium !default;
$button__padding-small: 0 $spacer !default;
$button__border: none !default;
$button__background: $color-primary !default;
$button__font-color: $black !default;
$button__background-hover: $gray-dark !default;
$button__border-hover: none !default;
$button__font-color-hover: $color-primary !default;
$button__background-focus: $color-primary !default;
$button__border-focus: none !default;
$button__font-color-focus: $gray-dark !default;
$button__background-active: darken($gray-dark, 25) !default;
$button__border-active: none !default;
$button__font-color-active: $color-primary !default;
$button__border-disabled: none !default;
$button__background-disabled: #aaa !default;
$button__font-color-disabled: $white !default;

// White
$button__padding--white: $button__padding !default;
$button__padding-small--white: $button__padding-small !default;
$button__border--white: none !default;
$button__background--white: $white !default;
$button__font-color--white: #ccc !default;
$button__background-hover--white: $white !default;
$button__border-hover--white: none !default;
$button__font-color-hover--white: #aaa !default;
$button__background-focus--white: $white !default;
$button__border-focus--white: none !default;
$button__font-color-focus--white: #aaa !default;
$button__background-active--white: $white !default;
$button__border-active--white: none !default;
$button__font-color-active--white: #aaa !default;
$button__border-disabled--white: none !default;
$button__background-disabled--white: #aaa !default;
$button__font-color-disabled--white: $white !default;

// Secondary
$button__padding--secondary: $button__padding !default;
$button__padding-small--secondary: $button__padding-small !default;
$button__border--secondary: #eee !default;
$button__background--secondary: $white !default;
$button__font-color--secondary: #ccc !default;
$button__background-hover--secondary: $white !default;
$button__border-hover--secondary: #eee !default;
$button__font-color-hover--secondary: #aaa !default;
$button__background-focus--secondary: $white !default;
$button__border-focus--secondary: #eee !default;
$button__font-color-focus--secondary: #aaa !default;
$button__background-active--secondary: $white !default;
$button__border-active--secondary: #eee !default;
$button__font-color-active--secondary: #aaa !default;
$button__border-disabled--secondary: none !default;
$button__background-disabled--secondary: #aaa !default;
$button__font-color-disabled--secondary: $white !default;

// Secondary White
$button__padding--secondary-white: $button__padding !default;
$button__padding-small--secondary-white: $button__padding-small !default;
$button__border--secondary-white: #eee !default;
$button__background--secondary-white: $white !default;
$button__font-color--secondary-white: #ccc !default;
$button__background-hover--secondary-white: $white !default;
$button__border-hover--secondary-white: #eee !default;
$button__font-color-hover--secondary-white: #aaa !default;
$button__background-focus--secondary-white: $white !default;
$button__border-focus--secondary-white: #eee !default;
$button__font-color-focus--secondary-white: #aaa !default;
$button__background-active--secondary-white: $white !default;
$button__border-active--secondary-white: #eee !default;
$button__font-color-active--secondary-white: #aaa !default;
$button__border-disabled--secondary-white: none !default;
$button__background-disabled--secondary-white: #aaa !default;
$button__font-color-disabled--secondary-white: $white !default;

// Button Label
$button__padding--label: $button__padding !default;
$button__padding-small--label: $button__padding-small !default;
$button__border--label: none !default;
$button__background--label: $white !default;
$button__font-color--label: $thunder !default;
$button__background-hover--label: $white !default;
$button__border-hover--label: $thunder !default;
$button__font-color-hover--label: $thunder !default;
$button__background-focus--label: $white !default;
$button__border-focus--label: $thunder !default;
$button__font-color-focus--label: $thunder !default;
$button__background-active--label: $white !default;
$button__border-active--label: $thunder !default;
$button__font-color-active--label: $thunder !default;
$button__border-disabled--label: none !default;
$button__background-disabled--label: #aaa !default;
$button__font-color-disabled--label: $white !default;

//fluid
$button__width--fluid: 100% !default;

.button {

    display: inline-flex;
    justify-content: center;
    cursor: pointer;
    height: $button__height;
    min-width: $button__width;
    padding: $button__padding;
    border: $button__border;
    border-radius: $button__border-radius;
    outline: $button__outline;
    background: $button__background;
    font-family: $button__font-family;
    font-size: $button__font-size;
    color: $button__font-color;
    transition: $button__transition;
    font-weight: $button__font-weight;
    line-height: $button__line-height;
    vertical-align: middle;
    align-items: center;
    text-align: center;

    .button__icon,
    .button__icon use
    {
        stroke: $button__font-color;
    }

    &--fluid {
        width: $button__width--fluid;
    }

    @include isIE() {
        align-items: center;
    }

    &--link {
        align-items: center;
        text-decoration: none;

        &:hover,
        &:focus {
            text-decoration: none;
            color: $button__font-color-hover;
        }
    }

    &--icon {
        align-items: center;
    }

    &__icon {

        width: $button__size--icon;
        height: $button__size--icon;
        transition: $button__transition;

    }

    &:hover:not(.button--transparent):not(.quantity-update__button) {

        //@extend %shadow--l3;

        background: $button__background-hover;
        border: $button__border-hover;
        color: $button__font-color-hover;

        .button__icon,
        .button__icon use
        .icon {
            stroke: $button__font-color-hover;

        }

    }

    &:focus {

        background: $button__background-focus;
        border: $button__border-focus;
        color: $button__font-color-focus;

        .button__icon,
        .button__icon use
        .icon {
            stroke: $button__font-color-focus;
        }

    }

    &:active {

        background: $button__background-active;
        border: $button__border-active;
        color: $button__font-color-active;

        .button__icon,
        .button__icon use
        .icon {
            stroke: $button__font-color-active;
        }

    }

    &--disabled,
    &:disabled {

        background: $button__background-disabled;
        border: $button__border-disabled;
        color: $button__font-color-disabled;

    }

    &--icon-left {

        .button__icon {

            margin-right: 10px;
            order: -1;

        }

    }

    &--icon-right {

        .button__icon {

            margin-left: 10px;
            order: 1;

        }

    }

    &--small {

        font-size: $button__font-size-small;
        padding: $button__padding-small;
        height: $button__height-small;

        &.button--icon-left {

            .button__icon {
                margin-right: 6px;
            }

        }

        &.button--icon-right {

            .button__icon {
                margin-left: 6px;
            }

        }

    }

    &--secondary {

        padding: $button__padding--secondary;
        border: $button__border--secondary;
        background: $button__background--secondary;
        color: $button__font-color--secondary;

        .button__icon,
        .button__icon use {
            stroke: $button__font-color--secondary;
        }

        &:hover:not(.button--transparent):not(.quantity-update__button) {

            //@extend %shadow--l3;

            background: $button__background-hover--secondary;
            border: $button__border-hover--secondary;
            color: $button__font-color-hover--secondary;

            .button__icon,
            .button__icon use,
            .icon {
                stroke: $button__font-color-hover--secondary;
            }

        }

        &:focus {

            background: $button__background-focus--secondary;
            border: $button__border-focus--secondary;
            color: $button__font-color-focus--secondary;

            .button__icon,
            .button__icon use,
            .icon {
                stroke: $button__font-color-focus--secondary;
            }

        }

        &:active {

            background: $button__background-active--secondary;
            border: $button__border-active--secondary;
            color: $button__font-color-active--secondary;

            .button__icon,
            .button__icon use,
            .icon {
                stroke: $button__font-color-active--secondary;
            }

        }

        &:disabled {

            background: $button__background-disabled--secondary;
            border: $button__border-disabled--secondary;
            color: $button__font-color-disabled--secondary;

        }

        &.button--small {
            padding: $button__padding-small--secondary;
        }

    }

    &--green {

        padding: $button__padding--green;
        border: $button__border--green;
        background: $button__background--green;
        color: $button__font-color--green;

        .button__icon,
        .button__icon use {
            stroke: $button__font-color--green;
        }

        &:hover:not(.button--transparent)  {

            //@extend %shadow--l3;

            background: $button__background-hover--green;
            border: $button__border-hover--green;
            color: $button__font-color-hover--green;

            .button__icon,
            .button__icon use,
            .icon {
                stroke: $button__font-color-hover--green;
            }

        }

        &:focus {

            background: $button__background-focus--green;
            border: $button__border-focus--green;
            color: $button__font-color-focus--green;

            .button__icon,
            .button__icon use,
            .icon {
                stroke: $button__font-color-focus--green;
            }

        }

        &:active {

            background: $button__background-active--green;
            border: $button__border-active--green;
            color: $button__font-color-active--green;

            .button__icon,
            .button__icon use,
            .icon {
                stroke: $button__font-color-active--green;
            }

        }

        &:disabled {

            background: $button__background-disabled--green;
            border: $button__border-disabled--green;
            color: $button__font-color-disabled--green;

        }

        &.button--small {
            padding: $button__padding-small--green;
        }

    }

    &--white {

        padding: $button__padding--white;
        border: $button__border--white;
        background: $button__background--white;
        color: $button__font-color--white;

        .button__icon,
        .button__icon use {
            stroke: $button__font-color--white;
        }

        &:hover {

            //@extend %shadow--l3;

            background: $button__background-hover--white;
            border: $button__border-hover--white;
            color: $button__font-color-hover--white;

            .button__icon,
            .button__icon use,
            .icon {
                stroke: $button__font-color-hover--white;
            }

        }

        &:focus {

            background: $button__background-focus--white;
            border: $button__border-focus--white;
            color: $button__font-color-focus--white;

            .button__icon,
            .button__icon use,
            .icon {
                stroke: $button__font-color-focus--white;
            }

        }

        &:active {

            background: $button__background-active--white;
            border: $button__border-active--white;
            color: $button__font-color-active--white;

            .button__icon,
            .button__icon use,
            .icon {
                stroke: $button__font-color-active--white;
            }

        }

        &:disabled {

            background: $button__background-disabled--white;
            border: $button__border-disabled--white;
            color: $button__font-color-disabled--white;

        }

        &.button--small {
            padding: $button__padding-small--white;
        }

    }


    &--secondary-white {

        padding: $button__padding--secondary-white;
        border: $button__border--secondary-white;
        background: $button__background--secondary-white;
        color: $button__font-color--secondary-white;

        .button__icon,
        .button__icon use {
            stroke: $button__font-color--secondary-white;
        }

        &:hover {

            //@extend %shadow--l3;

            background: $button__background-hover--secondary-white;
            border: $button__border-hover--secondary-white;
            color: $button__font-color-hover--secondary-white;

            .button__icon,
            .button__icon use,
            .icon {
                stroke: $button__font-color-hover--secondary-white;
            }

        }

        &:focus {

            background: $button__background-focus--secondary-white;
            border: $button__border-focus--secondary-white;
            color: $button__font-color-focus--secondary-white;

            .button__icon,
            .button__icon use,
            .icon {
                stroke: $button__font-color-focus--secondary-white;
            }

        }

        &:active {

            background: $button__background-active--secondary-white;
            border: $button__border-active--secondary-white;
            color: $button__font-color-active--secondary-white;

            .button__icon,
            .button__icon use,
            .icon {
                stroke: $button__font-color-active--secondary-white;
            }

        }

        &:disabled {

            background: $button__background-disabled--secondary-white;
            border: $button__border-disabled--secondary-white;
            color: $button__font-color-disabled--secondary-white;

        }

        &.button--small {
            padding: $button__padding-small--secondary-white;
        }

    }

    &--label {

        padding: $button__padding--label;
        border: $button__border--label;
        background: $button__background--label;
        color: $button__font-color--label;
        min-width: 0;

        .button__icon,
        .button__icon use{
            stroke: $button__font-color--label;
        }

        &:hover {

            background: $button__background-hover--label;
            border: $button__border-hover--label;
            box-shadow: none!important;
            color: $button__font-color-hover--label;

            .button__icon,
            .button__icon use,
            .icon {
                stroke: $button__font-color-hover--label;
            }

        }

        &:focus {

            background: $button__background-focus--label;
            border: $button__border-focus--label;
            color: $button__font-color-focus--label;

            .button__icon,
            .icon {
                stroke: $button__font-color-focus--label;
            }

        }

        &:active {

            background: $button__background-active--label;
            border: $button__border-active--label;
            color: $button__font-color-active--label;

            .button__icon,
            .button__icon use,
            .icon {
                stroke: $button__font-color-active--label;
            }

        }

        &:disabled {

            background: $button__background-disabled--label;
            border: $button__border-disabled--label;
            color: $button__font-color-disabled--label;

        }

        &.button--small {
            padding: $button__padding-small--label;
        }

    }

    &.button--transparent{
        background: rgba(255, 255, 255, 0.14);
        border: 1px solid white;
        color: white;

        &:hover {
            background: rgba(255, 255, 255, 0.4);
            border: 1px solid white;
        }

        .button__icon{
          use{
              stroke:#231f20;
          }
        }
    }

}

.button__text-mobile {
    display: inline;
}

.button__text-desktop {
    display: none!important;
}

a.button {

    &:hover {
        text-decoration: none;
    }

    // Redo

}

@media all and (max-width: 767px) {

    /*.button {

        .button__text-mobile {
            display: inline;
        }

        .button__text-desktop {
            display: none;
        }

    }*/

}
