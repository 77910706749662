.checkout-cart-index {

    #maincontent {

        min-height: 800px;

        @media all and (max-width: 991px) {
            margin-top: 0 !important;
        }

        .heading--page {
            margin: 60px 0 15px;
        }

        .cart-list-item {

            &__image {
                @media all and (max-width: 991px) {
                    max-width: 200px;
                }

                @media all and (max-width: 550px) {
                    display: none;
                }
            }

            &__details {
                margin: 0;
                justify-content: flex-end;
            }

            .product-cart-item-sku-container {

                p.cart-item-element {

                    font-size: 13px;
                    text-transform: uppercase;
                    color: #949494;
                    margin: 10px 0;
                    display: inline-block;

                    strong {

                        font-weight: 600;

                    }

                    &.stock-status {

                        text-transform: none;
                        font-weight: bold;
                        margin-left: 30px;

                        &.in-stock {

                            color: $green;

                        }

                        &.out-of-stock {

                            color: $dte-red;

                        }

                    }

                }

            }

            .cart-list-item__actions {

                display: flex;
                justify-content: flex-start;
                align-items: center;

                .develo-update-cart-button {

                    width: auto;
                    min-width: 0;
                    height: auto;
                    padding: 11px;
                    border: none;
                    background-color: #fff;
                    color: $dte-bright-blue;
                    font-size: 13px;
                    cursor: pointer;

                }

                .cart-price {

                    font-weight: 600;
                    font-size: 15px;

                }

                .cart-list-item__qty {

                    margin: 0 40px 0 55px;

                    @media all and (max-width: 767px) {
                        width: auto;
                        margin: 0 20px !important;
                    }
                }

                .cart-list-item__actions-item-edit {

                    display: none;

                }

                .cart-list-item__actions-item-remove {

                    border: none;
                    background-color: #fff;

                    @media all and (max-width: 767px) {

                        margin-left: auto;

                    }

                    &::before {

                        content: "Remove";
                        color: $dte-bright-blue;
                        font-weight: normal;

                    }

                    svg {

                        display: none;

                    }

                }

            }

            .cart-list-item__qty-wrapper {

                display: flex;
                height: 30px;

                .cart-list-item__qty-input {

                    height: 30px;
                    padding: 0;
                    border-top: 1px solid #ddd;
                    border-bottom: 1px solid #ddd;
                    border-radius: 0;
                    font-size: 13px;

                }

                button.qty-update-btn {

                    border: none;
                    background-color: #fff;
                    $cart-item-qty-border-radius: 4px;

                    &.qty-update-decrease {

                        border: 1px solid #ddd;
                        border-right: none;
                        border-radius: $cart-item-qty-border-radius 0 0 $cart-item-qty-border-radius;

                    }

                    &.qty-update-increase {

                        border: 1px solid #ddd;
                        border-left: none;
                        border-radius: 0 $cart-item-qty-border-radius $cart-item-qty-border-radius 0;

                    }

                }

            }

            &:nth-last-child(2) {

                margin-bottom: 20px;

            }

            .cart-item-sku{
                float: left;
            }

        }

        .cart-list-item__qty-input {
            text-align: center;
        }

        .cart-list-item__actions-item {

            width: auto;
            min-width: 0;
            height: auto;
            padding: 11px;

            .button__icon {

                width: 13px;
                height: 13px;

            }

        }

        .cart-list-item__name {

            font-size: 17px;
            font-weight: 500;

        }

        .cart-list-item__price {

            font-weight: 600;
            font-size: 15px;

        }

        @media (min-width: 768px) {

            .cart-list-item__details {

                align-items: end;

            }

        }

        @media (min-width: 768px) {

            .cart-actions__button {

                font-size: 16px;
                padding: 11px 19px;
                height: 40px;
                width: auto;
                min-width: 0;

            }

        }

        > .title-wrapper {

            display: none;

        }

        .column.main > .cart-empty {

            max-width: 1440px;
            margin: 0 auto;
            padding: 0 16px;

        }

    }

    .cart__products-list {

        border-top: none;

        .cart-item-count-summary {

            border-bottom: 1px solid #c2c1df;
            padding-bottom: 15px;

            @media all and (max-width: 991px) {
                padding-top: 15px;

                h2 {
                    margin: 0;
                }
            }

            h2 {

                font-size: 18px;
                font-weight: bold;

            }

        }

    }

    #shopping-cart-table {

        .cart-secondary-summary-container {

            display: flex;
            justify-content: flex-end;
            padding: 25px 10px;
            font-size: 15px;
            align-items: baseline;

            .secondary-summary-label {
            }

            .secondary-summary-value {

                min-width: 110px;
                text-align: right;
                font-size: 20px;
                font-weight: bold;

            }

        }

    }

    .cart-checkout-fixed-bar {

        position: relative;
        z-index: 999;
        background: $white;
        box-shadow: 0px 3px 5px 0px rgba(200,200,200,0.5);

        .cart-checkout-fixed-bar--inner {

            position: relative;
            padding: 15px 30px;

        }

        .basket-total {

            display: inline-block;
            text-align: center;

            &--title {

                margin: 0;

            }

            &--amount {

                margin: 0;
                color: #2f338a;
                font-weight: 700;
                font-size: 14px;

            }

        }

        .action.primary {

            position: absolute;
            top: 50%;
            right: 15px;
            transform: translateY(-50%);
            margin: 0;
            width: auto;
            min-width: 160px;

        }

    }

    .paypal-express-in-context-mini-cart-container img{
        width: 100%;
        margin: auto;
    }

    .cart-summary .stripe-payments-express-minicart{
        margin-top: 5px;
        margin-bottom: 15px;
    }

    .message {
        margin: 16px 0;
    }

    .block.crosssell {

        //Replaced with popular products component below
        display: none;

    }

    .popular-products-container {

        position: relative;
        border: 1px solid #e0e0e0;
        max-width: 550px;
        margin-left: 45px;

        @media all and (max-width: 1129px) {

            margin-left: 15px;

        }

        @media all and (max-width: 991px) {

            margin: 0 auto;

        }

        .popular-product-container {

          padding: 70px 25px 25px;

          .popular-product-image {

            width: 250px;
            max-width: 100%;
            margin: 0 auto;

            img {

              width: auto !important;
              height: 235px;
              margin: 0 auto;

            }

          }

          h2.product-name {

            font-size: 18px;
            font-weight: 500;
            margin-top: 15px;
            padding-top: 0;
            width: 95%;

          }

          .product-sku-container {

            color: #949494;
            margin: 17px 0;
            font-size: 13px;

          }

          .price-box {

            .price-container {

              span.price {

                font-size: 28px;
                display: inline-block;

              }

            }

          }

          .product-bottom-section-container {

            display: flex;
            align-items: center;

            .product-price-container {
                width: 190px;
            }

            .product-stock-container {

                margin-left: 20px;

              .stock-status-inner {

                display: flex;
                align-items: center;
                color: #19B823;

                img, svg {

                  width: 20px;
                  margin-right: 5px;

                }

              }

            }

            .product-wishlist-container {

              margin-left: auto;

            }

            @media all and (min: 1024px) and (max: 1100x) {

              flex-wrap: wrap;

              .product-price-container {

                order: 1;

              }

              .product-stock-container {

                order: 3;

              }

              .product-wishlist-container {

                order: 2;

              }

            }


          }

          .price-wrapper {

            font-size: 26px;
            font-weight: 600;

            span {

              display: block; // required for first letter

            }

            ::after {

              content: 'ex VAT';
              display: inline;
              width: 100%;
              height: 100%;
              font-weight: normal;
              font-size: 16px;
              margin-left: 5px;

            }

          }

        }

        .popular-products-title {

          position: absolute;
          top: 0;
          z-index: 1;
          padding: 20px 30px;

          h5 {

            font-size: 14px;
            font-weight: 600;
            color: #949494;

          }

        }

        .owl-dots {

          position: absolute;
          top: 0;
          right: 30px;
          width: auto;

          .owl-dot {

            > span {

              background-color: #efefef;
              border-color: #efefef;
              width: 8px;
              height: 8px;

            }

            &:hover {

              > span {

                background-color: #949494;

              }

            }

            &.active {

              > span {

                background-color: #949494;
                border-color: #949494;

              }

            }

          }

        }

        .owl-stage-outer {

          padding: 0;

        }

      }
}

.cart-summary {

    background-color: #fff;
    -webkit-box-shadow: 0 2px 10px 2px rgba(204,204,204,0.58);
    -moz-box-shadow: 0 2px 10px 2px rgba(204,204,204,0.58);
    box-shadow: 0 2px 10px 2px rgba(204,204,204,0.58);

    .shipping-estimation.cart-summary__item {

        .shipping-estimation__title {

            .shipping-estimation__title-icon {

                font-size: 24px;
                font-weight: bold;
                transform: none;
                display: flex;
                align-items: center;


            }

        }

        &.active {

            .plus {

                display: none;

            }

        }

        &:not(.active) {

            .minus {

                display: none;

            }

        }

    }

    .shipping-estimation__title-text {

        font-size: 15px;

    }
    .shipping-estimation__title-icon-minus,
    .shipping-estimation__title-icon {

        width: 15px;

    }

    .paypal-express-in-context-mini-cart-container::before {

        content: none!important;

    }

    .cart-summary__item {

        h2 {

            font-size: 18px;
            font-weight: bold;

        }

        .cart-summary__checkout-button {

            font-size: 15px;

        }

    }

    .cart__bottom {

        border-top: none;

        #block-discount {

            margin: 10px 0 0;
            padding: 5px;

            .discount__title {

                display: none;

            }

            .discount__content {

                display: block!important;

            }

            .discount__controls {

                display: flex;
                justify-content: space-between;
                margin: 0;

                .discount__input {

                    width: 67%;

                    input::placeholder {

                        color: #666;
                        font-size: 13px;

                    }

                }

                .discount__button {

                    height: 44px;
                    max-width: 30%;

                }

            }

        }

    }

}

.cart-container {
    justify-content: space-between;
    > div {

        &.col-lg-8 {

            flex-basis: 69%;
            max-width: 880px;

          @media all and (min-width: 992px) and (max-width: 1129px) {

            flex-basis: 59%;
            max-width: 59%;

          }

        }

        &.col-lg-4 {

            flex-basis: 31%;
            max-width: 31%;

          @media all and (min-width: 992px) and (max-width: 1129px) {

            flex-basis: 41%;
            max-width: 41%;

          }

        }

        @media all and ( max-width: 991px ) {

            &.col-lg-4,
            &.col-lg-8 {

                flex-basis: 100%;
                max-width: 100%;

            }

        }

    }

    @media all and ( min-width: 768px ) {

        padding: 0 30px;

    }

}

#block-shipping {

    border: none;

}

@media (max-width: 1129px) {

    .checkout-cart-index {

        .cart-checkout-fixed-bar {
            top: 56px;
        }

    }

}

@media (min-width: 768px) {

    .checkout-cart-index {

        #maincontent {

            margin-top: 55px;

            .cart-actions__button {

                font-size: 16px;
                padding: 11px 19px;
                height: 40px;
                width: auto;
                min-width: 0;

            }

        }

    }

}

@media all and (max-width: 991px) {

    .paypal.checkout {

      max-width: 450px !important;
      text-align: center;

    }

}

@media all and (max-width: 1129px) {

  .row.cart-container {

    padding: 0

  }

}

/* Fixing weird FF bug for JS prices leaking out doc */
.cart-totals__row.cart-totals__row--summary {
    @media all and (max-width: 390px),
           all and (min-width: 1130px) and (max-width: 1200px) {
        flex-wrap: wrap;
    }
    .amount.cart-totals__row-value.cart-totals__row-value--total {
        min-width: 110px;
        text-align: right;

        @media all and (max-width: 390px),
               all and (min-width: 1130px) and (max-width: 1200px) {
            width: 100%;
            text-align: left;
            margin-top: 5px;
        }
    }
}
