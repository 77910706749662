$active-filters__remove-size         : 48px !default;
$active-filters__remove-padding      : 0 !default;
$active-filters__remove-border       : none !default;
$active-filters__remove-background   : none !default;
$active-filters__remove-icon-margin  : 0 auto !default;
$active-filters__remove-icon-padding : 0 !default;
$active-filters__icon-fill           : $red !default;
$active-filters__icon-size           : $font-size-base !default;
$active-filters__label-font-weight   : $font-weight-bold !default;
$active-filters__clear-border-size   : 2px !default;
$active-filters__clear-border        : $active-filters__clear-border-size solid $red !default;
$active-filters__clear-border-radius : 24px !default;
$active-filters__clear-margin        : 0 0 0 $spacer--extra-large !default;
$active-filters__clear-height        : 48px !default;
$active-filters__clear-line-height   : ($active-filters__clear-height - (2 * $active-filters__clear-border-size));
$active-filters__clear-font-size     : $font-size-base !default;
$active-filters__clear-color         : $red !default;
$active-filters__clear-font-weight   : $font-weight-bold !default;
$active-filters__clear-color\@medium : $color-secondary !default;

.filters__active {
    margin: 15px 0;
}

.active-filters {

    &.list {
        margin: 15px 0;
    }

    &__item {

        display: flex;
        align-items: center;
        padding: 7px 0;

    }

    &__remove {
        display: flex;
        align-items: center;
        min-width: $active-filters__remove-size;
        padding: $active-filters__remove-padding;
        border: $active-filters__remove-border;
        outline: none;
        background: $active-filters__remove-background;
        cursor: pointer;
        word-break: break-all;
    }

    &__remove-icon {
        display: block;
        padding: $active-filters__remove-icon-padding;
        margin: $active-filters__remove-icon-margin;
        width: $active-filters__icon-size;
        height: $active-filters__icon-size;
        fill: $active-filters__icon-fill;
    }

    &__label {
        max-width: calc(100% - #{$active-filters__remove-size});
        word-break: break-all;
        font-weight: $active-filters__label-font-weight;
    }

    &__value {
        max-width: calc(100% - #{$active-filters__remove-size});
        word-break: break-all;
    }

    &__clear-all {

        margin: 20px 15px;
        background: transparent;

    }

}

.algolia-filter-open{
    position: fixed;
    top: 215px;
    height: 100%;
    width: 100%;
    background-color: #fff;
}
.emulate-mobile-scroll{
    .algolia-filter-open{
        top: 110px;
    }
}
