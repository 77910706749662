%shadow--l1 {
    box-shadow: 1px 2px 5px 0 rgba(65, 64, 64, 0.37);
}

%shadow--l2 {
    box-shadow: 1px 3px 7px 0 rgba(65, 64, 64, 0.37);
}

%shadow--l3 {
    box-shadow: 0 3px 9px 1px rgba(65, 64, 64, 0.32);
}

%shadow--l4 {
    box-shadow: 0 4px 11px 1px rgba(65, 64, 64, 0.32);
}

%shadow--l5 {
    box-shadow: 2px 3px 14px 3px rgba(65, 64, 64, 0.29);
}

.shadow {

    &--l1 {
        @extend %shadow--l1;
    }

    &--l2 {
        @extend %shadow--l2;
    }

    &--l3 {
        @extend %shadow--l3;
    }

    &--l4 {
        @extend %shadow--l4;
    }

    &--l5 {
        @extend %shadow--l5;
    }

}
