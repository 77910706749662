body.wishlist-index-index {

  .actions-toolbar {

    margin-bottom: 30px;

    .button.action__button.share {

      svg {

        display: none;

      }

    }

    .action.back {

      display: none;

    }

  }
  .wishlist-toolbar .toolbar{
      width: 100%;
  }

  .wishlist__grid {

    .wishlist__item {

      &.catalog-grid-item {

        > div {

          border-radius: 0;
          padding: 7px 0 0;

        }

          &.__price {

              margin-top: 5px;

          }

      }

        .catalog-grid-item__details-inner {

            margin-top: 5px;

        }

      .catalog-grid-item-inner {

        border: none;
        padding-bottom: 190px;

        .catalog-grid-item__secondary-action {
          .develo-product-more-info-popup-toggle {
            float:left;
          }
        }

        .quantity-update-container {

          margin-right: 5px;

          input,
          .quantity-update__button {

            padding-top: 8px;
            height: 32px;

            &--minus {

              padding-left: 3px;
              padding-right: 0;

            }

            &--plus {

              padding-left: 0px;
              padding-right: 3px;

            }

            svg {

              width: 14px;
              height: 14px;

            }

          }

          input {

            width: 58px;
            padding: 0;

          }

          button.action__button.update {

            height: 32px;
            font-size: 15px;
            margin-top: 5px;
            width: auto;
            padding: 8px 0;

          }

        }

        .catalog-grid-item__actions {

          .catalog-grid-item__primary-form {

            justify-content: space-between;

          }

        }

      }

      img.product-image-photo {

        height: 150px;
        width: auto;
        margin: 15px auto;

      }

      .wishlist__item-actions {

        text-align: right;

        .wishlist__remove.button--icon {

          background-color: #fff;
          padding: 0;
          transition: all 200ms ease-in-out;

          &:hover {

            box-shadow: none;
            transform: scale(1.1);

          }

          svg.button__icon {

            width: 24px;
            height: 24px;

          }

        }

      }

      .wishlist__item-name .wishlist__item-name-link {

        min-height: 0;
        height: auto;
        font-size: 16px;
        color: $thunder;

      }

      .wishlist__comment {

        margin: 20px 0 0;

        .input__label {

          display: none;

        }

        .input__field--textarea {

          min-height: 42px;
          height: auto;

        }

      }

      .price-box {

        .price-wrapper {

          font-size: 23px;

        }

      }

      .wishlist__item-inner {

        margin-top: 15px;
        flex-direction: row-reverse;

        .quantity-update-container {

          flex-basis: 25%;

          button.quantity-update__button,
          input.wishlist__field-qty {

            height: 35px;
            padding: 9px 5px 10px;
            font-size: 14px;

            svg {

              height: 16px;
              width: 16px;

            }

          }

          .action__button.update {

            padding: 0;
            border: none;
            font-size: 14px;
            width: 100%;
            min-width: 0;
            height: auto;
            margin-top: 2px;
            color: blue;

            &:hover {

              box-shadow: none;
              background-color: #fff;
              text-decoration: underline;

            }

          }

        }

        .wishlist__item-actions--to-cart {

          flex-basis: 55%;
          padding-top: 0;

        }

        .dte-add-toquote-button {

          height: 48px;
          font-size: 18px;
          padding: 11px 20px;

        }

      }

      > div {

        height: 100%;

      }

        // Fixes for adding realtime info onto wishlist grid items

        .stock-status-inner {

            display: flex;
            align-items: center;
            font-weight: bold;

            &.stock-qty-in-stock {

                color: #46c026;
                margin-bottom: 5px;

            }

            &.stock-qty-oos {

                height: 20px;

            }

            img {

                max-width: 20px;
                margin-right: 5px;

            }

            // OOS
            svg {

                margin-right: 5px;
                width: 20px;
                height: 20px;

            }

        }

        .product-price-container.price-wrapper {

            span[itemprop='lowPrice'] {

                &::after {

                    display: inline;
                    content: 'Ex. VAT';
                    font-size: 13px;
                    margin-left: 5px;
                }

            }

        }

        .catalog-grid-item__brand {

          display: flex;

          .attributepages-option-image {

            position: static;

          }

        }

    }

  }

  .action.wishlist__action {

    padding-top: 0;

    > .action__handler {

      width: 100%;
      text-align: right;

      > .action__button.share {

        margin-right: 0;

      }

      > .action__button.update,
      > .action__link.back {

        display: none;

      }

    }

  }

}
// hide Ex VAT for amazon FC only

body.amazon-store.wishlist-index-index .wishlist__grid .wishlist__item .product-price-container.price-wrapper span[itemprop='lowPrice']::after {
  content: '';

}
