.cms-shop {

  #maincontent {

    > .columns {

      margin: 0 30px;

      @media all and (max-width: 500px) {

        margin: 0 auto;

      }

    }

  }

    .hero-slider-container {

        .custom-slider-item {

            &.banner-type-1 {

                .banner-title {

                    font-size: 36px;

                }

                .banner-description {

                    font-size: 36px;
                    font-weight: 300;

                }

                .button {

                    padding: 14px 39px;
                    font-size: 14px;

                }

            }

        }

    }

  .owl-carousel {

    .owl-stage {

      a {

        h5 {

          font-weight: bold;
          color: #2e3687;
          font-size: 14px;

        }

      }

    }

  }

  .shop-page-content {

    img {

      width: 100%;
      height: auto;

    }

    .fitbit-container {

      display: flex;



      .right-section-container{
        width: 40%;
      }

      .left-block {

        width: 40%;
        position: relative;
        padding-right: 30px;

        @media all and (max-width: 1023px) {

          flex-basis: 100%;
          padding: 0;
          margin: 10px 0;

          .right-section-container{
            width: 100%;
          }

        }

        .block-container {

          position: relative;
          height: 100%;
          background-repeat: no-repeat;
          background-size: cover;

          img {

            opacity: 0;

            @media all and (max-width: 1023px) {

              max-width: 800px;

            }

          }

          .content-container {

            position: absolute;
            top: 50px;
            left: 65px;

            @media all and (max-width: 630px) {

              top: 20px;
              left: 30px;

            }

            .content-title {

              font-size: 50px;
              margin-bottom: 20px;

              @media all and (max-width: 630px) {

                font-size: 45px;
                margin-bottom: 5px;

              }

              @media all and (max-width: 500px) {
                font-size: 25px;
              }

            }

            .content-text {

              font-size: 15px;
              line-height: 30px;
              max-width: 240px;

              @media all and (max-width: 630px) {

                line-height: 24px;

              }

              @media all and (max-width: 500px) {

                font-size: 13px;

              }

            }

          }

          .content-button {

            position: absolute;
            bottom: 50px;
            left: 65px;

            @media all and (max-width: 630px) {

              bottom: 30px;
              left: 30px;

            }

          }

          @media all and (max-width: 500px) {

            max-width: 450px;

            .content-title {

              font-size: 25px;

            }

            .content-text {

              font-size: 13px;

            }

            .content-button {

              .button {

                @media all and (max-width: 500px) {

                  height: 40px;

                }

              }

            }

          }

        }

      }

      .right-block {

        width: 40%;

        @media all and (max-width: 1023px) {
          flex-basis: 100%;
          width: 100%;
          max-width: 500px;
          margin: 10px auto;
        }

        .block-container {

          margin: 15px;

        }

      }

        .right-section-container {

            width: 40%;

            .help-and-support-container {

                margin-top: 35px;

            }

        }

        @media all and ( max-width: 1000px ) {

            flex-wrap: wrap;

            .right-section-container {

                width: 100%;

            }

        }

    }

    .welcome-to-dte-group {

      display: flex;
      flex-wrap: wrap;
      margin-top: 30px;
      width: 60%;

        @media all and ( max-width: 1000px ) {

            width: 100%;

        }

      .left-block {

        //width: 60%;

          // New Content Changes
          width: 100%;

        @media all and (max-width: 1023px) {

          width: 100%;

        }

        .content-container {

            // New Content Changes
            // max-width: 770px;

          .content-title {

              font-weight: 500;
              font-size: 22px;
            line-height: 27px;
            margin-bottom: 20px;

          }

          .content-text {

            p {

              font-size: 15px;
              line-height: 26px;

                a {

                    font-weight: 500;
                    text-decoration: none;
                    color: $dte-bright-blue;

                }

            }

          }

        }

      }

      .right-block {

        width: 40%;

        @media all and (max-width: 1023px) {

          width: 100%;
          max-width: 600px;

        }

      }

    }

  }

  .popular-products-container {

    position: relative;
    border: 1px solid #e0e0e0;

    .popular-product-container {

      padding: 70px 25px 25px;

      .popular-product-image {

        width: 250px;
        max-width: 100%;
        margin: 0 auto;

        img {

          width: auto !important;
          height: 235px;
          margin: 0 auto;

        }

      }

      h2.product-name {
        font-size: 18px;
        font-weight: 500;
        margin-top: 15px;
        padding-top: 0;
        width: 95%;
        text-overflow: ellipsis;
        max-height: 43px;
        overflow: hidden;
      }

      .product-sku-container {

        color: #949494;
        margin: 17px 0;
        font-size: 13px;

      }

      .price-box {

        .price-container {

          span.price {

            font-size: 28px;
            display: inline-block;

            &::first-letter {

              font-size: 16px;
              font-weight: normal;

            }

          }

        }

      }

      .product-bottom-section-container {

        display: flex;
        align-items: center;

        .product-price-container {
            width: auto;
        }

        .product-stock-container {

            margin-left: 20px;

          .stock-status-inner {

            display: flex;
            align-items: center;
            color: #19B823;

            img, svg {

              width: 20px;
              margin-right: 5px;

            }

          }

        }

        .product-wishlist-container {

          margin-left: auto;

        }

        @media all and (min: 1024px) and (max: 1100x) {

          flex-wrap: wrap;

          .product-price-container {

            order: 1;

          }

          .product-stock-container {

            order: 3;

          }

          .product-wishlist-container {

            order: 2;

          }

        }

          @media all and ( max-width: 767px ) {

              display: block;

              > .product-price-container {

                  margin-bottom: 20px;

              }

              > .product-stock-container {

                  display: inline-block;
                  margin-left: 0;

              }

              > .product-wishlist-container {

                  display: inline-block;
                  float: right;

              }

          }

      }

      .price-wrapper {

        font-size: 26px;
        font-weight: 600;

        span {

          display: block; // required for first letter

          &::first-letter {

            font-size: 16px;
            font-weight: normal;

          }

        }

        ::after {

          content: 'ex VAT';
          display: inline;
          width: 100%;
          height: 100%;
          font-weight: normal;
          font-size: 16px;
          margin-left: 5px;

        }

      }

    }

    .popular-products-title {

      position: absolute;
      top: 0;
      z-index: 1;
      padding: 20px 30px;

      h5 {

        font-size: 14px;
        font-weight: 600;
        color: #949494;

      }

    }

    .owl-dots {

      position: absolute;
      top: 0;
      right: 30px;
      width: auto;

      .owl-dot {

        > span {

          background-color: #efefef;
          border-color: #efefef;
          width: 8px;
          height: 8px;

        }

        &:hover {

          > span {

            background-color: #949494;

          }

        }

        &.active {

          > span {

            background-color: #949494;
            border-color: #949494;

          }

        }

      }

    }

    .owl-stage-outer {

      padding: 0;

    }

  }

}
