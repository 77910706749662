.checkout-index-index {

  .header {

    @media all and (max-width: 1129px) {

      display: block !important;
    }

    .header__wrapper {

      .header-text {

        display: none !important;

      }

      .header__menu_wrapper {

        display: flex;

        @media all and (max-width: 1129px) {

          flex: 1 !important;

        }

        >div {

          opacity: 0;
          height: 0;

        }

        &:before {

          display: block;
          position: absolute;
          content: '';
          width: 100%;
          height: 100%;
          background-image: url(../images/padlock.png);
          background-position: right;
          background-repeat: no-repeat;
          background-size: 40px auto;
          right: 150px;

        }

        &:after {

          content: 'Secure Checkout';
          height: 100%;
          position: absolute;
          width: 100%;
          right: 20px;
          font-size: 16px;
          color: #686868;
          display: flex;
          align-items: center;
          justify-content: flex-end;

        }

      }

    }

  }

  .title-wrapper {
    display: none;
  }

  .checkout-securely-title {
    text-align: left;
    margin-top: 2rem;
  }

  .login-to-checkout-container {
    display: flex;
    flex-wrap: wrap;
    padding: 0 1rem;
    justify-content: flex-end;
    align-items: center;

    .checkout-header--title.already-have-account-title {
      width: 100%;
    }

    .checkout-header--title.or-complete-checkout {
      width: 100%;
      margin-top: 0.5rem;
    }

  }

  .develo-opc-shortcuts-container {
    float: unset !important;
  }

  #stripecreditcards-payment-form {

    display: none;

  }

  .stripe-icon-container img.stripe-icon {

    width: auto;
    display: inline;

  }

  #stripe-payments-card-number {

    width: 220px;

  }

  #checkout-step-shipping {


    text-align: left;

    >.field.addresses {

      text-align: left;

      ~.button {

        margin-bottom: 30px;

      }

      >.control {

        &:after {

          content: none;

        }

      }

    }

    #co-shipping-form {
      .control._with-tooltip {
        .message {
          border: none;
          &::after {
            display: none;
          }
        }
      }
    }

  }

  &.firecheckout {

    &.firecheckout-col1-set.fc-multistep #checkout .authentication-wrapper {

      float: none;
      display: inline-block;
      text-align: left;

    }

    #maincontent {

      margin-top: 0;

      >.title-wrapper {

        margin-bottom: 0;

        .heading {
          margin-bottom: 0;
        }

      }

    }

    .checkout-header--title {

      font-size: 18px;
      color: $light-navy-blue;
      display: inline-block;
      vertical-align: middle;

      &.or-complete-checkout {

        margin-top: 20px;
        display: block;

      }

      &.checkout-header--express-checkout {

        vertical-align: top;
        margin: 2px 10px 2px 0;

      }

      &.checkout-header--express-checkout-or,
      &.checkout-header--express-checkout-continue-checkout-below {
        display: none;
      }

    }

    .develo-opc-shortcuts-container {

      float: right;
      margin-bottom: 5px;

      .develo-opc-shortcuts {

        display: inline-block;

        .stripe-payments-express-minicart {
          display: none !important;
        }

      }

      a[data-action="paypal-in-context-checkout"] {

        display: inline-block;
        width: 210px;

      }

    }

    #checkout {

      .opc-progress-bar {
        padding: 0 0 15px;
      }

      .authentication-wrapper {

        .checkout-header--title {

          +.button.button--secondary {

            width: auto;
            display: inline-block;
            margin-left: 10px;
            margin-bottom: 5px;

          }

        }

      }

    }

  }

  &.firecheckout .opc-wrapper {



    .billing-address-details {

      padding-left: 0;
      margin-bottom: 30px;

      .action-edit-address {

        position: static;
        display: block;

      }

    }

    .shipping-address-item {
      &.selected-item{
        font-weight:bold;
      }
    }

    #checkout-step-shipping,
    #shipping-method-buttons-container,
    .billing-address-details {

      .shipping-address-item {

        padding-left: 10px !important;
        border-left: 1px solid silver;
        border-radius: 0;
        padding-bottom: 0px;
        margin-bottom: 55px;

      }

      .button {

        display: inline-flex;
        justify-content: center;
        cursor: pointer;
        height: 48px;
        min-width: 100px;
        padding: 11px 20px;
        border: none;
        border-radius: 100px;
        outline: none;
        background: $color-secondary;
        font-family: "Montserrat", sans-serif;
        font-size: 18px;
        color: #fff;
        transition: all 0.3s ease-in-out;
        font-weight: 400;
        line-height: 1;
        vertical-align: middle;
        align-items: center;
        box-shadow: none;

        &:hover {

          background: $color-secondary;
          border: none;
          color: #fff;
          padding: 11px 20px;
          @extend %shadow--l1;

        }

        &.button--secondary {

          border: 1px #231f20 solid;
          background: #fff;
          color: #231f20;

          &:hover {

            background: #dc2048;
            border: 1px #dc2048 solid;
            color: #fff;
            @extend %shadow--l1;

          }

        }

      }

      .action-select-shipping-item {

        width: auto;
        display: block;
        position: static;

        visibility: hidden;
        cursor:pointer;

      }

      .shipping-address-item:hover{

        .action-select-shipping-item {
          visibility: visible;
        }

      }

    }

    span.note,
    #stripe-card-errors {

      color: #dc2048;

    }

  }

  &.firecheckout #checkout #checkout-step-payment .checkout-billing-address {

    .billing-address-same-as-shipping-block {

      .checkbox__label {

        display: inline-block;
        padding-left: 0;
        margin: 0 0 6px 0;
        font-size: 12px;
        color: #9b9b9b;
        font-family: "Montserrat", sans-serif;
        font-weight: normal;
        line-height: 1.5;
        transition: none;
        text-transform: uppercase;

        &:before {

          content: none;

        }

      }

      #billing-address-same-as-shipping-shared {

        transform: translate(-1px, -1px) !important;
        width: 20px !important;
        height: 20px !important;

      }

    }

    >.fieldset {

      margin-bottom: 30px;

      >div.field.field-select-billing {

        label {

          display: none !important;

        }

      }

      .actions-toolbar button.button {

        display: inline-flex;
        justify-content: center;
        cursor: pointer;
        height: 48px;
        min-width: 100px;
        padding: 11px 20px;
        border: none;
        border-radius: 100px;
        outline: none;
        background: $color-secondary;
        font-family: "Montserrat", sans-serif;
        font-size: 18px;
        color: #fff;
        transition: all 0.3s ease-in-out;
        font-weight: 400;
        line-height: 1;
        vertical-align: middle;
        align-items: center;
        box-shadow: none;

        &:hover {

          background: $color-secondary;
          border: none;
          color: #fff;
          padding: 11px 20px;
          @extend %shadow--l1;

        }

        +button {

          border: 1px #231f20 solid;
          background: #fff;
          color: #231f20;

          &:hover {

            background: #dc2048;
            border: 1px #dc2048 solid;
            color: #fff;
            @extend %shadow--l1;

          }

        }

      }

    }

  }

  &.firecheckout.fc-form-tooltips #checkout {

    .checkout-payment-method:not(.fc-size-xs) .discount-code .payment-option-inner {

      margin-right: 0;

    }

    #block-discount-heading {

      &:after {

        width: 9px;
        height: 9px;
        margin: 0px 0 0 13px;

      }

      +.discount__title-icon {

        display: none;

      }

    }

    .swissup-checkout-fields {

      display: none;

    }

  }

  &.firecheckout #checkout [data-collapsible="true"]>[role="tab"]>span#block-attachments-heading {

    font-size: 24px;
    color: $color-primary;
    text-transform: capitalize;
    margin-top: 30px;
    padding: 0 0 15px;

    &:after {

      content: none;

    }

  }

  &.firecheckout #checkout {

    .opc-wrapper {

      .step-title {

        font-size: 1.5em;
        color: $color-primary;
        margin-top: 30px;

        +.divider {

          margin-bottom: 15px;

        }

      }

    }

    .opc-wrapper {

      .opc-payment {

        .step-title {

          margin-top: 0;

        }

        .payment-methods {

          #paypal_express {

            +label {

              img {

                display: inline-block;
                width: auto;
                max-width: 68px;
                vertical-align: middle;

              }

            }

          }

        }

      }

    }

  }

  .payment-method {

    ul.credit-card-types {

      padding-left: 0;

      li.item {

        display: inline-block;

      }

    }

  }

  &.firecheckout .place-order .actions-toolbar .primary .action {

    display: inline-flex;
    justify-content: center;
    cursor: pointer;
    height: 48px;
    min-width: 100px;
    padding: 11px 20px;
    border: none;
    border-radius: 100px;
    outline: none;
    background: $color-secondary;
    font-family: "Montserrat", sans-serif;
    font-size: 18px;
    color: #fff;
    transition: all 0.3s ease-in-out;
    font-weight: 400;
    line-height: 1;
    vertical-align: middle;
    align-items: center;
    box-shadow: none;

    &:hover {

      background: $color-secondary;
      border: none;
      color: #fff;
      padding: 11px 20px;
      @extend %shadow--l1;

    }

  }

  &.firecheckout {

    .payment-method {

      .payment-method-content {

        padding-left: 0;

        #stripe-card-element {

          width: 100%;
          padding: 0.90em 7px 0.68em 18px;
          border-style: solid;
          border-width: 2px;
          border-color: rgb(239, 242, 247);
          background-color: transparent;
          height: 50px;
          border-radius: 30px;
          box-shadow: none;
          margin-top: 30px;

        }

      }

    }

  }

  //&.firecheckout #checkout .product-view__qty {
  //
  //  button.quantity-update__button {
  //
  //    height: 48px;
  //
  //  }
  //
  //  input[type=number] {
  //
  //    border-top: 1px solid #4c4555;
  //    border-bottom: 1px solid #4c4555;
  //    border-left: 0;
  //    border-right: 0;
  //    border-radius: 0;
  //    width: 38px;
  //    height: 48px;
  //    color: #4c4555;
  //    text-align: center;
  //    -webkit-appearance: none;
  //    -moz-appearance: none;
  //    appearance: none;
  //
  //  }
  //
  //}

  .opc-summary-wrapper {
      .minicart-product {

          justify-content: space-between;
          padding-right: 20px;

          .product-item-name-block {

              margin-bottom: 5px;

          }

          .product-image-container {

              width: 72px !important;
              height: auto !important;
              min-width: 72px;

          }

          .product-item-details {

              flex: 3;

              @media all and (max-width: 767px) {

                  padding-left: 20px;

              }

              .subtotal {

                  margin-top: 14px;
                  padding-left: 10px;

              }

          }

      }
    .product-item-inner {
      .details-qty {
        display: inline-block;
        width: 75%;

        .qty-wrapper {
          display: inline-flex;
          width: 100%;
          align-items: center;
        }
      }

      .subtotal {
        float: right;
        margin-top: 10px;
        text-align: left;
        width: 25%;
      }
    }
  }

  .swissup-attachment-row .order-attachment-preview img {

    max-height: 150px;
    height: auto;
    width: auto;
    margin: 0 auto;
    max-width: 150px;

  }

  .checkout-summary {

    .minicart-product {

      .product-image-container {

        display: block;
        margin-right: 15px;

      }

    }

  }

  #shipping-new-address-form {

    .field.street {

      legend.label {

        display: block;
        color: #231f20;
        margin-bottom: 0;

      }

    }

  }

}

.firecheckout.firecheckout-col1-set.fc-multistep #checkout .opc-wrapper .opc {
  margin-bottom: 80px;
}

#customer-email-fieldset label.label {
  margin-left: 0 !important;
}

.modal-content .block-customer-login {
  padding: 30px;
}

.modal-content .block-customer-login .action-login {
  margin-top: 30px;
}

.modal-content .block-customer-login .action-remind {
  font-size: 14px;
  padding-top: 6px;
  padding-left: 5px;
  color: #9b9b9b;
}

#maincontent {
  min-height: 500px;
}

.firecheckout #checkout .opc-wrapper .step-title {
  font-size: 1.5em;
  color: #141b76;
}

.radio__field {
  display: none !important;
}

h2.checkout-summary__title {

  line-height: 1.25;
  font-size: 1.5em;
  color: #141b76;
  font-weight: bold;

}

.opc-wrapper svg,
.firecheckout .main-container svg,
.opc-block-summary svg {
  width: 15px;
  height: 15px;
}

#checkoutSteps li {
  list-style: none;
}

fieldset.field {
  border: none;
}

#customer-email-fieldset .field-tooltip,
.field-tooltip {
  display: none;
}

#customer-email-fieldset {
  margin-bottom: 30px;
}

#customer-email-fieldset .note {
  line-height: 35px;
}

.table-checkout-shipping-method .col-method {
  width: 20px;
}

.tippy-content {
  font-size: 13px;
}

#checkout .opc-block-summary .details-qty .qty-wrapper {
  margin: 5px 0px;
}

.table-checkout-shipping-method {
  margin-top: 5px;
}

.table-checkout-shipping-method .radio {
  margin-bottom: none;
}


#checkout-shipping-method-load>table>tbody>tr>td:nth-child(1)>div>label>span {
  width: 20px;
  height: 20px;
}

#checkout-shipping-method-load .radio {
  margin-bottom: 0;
  width: 25px;
}

#checkout-shipping-method-load .radio__icon::before {
  -webkit-transform: scale(0.5) !important;
  transform: scale(0.5) !important;
}

#__lpform_customer-email_icon {
  width: auto;
}

.input__field:focus {
  color: #141b76;
}

svg.checkout-products__title-icon {
  display: none
}

.firecheckout #maincontent {
  max-width: 1200px;
}

.firecheckout .modal-popup .heading--page {
  margin-top: 0;
  text-align: left;
  padding: 15px 20px;
  margin-bottom: 0;
}

.firecheckout .modal-popup .modal__close-button {
  position: absolute;
  right: 0;
  top: 0;
}

.checkout-index-index {

  &.firecheckout {

    .modal-footer {

      .button {

        display: inline-flex;
        justify-content: center;
        cursor: pointer;
        height: 48px;
        min-width: 100px;
        padding: 11px 20px;
        border: none;
        border-radius: 100px;
        outline: none;
        background: $color-secondary;
        font-family: "Montserrat", sans-serif;
        font-size: 18px;
        color: #fff;
        transition: all 0.3s ease-in-out;
        font-weight: 400;
        line-height: 1;
        vertical-align: middle;
        align-items: center;
        box-shadow: none;

        &:hover {

          background: $color-secondary;
          border: none;
          color: #fff;
          padding: 11px 20px;
          @extend %shadow--l1;

        }

        &.button--secondary,
        &.secondary {

          border: 1px #231f20 solid;
          background: #fff;
          color: #231f20;

          &:hover {

            background: #dc2048;
            border: 1px #dc2048 solid;
            color: #fff;
            @extend %shadow--l1;

          }

        }

      }

    }

    #checkout {

      input[type="radio"]::after {

        background-color: $thunder;
        border-radius: 50%;

      }

      input#shipping-save-in-address-book {

        opacity: 0;

      }

      .billing-address-same-as-shipping-block {

        input.checkbox__field {

          &:after {

            background-color: $thunder;

          }

        }

      }

      label {

        color: $thunder;

        &.checkbox__label {

          position: relative;

          span {

            color: $thunder;

          }

        }

      }

      #shipping-save-in-address-book~label.checkbox__label {

        span {

          margin-left: 30px;

        }

      }

    }

  }

}

@media all and (max-width: 992px) {

  .checkout-index-index {

    &.firecheckout.fc-form-compact .form-shipping-address.fc-size-l .fieldset div.field,
    &.firecheckout.fc-form-compact #checkout .checkout-shipping-address.fc-size-l .fieldset div.field,
    &.firecheckout.fc-form-compact #checkout .checkout-payment-method.fc-size-l .checkout-billing-address .fieldset div.field {
      width: 100%;
    }

  }

}


@media all and (max-width: 767px) {

  .checkout-index-index {

    &.firecheckout {

      #maincontent {

        >.title-wrapper {

          text-align: center;

          .heading {
            margin: 0 0 10px;
          }

        }

      }

      .develo-opc-shortcuts-container {

        float: none;

        .develo-opc-shortcuts {

          display: block;
          max-width: 100%;
          width: 150px;
          margin: 5px auto 8px;

        }

      }

      #checkout {

        .opc-progress-bar {
          display: none;
        }

        .authentication-wrapper .checkout-header--title+.button.button--secondary {

          font-size: 15px;
          padding: 11px 14px;
          min-width: 0;
          height: auto;

        }

      }

      .checkout-header--title {

        font-size: 15px;

        &.already-have-account-title {

          margin: 0;
          vertical-align: baseline;

        }

        &.or-complete-checkout {
          display: none;
        }

        &.checkout-header--express-checkout {

          display: block;
          text-align: center;
          color: $thunder;
          margin: 0 0 7px;

        }

        &.checkout-header--express-checkout-or {

          display: block;
          text-align: center;
          font-size: 18px;
          color: $thunder;
          margin: 0 0 5px;

        }

        &.checkout-header--express-checkout-continue-checkout-below {

          display: block;
          text-align: center;
          margin: 0;

        }

      }

      &.firecheckout-col1-set.fc-multistep #checkout .authentication-wrapper {

        margin: 0;
        padding: 0 15px 15px;
        text-align: center;
        display: block;

      }

    }

  }

}

@media all and (max-width: 460px) {

  .checkout-index-index {

    #discount-form {

      .discount__field,
      .discount__button {

        position: static;

      }

    }

  }

}

// Fixing Base Firecheckout to design theme and general bugs
#checkoutSteps {
    padding: 0 20px;
}

.checkout-index-index.firecheckout #checkout input[type="radio"]::after {
    background-color: $dte-bright-blue;
}

#billing-address-same-as-shipping-shared {
    display: none !important;

    ~ label {
        width: 100% !important;
        margin: 0 0 0 30px !important;
        display: flex !important;
        padding-right: 20px;

        &::after {
            left: -35px;
            background-image: none;
            border: 1px solid $border-input-grey !important;
            opacity: 1 !important;
        }
    }

    &:checked ~ label {
        &::after {
            background-image: url("data:image/svg+xml,%3Csvg  viewBox='0 0 24 24' width='24' height='24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m18.7357432 7.12093254-9.15691975 9.15619256-4.57882345-4.5773691' fill='none' stroke='%230AB6FF' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
        }
    }
}

.checkout-index-index {

    .ajax-loader {
        height: 100vh;
        width: 100vw;
        background: transparent;
    }

    .billing-address-same-as-shipping-block {
        ~ fieldset .actions-toolbar {
            display: flex;
            align-items: center;
            justify-content: space-between;

            button {
                border: 1px solid transparent !important;
                border-radius: 4px !important;
                outline: none !important;
                background: $dte-bright-blue !important;
                color: white !important;
                font-weight: bold !important;
                font-size: 13px !important;
                width: 45%;
            }
        }
    }
}

.payment-method-content {
    display: none;
}

.payment-method._active .payment-method-content {
    display: block;
}

.payment-method.stripe-payments .payment-method-title {
    display: flex !important;
}

.payment-method.stripe-payments .payment-method-title label {
    display: flex !important;
    flex-direction: column-reverse;
}

.payment-method.stripe-payments .payment-method-title label span:nth-of-type(2) {
    position: relative;
    top: -2px;
    left: 4px;
}

.payment-method.stripe-payments .__PrivateStripeElement {
    width: 100%;
    max-width: 100%;
}

#co-payment-form {
    .control._with-tooltip {
        .message {
            border: none;

            &::after {
                display: none;
            }
        }
    }
}

.control._with-tooltip {
    .message {
        outline: none;
    }
}

.stripe-payments-elements {
    .pci-dss-info-block {
        margin: 1.5rem 0 0 0;
        width: 100%;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;

        svg {
            min-width: 25px;
            position: static !important;
        }

        .pci-dss-info-text {
            position: static !important;
        }
    }
}
