$status-bar-height: 90px;

.develodesign_portalorders-orders-view {

    .order-view__tabs .tab__content--active{
        padding: 0 20px;
    }

    .col-xs-12 col-lg-10{
        width: 100%;
        flex-basis: 100%;
        max-width: 100%;
        padding: 0;
        margin: 0 !important;
    }
    .col-xs-12 col-lg-2{
        display: none;
    }
    .title-wrapper {

        display: none;

    }

    // Portal order specific top toolbar styles

    #maincontent {

        margin-top: -10px;

        .top-account-toolbar {

            margin-bottom: 15px;
            height: 60px;
            margin-top: 34px;

            .actions-toolbar {

                padding-top: 0;

            }

            .email-form-toggle-btn,
            .button.back {

                margin: 10px 0;
                height: 40px;

            }

            .email-form-toggle-btn {

                padding-top: 9px;

            }

        }

    }

    // END OF Portal order specific top toolbar styles

    .email-form {
        text-align: left;
        width: 100%;
    }

    #desk_message_form {
        width: 100%;
        margin-bottom: 30px;
        margin-left: auto;
        padding: 20px;

        .ticket-subject {
            display: none;
        }

        .create-message {
            label {
                display: none;
            }
        }
    }

    .feedback-form-toggle-btn {

        background-color: transparent;
        border: none;
        font-size: 17px;
        font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
        cursor: pointer;

    }

    .feedback-form-container {

        display: none;
        width: 100%;
        margin: 0 auto;
        padding: 50px 0 20px;
        border-top: 1px solid #d7d7d7;
        border-bottom: 1px solid #d7d7d7;
        margin-bottom: 50px;

        h3.legend {

            display: none;

        }

        form {

            max-width: 550px;
            width: 80%;
            margin: 0 auto;

            .fieldset {

                display: block;

            }

            div.field {

                max-width: none;
                margin-bottom: 30px;

            }

            .starry {

                .standard_star {

                    margin-right: 5px;

                }

            }

            .actions-toolbar {

                text-align: right;

                button {

                    width: auto;

                }

            }

        }

    }

    form.form-attachments {

        &:after {

            content: "";
            display: table;
            clear: both;

        }

        .attachment-container {

            width: 100%;
            margin-top: 0;
            padding: 2px;

        }

        .order-attachment-preview {

            /*height: 100%;
            padding: 30px;
            background-color: #f7f7f7;
            vertical-align: middle;
            @extend %shadow--l1;*/

            .swissup-attachment-default-preview {

                display: none;

            }

            img.thumbnail {

                display: none;

            }

            .attachment-file {

                .filesize {

                    display: none;

                }

            }

        }

        .order-attachment-content {

            textarea.comment {

                resize: vertical;

            }

        }

    }

    .po-header {

        margin: 0 0 -15px;
        display: flex;
        width: 100%;
        background-color: transparent;
        padding: 19px 30px 0;
        justify-content: start;

        > div {

            &:not(:last-of-type) {

                margin-right: 50px;

            }

            h2 {

                display: block;
                color: #888888;
                font-weight: 500;
                text-transform: uppercase;
                font-size: 14px;

                + h2 {

                    font-size: 24px;
                    font-weight: 600;
                    color: #231f20;
                    margin-bottom: 0;

                }

            }

        }

        .review-container {

            flex: 1;

        }

    }

    .amazon-order-table-container {

        display: inline-block;
        width: 80%;

    }

    $section-border: 1px solid $higlight-blue;

    #my-amazon-orders-table,
    #my-amazon-contacts-table {

        margin: 0;
        max-width: 750px;

        thead {

            th {

                color: $dark-grey;
                font-weight: bold;
                text-transform: none;
                font-size: 15px;
                border-bottom: none;

            }

        }

        tbody {

            .total-tr {

                @media all and (max-width: 534px) {

                    background: #f1f8fb;

                    td {

                        display: flex;
                        justify-content: center;
                        padding-bottom: 0 !important;

                        &:first-of-type {

                            display: none;

                        }

                    }

                }

            }

            td {
                border-top: 1px solid $border-input-grey;
                border-bottom: none;
                font-size: 15px;

                @media all and (max-width: 534px) {

                    font-weight: bold;

                    &:first-child {
                        padding: 5px 16px 5px 16px;
                    }

                    &::before {
                        font-weight: normal;
                    }

                }

            }

        }

    }

    #my-amazon-contacts-table {

        tbody {

            td {

                font-size: 17px;
                font-weight: 600;

                @media all and (max-width: 767px) {

                    font-size: 14px;
                    padding-left: 0 !important;

                }

            }

        }

    }


    .shared-width-btn {

        width: 167px;

    }

    #my-amazon-orders-table {

        max-width: none;

        thead th {

            padding: 25px 10px;

        }

        tbody {

            tr {

                &.total-tr-spacer {

                    @media all and (max-width: 767px) {

                        padding: 0;

                    }

                }

                &.total-tr {

                    td {

                        font-weight: 600;
                        font-size: 22px;
                        padding: 30px 0;
                        text-align: right;
                        padding-right: 20px;
                        @media all and (max-width: 767px) {
                            border: none !important;
                        }

                    }

                    @media all and (max-width: 767px) {

                        padding: 0;

                    }

                }

                td.price-aligned {

                    width: 135px;
                    padding-right: 30px;

                    span {

                        float: left;

                    }

                }

                td {

                    font-size: 13px;

                    &.qty {

                        width: 75px;

                        //@media all and ( max-width: 767px ) {
                        //
                        //    padding-left: 7px;
                        //
                        //}

                    }

                    @media all and (max-width: 767px) {

                        &:before {

                            margin-right: 7px;
                            max-width: none;
                            width: auto;

                        }

                    }

                }

            }

        }

    }

    .add-contact-container {

        display: flex;
        justify-content: space-between;
        padding: 15px;

        .add-contact-btn-container {

            button {

                .close {

                    display: none;

                }

                &.active {

                    span {

                        display: none;

                        &.close {

                            display: inline;

                        }

                    }

                }

            }

        }

    }

    .feedback-modal-btn {

        display: inline-block;
        float: right;
        position: relative;

        .feedback-icon {

            position: absolute;
            width: 31px;
            left: -35px;
            top: 7px;

        }

        .feedback-form-toggle-btn {

            color: #444;

        }

    }

    .grey-border-white-bg {

        border: 15px solid #f7f7f7;
        background-color: #fff;

    }

    .delivery-info-container {

        display: flex;
        font-size: 17px;
        margin: 30px 0;
        padding: 20px 20px 15px;

        > div {

            flex: 1;
            color: #888888;
            font-weight: 500;
            text-transform: uppercase;
            font-size: 14px;

            @media all and (max-width: 767px) {

                font-size: 12px;

            }

            strong {

                text-transform: none;
                color: #231f20;
                font-size: 17px;
                margin-left: 12px;

                @media all and (max-width: 767px) {

                    margin-left: 0;
                    display: block;

                }

            }

        }

        &:last-of-type {

            text-align: right;

        }

        > div:first-child {

            margin-bottom: 5px;

        }

        @media all and (max-width: 767px) {

            display: block;

        }

    }

    .delivery-address-container {

        display: flex;
        justify-content: space-between;
        padding: 15px;
        background: #f1f8fb;

        @media all and (max-width: 534px) {
            padding: 0;
        }

        @media all and (max-width: 682px) {

            .tab--inline .tab__title {
                width: 100%;
                margin: 0px;
                border-radius: 0px;
            }

        }

        > div {

            width: 100%;
            padding: 15px;

            @media all and (min-width: 1200px) {
                padding: 30px;
            }

        }

        .title {

            h3 {

                color: $dark-grey;
                font-weight: bold;
                font-size: 18px;
                border-bottom: none;
                margin-bottom: 10px;

            }

        }

        @media all and (max-width: 767px) {

            display: block;

            > div {

                width: 100%;
                margin-bottom: 15px;
                padding: 0px;
            }

        }

        .delivery-address,
        .invoice-address {

            background: $white;
            box-shadow: none;
            border: none;

            @media all and (max-width: 534px) {
                padding: 15px;
            }

            dl {
                display: grid;
                grid-template: auto / 12em 4fr;
                margin: 10px 0;
                max-width: 600px;


                &.billing-emails {

                    @media all and (max-width: 682px) {
                        margin-top: 35px;
                    }
                }
            }

            dt {
                grid-column: 1;
                font-size: 15px;
                font-weight: 500;
                margin: 7px 0;
            }

            dd {
                font-size: 14px;
                grid-column: 2;
                margin: 7px 0;
                color: #686868;

                a {
                    font-weight: bold;
                    color: #0AB6FF;
                }
            }

            hr {
                border: 1px solid rgb(243, 243, 243);
            }

            .add-new-email-button-container {
                float: right;
                position: relative;
                left: 46px;


                @media all and (max-width: 682px) {
                    top: 31px;
                    left: 1px;
                }

                .button {
                    width: 100px;
                    height: 35px;
                    position: absolute;
                    right: 10px;
                    margin-top: -10px;
                }
            }

            #add-contact-input, #add-contact-submit-btn {
                height: 35px;
            }

            svg {
                width: 20px;
                height: 20px;
                float: right;
            }
        }

    }

    .contact-info-container {

        width: 100%;
        padding: 15px;
        background-color: #f7f7f7;
        margin: 0 0 30px;
        @extend %shadow--l1;

        h4 {

            font-size: 20px;
            line-height: 1.4;

        }

        .contact-info-sections {

            display: flex;
            justify-content: space-between;
            margin-top: 30px;
            font-size: 19px;

            h5 {

                font-size: 15px;
                text-transform: uppercase;

            }

            p {

                font-size: 14px;
                line-height: 22px;

            }

            @media all and (max-width: 767px) {

                display: block;

            }

        }

    }

    .add-contact-form-container {

        margin-bottom: 15px;
        padding: 15px;
        border-bottom: 1px solid rgb(243, 243, 243);
        border-top: 1px solid rgb(243, 243, 243);
        margin-left: 30px;


        @media all and (max-width: 682px) {
            margin-top: 30px;
        }

        > div {

            display: flex;

        }

        input.input__field {

            max-width: 400px;
            margin-right: 15px;

        }

        &.error {

            .error-msg {

                display: inline-block !important;
                color: #dc2048;
                font-size: 13px;
                margin-top: 10px;

            }

        }

    }

    //.table-wrapper {
    //
    //  margin-bottom: 50px;
    //
    //  #my-amazon-orders-table {
    //
    //    caption {
    //
    //      background: transparent;
    //      color: $thunder;
    //      text-transform: none;
    //      font-size: 24px;
    //      font-weight: 300;
    //      border-bottom: 1px $thunder solid;
    //      text-align: left;
    //      padding: 15px 0;
    //      margin-bottom: 15px;
    //
    //    }
    //
    //    border: none;
    //    border-radius: 0;
    //
    //    th.col {
    //
    //      border: none;
    //      background-color: #dcdcdc;
    //      text-transform: uppercase;
    //      font-weight: 600;
    //      font-size: 13px;
    //      text-align: left;
    //
    //    }
    //
    //    td.col {
    //
    //      border-right: none;
    //      background-color: #f7f7f7;
    //      font-size: 15px;
    //      padding: 20px 16px;
    //
    //      &.actions {
    //
    //        text-align: right;
    //        padding: 0 16px;
    //
    //        a {
    //
    //          span {
    //
    //            display: inline-block;
    //            padding: 5px;
    //            color: #6c727f;
    //            font-weight: 600;
    //
    //          }
    //
    //          &:hover {
    //
    //            background-color: $color-secondary;
    //            border-color: $color-secondary;
    //
    //            span {
    //
    //              color: #fff;
    //
    //            }
    //
    //          }
    //
    //        }
    //
    //      }
    //
    //    }
    //
    //    .table tbody tr:last-child td:first-child {
    //
    //      border-bottom-left-radius: 0;
    //
    //    }
    //
    //    .table tbody tr:last-child td:last-child {
    //
    //      border-bottom-right-radius: 0;
    //
    //    }
    //
    //    tfoot {
    //
    //      .table-total {
    //
    //        text-align: right;
    //        font-size: 20px;
    //        padding: 25px 128px;
    //
    //        @media all and ( max-width: 767px ) {
    //
    //          padding: 25px 30px;
    //          background-color: #f7f7f7;
    //
    //        }
    //
    //      }
    //
    //    }
    //
    //  }
    //
    //}

    // File upload styles

    .portalorder-file-upload-container {

        cursor: pointer;

        .section-title {

            background: transparent;
            color: $thunder;
            text-transform: none;
            font-size: 24px;
            font-weight: 300;
            border-bottom: 1px $thunder solid;
            text-align: left;
            padding: 15px 0;
            margin-bottom: 15px;
            display: none;

        }

        .attachment-container {
            margin-top: 40px;
            display: flex;
            flex-direction: column;
            flex-flow: wrap;
            justify-content: space-between;

            &:after {

                content: "";
                display: table;
                clear: both;

            }

            @media all and (max-width: 767px) {

                display: block;

            }
        }

        #attachment-form {

            display: flex;
            flex-wrap: wrap;

            .attachment-top-container {

                display: flex;
                order: 2;
                flex-basis: 100%;
                align-items: center;
                justify-content: center;
                padding: 16px;
                margin: 0 auto;

                @media all and (max-width: 682px) {
                    padding: 5px;
                }

                .attachment-top {

                    text-align: center;

                    .header__icon {

                        display: inline-block;
                        background-color: #fff;
                        height: 28.55px;
                        width: 31px;
                        margin: -12px auto;


                    }

                    .attachment-title {

                        color: #303030;
                        font-weight: 500;
                        font-size: 17px;
                        border-bottom: none;
                        display: inline-block;
                        padding: 0 15px;
                        line-height: 21px;
                        margin-right: 30px;

                    }

                }

                .attachment-button {
                    text-align: center;
                }

                + .attachment-container {

                    order: 1;

                    &:after {

                        content: "";
                        flex: auto;

                    }

                }

            }

        }

        .swissup-attachment-row {

            text-align: left;
            width: 31.5%;
            margin-right: 1.83333333%;
            border-bottom: 1px solid #e0e0e0;

            &:nth-child(-n+3) {

                border-top: 1px solid #e0e0e0;

            }

            .swissup-attachment-row-content {

                height: 100%;

                > * {

                    vertical-align: middle;

                }

                .swissup-attachment-file-type {

                    display: inline-flex;
                    align-items: center;
                    background-color: #fff;
                    width: 46px;
                    height: 100%;

                    img {

                        width: 50%;
                        min-width: 20px;
                        max-width: 23px;

                    }

                    &.csv {


                    }

                }

            }

            .order-attachment-content {

                textarea {

                    background-color: #fff;
                    color: #231f20;
                    width: auto;
                    height: 48px;
                    padding: 8px 16px;
                    border: 1px solid #888;
                    border-radius: 25px;
                    font-size: 16px;
                    font-family: inherit;
                    line-height: 28px;
                    text-align: center;
                    width: 390px;
                    max-width: 100%;

                }

            }

            .order-attachment-preview {

                cursor: default;
                display: inline-block;
                width: auto;
                padding: 5px 5px 10px 0;
                max-width: calc(100% - 170px);

                &:before {

                    content: '';
                    display: block;
                    color: #8c8d93;
                    font-size: 11px;
                    text-transform: uppercase;
                    font-weight: 600;
                    letter-spacing: 0.4px;
                    margin-bottom: 5px;

                }

                .thumbnail {

                    max-width: 75px;

                    + .filename {

                        margin-top: 8px;

                    }

                }

                .filename {

                    color: #6d6d6d;
                    font-size: 14px;
                    word-wrap: break-word;

                }

            }

            .order-attachment-content {

                display: inline-flex;
                width: auto;
                text-align: right;
                padding: 0 10px 0 0;
                float: right;
                align-items: center;
                height: 100%;

                &:hover {

                    .swissup-attachment-download,
                    .swissup-attachment-remove {

                        opacity: 0.5;

                    }

                }

                .swissup-attachment-remove {

                    display: inline-block;
                    height: 30px;
                    width: 30px;
                    background-image: url("data:image/svg+xml,%3Csvg width='24px' height='24px' viewBox='0 0 24 24' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Icon-/-System-/-24px-/-Black-/-Delete' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' stroke-linecap='round' stroke-linejoin='round'%3E%3Cg id='Icon-/-System-/-24px-/-Black-/-Add' stroke='%23DC2048'%3E%3Cpath d='M12.1079696,12.1093152 L8.045375,12.1085875 L12.1079696,12.1093152 L12.1086583,15.954625 L12.1079696,12.1093152 Z M12.1079696,12.1093152 L12.1072417,8.045375 L12.1079696,12.1093152 L15.954625,12.1100042 L12.1079696,12.1093152 Z' id='Combined-Shape' transform='translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) '%3E%3C/path%3E%3Cpath d='M20.1263542,11.9985833 C20.1263542,16.488 16.4890625,20.1267083 12.0010625,20.1267083 C7.5130625,20.1267083 3.87364583,16.488 3.87364583,11.9985833 C3.87364583,7.512 7.5130625,3.87329167 12.0010625,3.87329167 C16.4890625,3.87329167 20.1263542,7.512 20.1263542,11.9985833 Z' id='Stroke-3' transform='translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) '%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
                    background-repeat: no-repeat;
                    background-position: center center;
                    margin-left: 5px;
                    cursor: pointer;
                    transition: all 200ms ease-in-out;

                    &:hover {

                        opacity: 1;
                        transform: scale(1.1);

                    }

                }

                .swissup-attachment-download {

                    display: inline-block;
                    height: 30px;
                    width: 18px;
                    background-image: url("data:image/svg+xml,%3Csvg width='24px' height='24px' viewBox='0 0 24 24' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Icon-/-System-/-24px-/-Black-/-Dowload' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' stroke-linecap='round' stroke-linejoin='round'%3E%3Cg id='Group-7' transform='translate(12.000000, 12.000000) rotate(-180.000000) translate(-12.000000, -12.000000) translate(2.526316, 3.000000)' stroke='%23231F20'%3E%3Cpolyline id='Stroke-1' points='4.74738947 5.00513684 9.52938947 0.223136842 14.3107579 5.00513684'%3E%3C/polyline%3E%3Cpath d='M9.52926316,13.2838105 L9.52926316,0.223389474' id='Stroke-3'%3E%3C/path%3E%3Cpolyline id='Stroke-5' points='0.157957895 11.6798842 0.157957895 17.8298842 18.9006947 17.8298842 18.9006947 11.6798842'%3E%3C/polyline%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
                    background-repeat: no-repeat;
                    background-position: center center;
                    background-size: contain;
                    margin-left: 5px;
                    cursor: pointer;
                    transition: all 200ms ease-in-out;

                    &:hover {

                        opacity: 1;
                        transform: scale(1.1);

                    }

                }

            }

            @media all and (max-width: 767px) {

                width: 100%;

            }

        }

    }

    .swissup-attachment-error {

        border: 1px #202780 solid;
        color: #1f267f;

        &.danger {

            text-align: left;
            padding: 10px 30px;
            background: #f1f1f1;
            border: 1px #f00 solid;
            margin: 10px 0 0;
            color: #db1f48;

        }

        .close {
            margin-right: 8px;
        }

    }

    @media all and (max-width: 767px) {

        .portalorder-file-upload-container {

            .swissup-attachment-row {

                .order-attachment-preview {

                    .filename {
                        margin: 0;
                    }

                }

                .order-attachment-content {

                    width: 80px;
                    display: block;

                    .swissup-attachment-remove {

                        right: auto;
                        left: 0;
                        top: auto;
                        bottom: 0;

                    }

                    .swissup-attachment-download {

                        left: auto;
                        right: 0;
                        top: auto;
                        bottom: 0;

                    }

                }

            }

        }

    }

    .scroll-to-top-button-container {

        text-align: right;
        margin-top: 30px;

        &.left {

            text-align: left;
            margin-top: 10px;

        }

        .scroll-to-top-button {

            background-color: $color-primary;
            padding: 14px 15px 13px;
            border-radius: 50%;
            cursor: pointer;

            &:hover {

                background-color: #343998;
                @extend %shadow--l1;

            }

        }

    }

    #main-content {
        margin: 0 auto;
    }

    // Fixing bottom tabs px issue

    .tab--inline {

        &::before {

            top: unset;
            bottom: 0;
            z-index: 3;

        }

        .tab__title {

            bottom: -1px;
            border-bottom: 1px #ABE5FF solid !important;
            z-index: 4;

            &--active {

                z-index: 4;
                border-bottom: 1px solid white !important;

            }

        }

    }

}

/* order view dropdown menu element */
.order-dropdown-menu-control {
    right: 20px;
    margin-top: -25px;
    cursor: pointer;
    position: relative;
    float: right;

    /* remove selection */
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;

    .menu-dot {
        height: 8px;
        width: 8px;
        background-color: $very-light-blue;
        border-radius: 50%;
        display: inline-block;
        margin-right: 4px;
        pointer-events: none;
    }

    &.active {
        .menu-dot {
            background-color: $dark-grey;
        }
    }
}

.order-dropdown-menu-container {

    display: none;
    position: absolute;
    right: 1px;
    background: #fff;
    padding: 15px 30px 15px 30px;
    box-shadow: $box-shadow-defined-grey;
    margin-top: 10px;
    z-index: 10;
    min-width: 216px;
    top: 25px;

    &:before {
        content: ' ';
        display: block;
        position: absolute;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 15px 20px 15px;
        border-color: transparent transparent #ffffff transparent;
        top: -15px;
        right: 7px;
    }

    .order-dropdown-menu {

        list-style: none;
        padding: 0px;

        li {
            position: relative;
            padding: 5px 0 5px 30px;

            a {
                color: $dte-blue;
                text-decoration: none;
                font-size: 14px;

                svg {
                    margin: 10px 0;
                    position: absolute;

                    top: -4px;
                    width: 20px;
                    height: 20px;
                    display: inline-block;
                    left: 0px;
                }
            }
        }
    }
}

.sales-order-history {
    &.dte-store--default {
        .bom-btn-search-form-container {
            #quoteSearchForm {
                .dte-search-form-text-container,
                .dte-search-form-button-container {
                    display: none;
                }

            }

            .dte-bom-button-container {
                display: none;
            }
        }

    }

    .breadcrumbs-container {
        display: flex;

        @media all and (max-width: 767px) {

            display: block;

        }

        @media all and (max-width: 700px) {

            padding: 20px 61px;

            .breadcrumbs-content {

                flex-wrap: wrap;

                .bom-btn-search-form-container {

                    flex-basis: 100%;
                    justify-content: flex-start;
                    text-align: left !important;

                    .dte-bom-button-container {

                        margin-left: 0;

                    }

                }

            }

        }

        .breadcrumbs {

            &.dte-amazon {

                flex: 1;
                display: block;

                @media all and (max-width: 1129px) {

                    flex: 0.44;

                }

                .items {

                    max-width: none;

                    @media all and (max-width: 767px) {

                        padding: 0;
                        margin-top: 0;

                    }

                }

            }

        }

        .bom-btn-search-form-container {

            flex: 1;
            text-align: right;
            padding: 0 20px;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            flex-wrap: wrap;

            .dte-bom-button-container {

                float: none;
                margin-left: 20px;

                a.button {

                    width: auto;
                    margin: 0;

                }

                #country-filter select {

                    background-color: white;

                }

            }

            #quoteSearchForm {

                float: none;
                margin: 0 !important;

                #quoteSearch {
                    width: 200px;

                }

                .dte-search-form-button-container {

                    top: -1px;
                    height: 44px;
                    left: -4px;

                    .quoteSearchButton {

                        height: 100%;

                    }

                }

            }

            @media all and (max-width: 767px) {

                text-align: left;

            }

        }

    }

}

.portal-order-container {
    @media all and (max-width: 534px) {
        background: $breadcrumbs-bg-colors;
    }

    h4 {
        font-size: 16px;
        font-weight: 600;
        color: $dark-grey;
        margin: 10px;
    }

}

.status-update-bar-container {

    background-color: transparent;

    display: block;
    width: 100%;
    clear: both;
    background: transparent;
    box-shadow: none;
    padding-top: 30px;

    .status-update-bar {

        width: 100%;
        padding: 0 10px;
        margin: 15px auto;
        color: $black;
        display: flex;
        position: relative;
        height: $status-bar-height;

        .status-bar-item {

            box-shadow: $box-shadow-defined-right;
            padding: 21px 30px 26px 100px;
            text-align: left;
            width: 32%;
            position: absolute;
            background-color: transparent;
            margin-right: 3%;
            border-top: 1px solid $higlight-blue;
            border-left: 1px solid $higlight-blue;
            border-bottom: 1px solid $higlight-blue;

            @media all and (max-width: 1129px) {
                padding-left: 10px;
            }

            .icon {
                display: inline-block;
                position: absolute;
                left: 40px;

                .icon_order_status-bar {
                    width: 35px;
                    height: 35px;
                    stroke: transparent;
                }

                @media all and (max-width: 1129px) {
                    left: auto;
                    right: 25px;
                }
            }

            &.complete {
                background: $white;
            }

            .title {

                color: $dark-grey;
                text-transform: none;
                font-weight: 400;
                font-size: 16px;
                margin-bottom: 5px;

                @media all and (max-width: 1129px) {
                    font-size: 14px;
                }

            }

            .value {

                font-size: 17px;
                font-weight: 600;
                color: $dark-grey;
                @media all and (max-width: 1129px) {
                    font-size: 13px;
                }
            }

            &:after {

                content: ' ';
                background-color: $breadcrumbs-bg-colors;
                width: 50px;
                height: 52px;
                position: absolute;
                right: -43px;
                top: 19px;
                border-right: 1px solid #B8EAFF;
                border-top: 1px solid #B8EAFF;
                transform: rotate(45deg) skew(15deg, 15deg);
                transform-origin: 50% 50%;
                z-index: 1;
                margin-right: 4%;
                @media all and (max-width: 1129px) {
                    display: none;
                }
            }

            &:before {

                content: ' ';
                background-color: $breadcrumbs-bg-colors;
                width: 50px;
                height: 50px;
                position: absolute;
                left: -25px;
                top: 19px;
                border-right: 1px solid #B8EAFF;
                border-top: 1px solid #B8EAFF;
                transform: rotate(45deg) skew(15deg, 15deg);
                transform-origin: 50% 50%;
                z-index: 1;
                margin-right: 4%;
                @media all and (max-width: 1129px) {
                    display: none;
                }
            }

            &.complete {
                &:after {
                    background-color: $white;
                }
            }

            @media all and (min-width: 768px) {
                &:nth-child(1) {
                    z-index: 4;

                    &:before {
                        display: none;
                    }
                }

                &:nth-child(2) {
                    left: 33.45%;
                    z-index: 3;
                }

                &:nth-child(3) {
                    left: 66.2%;
                    z-index: 2;
                    width: 33%;

                    &:after {
                        display: none;
                    }
                }
            }

            .mobile-chevron {
                display: none;
                height: 20px;

                @media all and (max-width: 534px) {
                    display: block;

                    svg {
                        transform: rotate(90deg)
                    }
                }
            }
        }

        @media all and (max-width: 767px) {

            height: auto;
            display: block;

            .status-bar-item {

                width: 49%;
                padding: 5px 30px;
                position: relative;
                margin: 10px 0;
                display: inline-block;

                .title {
                    font-size: 12px;
                }

                .value {
                    font-size: 12px;
                }

                &::after {

                    content: none;
                    display: none;

                }

            }

        }

        @media all and (max-width: 534px) {
            .status-bar-item {
                width: 100%;
                margin: 0;
                max-width: 180px;
                background: transparent !important;
                box-shadow: none;
                border: none;
                text-align: center;
                display: block;
                margin: 0 auto;

                .icon {
                    display: none;
                }

                .title {
                    font-size: 14px !important;
                    color: #686868;
                }

                .value {
                    font-size: 14px;
                    font-weight: bold;
                    color: #686868;
                }

                &.complete {

                    .title {
                        color: #303030;
                    }

                    .value {
                        color: #32c036;
                    }
                }
            }
        }
    }
}

// Order View Page Table Changes

.develodesign_portalorders-orders-view,
.quotelist-account-index,
.sales-order-history {

    @media all and (max-width: 534px) {
        table {
            td {
                border: none !important;
            }
        }
    }

    #maincontent {
        @media all and (max-width: 534px) {
            padding: 0 15px;
            margin: 0 auto;
        }
    }
}

.breadcrumbs-container {
    &.container {
        margin: 0 auto;
    }
}

.portal-order-container {

    @media all and (min-width: 535px) {
        h4 {
            margin: 0;
            padding: 15px 25px;
        }
    }
}

#my-amazon-orders-table {

    tbody {
        tr:not(.total-tr) {
            td {

                &.qty-mobile {
                    display: none;
                }

                @media all and (max-width: 534px) {
                    &.qty-desktop {
                        display: none;
                    }
                    &.qty-mobile {
                        display: flex;
                    }
                    &::before {
                        min-width: 100px;
                    }
                }
            }
        }
    }
}
