/* About us blocks */
.our-partners,
.our-accreditations {
  max-width: 1440px;
  margin: 40px auto;
  padding: 0 82px;

  @media all and (max-width: 500px) {

    padding: 0 40px;

  }

  &--content {
    max-width: 400px;

    .partners-title,
    .accreditations-title {
      font-size: 18px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 32px;
      color: #303030;
      margin-bottom: 20px;
    }

    .partners-text,
    .accreditations-text {
      font-size: 14px;
      letter-spacing: 0;
      line-height: 26px;
      color: #303030;
      margin-bottom: 40px;
    }
  }

  &--grid {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;

    @media all and (max-width: 500px) {

      justify-content: space-evenly;

    }

    .partner-grid-item,
    .accreditation-grid-item {
      flex-basis: 50%;
      padding: 0 10px;

      @media all and (max-width: 1024px) {

      margin-bottom: 50px;

      }

      @media all and (max-width: 500px) {

        padding: 0 30px;

      }

      img {
        width: 100%;
        height: auto;
        margin: 0 auto;
      }
    }

    .partner-grid-item {

      img {
        max-width: 100px
      }
    }

    .accreditation-grid-item {

      img {
        max-width: 200px;
      }
    }
  }
}

.our-accreditations {

  margin: 0 auto -20px;
  display: none;
}

/* Accrediation img sizes adjustments */

//.our-accreditations {
//  .accreditation-grid-item {
//    img {
//      min-width: 70%;
//
//      @media all and (min-width: 1024px) {
//        min-width: 100%;
//      }
//    }
//  }
//}

/* About us blocks */

/* Our Partners Desktop */
@media all and (min-width: 1024px) {
  .our-partners,
  .our-accreditations {
    &--content {
      .partners-title,
      .accreditations-title {
        font-size: 22px;
        margin-bottom: 30px;
        font-weight: 500;
      }

      .accreditations-title {

        margin-bottom: 0;

      }

      .partners-text,
      .accreditation-text {
        font-size: 15px;
      }
    }

    &--grid {
      display: flex;
      justify-content: center;
      flex-wrap: unset;

      .partner-grid-item,
      .accreditation-grid-item {
        flex: 1;
        padding: 0 20px;
        &.partner-grid-item-fitbit {
          display: none;
        }
      }
    }
  }
}


.cms-about {

  .title-wrapper {

    display: none;

  }

    nav.breadcrumbs {
        display: none;
    }
    .container {
      margin: 0 auto; // Remove gutters as not on design for this page tablet etc.
    }

    .about-us {

      &--banner {
        margin-bottom: 77px;

        .banner-image {
          //background-size: cover;
          //background-position: center;
          //background-repeat: no-repeat;
          //padding-top: 18.33%;
          position: relative;
          height: 180px;
          background-position: center bottom;
        }

        .banner-content {
          position: absolute;
          display: flex;
          align-items: center;
          height: 100%;
          width: 100%;
          top: 0;
          left: 0;

          h1 {
            color: white;
            font-weight: bold;
            margin-left: 110px;
            font-size: 36px;
            line-height: 50px;
            margin-bottom: 0;
          }
        }

        .banner-footer-text {
          display: flex;
          justify-content: center;
          gap: 100px;
          padding: 58px 110px;
          flex-wrap: wrap;
          background: #f1f8fb;

          @media all and (max-width: 767px) {

            padding: 50px;

          }

          p {
            font-size: 17px;
            line-height: 26px;
          }

          .text-block-left {
            flex: 1%;
            > p :first-of-type {
              margin-bottom: 25px;
            }
          }

          .text-block-right {
            flex: 1;
          }
        }
      }

      &--page-content {
        .section-title {
          text-align: center;
          margin-bottom: 55px;
          font-weight: 500;
        }

        .features--logos {
          display: flex;
          justify-content: center;
          flex-wrap: wrap;
          padding-bottom: 28px;
          margin: 0 82px;

          .features-group {
            flex-basis: 100%;
            margin-bottom: 41px;

            @media all and (min-width: 420px) and (max-width: 1023px) {
              flex-basis: 50%;
            }

            .feature-logo {
              margin-bottom: 16px;

              img {
                max-width: 114px;
                margin: 0 auto;
              }
            }

            .feature-title {
              font-size: 18px;
              text-align: center;
              margin-bottom: 10px;
              font-weight: 500;
            }

            .feature-text {
              font-size: 14px;
              text-align: center;
              padding: 0 35px;
              line-height: 23px;
            }
          }
        }

        .features--logos + .features--logos {
          border-bottom: 1px solid #e0e0e0;
          margin-bottom: 67px;
        }

        /* Features Logos Desktop */
        @media all and (min-width: 1024px) {
          .features--logos {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            padding-bottom: 40px;

            .features-group {
              flex: 1;

              .feature-logo {
              }

              .feature-title {
                font-size: 20px;
              }

              .feature-text {
                font-size: 14px;
                line-height: 26px;
              }
            }
          }
        }

        .info-blocks {
          margin: 80px 0;
          .info-block {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            flex-direction: column;

            .left-block {
              flex-basis: 50%;
              background: #F1F8FB;
              display: flex;
              align-items: center;
              padding: 10%;

              .block-content {

                h3 {

                  font-weight: 500;

                }

                p {
                  font-size: 15px;
                  line-height: 26px;
                }
              }
            }

            .right-block {
              width: 100%;
            }
          }
        }

        /* Info Blocks Desktop */
        @media all and (min-width: 1024px) {
          .info-blocks {
            .info-block {
              flex-direction: row;

              &:last-of-type {
                flex-direction: row-reverse;
              }

              .left-block {
                padding: 3%;

                .block-content {
                  max-width: 400px;
                }
              }

              .right-block {
                width: 50%;
              }
            }
          }
        }

        @media all and (min-width: 1250px) {
          .info-blocks {
            .info-block {
              .left-block {
                padding: 5% 82px;
              }
            }
          }
        }
      }
    }
}
