$checkbox__margin: 10px 0 !default;
$checkbox__line-height: $font-line-height !default;
$checkbox__label-padding: 26px !default;
$checkbox__label-color: #333 !default;
$checkbox__label-font-size: $font-size-base !default;
$checkbox__label-text-transform: none !default;
$checkbox__spacer: 18px !default;
$checkbox__gap: 0 !default;
$checkbox__icon-border-radius: 2px !default;
$checkbox__icon-check-mark-color: $white !default;
$checkbox__icon-fill: $color-primary !default;
$checkbox__icon-transition: $transition-base !default;
$checkbox__border: 2px solid $gray !default;
$checkbox__border--checked: none !default;
$checkbox__color--fake: $gray-dark !default;
$checkbox__unchecked-background-color: transparent !default;
$checkbox__hover-background-color: $white !default;
$checkbox__hover-border-color: $gray-dark !default;
$checkbox__disabled-background-color: $white !default;
$checkbox__disabled-border-color: $gray-lighter !default;
$checkbox__disabled-label-color: $gray-lighter !default;

.checkbox {

  position: relative;
  margin: $checkbox__margin;

  .checkbox__icon {
    display: none !important;
  }

  &__label {

    cursor: pointer;
    color: $checkbox__label-color;
    display: flex;
    align-items: center;
    font-size: $checkbox__label-font-size;
    line-height: $checkbox__line-height;
    padding-left: $checkbox__label-padding;
    min-height: $checkbox__spacer;
    text-transform: $checkbox__label-text-transform;
    margin-left: 5px;

    &:before,
    &:after {

      content: '';
      position: absolute;
      top: $checkbox__gap;
      left: $checkbox__gap;
      width: $checkbox__spacer;
      height: $checkbox__spacer;
      transition: $checkbox__icon-transition;
      background-color: $checkbox__unchecked-background-color;

    }

    &:before {
      border: $checkbox__border;
      border-radius: $checkbox__icon-border-radius;
    }

    &:hover {

      color: $checkbox__label-color;

      &:before {

        background-color: $checkbox__hover-background-color;
        border-color: $checkbox__hover-border-color;

      }

    }

  }

  &__icon {

    position: absolute;
    top: $checkbox__gap;
    left: $checkbox__gap;
    width: $checkbox__spacer;
    height: $checkbox__spacer;
    fill: $checkbox__icon-fill;
    background: $checkbox__icon-check-mark-color;
    cursor: pointer;
    opacity: 0;
    transition: $checkbox__icon-transition;
    border-radius: 3px;

  }

  &--link {

    display: block;
    text-decoration: none;
    color: $checkbox__color--fake;

    &:hover {
      color: $checkbox__label-color;
    }

  }

  &--link-checked {

    display: block;

    .checkbox__icon {

      opacity: 1;
      z-index: 1;

    }

    .checkbox__label:before {
      border: $checkbox__border--checked;
    }

  }

  &__field {

    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;

    &:checked + .checkbox__icon {
      opacity: 1;
    }

    ~ .checkbox__label:after {
      background-image: url("data:image/svg+xml,%3Csvg  viewBox='0 0 24 24' width='24' height='24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m18.7357432 7.12093254-9.15691975 9.15619256-4.57882345-4.5773691' fill='none' stroke='%230AB6FF' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
      border-radius: 3px;
      transform: scale(80%);
      opacity: 0;
      transition: all ease-in-out 0.2s;
      top: 2px;
      left: 1px;
    }

    &:checked ~ .checkbox__label:before {
      border: 1px solid $dte-bright-blue;
    }

    &:checked ~ .checkbox__label:after {
      transform: scale(80%);
      opacity: 1;
    }

    &:disabled,
    &[readonly] {

      opacity: 1;

      ~ .checkbox__label {

        cursor: default;
        color: $checkbox__disabled-label-color;

        &:before {

          background-color: $checkbox__disabled-background-color;
          border-color: $checkbox__disabled-border-color;

        }

      }

    }

  }

}
