.aw-rma__select-order-table {


  border: none;
  border-radius: 0;

  tbody {

    > tr {

      > td {

        &:first-child {
          border-left: $table__border;
        }

        &:last-child {
          border-right: $table__border;
        }

      }

    }

  }

  caption {

    background: transparent;
    color: $thunder;
    text-transform: none;
    font-size: 24px;
    font-weight: 300;
    border-bottom: 1px $thunder solid;
    text-align: left;
    padding: 15px 0;
    margin-bottom: 15px;

  }

  th.col {

    border: none;
    background-color: #dcdcdc;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 13px;
    text-align: left;

  }

  td.col {

    border-right: none;
    background-color: #fff;
    font-size: 15px;
    padding: 20px 16px;

    &.actions {

      text-align: right;
      padding: 0 16px;

      a {

        span {

          display: inline-block;
          padding: 5px;
          color: #6c727f;
          font-weight: 600;

        }

        &:hover {

          background-color: $color-secondary;
          border-color: $color-secondary;

          span {

            color: #fff;

          }

        }

      }

    }

  }

  .table tbody tr:last-child td:first-child {

    border-bottom-left-radius: 0;

  }

  .table tbody tr:last-child td:last-child {

    border-bottom-right-radius: 0;

  }

  .order-item-row {

    .col.product {

      .product-item-image {

        max-width: 125px;
        background-color: #fff;
        margin-bottom: 15px;

      }

    }

  }

}

.aw_rma-customer-createrequeststep {

  .box-actions {

    margin: 15px 0 20px;

    a.action {

      display: inline;

      &:first-of-type {

        margin-right: 5px;

      }

    }

  }

  .aw-rma__return-item-container .item-details .product-details {

    margin-left: 40px;

  }


  .item-message.tick-checkbox {

    font-size: 14px;
    margin-bottom: 20px;
    color: #fff;
    padding: 6px 12px;
    border-radius: 3px;

  }

  .title-wrapper {

    &.aw-rma__title {

      display: block;
      margin: 20px 0;

      .heading--page {
        display: inline-block;
      }

    }

  }

  .aw-rma-new__form {

    margin-bottom: 100px;

    .aw-rma__actions {
      margin-top: 45px;
    }

    .aw-rma__field {

      overflow: visible;

      .label,
      .control
      {

        text-align: left;
        float: none;
        display: block;
        width: auto!important;

      }

      .label {

        line-height: 1.5;
        min-width: 145px;

      }

      .control {

        margin: 5px 0 15px;
        min-width: 300px;

      }

      .fieldset {

        .legend {
          color: red;
        }

      }

      .choices__list--dropdown {
        z-index: 30;
      }

    }

  }

  .aw-rma__return-item-container {

    border: $table__border;
    border-radius: 4px;
    padding: 20px;

    .select__field {
      background-color: $white;
    }

    .product-item-image {

      max-width: 125px;
      margin: 0 0 15px;

    }

    .item-details {

      .item-select {

        top: 0;
        margin: 0;

        > .checkbox {
          margin: 0;
        }

      }

    }

    .product-item-details {

      margin: 0 0 5px;

      a {
        color: $thunder;
      }

    }

    .price {
      color: $label-color;
    }

    .item-return-details {

      background: transparent;

      .content {
        margin: 0 30px;
      }

    }

  }

  @media all and (min-width: 768px) {

    .title-wrapper {

      &.aw-rma__title {

        .heading--page {
          margin: 5px 0;
        }

        @media all and (min-width: 768px) {

          .aw-rma__actions {
            float: right;
          }

        }

      }

    }

  }

}

.aw_rma-guest-index {

  .aw-rma-new-guest {

    .form {

      .actions-toolbar {

        .primary {

          text-align: left;

          button {

            width: auto;

          }

        }

      }

    }

  }

}
