$select__margin                          : $spacer !default;
$select__padding                         : 0 $spacer--medium 0 $spacer--medium !default;
$select__border                          : 1px solid $form-elements-border-color !default;
$select__spacing                         : 40px !default;
$select__border-radius                   : 20px !default;
$select__field-background                : $white !default;
$select__field-list-border-radius--single: 20px !default;
$select__field-item-color                : #333 !default;
$select__field-item-padding              : $select__padding !default;
$select__field-item-margin               : 0 !default;
$select__field-item-border-bottom        : 1px $select__input-border-color solid !default;
$select__field-item-disabled-color       : #ccc !default;
$select__field-item-hover-border-bottom  : $select__field-item-border-bottom !default;
$select__field-item-hover-color          : $select__field-item-color !default;
$select__input-border-color              : $form-elements-border-color !default;
$select__input-focus-selected-border-color: $form-elements-border-color !default;
$select__input-focus-selected-color      : $select__field-item-color !default;
$select__input-caret-right               : 11.5px !default;

.select {

    margin-bottom: $select__margin;
    cursor: pointer;

    &__field {

        position: relative;
        z-index: 4;
        //background: $select__field-background;

        &--native {
            width: 100%;
            height: $select__spacing;
            padding: $select__padding;
            border: $select__border;
            border-radius: $select__border-radius;
            appearance: none;
            font-family: inherit;
        }

    }

    &__field-item {

        cursor: pointer;
        min-height: $select__spacing;
        font-size: $font-size-base;
        line-height: 1.25;
        margin: $select__field-item-margin;
        position: relative;
        padding: $select__field-item-padding;
        border-bottom: $select__field-item-border-bottom;

        &:last-child {
            border-bottom: none;
        }

        &.is-highlighted.choices__item--selectable {

            background-color: transparent;
            border-bottom: $select__field-item-hover-border-bottom;
            color: $select__field-item-hover-color;

        }

        &.choices__item--disabled {
            color: $select__field-item-disabled-color;
        }

    }

    &__field-list {

        background: $select__field-background;

        &--single {

            height: $select__spacing;
            line-height: $select__spacing;
            padding: 0;
            border: $select__border;
            border-radius: $select__border-radius;

        }

    }

    &__field-input {

        &:focus {
            outline: none;
        }

    }

}

.custom-select {

    width: 65px;
    margin-right: 10px;
    display: inline-block;

    select {

        display: block;
        font-size: 16px;
        font-family: sans-serif;
        font-weight: 700;
        color: #444;
        line-height: 1.3;
        padding: 5px 1.4em 5px 15px;
        width: 100%;
        max-width: 100%; /* useful when width is set to anything other than 100% */
        box-sizing: border-box;
        margin: 0;
        border: 1px solid #888888;
        border-radius: 25px;
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;
        background-color: #fff;
        background-image: url('../images/chevron-down@2x.png');
        background-repeat: no-repeat;
        background-position: right .4em top 50%, 0 0;
        background-size: 25px;

    /* Hide arrow icon in IE browsers */
    &::-ms-expand {
        display: none;
    }
    /* Hover style */
    &:hover {
        border-color: #888;
    }
    /* Focus style */
    &:focus {
        border-color: #aaa;
        /* It'd be nice to use -webkit-focus-ring-color here but it doesn't work on box-shadow */
        box-shadow: 0 0 1px 3px rgba(59, 153, 252, .7);
        box-shadow: 0 0 0 3px -moz-mac-focusring;
        color: #222;
        outline: none;
    }

    /* Set options to normal weight */
    option {
        font-weight:normal;
    }

    /* Disabled styles */
    &:disabled,
    &[aria-disabled=true] {
        color: graytext;
        background-image: url('../images/chevron-down@2x.png'),
        linear-gradient(to bottom, #ffffff 0%,#e5e5e5 100%);
    }

    &:disabled:hover,
    &[aria-disabled=true] {
        border-color: #aaa;
    }

}

    .select-replace{
        border-radius: 6px;
        border-right: 1px solid;
        width: 60px;
        border-left: 1px solid;
    }
}

.catalog-list-item .custom-select {
    width: 65px;
    margin-top: -15px;
    margin-bottom: 20px;
    margin-right: 10px;

    .quantity-update__input{
        font-size: 13px;
    }
}
.catalog-list-item__actions{
    .catalog-list-item__primary-form{
        label{
            display: inline-block;
        }
    }
}

// Choices.js lib styles overwrites
.choices {

    &.is-open {

        .select__field-list.select__field-list--single {

            border-color: $select__input-focus-selected-border-color;

            .select__field-item {
                color: $select__input-focus-selected-color;
            }

        }

    }

    &__list--dropdown {

        margin-top: -13px;
        padding-top: 20px;
        border-top: 0;
        max-height: 300px;
        overflow: auto;

        .is-open & {
            border-radius: 0 0 $select__border-radius $select__border-radius;
            border-color: $select__input-border-color;
        }

    }

    &[data-type*="select-one"]:after {

        right: $select__input-caret-right;
        background-image: url( '../images/chevron-down@2x.png' );
        background-size: 100% 100%;
        width: 24px;
        height: 24px;
        top: 50%;
        transform: translateY(-50%);
        margin: 0;
        margin-left: -2px;
        border: none;
        z-index: 26;

    }

}
