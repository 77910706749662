@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {

  /* IE10 / IE11 CSS styles go here */

  .header__buttons {

    .header-button__icon {
      stroke: #fff;
    }

  }

  .fullscreen-menu-wrapper {

    top: 136px;
    width: 0;
    height: 0;
    overflow: hidden;

    .fullscreen-menu-wrapper__item--open {

      > .fullscreen-menu-wrapper__inner-list {
        top: 86px;
      }

    }

  }

  .side-menu {

    &__content {

      height: auto;
      opacity: 0;
      width: 0;
      min-width: 0;

    }

    &:not(.side-menu--is-open) {

      .side-menu__trigger-wrapper {

        height: 0;
        opacity: 0;
        width: 0;
        min-width: 0;
        overflow: hidden;

      }

    }

    &.side-menu--is-open {

      .fullscreen-menu-wrapper {

        width: 100%;
        height: auto;
        overflow: visible;

      }

    }

  }

  body.firecheckout {

    .opc-block-summary {

      .minicart-items {

        .product-item {


          .product-image-container,
          .minicart-product__image {

            display: inline-block;
            min-width: 78px;

          }

        }

      }

    }

  }

  .input__field,
  .catalog-category-view #algolia_instant_selector .sbx-sffv__input {
    line-height: 1;
  }

  .cart-list-item__details {
    flex: 0 0 30%;
  }



  @media all and (min-width: 768px) {

    .header__wrapper {

      .side-menu__content {

        .side-menu__trigger-wrapper,
        .side-menu__trigger-main-menu.side-menu__trigger-main-menu {

          position: fixed;
          left: 0;
          z-index: 100;
          top: 86px;

        }

      }

    }

  }

}
