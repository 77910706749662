.catalogsearch-result-index {

    .heading--page {
        margin: 0 auto;
    }

    .title-wrapper {
        margin-bottom: 0;
    }

    .breadcrumbs {

        padding-left: 15px;
        padding-right: 15px;

    }

    /*.catalog-list__sidebar,
    .catalog-list__sidebar-additional {
        display: none!important;
    }*/

    .catalog-list {
        margin-top: 0;
    }

    .catalog-list__products {

        float: none;
        width: auto;
        padding: 0;

    }

    .products-grid {

        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        justify-content: flex-start;
        margin: 1px 1px 0px 0px;

        .catalog-grid-item {

            margin: -1px 0 0 -1px;
            border: 1px #888 solid;
            display: flex;
            width: 100%;

            .catalog-grid-item-inner {

                width: 100%;
                margin: 0;
                border: none;

            }

        }

        .secondary-addto-links {

            .button__text {
                display: none;
            }

        }

        .catalog-grid-item__name {
            padding: 0;
        }

        .catalog-grid-item__primary-form {
            display: block;
        }

        .catalog-grid-item__primary-action,
        .catalog-grid-item .dte-add-toquote-container,
        .catalog-grid-item .dte-add-toquote-container .button
        {

            width: 100%;
            max-width: 100%;
            margin: 0;

        }

        .catalog-grid-item__primary-action + .dte-add-toquote-container {
            margin-top: 10px;
        }

    }

    .algolia-instantsearch-full-searchinput-container{
        height: 5px;
        padding: 0;
    }
    #instant-search-bar-container{
        display: none;
    }
}

.related-search-terms {

    background: $nearly-white;
    padding: 15px;
    margin-bottom: 20px;

    .title {

        font-weight: 600;
        font-size: 16px;
        margin: 5px 0 12px;

    }

    .item {

        display: inline-block;
        margin: 4px 0;

        + .item {
            margin-left: 8px;
        }

        a {

            color: $thunder;
            font-weight: 300;
            font-size: 15px;
            background-color: #fff;
            border-radius: 50px;
            display: inline-block;
            padding: 4px 16px;

        }

    }

}

@media all and (min-width: 767px) {

    .catalogsearch-result-index {

        .products-grid {

            .catalog-grid-item {

                width: 33.33333333%;

            }

        }
    }

}

@media all and (min-width: 992px) {

    .catalogsearch-result-index {

        .products-grid {

            .catalog-grid-item {

                width: 25%;

            }

        }

    }

}

@media all and (min-width: 600px) and (max-width: 767px) {

    .catalogsearch-result-index {

        .products-grid {

            .catalog-grid-item {

                width: 50%;

            }

        }

    }

}

@media all and (max-width: 767px) {

    .catalogsearch-result-index {

        .title-wrapper {
            margin: 30px 0 0;
        }

        .toolbar {
            margin-bottom: 10px;
        }

        /*.toolbar__sorter-label {
            display: none;
        }

        .toolbar__sorter {

            border: none;
            width: 54%;

         }

        .toolbar__limiter {

            width: 43%;

            .toolbar__limiter-wrapper {

                display: block;
                width: 100%;

            }

         }
*/
    }

}

// Let search bar on mobile keep visibility when algolia results are open
body.header-search-visible header {


    @media all and (max-width: 1129px) {

        z-index: 9999 !important;

    }

}

// Search Autocomplete design update
body.amazon-store #maincontent {
   #algolia-autocomplete-container {
        .aa-dropdown-menu {
            .algoliasearch-autocomplete-price {
                .price {
                    &::after {
                        content: '';
                    }
                }
            }
        }
    }

}

#algolia-autocomplete-container {

    .aa-dropdown-menu {

        .autocomplete-wrapper {

            max-width: 1440px;
            margin: 0 auto;
            padding: 0 30px;

            .grid__column {

                padding-left: 0;

                .other-sections {

                    margin: 0 0 20px 0;

                }

            }

            .search-count-container {

                width: 100%;
                font-size: 25px;
                font-weight: 500;
                margin: 30px 0;

              }

              #autocomplete-products-footer {

                font-size: 14px;
                font-weight: 500;

                a {

                    font-weight: 500;

                }

              }

            .category,
            .products-header {

                font-size: 18px !important;
                font-weight: bold;
                color: $dark-grey;
                padding: 0 0 20px 0;
                border-bottom: 1px solid $border-input-grey;
                line-height: 1.33;
                margin: 0;

                strong {

                    font-weight: bold;

                }

            }

            .category {

                + .aa-suggestions {

                    padding-top: 20px;

                    .aa-suggestion {

                        a {

                            padding: 0 0 10px 0;
                            font-size: 15px;

                            em {

                                font-weight: 550;

                            }



                        }

                        // Item hover
                        &.aa-cursor {

                            background: white;

                        }

                        &:nth-of-type(1) {

                            padding-top: 0;

                        }

                        @media all and (max-width: 767px) {

                            &:nth-of-type(2) {

                                padding-top: 0;

                            }

                        }

                    }

                }

            }

            .aa-dataset-products  {

                .aa-suggestions {

                    margin: 0;

                    .aa-suggestion {

                        border: none !important;
                        padding-top: 20px !important;
                        padding-bottom: 0 !important;

                        // Item hover
                        &.aa-cursor {

                            background: white;

                        }

                        &:nth-of-type(1),
                        &:nth-of-type(2) {

                            padding: 20px 0;

                        }

                        @media all and (max-width: 767px) {

                            &:nth-of-type(2) {

                                padding: 0 0 20px 0;

                            }

                        }

                        a {

                            padding: 0;

                        }


                        &::after {

                            content: '';
                            display: block;
                            border-bottom: 1px solid $border-input-grey;
                            width: 100%;
                            padding-top: 20px;

                        }

                        @media all and (min-width: 768px) {

                            &:nth-child(even) {

                                padding-left: 21.5px;

                            }

                            &:nth-child(odd) {

                                padding-right: 21.5px;

                            }

                        }

                        .thumb {

                            margin-right: 10px;

                        }

                        .info {

                            .autocomplete-suggestion--name {

                                overflow: hidden;
                                text-overflow: ellipsis;
                                font-size: 17px;
                                font-weight: 500;
                                margin-bottom: 5px;

                            }

                            .autocomplete-suggestion--sku {

                                font-size: 13px;
                                color: $mid-grey;
                                margin-left: 5px;
                                width: 100%;

                                &::before {

                                    content: 'SKU: ';
                                    display: inline-block;
                                    font-size: 13px;
                                    color: $mid-grey;
                                    font-weight: bold;

                                }

                            }

                            .catalog-grid-item__sku {
                                font-size: 13px;
                                color: $mid-grey;
                                margin-left: 5px;
                                width: 100%;
                            }

                            .autocomplete-suggestion--uin {

                                font-size: 13px;
                                color: $mid-grey;
                                margin-left: 5px;
                                width: 100%;
                                float: left;

                                &::before {

                                    content: 'SKU: ';
                                    display: inline-block;
                                    font-size: 13px;
                                    color: $mid-grey;
                                    font-weight: bold;

                                }

                            }

                            .info-details {

                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                                flex-wrap: wrap;

                                &--right {

                                    display: flex;
                                    justify-content: space-between;
                                    align-items: center;

                                    // @media all and (min-width: 768px) and (max-width: 1129px) {

                                    //     width: 100%;
                                    //     margin-bottom: 20px;

                                    // }

                                    // @media all and (max-width: 500px) {

                                    //     width: 100%;
                                    //     margin-bottom: 20px;

                                    // }

                                }

                                .algoliasearch-autocomplete-price {

                                    margin: 0;

                                    @media all and (min-width: 768px) and (max-width: 1129px) {

                                        width: 100%;
                                        margin-bottom: 10px;

                                    }

                                    @media all and (max-width: 500px) {

                                        width: 100%;
                                        margin-bottom: 10px;

                                    }

                                    .price {

                                        font-size: 18px;
                                        font-weight: bold;
                                        display: block;

                                        &::first-letter {

                                            font-size: 12px;
                                            font-weight: 500;

                                        }

                                        &::after {

                                            content: ' ex VAT';
                                            display: inline-block;
                                            font-size: 12px;
                                            font-weight: 500;
                                            margin-left: 5px;

                                        }

                                    }

                                }

                                @media all and (min-width: 1130px) {

                                    position: absolute;
                                    bottom: 0;
                                    width: 75%;

                                }

                            }

                            .product-stock-container {

                                margin-right: 20px;

                                .stock-status-inner {

                                    display: flex;
                                    color: $alternative-green;

                                    img,
                                    svg {

                                        width: 20px;

                                    }

                                    img {

                                        margin-right: 5px;

                                    }

                                    svg {

                                        margin-right: 2px;

                                    }


                                }

                            }

                            .add-to-wishlist {

                                button {

                                    background: none;
                                    border: none !important;
                                    padding: 0 5px;
                                    height: 100%;
                                    min-width: auto;

                                }

                            }

                        }

                    }

                }

            }

        }

    }

}
