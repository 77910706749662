$cookie-message__width                  : 100% !default;
$cookie-message__background-color       : $gray-lightest !default;
$cookie-message__font-size              : $font-size-small !default;
$cookie-message__font-family            : $font-family-base !default;
$cookie-message__text-color             : $gray !default;
$cookie-message__text-padding           : $spacer--medium $spacer--medium 0 $spacer--medium !default;
$cookie-message__text-padding--small    : $spacer--medium $spacer $spacer--medium $spacer--medium !default;
$cookie-message__link-color             : $blue !default;
$cookie-message__close-color            : $gray !default;
$cookie-message__close-size             : 48px !default;
$cookie-message__close-font-weight      : $font-weight-bold !default;
$cookie-message__close-padding          : 0 $spacer--medium 0 0 !default;
$cookie-message__close-padding--small   : 0 $spacer--medium 0 $spacer !default;
$cookie-message__close-icon-fill        : $gray !default;
$cookie-message__close-icon-size        : 14px !default;
$cookie-message__close-icon-margin-left : $spacer !default;

.message.global.cookie {

    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9999999999999999;
    background-color: #e5e5e5;
    border-radius: 0;
    border: none;
    margin: 0;

    .actions {

        position: absolute;
        right: 15px;
        top: 50%;
        transform: translateY(-50%);

    }

    .content {

        > p {
            margin: 10px 183px 10px 0;
        }

    }

    #btn-cookie-allow {
        width: auto;
    }

}

.cookie-message {
    display: none;
    width: $cookie-message__width;
    background-color: $cookie-message__background-color;
    box-sizing: border-box;
    font-size: $cookie-message__font-size;

    &__container {
    }

    &__text {
        color: $cookie-message__text-color;
        text-align: justify;
        padding: $cookie-message__text-padding;
        font-size: inherit;
        font-family: inherit;

        @include mq($screen-s) {
            text-align: left;
            padding: $cookie-message__text-padding--small;
        }
    }

    &__link {
        color: $cookie-message__link-color;
    }

    &__close {

    }


    &--open {

        display: block;
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 10;

    }

}

.cookie-message__text > a {
    @extend .cookie-message__link;
}
