#maincontent {

  .aw-rma__actions {

    margin-top: 10px;

    > .button {

      display: inline-block;
      width: auto;

      &[data-role=aw-rma-back-button] {

        float: left;

      }

    }

  }

  .aw-rma__block-items {

    .block-title {

      font-size: 17px;
      margin: 15px 0;
      padding: 15px;
      background-color: #f5f5f5;

    }

  }

  .aw-rma__block-general {

    .aw-rma__box-contact-info {

      margin: 50px 0 15px;

      .box-title {

        font-weight: bold;
        margin-bottom: 10px;

      }

      .box-content {

        line-height: 1.6;

      }

    }

  }

  .aw-rma__block-thread {

    > .actions {

      .aw-rma-update-request-button {

        margin-top: 20px;

      }

    }

    ul.messages {

      .aw-rma-view__thread-message {

        width: 49.5%;
        float: left;
        margin: 0 0 5px;

        &:nth-child(odd) {

          margin: 0 1% 7px 0;

        }

      }

    }

  }
}

// styles for RMA login / index
.aw_rma-guest-index {

  .title-wrapper {

    .heading--page {

      margin: 16px 0 0 0;
      width: 100%;
      text-align: center;

    }

  }

  .aw-rma-new-guest {

    display: flex;
    justify-content: center;
    align-items: stretch;
    margin: 0 16px;

    @media all and (max-width: 1024px) {

      flex-wrap: wrap;

    }

    .block-customer-login {

      @media all and (min-width: 1025px) {

        margin-right: 20px;

      }

    }

    .block-customer-login,
    .block-guest-order {

      border: 1px solid #e0e0e0;
      border-radius: 3px;
      padding: 35px 35px 110px 35px;
      position: relative;
      margin-bottom: 20px;
      max-width: none;

      @media all and (max-width: 1024px) {

        width: 51%;

      }

      .block-title {

        font-size: 18px;
        border: 0;

        strong {

          font-weight: bold !important;

        }

      }

      .block-content {

        font-size: 15px;

      }

      form {

        .fieldset {

          max-width: 480px;

          .field.email,
          .field.password,
          .field.order-number {

            display: flex;
            align-items: center;
            margin-bottom: 20px;

            @media all and (max-width: 1140px) {

              flex-wrap: wrap;

            }

            label {

              width: 20%;
              font-size: 15px;
              color: #686868;
              text-transform: capitalize;

              @media all and (max-width: 1140px) {

                width: 100%;
                margin-bottom: 5px;

              }

            }

            .control {

              width: 80%;

            }

          }

          a.remind {

            margin-bottom: 20px;

          }

          .actions-toolbar {

            position: absolute;
            bottom: 35px;
            margin: 0;
            justify-content: space-between;
            padding-right: 70px;

            .primary button {

              margin: 0;

            }

          }

        }

      }

    }

  }

  .login__input {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    label {
      flex-basis: 30%;
      font-size: 15px;
      color: #686868;
      text-transform: capitalize;
    }

    input {
      flex-basis: 60%;
    }

    @media all and (max-width: 950px) {
      label {
        flex-basis: 100%;
      }
    }
  }

    @media all and (max-width: 800px) {

        .aw-rma-new-guest {

            width: 100%;
            display: block;
            margin: 0;

            .block-customer-login,
            .block-guest-order {

                width: 100%;

            }

        }

    }

}
