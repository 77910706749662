$dashboard-nav-padding: 10px !default;
$dashboard-nav-margin: 10px !default;

.dashboard-nav {
  margin-bottom: $dashboard-nav-margin;

  &__mobile {
    display: block;
    position: relative;
    border-width: 1px 0;
    border-color: $gray-dark;
    border-style: solid;
    cursor: pointer;
    font-size: 18px;
    font-weight: bold;
    margin-left: 20px;
    padding: 10px 10px 10px 0;
    border-bottom: 1px solid $hairline-grey;
    border-top: 0;

    @include mq($screen-m) {
      display: none;
    }

    &:after {
      content: '';
      position: absolute;
      top: 50%;
      right: 0;
      background-image: url("data:image/svg+xml,%3Csvg width='24px' height='24px' viewBox='0 0 24 24' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3C!-- Generator: Sketch 52.5 (67469) - http://www.bohemiancoding.com/sketch --%3E%3Ctitle%3EIcon / System / 24px / Utility Blue / Chevron Right 3 2 %3C/title%3E%3Cdesc%3ECreated with Sketch.%3C/desc%3E%3Cg id='Icon-/-System-/-24px-/-Utility-Blue-/-Chevron-Right--3--2-' stroke='none' stroke-width='3' fill='none' fill-rule='evenodd' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline id='Chevron' stroke='%232e3687' points='8.93279275 4.54234436 16.431825 12.0413766 8.93279275 19.5396818'%3E%3C/polyline%3E%3C/g%3E%3C/svg%3E");
      height: 24px;
      width: 24px;
      background-repeat: no-repeat;
      transform: translate(0%, -50%) scale(65%) rotate(90deg);
      transition: all ease-in-out 0.2s;
    }

    &--active {
      &:after {
        transform: translate(0%, -50%) scale(65%) rotate(-90deg);
      }
    }

    + .dashboard-nav__content {
      .dashboard-nav__title {
        @media all and (max-width: 767px) {
          display: none !important;
        }
      }
    }
  }

  &__content {
    display: none;
    padding: $dashboard-nav-padding;
    margin-bottom: $dashboard-nav-margin;

    &--visible {
      display: block;
    }

    @include mq($screen-m) {
      display: block;
    }
  }

  &__list {
    margin: 0;
  }

  &__item {
    transition: $transition-base;
    padding: 0;

    a,
    strong {
      color: $dte-blue;
      font-weight: bold;
      padding: 10px 10px 10px 0;
      margin-left: 10px;
      border-bottom: 1px solid #E0E0E0;
      height: 40px;
      display: flex;
      align-items: center;
    }

    &:hover {
      background-color: $white;
    }

    .delimiter {
      display: none;
    }
  }

  &__title {
    margin-bottom: $dashboard-nav-margin;
    padding-bottom: 10px;
    border-bottom: 1px solid #E0E0E0;
  }

  &__item > a,
  &__link {
    font-size: 15px;
    height: 44px !important;
    display: block;
  }

  // This class is changing by Magento
  .current {
    position: relative;

    &:hover {
      background-color: initial;
    }

    & > a {
      padding: 0;
    }

    &:after {
      content: '';
      position: absolute;
      top: 50%;
      right: 0;
      background-image: url("data:image/svg+xml,%3Csvg width='24px' height='24px' viewBox='0 0 24 24' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3C!-- Generator: Sketch 52.5 (67469) - http://www.bohemiancoding.com/sketch --%3E%3Ctitle%3EIcon / System / 24px / Utility Blue / Chevron Right 3 2 %3C/title%3E%3Cdesc%3ECreated with Sketch.%3C/desc%3E%3Cg id='Icon-/-System-/-24px-/-Utility-Blue-/-Chevron-Right--3--2-' stroke='none' stroke-width='3' fill='none' fill-rule='evenodd' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline id='Chevron' stroke='%232e3687' points='8.93279275 4.54234436 16.431825 12.0413766 8.93279275 19.5396818'%3E%3C/polyline%3E%3C/g%3E%3C/svg%3E");
      height: 24px;
      width: 24px;
      background-repeat: no-repeat;
      transform: translate(0%, -50%) scale(65%);
    }
  }

  // This clases to disabled links module
  .nav.item {
    @extend .list__item;
    @extend .dashboard-nav__item;
  }

  .nav.item.current {
    @extend .current;
  }

  .nav.item.current {
    & > a {
      color: $red;
    }
  }

  .nav.item > a {
    @extend .link--invert;
  }
}
