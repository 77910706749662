// Add any blog page specific styles here

.blog-page {
    &.blog-post-view {
        .column.main {
            @media all and (min-width: 768px) {
                flex: 1 0 calc(100% - 35%);
                max-width: calc(100% - 35%);
            }

            @media all and (min-width: 1130px ) and (max-width: 1456px) {
                margin-left: 15px;
            }
        }

        .sidebar {
            padding-right: 15px;

            > div:not(:last-of-type) {
                border-bottom: 1px solid $blog-grey-lighter;
            }

            @media all and (min-width: 768px) {
                padding-left: 2rem;
                flex: 0 0 35%;
                max-width: 35%;
                position: sticky;
                top: 20px;
                height: 100%;
            }

            @media all and (min-width: 991px) {
                padding-left: 3rem;
            }

            @media all and (min-width: 1140px) {
                padding-left: 4rem;
            }

            @media all and (min-width: 1250px) {
                padding-left: 5rem;
            }
        }
    }

    .blog-category-view {

        nav.breadcrumbs,
        .title-wrapper {
            display: none;
        }
    }
}


.blog-post-view .post-holder {
    .post-banner-container {
        margin-bottom: 3rem;

        .post-banner-image {
            border-radius: 10px;
            width: 100%;
            min-height: 480px;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            display: flex;
        }

        .post-banner-content {
            display: flex;
            flex-direction: column;
            gap: 1rem;
            justify-content: center;
            padding: 30px 30px 30px 80px;

            @media all and (max-width: 768px) {
                text-align: center;
                padding: 30px;
            }

            .post-banner-title h1 {
                color: white;
                font-size: 56px;
                margin-bottom: 1rem;
                text-shadow: 4px 4px black;
                background-color: rgba(255, 255, 255, 0.6);

                @media all and (max-width: 1140px) {
                    font-size: 40px;
                }
            }

            .related-post-category {
                margin-right: 1rem;

                a {
                    color: white !important;
                    font-size: 18px;
                    text-decoration: none;
                }
            }

            .post-date {
                color: $blog-body-lighter;
                font-size: 18px;
            }
        }
    }

    p {
        color: $blog-body-light;
        font-size: 18px;
    }

    h2, h3, h4, h5, h6 {
        color: $blog-body;
    }

    h2 {
        font-size: 38px;
        font-weight: bold;
        margin: 3rem 0;
    }

    h4 {
        font-size: 24px;
        margin: 1.5rem 0;
    }

    a {
        color: $blog-red-dark;
        text-decoration: underline;

        &:hover,
        &:focus {
            color: $blog-red-light;
        }
    }

    figure {
        margin: 4rem 0;
    }

    figcaption {
        color: $blog-grey-darker;
        font-size: 18px;
    }
}
