.modal-popup {

  // Need a way of differentiating small vs normal modals

  .quote-popup-modal-content {

    > .loader {

      position: static;
      margin: 300px;

    }

    .quote-list-existing-container {

      .quote-popup-button-existing {

        position: relative;

        &:after {

          content: '+';
          position: absolute;
          right: 15px;
          font-size: 29px;
          font-weight: 100;
          margin-top: -6px;

        }

      }

    }

  }

  .modal-inner-wrap {
    max-width: 600px;
    width: 90%;
    @extend %shadow--l3;

    .modal-header {

      padding: 0;
      text-align: right;

      h1.heading--page {
        display: none;
      }

    }

    .quote-list-actions-container {

      margin-top: 15px;
      text-align: right;

    }
  }

  .modal-title {
    margin: 0 0 30px;
    text-align: center;
  }

  button + button {
    margin-left: 15px;
  }

  .quote-list-existing-container {

    .quote-popup-button-existing {

      display: block;
      margin: 0 0 7px;
      width: 100%;
      text-align: left;
      background-color: #f2f2f2;
      color: black;
      border-radius: 3px;

      &:nth-child(odd) {

        background-color: #e6e6e6;

      }

    }

    + button.quote-popup-button-cancel {

      margin: 20px 0 0;
      float: right;

    }

  }

  .modal__close-button {

    position: static;
    min-width: 0;
    padding: 0;
    min-height: 0;
    height: 44px;
    width: 44px;
    padding: 10px;
    margin: 10px 10px 0;

    &-icon {
      width: 15px;
      height: 15px;
    }

    &:hover {

      background-color: transparent;

    }
  }

  &.popup-authentication{

    .modal-inner-wrap{
      max-width: 900px;
    }

  }

}

@media all and (min-width: 768px) {

  .modal-popup.popup-authentication{

    .modal-inner-wrap{

      .block-new-customer{

        margin-bottom: 78px;

      }

    }

  }

}
