$amazonCategoryTopNavigationCols: 11;
$categoryTopNavigationCols: 9;
$categoryTopNavigationSubcategoriesCols: 4;

.amazon-store {
    .top-navigation-categories-container {
        .top-navigation-categories--content {
            .level-0--container {
                .level1 {
                    @media all and (min-width: 768px) {
                        width: auto;
                    }
                }
            }
        }
    }

    .category-sidebar-categories--content {
        .non-approved {

            display: none;

            .level-1--container:first-child {
                display: none !important;
            }
        }

    }
}

.top-navigation-categories-container {

    margin: auto;
    width: 100%;
    z-index: 29;
    background-color: $color-primary;
    max-width: 1440px;

    .top-navigation-categories--content {

        @media all and (min-width: 1024px) and (max-width: 1129px) {

            border-top: 1px solid white;

        }

        ul,
        li {

            padding: 0;
            margin: 0;
            list-style: none;

        }

        .level-0--container {

            background-color: $color-primary;
            display: flex;

            .expand {
                display: none;
            }

            .level1 {

                min-height: 48px;
                vertical-align: top;
                background-color: $color-primary;
                transition: all 0.35s;
                position: relative;
                display: flex;
                align-items: center;

                &:hover {
                    color: #fff;
                }

                > a {

                    color: #fff;
                    text-decoration: none;
                    display: block;
                    transition: all 0.35s;
                    font-size: 14px;
                    line-height: 1.2;
                    font-weight: 600;
                    margin: 0;
                    padding: 0 12px;
                    text-align: center;
                }

                > ul.level-2--container {

                    display: block;
                    overflow: visible;
                    height: 0;
                    visibility: hidden;
                    opacity: 0;
                    max-height: 0;
                    width: auto;
                    min-width: 310px;
                    transition: opacity 0s;
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 100%;
                    padding: 0;
                    font-size: 13px;

                    > li.level-2--container {

                        > a {

                            display: block;
                            padding: 5px 25px;
                            position: relative;
                            color: $thunder;
                            transition: background-color 200ms ease-in-out, color 200ms ease-in-out;
                            font-size: 14px;
                            font-weight: 500;
                            cursor: pointer;

                            &:hover {

                                text-decoration: none;
                                background-color: #F1F8FB;
                                color: #2f358a;

                            }

                        }

                        &.hover {

                            > a {

                                text-decoration: none;
                                background-color: #F1F8FB;
                                color: #2f358a;

                            }

                        }

                        &.has-child-categories {

                            a {

                                &:after {

                                    content: '\203A';
                                    position: absolute;
                                    right: 15px;
                                    margin: 0 8px;
                                    color: #686868;
                                    font-size: 25px;
                                    font-weight: 300;
                                    top: 50%;
                                    transform: translateY(-56%);

                                }

                            }

                        }

                        ul.level-3--container {

                            display: none;
                            width: auto;
                            min-width: 320px;
                            padding: 12px 0 19px;
                            background-color: #fff;
                            opacity: 0;
                            transition: opacity 0.35s;
                            max-height: 100vh;
                            min-height: 400.5px;
                            position: absolute;
                            left: 100%;
                            top: 0;
                            border-left: 1px solid #e6e6e6;

                            > li.level-3--container {

                                a {

                                    color: #2f358a;
                                    font-weight: bold;
                                    display: block;
                                    padding: 5px 25px;
                                    position: relative;
                                    transition: background-color 200ms ease-in-out, color 200ms ease-in-out;
                                    cursor: pointer;

                                    &:after {

                                        content: none;

                                    }

                                    &:hover {

                                        text-decoration: underline;

                                    }

                                }

                                &:last-child {

                                    margin-top: 30px;

                                    > a {

                                        color: #0AB6FF;
                                        background: white;
                                        font-weight: 500;
                                        font-size: 13px;

                                        &:hover {

                                            text-decoration: none;
                                            font-weight: bold;
                                        }

                                    }

                                }

                            }

                        }

                        &:hover {

                            > ul.level-3--container {

                                display: block;
                                opacity: 1;

                            }

                        }

                        &:last-child {

                            margin-top: 30px;

                            > a {

                                color: #0AB6FF;
                                background: white;
                                font-size: 13px;

                                &:hover {

                                    font-weight: bold;

                                }

                            }

                        }

                    }

                }

                &.hover {

                    > ul.level-2--container {

                        min-height: 400.5px;
                        visibility: visible;
                        height: auto;

                    }

                }

            }

        }

    }

}

.catalog-category-view {

    .catalog-list {

        margin-top: 0;

    }

    #algolia_instant_selector {

        .category-toolbar-top-parent-container {

            margin: 0;

            > .toolbar {

                display: none;

            }

            > .row {

                margin-right: 0;
                margin-left: 0;

                > #algolia-right-container {

                    padding: 0;

                }

            }

        }

    }

    .catalog-list__products .hero-slider-partners {

        margin-top: 25px;

    }

}

#algolia-right-container {

    .algolia-instantsearch-full-searchinput-container {

        text-align: center;

        @media all and (max-width: 991px) {

            padding-top: 0;
            padding-bottom: 0;
            margin-top: 15px;
            margin-bottom: 0;

        }

        @media all and ( min-width: 992px ) {

            display: flex;
            justify-content: space-between;
            align-items: center;

        }

        .toolbar__mode {

            display: inline-block;
            max-width: 40%;
            opacity: 0;
            transition: opacity 150ms ease-in-out;
            float: right;

            &.loaded {

                opacity: 1;

            }

            > button {
                background-color: transparent;
            }

        }

        #instant-search-bar-container {

            margin: 0;
            display: inline-block;
            max-width: none;
            opacity: 0;
            transition: opacity 150ms ease-in-out;

            @media all and (min-width: 992px) {

                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-right: 15px;

            }

            ~ #sort-by-container {

                font-size: 16px;
                position: relative;
                order: 1;
                margin-left: 15px;

                @media all and (max-width: 991px) {

                    display: none;

                }

                .ais-root.ais-sort-by-selector {

                    &::after {

                        text-align: left;
                        content: 'Sort By';
                        display: block;
                        width: -webkit-max-content;
                        width: -moz-max-content;
                        width: max-content;
                        position: absolute;
                        left: 25px;
                        top: 50%;
                        transform: translateY(-50%);
                        font-size: 16px;
                        pointer-events: none;
                        width: calc(100% - 45px);
                        background: white;

                    }

                }

                select.ais-sort-by-selector {

                    font-family: $font-family-proxima-nova;
                    color: #333;
                    background-color: #fff;
                    padding: 15px 50px;
                    width: 50px;
                    height: 34px;
                    box-sizing: border-box;
                    border: 1px solid #e0e0e0;
                    border-radius: 4px;
                    -webkit-appearance: none;
                    border-bottom-width: 3px;

                    * {

                        font-size: 16px;

                    }

                }

            }

            &.loaded {

                opacity: 1;

            }

            @media all and (min-width: 992px) {

                .toolbar__category_name{
                  font-size:120%;
                  font-weight:bold;
                }

                #toolbar-amount {

                    flex: none;

                    .ais-stats--body {

                        > div > span {

                            font-size: 16px;
                            padding: 0 35px 0 0;

                        }

                    }

                }

                #instant-search-box {

                    flex: 1;

                }

            }

            @media all and (max-width: 991px) {

                .toolbar__category_name{

                    display: none!important;

                }

            }

            @media all and (max-width: 992px) {

                #toolbar-amount {

                    display: none;

                }

            }

        }

        @media all and ( min-width: 992px ) and ( max-width: 1200px ) {

            align-items: end;

            #instant-search-bar-container {

                display: block;
                justify-content: start;
                text-align: left;

                > .toolbar__category_name,
                > #toolbar-amount {

                    display: inline-block;

                }

                > #instant-search-box {

                    margin-top: 10px;

                }

            }

        }

    }

}

.toolbar_mode {

    display: none;

}

@media all and (min-width: 1024px) {

    .top-navigation-categories-container {

        .top-navigation-categories--content {

            .level-0--container {

                .level1 {

                    // Open style
                    &.hover {

                        cursor: pointer;

                        > .level-2--container {

                            opacity: 1;
                            max-height: 500px; // fallback
                            max-height: 100vh;
                            max-width: 100%;
                            background-color: #ffffff;
                            padding: 12px 0 19px;
                            z-index: 10;
                            transition: opacity 0.35s;

                        }

                    }

                    // Active menu state style
                    &.hover {

                        background-color: white;
                        border-top: 1px white solid;
                        z-index: 6;

                        > a {
                            color: #2e3687 !important;
                            font-weight: 600;
                        }

                    }

                    &.active {

                        > a {
                            color: #B8EAFF;

                        }

                    }

                }

                .level2 {

                    // Open style
                    &:hover {

                        background-color: $lipstick;

                        > a {

                            color: #fff;

                        }

                    }

                }

                .level-3--container {

                    .level3 {

                        // Open style
                        &:hover,
                        &.active {

                            background-color: $lipstick;

                            > a {

                                color: #fff;

                            }

                        }

                    }

                }

            }

        }

    }

}

@media all and (max-width: 1039px) {

    .top-navigation-categories-container {

        .mobile-button-container {

            //display: block !important;
            padding: 10px 15px;

            .button-toggle-navigation-categories-mobile {

                display: inline-block;
                width: auto;

            }

        }

        .top-navigation-categories--content {

            .level-0--container {

                .expand {

                    display: inline-block;
                    position: absolute;
                    right: 0;
                    top: 12px;
                    z-index: 1;

                    .button__icon {

                        display: none;
                        width: 16px;
                        height: 16px;

                    }

                }

                .level1 {

                    width: 100%;
                    min-height: 0;
                    display: block;
                    border-left: none;
                    position: relative;

                    &:last-child {
                        border-right: none;
                    }

                    > a {
                        padding: 12px 0;
                    }

                    &.active,
                    &.open {

                        margin: 0 -15px;
                        width: auto;

                        > a {

                            background-color: $lipstick;
                            color: #fff;
                            padding: 12px 12px;

                        }

                        > .expand {

                            right: 13px;
                            top: 12px;
                            border-radius: 50%;
                            background: #fff;
                            padding: 2px;

                        }

                    }

                    &.open {

                        > .level-2--container {

                            background: $light-navy-blue;
                            opacity: 1;
                            position: static;
                            max-height: none;
                            max-width: none;
                            padding: 5px;

                        }

                        > .expand {

                            .button__icon.expanded-icon {
                                display: block;
                            }

                        }

                    }

                    &:not(.open) {

                        > .expand {

                            .button__icon.expand-icon {
                                display: block;
                            }

                        }

                    }

                }

                .level2 {

                    width: 100%;
                    display: block;

                }

                .level-3--container {

                    width: 100%;
                    display: block;

                    .level3 {

                        > a {

                            color: #fff;
                            padding: 12px 12px;

                        }

                        &.active {

                            > a {

                                background: $lipstick;
                                margin: 0 -5px;

                            }

                        }

                    }

                }

            }

        }

        &.categories-open {

            .top-navigation-categories--content {
                display: block;
            }

            .mobile-button-container {

                .button-toggle-navigation-categories-mobile {

                    .inactive-text {
                        display: none;
                    }

                    .active-text {
                        display: inline;
                    }

                }

            }

        }

        &:not(.categories-open) {

            .top-navigation-categories--content {
                display: none;
            }

            .mobile-button-container {

                .button-toggle-navigation-categories-mobile {

                    .inactive-text {
                        display: inline;
                    }

                    .active-text {
                        display: none;
                    }

                }

            }

        }

    }

}

//.catalog-product-view .top-navigation-categories-container {
//
//  .mobile-button-container {
//
//    display: none !important;
//
//  }
//
//}

@media all and (min-width: 992px) and (max-width: 1023px) {

    .top-navigation-categories-container {

        .mobile-button-container {

            .button-toggle-filters-mobile {
                display: none;
            }

        }

    }

}

@media all and (max-width: 767px) {

    $mobileNavigationCategoryContainerTopPosition: 67px;

    .top-navigation-categories-container {

        top: $mobileNavigationCategoryContainerTopPosition;
        overflow: scroll;
        max-height: calc(100% - #{$mobileNavigationCategoryContainerTopPosition});

        .top-navigation-categories--content {

            .level-0--container {

                li.level1 {

                    &.categories-open {

                        > span.expand {

                            .button__icon {

                                &.expand-icon {
                                    display: none !important;
                                }

                                &.expanded-icon {
                                    display: block;
                                }

                            }

                        }

                        > .level-2--container {

                            position: static;
                            opacity: 1;
                            max-height: none;
                            max-width: none;

                            li.level3 {

                                a {

                                    color: #231f20;

                                }

                            }

                        }

                    }

                    > span.expand {

                        .button__icon {

                            width: 26px;
                            height: 26px;
                            margin: -5px;
                            padding: 5px;

                        }

                    }

                }

            }

        }

    }

}

// Fix for top category navigation menu breaking out of page
// Amazon - 11 Categories
.amazon-store {
    .top-navigation-categories-container {
        .top-navigation-categories--content {
            .container {
                .level-0--container {
                    li.level1 {
                        &:nth-last-child(-n+5) { // Last 5 items
                            ul.level-2--container {

                                left: unset;
                                text-align: left;

                                li.level-2--container {

                                    &.has-child-categories {
                                        // a {
                                        //     &::after {

                                        //         right: unset;
                                        //         left: 15px;
                                        //         transform: translateY(-56%) rotateY(-180deg);

                                        //     }
                                        // }

                                        .level-3--container {

                                            left: unset;
                                            right: 100%;

                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

// DTE - 9 Categories
.top-navigation-categories-container {
    .top-navigation-categories--content {
        .container {
            .level-0--container {

                justify-content: space-around;
                padding: 0px 20px;

                @media all and (max-width: 1100px) {
                    padding: 0;
                }

                li.level1 {

                    text-align: center;

                    ul.level-2--container {

                        text-align: left;

                    }

                    &:nth-last-child(-n+4) { // Last 4 items
                        ul.level-2--container {

                            left: unset;
                            text-align: left;

                            li.level-2--container {

                                &.has-child-categories {
                                    // a {
                                    //     &::after {

                                    //         right: unset;
                                    //         left: 15px;
                                    //         transform: translateY(-56%) rotateY(-180deg);

                                    //     }
                                    // }

                                    .level-3--container {

                                        left: unset;
                                        right: 100%;

                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.menu_overlay.active {

    background-color: rgba(0,0,0,0.43);
    width: 100vw;
    height: 100vh;
    position: absolute;
    z-index: 5;

}
