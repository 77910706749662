//Container
$quantity-update__container-border: none !default;
$quantity-update__container-border-radius: none !default;
$quantity-update__container-box-shadow: none !default;

// Input
$quantity-update__input-border: none !default;
$quantity-update__input-border-radius: none !default;
$quantity-update__input-width: 48px !default;
$quantity-update__input-height: 48px !default;
$quantity-update__input-color: $gray-dark !default;

// Button
$quantity-update__button-background: $white !default;
$quantity-update__button-color: $gray-dark !default;
$quantity-update__button-border: none !default;
$quantity-update__button-background-hover--disabled: $white !default;
$quantity-update__button-fill-hover--disabled: $gray !default;

// Icon
$quantity-updater__icon-fill: #000 !default;
$quantity-updater__icon-spacing: 16px !default;

// Error
$quantity-updater__error-margin: 0 0 0 $spacer !default;
$quantity-updater__error-max-width: 120px !default;

.quantity-update {

  display: flex;
  width: max-content;
  border: $quantity-update__container-border;
  border-radius: $quantity-update__container-border-radius;
  box-shadow: $quantity-update__container-box-shadow;

  &__input {
    border: $quantity-update__input-border;
    border-radius: $quantity-update__input-border-radius;
    width: $quantity-update__input-width;
    height: $quantity-update__input-height;
    color: $quantity-update__input-color;
    text-align: center;
    -webkit-appearance: none;
    -moz-appearance: none;
    padding-top: 0;
    padding-bottom: 0;
    line-height: 1;

    &:focus {
      outline: none;
    }

  }

  &__button {

    border: $quantity-update__button-border;
    background: $quantity-update__button-background;
    color: $quantity-update__button-color;
    margin: 0;
    padding-left: 15px;
    padding-right: 15px;
    min-width: 50px;
    line-height: 1;

    &--plus {

      font-size: 24px;
      font-weight: normal;

    }

    &--minus {
      font-size: 30px;
      position: relative;
      right: 0;
      bottom: 2px;
      font-weight: normal;

    }

    &--disabled {

      pointer-events: none;

      &:hover,
      &:focus {

        background: $quantity-update__button-background-hover--disabled;

        .quantity-update__icon {
          fill: $quantity-update__button-fill-hover--disabled;
        }

      }

      .quantity-update__icon {
        fill: $quantity-update__button-fill-hover--disabled;
      }

    }

    .button__icon {

      fill: $quantity-update__button-color;
      fill: inherit;

    }

    &:hover,
    &:focus {
      background: transparent;
      color: $dte-bright-blue;
      border: none;
    }

  }

  &__icon {

    fill: $quantity-updater__icon-fill;
    width: $quantity-updater__icon-spacing;
    height: $quantity-updater__icon-spacing;

    use {
      stroke: $quantity-updater__icon-fill !important;
    }

  }

  div.mage-error {

    order: 1;
    margin: $quantity-updater__error-margin;
    max-width: $quantity-updater__error-max-width;

  }

  // Disable spin buttons in number input type
  input[type="number"]::-webkit-outer-spin-button, // sass-lint:disable-line no-vendor-prefixes
  input[type="number"]::-webkit-inner-spin-button { // sass-lint:disable-line no-vendor-prefixes

    -webkit-appearance: none; // sass-lint:disable-line no-vendor-prefixes
    margin: 0;

  }

  input[type="number"] { // sass-lint:disable-line no-vendor-prefixes
    -moz-appearance: textfield; // sass-lint:disable-line no-vendor-prefixes
  }

}
