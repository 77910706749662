@import url('https://fonts.googleapis.com/css?family=M+PLUS+Rounded+1c:300,400|Montserrat:300,400,600,700');

// Text styles from Sketch

@font-face {
    font-family: 'Proxima Nova';
    src: url('../fonts/proxima/proximanova-black.woff2') format('woff2'), url('../fonts/proxima/proximanova-black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Proxima Nova';
    src: url('../fonts/proxima/proximanova-bold.woff2') format('woff2'), url('../fonts/proxima/proximanova-bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Proxima Nova';
    src: url('../fonts/proxima/proximanova-semi-bold.woff2') format('woff2'), url('../fonts/proxima/proximanova-semi-bold.woff') format('woff');
    font-weight: 550;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Proxima Nova';
    src: url('../fonts/proxima/proximanova-medium.woff2') format('woff2'), url('../fonts/proxima/proximanova-medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Proxima Nova';
    src: url('../fonts/proxima/proximanova-light.woff2') format('woff2'), url('../fonts/proxima/proximanova-light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Proxima Nova';
    src: url('../fonts/proxima/proximanova-regular.woff2') format('woff2'), url('../fonts/proxima/proximanova-regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Proxima Nova';
    src: url('../fonts/proxima/proximanova-thin.woff2') format('woff2'), url('../fonts/proxima/proximanova-thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

.Comments-Grey-2-Centre {
    font-family: $font-family-rounded-m-plus;
    font-size: 24px;
    font-weight: 300;
    line-height: 1.33;
    color: $white;
}

.Body-2-Black-Bold-Centre {
    font-family: $font-family-montserrat;
    font-size: 14px;
    font-weight: 600;
    line-height: 1.57;
    color: $thunder;
}

.Body-2-Black-Reg-Centre {
    font-family: $font-family-montserrat;
    font-size: 14px;
    line-height: 1.57;
    color: $thunder;
}

.Body-1-Black-Bold-Centre {
    font-family: $font-family-montserrat;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.5;
    color: $thunder;
}

.Body-1-Black-Reg-Centre {
    font-family: $font-family-montserrat;
    font-size: 16px;
    line-height: 1.5;
    color: $thunder;
}

.H5-Black-Centre {
    font-family: $font-family-montserrat;
    font-size: 18px;
    line-height: 1.44;
    color: $thunder;
}

.H4-Black-Centre {
    font-family: $font-family-montserrat;
    font-size: 20px;
    line-height: 1.4;
    color: $thunder;
}

.H3-Black-Centre {
    font-family: $font-family-montserrat;
    font-size: 24px;
    line-height: 1.33;
    color: $thunder;
}

.H2-Black-Centre {
    font-family: $font-family-montserrat;
    font-size: 32px;
    line-height: 1.25;
    color: $thunder;
}

.H1-Black-Centre {
    font-family: $font-family-montserrat;
    font-size: 40px;
    line-height: 1.2;
    color: $thunder;
}

.H1-Hero-Black-Centre {
    font-family: $font-family-montserrat;
    font-size: 56px;
    line-height: 1.11;
    color: $thunder;
}

.Label-Black-Centre {
    font-family: $font-family-montserrat;
    font-size: 12px;
    line-height: 1.67;
    color: #000000;
}

.Label-Red-Centre {
    font-family: $font-family-montserrat;
    font-size: 12px;
    line-height: 1.67;
    color: $lipstick;
}

.Label-Blue-Centre {
    font-family: $font-family-montserrat;
    font-size: 12px;
    line-height: 1.67;
    color: $light-navy-blue;
}

.Link-Active-Centre {
    font-family: $font-family-montserrat;
    font-size: 16px;
    line-height: 1.5;
    color: $dark-sky-blue;
}

.Label-Utility-Blue-Centre {
    font-family: $font-family-montserrat;
    font-size: 12px;
    line-height: 1.67;
    color: $dark-sky-blue;
}

.Extra-Input-Placeholder-centre {
    font-family: $font-family-montserrat;
    font-size: 16px;
    line-height: 1.5;
    color: $cloud-grey;
}

.Extra-Input-Error-centre {
    font-family: $font-family-montserrat;
    font-size: 16px;
    line-height: 1.5;
    color: $lipstick;
}

.Label-Grey-Centre {
    font-family: $font-family-montserrat;
    font-size: 12px;
    line-height: 1.67;
    color: #9b9b9b;
}

.Extra-Input-Disabled-Centre {
    font-family: $font-family-montserrat;
    font-size: 16px;
    line-height: 1.5;
    color: #ebebeb;
}

.Button-Grey-Centre {
    font-family: $font-family-montserrat;
    font-size: 18px;
    line-height: 1.44;
    color: #9b9b9b;
}

.Button-Red-Centre {
    font-family: $font-family-montserrat;
    font-size: 18px;
    line-height: 1.44;
    color: $lipstick;
}

.Button-Utility-Blue-Centre {
    font-family: $font-family-montserrat;
    font-size: 18px;
    line-height: 1.44;
    color: $dark-sky-blue;
}
