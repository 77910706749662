$mega-menu__margin                           : 0 !default;
$mega-menu__border-bottom                    : 1px solid $gray-light !default;
$mega-menu__font-color                       : $gray-darker !default;
$mega-menu__font-weight                      : $font-weight-bold !default;
$mega-menu__font-size                        : $font-size-base !default;
$mega-menu__font-size--large                 : 16px !default;
$mega-menu__background-color                 : $white !default;

$mega-menu__list-padding                     : 0 !default;
$mega-menu__list-justify-content             : flex-start !default;
$mega-menu__list-background-color            : $white !default;

$mega-menu__inner-list-padding               : 0 !default;
$mega-menu__inner-list-background-color      : $white !default;
$mega-menu__inner-list-box-shadow--level1    : 0 4px 6px 0 rgba(51, 51, 51, 0.3) !default;
$mega-menu__inner-list-border--level1        : 1px solid $gray-light !default;

$mega-menu__inner-item-padding--parent       : $spacer--large 0 $spacer--large ($spacer--large * 2) !default;
$mega-menu__inner-item-padding--parent-child : 0 !default;
$mega-menu__inner-item-margin--parent-child : 0 0 $spacer--medium !default;

$mega-menu__inner-item-padding--image        : 0 0 0 112px !default;

$mega-menu__link-padding                     : 12px $spacer--medium !default;
$mega-menu__link-margin                      : 0 $spacer--medium !default;
$mega-menu__link-color                       : $gray-darker !default;
$mega-menu__link-color--active               : $color-primary !default;
$mega-menu__link-text-decoration             : none !default;

$mega-menu__inner-link-margin                : 0 !default;
$mega-menu__inner-link-padding               : 0 !default;
$mega-menu__inner-link-font-weight           : $font-weight-normal !default;
$mega-menu__inner-link-text-decoration       : none !default;
$mega-menu__inner-link-color                 : $gray-darker !default;
$mega-menu__inner-link-color--active         : $color-primary !default;
$mega-menu__inner-link-color--image-focus    : $font-color-base !default;

.mega-menu {
    display: none;
    background: $mega-menu__background-color;
    color: $mega-menu__font-color;
    position: relative;
    margin: $mega-menu__margin;
    border-bottom: $mega-menu__border-bottom;
    @include mq($screen-xl) {
        display: block;
    }

    &__list {
        position: relative;
        display: flex;
        flex-wrap: nowrap;
        justify-content: $mega-menu__list-justify-content;
        padding: $mega-menu__list-padding;
        list-style: none;
        background-color: $mega-menu__list-background-color;
    }

    &__inner-list {
        padding: $mega-menu__inner-list-padding;
        background-color: $mega-menu__inner-list-background-color;
        list-style: none;
        &--level1 {
            display: none;
            position: absolute;
            top: 100%;
            left: 0;
            z-index: 4;
            width: 100%;
            border-top: $mega-menu__inner-list-border--level1;
            box-shadow: $mega-menu__inner-list-box-shadow--level1;
        }
    }

    &__item {
        &--parent {
            &:hover,
            &.focus-visible {
                .mega-menu__inner-list--level1 {
                    display: flex;
                }
            }

            // support for keyboard focus, for different browsers
            & > .mega-menu__inner-list--level1:focus {
                display: flex;
            }

            &.focus-within {
                .mega-menu__inner-list--level1 {
                    display: flex;
                }
            }

            & > .mega-menu__link:focus + .mega-menu__inner-list--level1 {
                display: flex;
            }
        }
    }

    &__inner-item {
        &--parent {
            padding: $mega-menu__inner-item-padding--parent;
            & > .mega-menu__inner-link {
                display: inline-block;
                margin: $mega-menu__inner-item-margin--parent-child;
                padding:  $mega-menu__inner-item-padding--parent-child;
                font-size: $mega-menu__font-size;
                font-weight: $mega-menu__font-weight;
            }
        }

        &--image {
            display: flex;
            flex-basis: 100%;
            justify-content: flex-end;
            padding: $mega-menu__inner-item-padding--image;

            .mega-menu__inner-link {
                display: block;
                &.focus-visible {
                    @include focus();
                    background-color: transparent;
                    color: $mega-menu__inner-link-color--image-focus;
                }
            }

            img {
                width: auto;
            }
        }
    }

    &__link {
        display: block;
        margin: $mega-menu__link-margin;
        padding: $mega-menu__link-padding;
        font-size: $mega-menu__font-size--large;
        font-weight: $mega-menu__font-weight;
        color: $mega-menu__link-color;
        text-decoration: $mega-menu__link-text-decoration;
        &.focus-visible,
        &:hover {
            text-decoration: $mega-menu__link-text-decoration;
            color: $mega-menu__link-color--active;
        }
    }

    &__inner-link {
        margin: $mega-menu__inner-link-margin;
        padding: $mega-menu__inner-link-padding;
        white-space: nowrap;
        font-size: $mega-menu__font-size;
        font-weight: $mega-menu__inner-link-font-weight;
        color: $mega-menu__inner-link-color;
        text-decoration: $mega-menu__inner-link-text-decoration;

        &:hover {
            text-decoration: $mega-menu__inner-link-text-decoration;
            color: $mega-menu__inner-link-color--active;
        }

        &.focus-visible {
            @include focus-inline();
        }
    }

    &__column-hidden {
        > .mega-menu__inner-link {
            display: none;
        }
    }
}
