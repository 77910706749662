
.catalog-list {

    //padding: 0 2px;
    //align-items: flex-end;

    .catalog-grid-item {
        //margin-left: 1px;
        //margin-right: 1px;

        .catalog-grid-item__details {

            padding: 15px;

            p {

                font-size: 13px;
                color: #333;

                span {

                    font-weight: 600;

                }

                &.catalog-grid-item__sku {

                    text-transform: uppercase;
                    color: #949494;
                    margin-top: 10px;

                }

            }

            .catalog-grid-item__name {

                margin-bottom: 0;

                a {

                    font-weight: normal;
                    font-size: 17px;

                }

            }

            .catalog-grid-item__sku {



            }

        }

        .catalog-grid-item__price {

            .price-wrapper {

                > span {

                    font-size: 26px;
                    display: inline;
                    font-weight: bold;
                    width: 100%;

                    &::first-letter {

                        font-size: 16px;
                        font-weight: normal;

                    }

                }

            }

        }

        .catalog-list-item__stock-status {

            .stock-status-inner {

                display: flex;
                align-items: center;
                font-size: 13px;
                font-weight: 500;
                line-height: 1;

                img {

                    width: 18px;
                    margin-right: 5px;
                    padding-bottom: 2px;

                }

                &.stock-qty-oos {

                    color: $highlight-orange !important;

                }

                &.stock-qty-in-stock {

                    color: #32c026;

                }

            }

        }

        .catalog-grid-item__primary-form {

            margin-top: 15px;

            .qty-selector {

                display: flex;
                border: 1px solid #e0e0e0;
                border-bottom-width: 3px;
                border-radius: 5px;
                width: auto;
                justify-content: space-around;
                margin-bottom: 5px;

                .quantity-update__input {

                    font-size: 15px;
                    height: 35px;

                }

                .qty-update-btn {

                    min-width: 25px;
                    font-size: 20px;
                    line-height: 1;
                    background-color: transparent;
                    color: #e0e0e0;
                    border: none;
                    padding: 0 5px;
                    font-weight: bold;
                    cursor: pointer;

                    &.qty-update-decrease {

                        font-size: 35px;
                        font-weight: normal;

                    }

                }

            }

            button.tocart {

                background-color: #32c026;
                padding: 7px;
                height: 37px;
                font-size: 13px;

                img {

                    display: inline-block;
                    width: 21px;
                    margin-right: 15px;

                }

                &.oos {

                    background-color: #dc2148;

                }

                &:hover {

                    color: #fff;
                    border-color: #32c026;

                    &.oos {

                        color: #fff;
                        border-color: #dc2148;

                    }

                }

                &:focus {
                    color: white;
                }

            }

            a.price-on-application {

                height: 37px !important;
                text-align: center;
            }

        }

    }

    @media (min-width: 480px) {

        .grid__column--1-3 {
            flex-basis: 33.33333%;
            max-width: 33.33333%;
        }

        .grid__column--1-2 {
            flex-basis: 50%;
            max-width: 50%;
        }

    }

    @media (min-width: 768px) {

        .grid__column--1-3 {
            flex-basis: 33.33333%;
            max-width: 33.33333%;
        }

        .grid__column--1-2 {
            flex-basis: 50%;
            max-width: 50%;
        }

    }

}

.develodesign_product_more_info_popup-container {

    background-color: rgba(0, 0, 0, 0.25);
    position: fixed;
    z-index: 99999999;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: none;

    .develodesign_product_more_info_popup-inner-container {

        @extend %shadow--l3;

        position: fixed;
        background-color: #fff;
        left: 15px;
        right: 15px;
        top: 100px;
        bottom: 45px;
        max-width: 788px;
        margin: 0 auto;
        border-radius: 5px;
        overflow: auto;
        transition: height 1s;

    }

    .loader {

        z-index: -2;
        opacity: 0;
        pointer-events: none;
        width: 100%;
        height: 100%;
        overflow: hidden;
        transition: opacity 0.5s;

    }

    .develodesign_product_more_info_popup-header {
        padding: 20px 30px;
    }

    .develodesign_product_more_info_popup-close-toggle {

        padding: 3px;
        min-width: 0;
        position: absolute;
        right: 0;
        top: 7px;
        height: auto;

    }

    .develodesign_product_more_info_popup-product-contents {

        height: 100%;
        padding: 20px 30px 82px;
        max-height: 100%;
        overflow-y: auto;

    }

    .tab--product-more-info-popup {

        position: relative;

        .tab__content--inner-title {

            border-bottom: 1px $thunder solid;
            padding: 0 0 8px;
            margin: 0 0 20px;

            h3 {
                margin: 0;
            }

        }

        .tab__content--inner-content {
            max-width: 550px;
        }

        #tab-popup-tech-specs {

            .tab__content--inner-content {
                max-width: 100%;
            }

        }

        .tech-spec-entry__specification {

            font-size: 14px;
            line-height: 18px;

        }

        .tech-spec-entry__title {
            margin: 30px 0 20px;
        }

        .dte-product-specs {

            .divider {
                margin-top: 20px;
            }

        }

    }

    .develodesign_product_more_info_popup-footer {

        background-color: $nearly-white;
        padding: 25px 30px;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1;
        overflow: hidden;
        max-height: 200px;
        transition: all 0.75s;
        transition-delay: 0.5s;

        .button {

            text-transform: uppercase;
            opacity: 1;
            transition: all 0.65s;
            transition-delay: 0.75s;

            + .button {
                margin-left: 10px;
            }

        }

        .button__text-mobile {
            display: none!important;
        }

        .button__text-desktop {
            display: inline!important;
        }

    }

    .dd-more-info--add-to-cart-form {

        display: inline-flex;
        margin-right: 10px;

    }

    .dd-more-info--add-to-quote {

        display: inline-flex;

        .button__icon {
            display: none;
        }

    }

}

.develodesign_product_more_info_popup-active {

    overflow: hidden;

    .develodesign_product_more_info_popup-container {
        display: block;
    }

}

.develodesign_product_more_info_popup-loading {

    .develodesign_product_more_info_popup-container {

        .loader {

            z-index: 2;
            opacity: 1;

        }

    }

    .develodesign_product_more_info_popup-product-contents {
        display: none;
    }

    .develodesign_product_more_info_popup-footer {

        padding: 0 30px;
        max-height: 0;

        .button {
            opacity: 0;
        }

    }

}

@media all and (min-width: 768px) {

    .develodesign_product_more_info_popup-container .develodesign_product_more_info_popup-inner-container {

        height: 80%;

        .column + .column {
            padding: 0 30px 0 0;
        }

    }

}

@media all and (max-width: 767px) {

    .develodesign_product_more_info_popup-container {

        .develodesign_product_more_info_popup-inner-container {

            top: 15px;
            bottom: 15px;

        }

        .develodesign_product_more_info_popup-close-toggle {

            z-index: 11;
            right: -30px;

            use,
            svg
            {
                stroke: #000;
            }

        }

        .develodesign_product_more_info_popup-header {

            position: fixed;
            top: 15px;
            right: 15px;
            left: 15px;
            z-index: 2;

        }

        .tab--product-more-info-popup {

            .tab__title {

                width: 100%;
                padding: 10px 0;

            }

        }

        .develodesign_product_more_info_popup-product-contents {

            padding: 0 30px 166px 30px;

            .product-image-container {

                display: block;
                max-width: 75%;
                margin: 0 auto;

            }

            .product-pricing-container {
                margin-bottom: 15px;
            }

            .product-title {

                font-size: 18px;
                line-height: 22px;

            }

            .product-sku {
                font-size: 14px;
            }

            .price-box {
                text-align: left;
            }

        }

        .develodesign_product_more_info_popup-footer {

            .button {
                width: 100%;
            }

            .dd-more-info--add-to-quote,
            .dd-more-info--add-to-cart-form
            {

                display: block;
                width: 100%;
                margin: 0;

            }

            .dd-more-info--add-to-cart-form {
                margin: 10px 0;
            }

        }

    }

}

.catalog-category-view {

  .hero-slider-container {

    .custom-slider-item-inner {

      text-align: left;

      .custom-slider-item-title {
        font-size: $heading-font-size--first-level;
      }

      .custom-slider-item-title,
      .custom-slider-item-description {

        color: $white;
        max-width: 725px;


      }

    }

  }

}

.catalog-category-view {

    &.instantsearch-view-mode-grid {

        #instant-search-results-container {

            .ais-infinite-hits {

                justify-content: space-between;
                margin: 30px 0;

                @media all and ( max-width: 500px ) {

                    justify-content: stretch;

                }

                .ais-infinite-hits--item {

                    flex-basis: 32%;
                    width: auto;
                    margin-bottom: 15px;

                    @media all and ( max-width: 500px ) {

                        margin-bottom: 0;

                    }

                }

            }

            .catalog-grid-item {

                .catalog-grid-item-inner {

                    padding: 0;
                    position: relative;
                    padding-bottom: 171px;

                    a.catalog-grid-item__brand {

                        top: 5px;
                        left: 5px;
                        bottom: unset;
                        right: unset;
                        z-index: 1;

                    }

                    .catalog-grid-item__image-wrapper {

                        padding: 0 15px;

                        img {

                            height: auto;
                            margin: 0;

                        }

                    }

                    .catalog-grid-item__details {

                        padding: 15px;

                        p {

                            font-size: 13px;
                            color: #333;

                            span {

                                font-weight: 600;

                            }

                        }

                        .catalog-grid-item__name {

                            margin-bottom: 0;

                        }

                    }

                    .product-card-bottom-container {

                        width: 100%;
                        background: #fff;
                        padding: 15px;
                        border: none;
                        position: absolute;
                        bottom: 0;

                        .price-wrapper {

                            min-height: 54px;
                            position: relative;

                            span {

                                &.after_special {

                                    position: absolute;
                                    bottom: 0;

                                }

                                &.before_special {

                                    text-decoration: line-through;
                                    font-size: 15px;
                                    display: block;
                                    margin-bottom: 0;
                                    left: 0;
                                    position: absolute;
                                    top: 0;
                                    font-weight: 700;

                                }

                            }

                        }

                        .inc-vat-info {

                            font-size: 14px;
                            font-weight: normal;
                            display: block;
                            margin: 10px 0 0;

                        }

                        .catalog-grid-item__stock-status {

                            color: #333333;
                            font-weight: 600;
                            font-size: 14px;
                            margin: 10px 0;

                        }

                        .catalog-grid-item__actions {

                            .catalog-grid-item__primary-action.tocart {

                                width: auto;
                                max-width: none;
                                font-size: 15px;
                                padding: 0 40px;

                                @media all and ( min-width: 768px ) {

                                    padding: 0 15px;

                                }

                            }

                        }

                        a.price-on-application {

                            margin-left: 15px;

                        }

                    }

                }

            }

        }

    }

}

.amazon-store {

  .catalog-category-view.instantsearch-view-mode-grid #instant-search-results-container {

    .catalog-grid-item .catalog-grid-item-inner .product-card-bottom-container {

      .catalog-grid-item__actions .catalog-grid-item__primary-action.tocart {

        margin: 0;

      }

    }

  }

}

.tab__title.tab__title--active {
    font-weight: bold;
    color: #303030;
}

.tech-spec-entry__specification {

    margin-bottom: 6px;
    font-size: 14px;
    line-height: 18px;

  }

  .tech-spec-entry__title {
    margin: 30px 0 20px;
  }

  .dte-product-specs {

    > .row > .col-md-12 {

      padding-left: 0;

    }

    .tech-specs-description {

      padding: 15px;
      background-color: #f6f6f6;

    }

    .tab__content--inner-title {

      margin-bottom: 0;

      &:first-of-type {

        margin-bottom: 15px;

      }

    }

    .divider {
      margin-top: 20px;
    }

    .tech-spec-entry__specification {

      > .row {

        padding: 15px 0;

      }

      &:nth-child(even) {

        background-color: #f6f6f6;

      }

    }

    .tech-specs-15-cutoff {

        display: none;


      &.show {

          display: block;

      }

    }

    .show-more-tech-specs-btn {

      margin-top: 30px;

      .more {

        display: inline;

      }

      .less {

        display: none;

      }

      &.less {

        .more {

          display: none;

        }

        .less {

          display: inline;

        }

      }

    }

  }

  .row.tech-spec-content-row {
    > div:first-of-type {
        margin-top: 0;
    }
  }
