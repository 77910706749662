.contact-index-index {

  #maincontent {
    &.container {
      margin: 0 auto;
    }
  }

  .hero-slider-container {

    .custom-slider-item {

      background-position: top;

      &::before {

        content: '';
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.6);

      }

      .custom-slider-item-inner {

        .banner-text {

          text-align: center;

          h1 {

            font-size: 60px;
            margin-bottom: 15px;

          }

          h1, h3 {

            color: #fff;

          }

          h3 {

            color: #fff;
            font-size: 21px;
            width: 405px;
            max-width: 100%;
            margin: 0 auto;

            @media all and (max-width: 767px) {

              margin: 0 auto;

            }

          }

        }

        > .container {

          margin: 0 auto;

          > h2 {
            display: none;
          }

        }

      }

    }

    .contact-banner-content {

      .buttons.grid {

        max-width: 385px;
        margin: 30px auto;

        @media all and (max-width: 1099px) {

          margin: 30px auto 0;

        }

        .button {

          width: 175px;
          margin: 15px 15px 0 0;
          float: left;

          @media all and (max-width: 1099px) {

            margin: 5px auto;

          }

        }

      }

      @media all and (max-width: 1099px) {

        padding-left: 15px;

      }

    }

  }

  .contact-us-content {

    margin: 30px 0;

    .contact-info {

      justify-content: space-around;

      @media all and (max-width: 799px) {

        display: block;

        .store {

          width: 100%;
          max-width: none;
          margin-bottom: 30px;

        }

      }

      .store {

        padding: 40px 20px;
        background-color: #f7f7f7;
        flex-basis: 30%;
        text-align: center;
        position: relative;

        .image {

          img {

            position: absolute;
            top: 0;
            left: 0;
            width: 45px;

          }

        }

        .address {

          max-width: 400px;
          margin: 0 auto;
          padding: 10px;
          min-height: 180px;

          p {

            font-size: 16px;
            line-height: 1.5;
            padding: 0 25px;
            margin-bottom: 0;

            strong {

              display: block;
              margin-bottom: 6px;
              margin-left: -25px;
              margin-right: -25px;

            }

          }

        }

        .find-us,
        .telephone {

          margin-bottom: 15px;

        }

        .telephone {

          margin-bottom: 45px;

          .button {

            min-width: 255px;

          }

        }

        .email-item {

          margin-bottom: 25px;

          .title {

            margin-bottom: 10px;
            font-size: 20px;

            + a {

              font-size: 16px;

            }

          }

        }

      }

    }

  }

  .modal-popup .heading--page {
    text-align: center;
    padding-top: 60px;

  }

  .modal-popup .modal__close-button {
    position: absolute;
    top: 0;
    min-width: 0;
    min-height: 0;
    height: 44px;
    width: 44px;
    margin: 10px 0 0;

  }

  .modal-content {
    text-align: center;
  }

  .contact-page-content {
    .contact-banner {
        .right-block {
            .form-container {
                .form-title {
                    font-weight: 500;
                    color: black;
                }
                .webforms {
                    .field {
                        padding-bottom: 1rem;
                    }
                }
            }
        }
    }
    .office-locations-overview-container {

      .office-locations {

        .office-location-container {
          margin: 5%;
        }

        .overview-title {
          font-size: 27px;
          font-weight: bold;
          margin-bottom: 30px;
        }

        .tab__content {

          padding: 0;

        }

        .tab__title {
          font-family: "Proxima Nova", "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
          font-weight: 500;
          font-size: 17px;
          color: #0ab6ff;
          border-bottom: 1px solid #e0e0e0;
        

          &--active {
            color: #303030;

            &::after {
              background-color: #0ab6ff;
            }
          }
        }

        .office-locations-slider-item {
          .address-content {
            border-bottom: 1px solid #e0e0e0;
            margin-bottom: 35px;

            p {
              font-size: 17px;
              margin: 0;
            }

            .address-name {
              font-weight: bold;
            }
          }

          .office-contact-info {
            display: flex;

            &:not(:last-child){
              
              a {

                color: #303030;

              }

            }

            p {
              margin: 0 10px 0 0;
              min-width: 40px;
              font-weight: 500;
            }

          }

          .directions-link {
            margin: 30px 0;

            a {
              font-weight: normal;
            }
          }

          a {
            text-decoration: none;
            font-weight: bold;
            color: #0ab6ff;
            font-size: 17px;

            &:hover,
            &:focus {
              color: #a9ebfe;
            }
          }
        }
      }

      .map-container {
        width: 100%;
      }
    }

    @media all and (min-width: 1024px) {
      .office-locations-overview-container {
        display: flex;

        .office-locations {
          max-width: 38%;

          .office-location-container {
            margin: 0 10%;
          }

          .position-container {
            position: relative;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%)
          }

          .overview-title {
            font-size: 27px;
            font-weight: bold;
          }

          .tab__title {
            font-size: 17px;
            color: #0ab6ff;
            border: 0;
            max-width: max-content;
            width: max-content;
            padding-bottom: 15px;

            &::after {
              border-bottom: 1px solid #e0e0e0;
              transform: unset;
              width: 100%;
              bottom: 0;
            }

            &--active {
              &::after {
                border-bottom: 1px solid #0ab6ff;
                transform: unset;
                width: 100%;
              }
            }
          }

          .office-locations-slider-item {
            .address-content {
              border-bottom: 1px solid #e0e0e0;

              p {
                font-size: 17px;
                margin: 0;
              }

              .address-name {
                font-weight: bold;
              }
            }

            a {
              text-decoration: none;
              font-weight: bold;
              color: #0ab6ff;

              &:hover,
              &:focus {
                color: #a9ebfe;
              }
            }
          }
        }
      }

      .map-container {
        width: 90%;
        margin: 0 auto;
      }
    }
  }

  footer {
    margin-top: 0;
  }

  .webforms .fieldset {

    padding: 0;

  }

}

