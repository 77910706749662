$catalog-list-item__padding: $spacer 0 !default;
$catalog-list-item__border: 1px solid $gallery-grey !default;
$catalog-list-item__font-size: 12px !default;
$catalog-list-item__font-size\@medium: 14px !default;
$catalog-list-item__font-size--main: 14px !default;
$catalog-list-item__font-size--main\@medium: 16px !default;

$catalog-list-item__image-width: 96px !default;
$catalog-list-item__image-width\@medium: 208px !default;
$catalog-list-item__image-height--inner: auto !default;

$catalog-list-item__badge-top-position\@medium: 24px !default;
$catalog-list-item__badge-left-position\@medium: 0 !default;
$catalog-list-item__badge-padding: 3px 7px !default;
$catalog-list-item__badge-padding\@medium: 6px 13px !default;
$catalog-list-item__badge-bg-color: $color-primary !default;
$catalog-list-item__badge-font-size: 10px !default;
$catalog-list-item__badge-font-size\@medium: 12px !default;

$catalog-list-item__details-width: 100%;
$catalog-list-item__details-margin: $spacer 0 0 0 !default;
$catalog-list-item__details-margin\@medium: 0 !default;

$catalog-list-item__name-margin: 0 $spacer 0 0 !default;
$catalog-list-item__name-margin\@medium: 0 $spacer--large 0 0 !default;
$catalog-list-item__name-color: $font-color-base !default;
$catalog-list-item__name-font-weight: $font-weight-normal !default;

$catalog-list-item__reviews-padding: 4px 0 0 0 !default;
$catalog-list-item__reviews-padding\@medium: 0 !default;

$catalog-list-item__price-margin: $spacer 0 !default;
$catalog-list-item__price-margin\@medium: $spacer--medium 0 0 0 !default;
$catalog-list-item__price-font-weight: $font-weight-bold !default;

$catalog-list-item__actions-margin: $spacer 0 !default;
$catalog-list-item__actions-primary-margin\@medium: $spacer 0 0 0 !default;
$catalog-list-item__actions-primary-button-width: 112px !default;
$catalog-list-item__actions-primary-button-width\@medium: 175px !default;
$catalog-list-item__actions-primary-button-height: 32px !default;
$catalog-list-item__actions-primary-button-height\@medium: 48px !default;
$catalog-list-item__actions-primary-button-padding\@medium: 0 $spacer !default;

$catalog-list-item__actions-secondary-margin: 0 0 0 $spacer--medium !default;
$catalog-list-item__actions-secondary-margin\@medium: 0 !default;
$catalog-list-item__actions-secondary-button-width: 32px !default;
$catalog-list-item__actions-secondary-button-width\@medium: 48px !default;
$catalog-list-item__actions-secondary-button-height: 32px !default;
$catalog-list-item__actions-secondary-button-height\@medium: 48px !default;
$catalog-list-item__actions-secondary-button-margin\@medium: 0 $spacer 0 0 !default;
$catalog-list-item__actions-secondary-button-icon-width: 12px !default;
$catalog-list-item__actions-secondary-button-icon-width\@medium: 18px !default;
$catalog-list-item__actions-secondary-button-icon-height: 12px !default;
$catalog-list-item__actions-secondary-button-icon-height\@medium: 18px !default;
$catalog-list-item__actions-secondary-button-icon-fill: $gray-darker !default;

$catalog-list-item__stock-status-font-weight: $font-weight-bold !default;
$catalog-list-item__stock-status-color--in-stock: $color-success !default;
$catalog-list-item__stock-status-color--out-of-stock: $color-danger !default;

.catalog-list-item {

  position: relative;
  display: block;
  padding: $catalog-list-item__padding;
  overflow: hidden;
  border: $catalog-list-item__border;
  border-top: none;

  > div {

    > .catalog-list-item__img-col {
      flex-basis: 10%;
    }

    > .catalog-list-item__main-col {
      flex-basis: 68%;
      max-width: 68%;
      position: relative;
    }

    > .catalog-list-item__right-col {
      flex-basis: 22%;
      max-width: 22%;
    }

  }

  &:first-child,
  &.is-first {
    border-top: $catalog-list-item__border;
  }

  &__image-wrapper {

    .image {

      width: $catalog-list-item__image-width;
      height: 100px;
      padding-top: 15px;

      @include mq($screen-m) {
        width: $catalog-list-item__image-width\@medium;
      }

    }

  }

  &__main {

    min-height: 100%;

  }

  &__brand {

    float: none;
    margin: 0;
    position: absolute;
    bottom: 5px;
    right: 0;

    .attributepages-option-title {

      font-size: 12px;
      color: $thunder;

    }

    .attributepages-parent-link {
      display: none;
    }

    .attributepages-option-image {

      display: inline-block;
      max-width: 50px;

    }

  }

  &__actions-secondary {

    position: absolute;
    top: 0;
    right: 0;

    .button {

      height: 34px;
      width: auto;
      padding: 5px 7px;
      background: $white;
      border: none;
      cursor: pointer;
      min-width: 0;

      .button__icon {

        width: 18px;
        height: 18px;
        transition: all 0.3s ease-in-out;

        use {
          stroke: $thunder;
        }

      }

      &:hover,
      &:focus {
        box-shadow: none;
      }


    }

  }

  &__primary-action,
  .dte-add-toquote-container,
  .dte-add-toquote-container .button {

    width: 100%;
    max-width: 100%;

  }

  .dte-add-toquote-container {
    margin: 7px 0 0;
  }

  &__right-col {

    background-color: $nearly-white;
    margin-top: -15px;
    margin-bottom: -15px;
    padding-top: 15px;
    padding-bottom: 15px;

  }

  &__right-col-inner {

    min-height: 100%;
    padding: 0 15px;

  }

  &__badge {

    border-radius: 0;
    padding: $catalog-list-item__badge-padding;
    font-size: $catalog-list-item__badge-font-size;
    background-color: $catalog-list-item__badge-bg-color;

    @include mq($screen-m) {

      position: absolute;
      top: $catalog-list-item__badge-top-position\@medium;
      left: $catalog-list-item__badge-left-position\@medium;
      padding: $catalog-list-item__badge-padding\@medium;
      font-size: $catalog-list-item__badge-font-size\@medium;

    }

  }

  &__link {
    display: block;
  }

  &__details {

  }

  &__name {

    margin: $catalog-list-item__name-margin;
    font-weight: $catalog-list-item__name-font-weight;
    font-size: $catalog-list-item__font-size--main;

    .link {
      color: $catalog-list-item__name-color;
      text-decoration: none;
    }

    a.catalog-list-item__link {
      padding-right: 102px;
    }
  }

  &__price,
  .price-from,
  .price-to,
  .minimal-price {

    font-size: 24px;
    line-height: 32px;
    font-weight: 400;
    margin: 0;

  }

  &__actions {


  }

  &__actions-primary {

  }


  &__stock-status {

    margin: 10px 0 0;
    display: block;

    &--out-of-stock {

      color: $catalog-list-item__stock-status-color--out-of-stock;
      margin: 10px 0;
      display: inline-block;

    }

  }

  &__cart-text--mobile {
    display: none;
  }

  @media all and (max-width: 767px) {

    padding: 15px 0 0;

    &__brand {
      display: none;
    }

    &__right-col {

      background: transparent;
      margin: 0;
      padding: 15px 0;

    }

    &__main,
    &__image-wrapper {
      padding-top: 22px;
    }

    &__price {

      .price {

        position: absolute;
        bottom: 7px;
        right: 3px;

      }

    }

    &__actions-primary {

      padding-right: 120px;

      > form {

        display: flex;
        align-items: flex-end;

      }

    }

    &__primary-action {
      margin-right: 9px;
    }

    &__cart-text--mobile {
      display: inline;
    }

    &__cart-text--desktop {
      display: none;
    }

    .dte-add-toquote-container {

      margin: 0;

      .button {

        &__text-mobile {
          display: inline !important;
        }

        &__text-desktop {
          display: none !important;
        }

      }

    }

    &__actions-secondary {

      position: absolute;
      top: 0px;
      right: 0;
      text-align: right;
      left: auto;
      bottom: auto;

    }

  }

  @media all and (max-width: 385px) {

    &__actions-primary {

      > form {

        display: block;
        align-items: initial;

      }

    }

    &__primary-action {
      margin-bottom: 6px;
    }

  }

}

// Phase 2
.catalog-list {
  .catalog-list-item__img-col {
    .catalog-list-item__brand {
      width: 100%;
      height: 100%;

      .catalog-grid-item__supplier-orig {
        position: absolute;
        top: 0;
        right: 10px;
      }
    }
  }
}

@media all and ( max-width: 991px) {

    .cart-list-item__image {

        padding: 0 15px 0 0;
        flex: auto;

    }

}
