$product-review__borders: 1px solid $gray-light;

.product-review {
    padding: $spacer--medium;
    &__title {
        margin-bottom: $spacer--large;
        text-transform: uppercase;
        font-size: $font-size-large;
        font-weight: $font-weight-base;
        letter-spacing: 2px;
    }
    &__reviews {
        .review {
            margin-bottom: $spacer--large;
            border-bottom: $product-review__borders;
        }
    }
    .review--summary {
        padding-bottom: $spacer--large;
        border-bottom: $product-review__borders;
        margin-bottom: $spacer--medium;
        .rating {
            @include mq($screen-m) {
                margin-right: $spacer--extra-large;
            }
        }
    }
    .review__amount {
        display: none;
        text-decoration: none;

        &-text {
            display: block;
            margin-right: $spacer--extra-large;
        }
    }
    .review__summary-handle {
        flex-wrap: wrap;
        @include mq($screen-m) {
            flex-wrap: nowrap;
            justify-content: flex-start;
            width: 100%;
            max-width: none;
        }
    }
    .review__summary-add {
        text-align: left;
        @include mq($screen-m) {
            flex-basis: auto;
        }
    }
    .list__item:last-child {
        .review {
            margin-bottom: 0;
        }
    }
    .toolbar {
        margin-bottom: $spacer--extra-large;
    }
}
