.hero-slider-partners {

  .owl-carousel {

    .owl-stage-outer {
      padding: 0;
    }

    .owl-stage {

      display: flex;
      flex-wrap: wrap;

    }

    .owl-item {

      display: flex;
      height: auto;

    }

    &.owl-theme {

      .owl-dots {

        position: static;
        margin: 20px auto;

        .owl-dot {

          span {

            background-color: $white;
            border: 1px $thunder solid;

          }

          &.active {

            span {
              background-color: $thunder;
            }

          }

        }

      }

    }

  }

  .banner-item {

    position: relative;
    background-size: auto 100%;
    background-position: center top;
    width: 100%;
    padding-top: 250px;
    height: 480px;

    .banner-title,
    .banner-description {
      color: $white;
    }

    .banner-title {
      margin: 0 0 10px;
    }

    .banner-description {

      margin: 0;
      min-height: 75px;

    }

    .banner-item-inner {
      margin: 0 30px;
    }

    .buttons {
      margin: 20px 0 0 0;
    }

      &.banner-link-no-button {

          padding-top: 0;

          .banner-item-inner {

              margin: 0;
              height: 100%;

              a {

                  display: block;
                  height: 100%;

              }

          }

          .buttons {

              margin: 0;

          }

      }

  }

}

@media all and (min-width: 768px) {

  .hero-slider-partners {

    .banner-item {


    }

  }

}

@media all and (max-width: 1328px) {

  .hero-slider-partners {

    .banner-item {



    }

  }

}

@media all and (max-width: 767px) {

  .hero-slider-partners {

    .banner-item {


    }

  }

}
