body {

    &:not(.transitions-ready) {

        * {
            transition: none !important;
        }

    }

}

.message-container {

    @media all and ( max-width: 1480px ) {
        margin: auto;
    }

}

.container {

    width: auto;
    max-width: 100%;

}

.page-wrapper {

    min-height: 500px;
    min-height: 77vh;

}

.d-none {
    display: none!important;
}

.d-block {
    display: block;
}

.d-inline-block {
    display: inline-block;
}

ul.ul-larger {

    font-size: 16px;
    line-height: 1.5;

    li {

        + li {
            margin-top: 10px;
        }

    }

}

@media (min-width: 1130px) {

    .container {

        margin: 0 auto;
        max-width: 1100px;

    }

}

*::selection,
*::-moz-selection {

    color: #fff;
    background: $dte-bright-blue;

}

.clearfix {

    zoom: 1; /* For IE 6/7 (trigger hasLayout) */

    &:before,
    &:after {
        content: "";
        display: table;
    }

    &:after {
        clear: both;
    }

}

.message {

    &.message-success {

        background: #f2faea;
        border: 1px #6abd0f solid;
        color: #559212;
        padding: 8px 20px;

        &:after {
            display: none;
        }

    }

}

.ellipsis-anim span {
    opacity: 0;
    -webkit-animation: ellipsis-dot 1s infinite;
    animation: ellipsis-dot 1s infinite;
}

.ellipsis-anim span:nth-child(1) {
    -webkit-animation-delay: 0.0s;
    animation-delay: 0.0s;
}
.ellipsis-anim span:nth-child(2) {
    -webkit-animation-delay: 0.1s;
    animation-delay: 0.1s;
}
.ellipsis-anim span:nth-child(3) {
    -webkit-animation-delay: 0.2s;
    animation-delay: 0.2s;
}

@-webkit-keyframes ellipsis-dot {
    0% { opacity: 0; }
    50% { opacity: 1; }
    100% { opacity: 0; }
}

@keyframes ellipsis-dot {
    0% { opacity: 0; }
    50% { opacity: 1; }
    100% { opacity: 0; }
}

// Public store only price changes.
body:not(.amazon-store) {
    .price-final_price .price:after, .price-wrapper .price::after {
        content: 'Excl. VAT';
        font-size: 10px;
        margin-left: 10px;
    }
}

@media all and ( max-width: 767px ) and ( max-height: 800px ) {

    body .fullscreen-menu-wrapper .fullscreen-menu-wrapper__item--open > .fullscreen-menu-wrapper__inner-list {

        overflow: scroll;

    }

}

.checkout-cart-index .block.crosssell {

    max-width: 100%;

    .owl-controls .owl-dots {

        margin: 20px auto 0;

    }

}

.develo-linked-product-container {

    > h4 {

        font-size: 22px;
        line-height: 1.4;
        font-weight: 500;
        @media all and ( min-width: 768px ) {
            padding: 0 35px;
        }
        margin-bottom: 0;

    }

    .develo-linked-product-slider,
    .develo-linked-product-slider-cart {

        @media all and ( min-width: 768px ) {
            padding: 0 35px;
        }

        .owl-nav {


            .owl-prev,
            .owl-next {

                &:hover {
                    color: transparent;
                    background-color: #2f338a;
                }

            }

            .owl-prev {

                left: -10px;

            }
            .owl-next {

                right: -10px;

            }

        }

        .owl-dots {

            display: flex;
            justify-content: space-between;
            max-width: 300px;
            width: 80%;
            margin: 0 auto;

            .owl-dot {

                flex: 1;

                > span {

                    width: 100%;
                    border-radius: 0;
                    border: 1px #c0c0c0 solid;
                    border-left: none;
                    border-right: none;
                    height: 5px;
                    margin: 0

                }

                &:first-of-type {

                    > span {

                        border-radius: 30px 0 0 30px;
                        border-left: 1px #c0c0c0 solid;

                    }

                }

                &:last-of-type {

                    > span {

                        border-radius: 0 30px 30px 0;
                        border-right: 1px #c0c0c0 solid;

                    }

                }

                &.active {

                    > span {

                        border-color: #444;
                        background-color: #444;

                    }

                }

                &:first-of-type {

                    &:last-of-type {

                        display: none;

                    }

                }

            }

        }

        .owl-item {

            .linked-product-item {

                border: 1px solid #f6f6f6;
                position: relative;

                .product-brand-logo-container {

                    position: absolute;
                    top: 5px;
                    left: 5px;
                    z-index: 1;

                    img {

                        max-width: 50px;

                    }

                }

                .linked-product-image {

                    img {

                        width: 75%;
                        margin: 0 auto;

                    }

                }

                .linked-product-info {

                    .linked-product-name {

                        font-weight: 600;
                        font-size: 14px;
                        padding: 15px;
                        height: 160px;
                        text-overflow: ellipsis;
                        overflow: hidden;

                    }

                }

                .linked-product-footer {

                    background-color: #f6f6f6;
                    min-height: 60px;
                    position: relative;

                    .linked-product-price {

                        .price-box {

                            .price-old_price {

                                text-decoration: line-through;
                                font-style: italic;
                                font-size: 14px;
                                color: #2f338a;
                                display: block;
                                margin-bottom: 5px;
                                left: 15px;
                                position: absolute;
                                top: 7px;
                                font-weight: 700;

                                span.price {

                                    &:after {

                                        content: none;

                                    }

                                }

                            }

                            .price-final_price {

                                left: 15px;
                                position: absolute;
                                bottom: 7px;

                                span.price {

                                    font-style: italic;
                                    color: #2f338a;
                                    font-weight: 800;
                                    font-size: 21px;

                                    &:after {

                                        content: none;

                                    }

                                }

                            }

                        }

                    }

                    .linked-product-actions {

                        display: inline-block;
                        position: absolute;
                        right: 15px;
                        top: 50%;
                        transform: translateY(-50%);

                        .linked-product-to-cart {

                            border: none;
                            border-radius: 50%;
                            background-color: red;
                            width: 35px;
                            padding: 0 0 0 6px;
                            cursor: pointer;
                            height: 35px;
                            min-width: unset;
                            max-width: none;

                            svg {

                                width: 22px;
                                height: 22px;

                            }

                            &:hover {

                                box-shadow: 0 3px 9px 1px rgba(65, 64, 64, 0.32);

                            }

                        }

                    }

                }

            }

        }

    }

    .develo-linked-product-slider-cart {

        .owl-nav {


            .owl-prev,
            .owl-next {

                &:hover {
                    color: transparent;
                    background-color: #2f338a;
                }

            }

            .owl-prev {

                left: -10px!important;

            }
            .owl-next {

                right: -10px!important;

            }

        }

    }

}

.face-cutoff-hide {
    display: none;
}

.filter--is-active.hide {
    display: none;
}

.filter--is-active.show {
    display: block;
}

.extra-facets-button {
    margin-bottom: 20px;
    display: none /* show with js after facets have loaded */
}

.extra-facets-button .less {
    display: none;
}

.extra-facets-button.less .more { 
    display: none;
}

.extra-facets-button.less .less { 
    display: inline;
}
