.page-all-categories {

    .catalog-list__products {

        float: none;
        width: auto;
        padding: 0;
        margin-bottom: 0;

    }

    .subcategories-a-z-list {
        margin: 30px 0 100px;
    }

    .level-one-container {

        margin-bottom: 50px;

        a {
            color: inherit;
        }

    }

    .level-two-container {
        margin-bottom: 30px;
    }

    .level-two--title {
        margin-bottom: 15px;
    }

    .level-three--title {
        margin-bottom: 5px;
    }

    @media all and (min-width: 768px) {

    }

}
