.header-primary-logo{
  text-align:center;
}

.logo {

    display: flex;
    align-items: center;
    flex: 1 100px;
    justify-content: center;

    &__image {
      max-height: 54px;
      max-width: 85px;
    }

}
