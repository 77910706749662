$table__padding                  : $spacer--medium !default;
$table__color                    : $black !default;
$table__background               : white !default;
$table__font-size                : $font-size-small+1 !default;
$table__font-size-th             : $font-size-small+2 !default;
$table__border-radius            : $border-radius !default;
$table__border                   : none !default;
$table__th-background            : white !default;
$table__td-background            : #f7f7f7 !default;
$table__tfoot-background         : $gray-lightest !default;
$table_border-bottom             : #E0E0E0 solid 1px;
$table__background-odd--odd-even : $white !default;
$table__background-even--odd-even: #f9f9f9 !default;
$table__th-font-weight--clean    : $font-weight-bold !default;
$table__th-background--clean     : transparent !default;
$table__background-odd--clean    : #f9f9f9 !default;
$table__background-even--clean   : $white !default;

$table__link-color: #0AB6FF;

.table {
  width: 100%;
  background: $table__background;
  margin: 1em 0;
  border: none;
  border-radius: $table__border-radius;
  color: $table__color;
  border-collapse: separate;
  border-spacing: 0;
  font-size: $table__font-size;

  &__caption {
    @include visually-hidden();
  }

  thead {
    tr {
      padding: 0;
      border-bottom: 0;

      @include mq($screen-m) {
        border-top-left-radius: $table__border-radius;
        border-top-right-radius: $table__border-radius;
      }


      th {
        text-align: left;
        text-transform: none;
        font-size: $table__font-size-th;
        font-weight:bold;

        border-bottom: $table_border-bottom ;

        &:first-child {
          @include mq($screen-m) {
            border-top-left-radius: $table__border-radius;
          }
        }

        &:last-child {
          @include mq($screen-m) {
            border-top-right-radius: $table__border-radius;
          }
        }
      }
    }
  }

  tr {
    display: block;
    padding: $table__padding 0;
    border-bottom: $table__border;

    @include mq($screen-m) {
      padding: 0;
      border-bottom: 0;
      display: table-row;
    }

    @include mq($max-screen: $screen-m - 1) {
      border-bottom: 0;
    }
  }

  th {
    display: none;

    &:last-child {
      @include mq($screen-m) {
        border-right: 0;
      }
    }

    @include mq($screen-m) {
      display: table-cell;
      padding: $table__padding;
      background: $table__th-background;
      border-bottom: $table__border;
      border-right: $table__border;
      text-align: left;
    }
  }

  td {
    display: flex;
    @include mq($max-screen: $screen-m - 1) {
      width:100%;
    }
    padding: 5px $table__padding;
    background: $table__td-background;
    border-bottom: $table_border-bottom ;

    svg{
      max-height:20px;
      max-width:20px;
    }

    a{
      color:$table__link-color;
      text-decoration: none;
      font-weight:bold;
    }

    &:before {
      content: attr(data-th);
      font-weight: bold;
      margin-right: 10px;
      max-width: 25%;
      width: 100%;
      display: block;

      @include mq($screen-m) {
        display: none;
      }
    }

    &:last-child {
      @include mq($screen-m) {
        border-right: 0;
      }
    }

    @include mq($screen-m) {
      display: table-cell;
      padding: $table__padding;
      background: $table__td-background;
      border-bottom: $table__border;
      border-right: none;
    }
  }

  tbody {
    tr {
      &:last-child {
        td {
          &:first-child {
            @include mq($screen-m) {
              border-bottom-left-radius: $table__border-radius;
            }
          }

          &:last-child {
            @include mq($screen-m) {
              border-bottom-right-radius: $table__border-radius;
            }
          }
        }
      }
    }
  }

  tfoot {
    tr {
      &:last-child {
        border-bottom: 0;

        td {
          border-bottom: 0;

          &:first-child {
            @include mq($screen-m) {
              border-bottom-left-radius: $table__border-radius;
            }
          }

          &:last-child {
            @include mq($screen-m) {
              border-bottom-right-radius: $table__border-radius;
            }
          }
        }
      }
    }

    td {
      display: block;
      padding: 5px $table__padding;
      background: $table__background;

      @include mq($screen-m) {
        display: table-cell;
        padding: $table__padding;
        background: $table__tfoot-background;
        border-right: $table__border;
      }
    }
  }

  &--odd-even {
    tbody {
      tr {
        &:nth-child(odd) {
          background: $table__background-odd--odd-even;
        }

        &:nth-child(even) {
          background: $table__background-even--odd-even;
        }

        &:first-child {
          border-top-left-radius: $table__border-radius;
          border-top-right-radius: $table__border-radius;

          @include mq($screen-m) {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
          }
        }
      }

      td {
        background-color: transparent;
      }
    }
  }

  &--clean {
    margin: 0;
    border: 0;
    border-radius: 0;

    thead {
      tr {
        padding: 0;
        border-bottom: 0;
      }
    }

    th {
      border-bottom: 0;
      border-right: 0;
      background: $table__th-background--clean;
      text-align: left;
      font-weight: $table__th-font-weight--clean;
    }

    td {
      border-bottom: 0;
      border-right: 0;
      background: $table__th-background--clean;
    }

    tbody {
      tr {
        &:nth-child(odd) {
          background: $table__background-odd--clean;
        }

        &:nth-child(even) {
          background: $table__background-even--clean;
        }
      }
    }
  }
}
