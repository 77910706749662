/* Toolbar specific changes here */

.toolbar-blog-posts {

    .mfblog-autoloader {
        margin: 0 auto;

        img {
            display: none !important;
        }
    }

    button {
        display: inline-flex;
        justify-content: center;
        cursor: pointer;
        height: 48px;
        min-width: 100px;
        padding: 10px 30px;
        border: 1px solid $blog-body;
        border-radius: 4px;
        outline: none;
        background: transparent;
        font-family: "Proxima Nova", "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
        font-size: 14px;
        color: $blog-body;
        text-transform: capitalize;
        transition: all 0.3s ease-in-out, outline-offset 1ms;
        font-weight: bold;
        line-height: 1;
        vertical-align: middle;
        align-items: center;
        text-align: center;
    }
}

