.customer-account-login {
  .title-wrapper {
    display: none;
  }

  .grid {
    &.login {
      justify-content: center;
      margin-top: 50px;

      @media all and (max-width: 991px) {
        display: block;
        width: 100%;
        margin-top: 0px;
      }

      .login-block {
        border: 1px solid #e0e0e0;
        border-radius: 3px;
        padding: 35px 35px 110px 35px;
        position: relative;
        margin-bottom: 20px;
        max-width: none;

        &:first-of-type {
          margin-right: 20px;
        }

        @media all and (max-width: 991px) {
          max-width: 460px;
          margin: 20px auto !important;
        }

        h3 {
          font-size: 18px;
          font-weight: bold;
        }

        // Login / Existing Customers
        #login-form {
          .login__input {
            display: flex;
            align-items: center;
            flex-wrap: wrap;

            label {
              flex-basis: 30%;
              font-size: 15px;
              color: #686868;
              text-transform: capitalize;
            }

            input {
              flex-basis: 60%;
            }

            @media all and (max-width: 950px) {
              label {
                flex-basis: 100%;
              }

              input {
                flex-basis: 100%;
                width:100%;
              }
            }
          }

          #remember-me-box {
            display: flex;
            align-items: center;
            flex-wrap: wrap;

            label {
              text-transform: capitalize;
            }

            .tooltip {
              &.wrapper {
                flex-basis: 100%;
                margin-left: 58px;
              }
            }
          }

          .field-recaptcha {
            margin: 10px 0 10px 40px;
          }
        }

        div[class*="__actions"] {
          position: absolute;
          bottom: 35px;
          margin: 0;
          justify-content: space-between;
          width: 100%;
          padding-right: 70px;

          .login__forgot-password {
            font-weight: bold;
          }
        }

        // Register / New Customers
        .new-account-item {
          display: flex;
          align-items: center;
          margin-bottom: 20px;

          svg {
            margin-right: 20px;
          }

          span {
            font-size: 14px;
            color: #526b79;
          }
        }
      }
    }
  }
}
.customer-account-forgotpassword {
  .field-recaptcha {
    margin-bottom: 10px;
  }
}

.customer-account-create,
.customer-account-forgotpassword {
  #maincontent {
      margin: 0 auto;
      padding: 0 15px;
  }
}

.customer-account-createpassword {

  #maincontent {

    padding: 0 45px;

  }

  .password-strength-meter {

    display: flex;
    align-items: center;

  }

}
