.help-and-support-page-container {
  .help-banner-container {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding-top: 24.3%;
    position: relative;
    min-height: 350px;
    margin-bottom: 40px;

    .banner-content {
      position: absolute;
      top: 50%;
      left: 0;
      margin-left: 4%;
      max-width: 475px;
      transform: translateY(-50%);

      .banner-title {
        font-size: 36px;
        line-height: 50px;
        color: #0ab6ff;
        font-weight: bold;
        margin-bottom: 20px;
      }

      .banner-text {
        font-size: 15px;
        line-height: 24px;
        color: white;
        margin-bottom: 20px;
      }
    }
  }

  .section-title {
    text-align: center;
    margin-bottom: 80px;
  }

  .features {
    &--logos {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      margin-bottom: 80px;

      .features-group {
        flex-basis: 100%;
        margin-bottom: 20px;

        .feature-logo {
          margin-bottom: 20px;

          img {
            max-width: 85px;
            margin: 0 auto;
          }
        }

        .feature-title {
          font-size: 18px;
          text-align: center;
          margin-bottom: 10px;
        }

        .feature-text {
          font-size: 14px;
          text-align: center;
          padding: 0 35px;
          line-height: 23px;
        }
      }
    }

    &--image-blocks {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;

      .feature-image-group {
        flex-basis: 100%;
        max-width: 405px;
        box-shadow: 0 4px 13px 0 rgba(0, 0, 0, 0.09);
        margin: 0 20px 40px 20px;

        .feature-content {
          padding: 40px 20px;

          .feature-title {
            color: #303030;
            font-size: 18px;
            line-height: 32px;
            margin-bottom: 20px;
          }

          .feature-text {
            color: #686868;
            font-size: 14px;
            line-height: 23px;
          }
        }
      }
    }
  }

  @media all and (min-width: 1024px) {
    .features {
      &--logos {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        .features-group {
          flex: 1;

          .feature-logo {
          }

          .feature-title {
            font-size: 18px;
          }

          .feature-text {
            font-size: 14px;
            line-height: 26px;
          }
        }
      }

      &--image-blocks {
        flex: 1;

        .feature-title {
          font-size: 22px;
        }

        .feature-text {
          font-size: 15px;
          line-height: 26px;
        }
      }
    }
  }
}
