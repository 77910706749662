.cms-index-index {
  .hero-slider-container {
    .custom-slider {
      .banner-image {
        &::after {
          opacity: 0.77;
          background-image: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(105,105,121,1) 20%, rgba(0,212,255,1) 100%);
        }
      }

      .custom-slider-item {
        &::after {
          background: none;
        }

        .custom-slider-item-description {
          color: $white;
        }
      }
    }
  }

  .footer {

    margin-top: 70px;

  }

}

/* ----- Amazon FC ----- */
.amazon-store {
    &.cms-new-amazon-homepage,
    &.cms-index-index {
        .hero-slider-container{
            .custom-slider-item {
                max-width: 1440px;
                min-height: 0;
                background-repeat: no-repeat;
                background-size: cover;
                background-position: top;
                margin: 0 auto;
                padding: 90px 0;
                &::after {
                    background-image: linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5));
                }
                .custom-slider-item-inner {
                    display: flex;
                    height: 100%;
                    justify-content: center;
                    align-content: center;

                    .custom-slider-item-description {
                        font-size: 36px;
                        line-height: 1.2;
                        font-weight: 600;
                        text-align: center;
                        margin-bottom: 0;
                    }
                }
            }
        }
        #maincontent {
            div.page {
                margin-bottom: 40px;
            }
        }
        .footer {
            margin-top: 0;
        }
    }
}
/* ----- Homepage (Default Store) ----- */
.dte-store--default {
  &.cms-index-index {
    .container {
      margin: 0 auto; // Remove gutters as not on design for this page tablet etc.
    }

      .page-wrapper {
          .hero-slider-container {
              .custom-slider-item {
                  max-width: 1440px;
                  height: 612px;
                  background-position: center -82px;
                  &.custom-slider-item-137 {
                    height: 500px;
                  }
                  &.custom-slider-item-139 {
                    height: 500px;
                  }
                  @media all and (max-width: 767px) {
                      padding-top: 100px;
                      background-position: 0;
                      height: auto;

                  }
                  .banner-container {
                      padding: 100px 0 0 0;
                      @media all and (max-width: 575px) {
                          padding: 0;

                      }

                      .banner-text {
                          p {
                            line-height: 35px;
                          }
                      }
                      .block-button {
                          padding: 20px 0 0 0;
                          .button {
                              min-width: 170px;
                              font-size: 14px;
                              width: 167px;
                              height: 44px;
                          }
                      }
                  }
              }


          }
      }

    .hero-slider-container .custom-slider .custom-slider-item .banner-container-left {
      @media (max-width: 767px)  {
        margin: 0;
      }
    }

    /* ----- Infographics Start ----- */
    .homepage-infographics {
      margin: 30px auto 50px;
      max-width: 1440px;

      .homepage-infographic {
        padding-right: 15px;

        @media all and (max-width: 1024px) {

          padding-right: 0;
          margin-bottom: 30px;

        }

        .infographic {
          &--image-container {
            margin: 20px auto;

              img {
                width:104px;
                height:auto;
                margin: 0 auto;
            }
        }

        &--content {
          text-align: center;
          margin: 0 auto;
          max-width: 500px;
        }

        &--title {
          margin: 0;

          h3 {
            font-size: 20px;
            margin: 0 0 18px 0;
            font-weight: 500;
          }
        }

        &--text {
          margin-right: 15px;

          @media all and (max-width: 1023px) {

            margin: 0;
            padding: 0 30px;

          }

          p {
            font-size: 14px;
            line-height: 23px;
            margin: 0;
          }
        }
      }
    }
  }

  /* Desktop */
    @media all and (min-width: 1024px) {
      .homepage-infographics {
        display: flex;
        justify-content: center;

        .homepage-infographic {
          display: flex;

          .infographic {
            &--image-container {
              flex: 1;
              margin: 0 25px;
              display: flex;
              align-items: center;
              justify-content: center;
            }

            &--content {
              flex: 2;
              text-align: left;
              max-width: 300px;

              &--title {
                h3 {
                  font-size: 20px;
                }
              }
            }
          }
        }

        .homepage-infographic:not(:last-of-type) {
          border-right: 1px solid #e0e0e0;
        }
      }
    }
    /* ----- Infographics End ----- */

    /* ----- Transforming with Cisco Start ----- */
    .transforming-with-cisco {
      display: flex;
      flex-direction: column-reverse;
      margin-bottom: 40px;

      .block {
        &--left {
          flex: 1;
          background-color: #0ab6ff;
          padding-bottom: 30px;

          .block-content {
            padding: 20px 40px;
            max-width: 368px;
              @media all and (max-width: 575px) {
                  padding-left: 27px;
                  padding-right: 49px;
                  padding-top: 27px;
              }
              @media all and (max-width: 912px) {
                  max-width: 100%;
              }

          }

          .block-title {
            color: #ffffff;
            font-size: 18px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 26px;
            margin-bottom: 20px;
          }

          .block-text {
            color: #ffffff;
            font-size: 14px;
            letter-spacing: 0;
            line-height: 26px;
            margin-bottom: 50px;
          }

          .block-button {
            font-size: 14px;
            letter-spacing: 0;
            line-height: 17px;

              // New Content Changes
              display: none;

            .button {

              background: transparent;

            }
          }
        }

        &--right {
          flex: 1;
          padding: 20px;
          background-color: #0ab6ff;

            @media all and (max-width: 575px) {
                padding: 15px;
            }
          .block-video {
            height: 100% !important;

            #cisco-video {
              width: 100% !important;
              margin: 0 auto;
              display: block;
              min-height: 250px;
            }
          }
        }
      }
    }

    /* Desktop */
    @media all and (min-width: 1024px) {
      .transforming-with-cisco {
        display: flex;
        flex-direction: row;

        .block {
          &--left {
            flex: 1;
            background-color: #0ab6ff;
            display: flex;
            align-items: center;
            min-height: 430px;
            padding-bottom: 0;

            .block-content {
              padding: 0;
              margin: 20px 82px;
            }

            .block-title {
              font-size: 22px;
            }

            .block-text {
              font-size: 15px;
            }

            .block-button {
            }
          }

          &--right {
            flex: 1;
            padding: 0;

            .block-video {
              height: 100%;

              #cisco-video {
                width: 100% !important;
                height: 100% !important;
              }
            }
          }
        }
      }
    }
    /* ----- Transforming with Cisco End ----- */

    /* ----- Smart Networking Wireless Warehouse Start ----- */
    .smart-tabs-blocks {
      margin: 0 auto;
      padding-bottom: 80px;
      max-width: 1318px;


      .smart-tabs-homepage-content {
        display: flex;
        justify-content: center;

        .tab-content {
          width: auto;
          height: 410px;
          @media (min-width: 320px) and (max-width: 1024px) {
            height: auto;
          }

          .tab-content-left {
            width: 60%;
            margin: 91px 90px 180px 54px;

            @media (min-width: 320px) and (max-width: 480px) {
              padding: 30px 50px;
              width: 100%;
              margin: 0 auto;

             /* p{
                padding:0;
              }*/
            }
          }
          p {
            font-size: 20px;
            line-height: 34px;
            padding-bottom: 40px;
          }
          h3 {
            font-size: 25px;
            font-weight: 600;
            letter-spacing: 0;
            line-height: 32px;
          }
          .button.button--transparent {
            color: #0AB6FF;
            border: 1px solid #0AB6FF;
            min-width: 203px;

            }
           a.button.second-m {
               margin-left: 40px;
             @media (min-width: 320px) and (max-width: 480px) {
                margin-top: 10px;
                margin-left: 0;
             }
          }

        }

      }

      .smart-tabs-homepage-container {
        position: relative;

        .smart-tabs-homepage-img-container {
          display:flex;
          margin: 0 auto;
          justify-content: center;
          flex-wrap: wrap;

          @media (min-width: 768px) and (max-width: 1024px) {
              display: block;

          }
        }

        .smart-tabs-homepage-item-tabs {
          display: flex;
          justify-content: stretch;

          @media (min-width: 320px) and (max-width: 820px) {
            flex-direction: column;
          }

          .tab-item {
            padding: 30px 103px;
            background-color: #526B79;
            color: #ffffff;
            font-weight: 500;
            margin-right: 1px;
            font-size: 25px;
            cursor: pointer;
            width: 100%;
            transition: all ease-in-out 0.3s;
            text-align: center;
            line-height: 32px;
            letter-spacing: 0;

            @media (min-width: 320px) and (max-width: 480px) {
              font-size: 18px;
              padding: 10px 102px 10px 27px;
              text-align: left;
            }

            &.active {
              background: #ffffff;
              color: #526B79;
              position: relative;
              &::after {
                content: "";
                display: block;
                position: absolute;
                border-top: 20px solid transparent;
                border-bottom: 20px solid transparent;
                border-right: 20px solid white;
                transform: rotate(90deg);
                bottom: unset;
                top: -30px;
                right: 50%;

              }

            }
          }
        }
      }



    }

    /* ----- Smart Networking Wireless Warehouse End ----- */

    /* ----- IT Megastore and Support Banner Start -----*/
    .it-megastore-banner-container {

      margin: 0 auto;
      height: 200px;
      display: inline-block;
      width: 100%;

      @media (min-width: 768px) and (max-width: 1024px) {
        width: auto;
      }


      .banner-container {
          padding-left: 5%;

        .banner-title {
          font-size: 38px;
          color: #0ab6ff;
          font-weight: bold;
          line-height: 50px;
          padding-top: 38px;
        }
        .banner-description {
          width: 50%;
          display: flex;
          padding-top: 12px;

          @media (min-width: 768px) and (max-width: 1024px) {
            width: 60%;
          }
          h2 {
            color: #FFFFFF;
            font-size: 27px;
            letter-spacing: 0.11px;
            line-height: 30px;
            margin-right: 20px;
          }
          .button {
            min-width: 168px;
            margin-right: 10px;
            @media (min-width: 320px) and (max-width: 480px) {
              margin-right: 29px;
            }
          }
        }

      }


      .it-megastore-banner-img {
        background-repeat: no-repeat;
        height: auto;
        width: 100%;
        background-size: cover;


        @media (max-width: 767px)  {
          max-width: 414px;
          text-align: center;
          height: auto;

          .banner-container {
            margin: 0 30px;
            .banner-description {
              display: block;
              width: auto;
               h2 {
                 padding-bottom: 19px;
               }
            }

          }
        }
        .banner-container {
          padding-bottom: 30px;
        }
      }
    }

    /* ----- IT Megastore and Support Banner End -----*/

    /* ----- Our Partners Grid Start ----- */
    .our-partners {
      margin: 20px auto 0;
      padding: 0 82px;

      &.clients {
        border-bottom: 1px solid #E0E0E0;
        padding-bottom: 60px;
        padding-top: 60px;

        @media (max-width: 550px) {
          padding-top: 140px;
        }
      }

      &--content {
        max-width: 400px;
          @media all and (max-width: 575px) {
              margin-left: 0;
          }

        .partners-title {
          font-size: 18px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 32px;
          color: #303030;
          margin-bottom: 20px;
        }

        .partners-text {
          font-size: 16px;
          letter-spacing: 0;
          line-height: 26px;
          color: #303030;
          margin-bottom: 40px;
        }
      }

      &--grid {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        align-items: center;

        .partner-grid-item {
          flex-basis: 50%;
          padding: 0 10px;
          margin-bottom: 0;

          @media all and (max-width: 1024px) {
          margin-bottom: 50px;
          }

          img {
            max-width: 100%;
            max-height: 80px;
            width: auto;
            height: auto;
            margin: 0 auto;
          }
          @media all and (max-width: 550px) {
            &:nth-of-type(odd) img {
              margin: 0 auto 0 0;
            }

            &:nth-of-type(even) img {
              margin: 0 0 0 auto;
            }
          }
        }
      }

      @media all and (max-width: 550px) {
        padding: 0 30px;
      }
    }

    /* Desktop */
    @media all and (min-width: 1024px) {
      .our-partners {
        &--content {
          .partners-title {
            font-size: 22px;
          }

          .partners-text {
            font-size: 15px;
          }
        }

        &--grid {
          display: flex;
          justify-content: center;
          flex-wrap: unset;

          .partner-grid-item {
            flex: 1;
          }
        }
      }
    }
  }
}

/* ----- Our Partners Grid End ----- */
