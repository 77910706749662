$minicart-product__padding                        : $spacer--medium 0 $spacer !default;
$minicart-product__border                         : 1px solid $gray-lighter !default;
$minicart-product__spacer                         : $spacer !default;

$minicart-product__image-width                    : 72px !default;

$minicart-product__info-padding                   : 0 0 0 $spacer--medium !default;

$minicart-product__name-line-height               : 1.15 !default;

$minicart-product__link-text-decoration           : none !default;

$minicart-product__update-width                   : 90px !default;
$minicart-product__update-height                  : 32px !default;
$minicart-product__update-padding                 : 0 $spacer !default;
$minicart-product__update-margin                  : 0 0 $spacer 0 !default;

$minicart-product__input-padding                  : $spacer 0 !default;

$minicart-product__field-width                    : 56px !default;
$minicart-product__field-height                   : 32px !default;
$minicart-product__field-padding                  : $spacer !default;
$minicart-product__field-margin                   : 0 0 0 $spacer !default;

$minicart-product__attributes-button-margin       : 0 !default;
$minicart-product__attributes-button-padding      : 0 !default;
$minicart-product__attributes-button-color        : $blue !default;
$minicart-product__attributes-button-color--active: $color-primary !default;
$minicart-product__attributes-button-bg-color     : $white !default;
$minicart-product__attributes-button-font-family  : $font-family-base !default;
$minicart-product__attributes-button-font-size    : $font-size-small !default;
$minicart-product__attributes-list-padding        : $spacer 0 0 0 !default;
$minicart-product__attributes-list-color          : $gray-dark !default;
$minicart-product__attributes-list-icon-margin    : 0 0 0 $spacer !default;

$minicart-product__price-color                    : $color-secondary !default;
$minicart-product__price-font-weight              : $font-weight-bold !default;

$minicart-product__action-button-bg               : transparent !default;
$minicart-product__action-icon-size               : 24px !default;
$minicart-product__action-icon-padding            : 2px !default;
$minicart-product__action-icon-color              : $gray !default;
$minicart-product__action-icon-color--active      : $color-primary !default;

.minicart-view-basket-btn {
    margin: 20px 0;
}

.minicart-content__total {

    margin: 0;
    padding: 15px 16px;

}

.minicart-product__name{
    font-size: 15px;
}

.minicart-content__products-list {
    margin: 15px 0 0;
}

/* Fixing Normal store bug */
.dte-store--default {

    header {
        .minicart-product:last-child {
            padding-bottom: 120px !important;
        }
    
        .cart.main.actions {
            margin: 0;
        }
    
        .minicart-content__actions {
            position: absolute;
            bottom: 0;
            width: 100%;
            background: white;
        }
    }
}

// .amazon-store #minicart-content-wrapper {
//     .minicart-product:last-child {
//         padding-bottom: 145px !important;
//     }
// }

.minicart-product {
    display: flex;
    width: 100%;
    flex-flow: row nowrap;
    padding: $minicart-product__padding;
    border-bottom: $minicart-product__border;

    &:last-child {
        border-bottom: none;
    }

    &__image {
        width: $minicart-product__image-width;
        img {
            width: $minicart-product__image-width;
            max-width: $minicart-product__image-width;
        }
    }

    &__info {
        display: flex;
        flex-flow: row wrap;
        width: calc(100% - #{$minicart-product__image-width});
        padding: $minicart-product__info-padding;
        text-align: left;
    }

    &__name {

        margin-bottom: $minicart-product__spacer;
        font-weight: $font-weight-normal;
        line-height: $minicart-product__name-line-height;
        font-size: 16px;

    }

    &__link {
        text-decoration: $minicart-product__link-text-decoration;
    }

    &__attributes {
        margin-bottom: $minicart-product__spacer;
    }

    &__attributes-button {
        display: flex;
        align-items: center;
        margin: $minicart-product__attributes-button-margin;
        padding: $minicart-product__attributes-button-padding;
        font-family: $minicart-product__attributes-button-font-family;
        font-size: $minicart-product__attributes-button-font-size;

        .dropdown-list__icon {
            position: static;
            margin: $minicart-product__attributes-list-icon-margin;
            fill: $minicart-product__attributes-button-color;
        }

        &.dropdown-list__label {
            color: $minicart-product__attributes-button-color;
            background-color: $minicart-product__attributes-button-bg-color;
            &:focus,
            &:hover {
                background-color: $minicart-product__attributes-button-bg-color;
            }
        }
    }

    &__attributes-list {
        padding: $minicart-product__attributes-list-padding;
        color: $minicart-product__attributes-list-color;
    }

    &__price {

        width: 100%;
        font-weight: $minicart-product__price-font-weight;
        color: $minicart-product__price-color;
        margin: 0 0 10px;

    }

    &__bottom {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    &__qty {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    &__update {

        display: none;
        height: $minicart-product__update-height;
        width: $minicart-product__update-width;
        padding: $minicart-product__update-padding;
        margin: $minicart-product__update-margin;
        font-size: 14px;

    }

    &__label,
    &__input {
        margin: 0;
    }

    &__input {
        padding: $minicart-product__input-padding;
    }

    &__field {
        padding: $minicart-product__field-padding;
        width: $minicart-product__field-width;
        height: $minicart-product__field-height;
        margin: $minicart-product__field-margin;
        text-align: center;
    }

    &__actions {
        display: flex;
        justify-content: flex-end;
    }

    .quantity-update {

        margin: 0;
        padding: 0;

        .quantity-update__input {

            margin: 0;
            padding: 8px 0;
            height: 32px;

        }

        .quantity-update__button {

            height: 32px;
            padding: 8px 8px;

            &.quantity-update__button--minus {
                padding-right: 0;
            }

            &.quantity-update__button--plus {
                padding-left: 0;
            }

        }

    }



    &__button {

        &.delete {

            padding: 0;
            height: 32px;
            width: 32px;
            min-width: 0;

            .minicart-product__button-icon {

                width: 18px;
                height: 18px;

            }

        }

        /*border: 0;
        background-color: $minicart-product__action-button-bg;
        padding: 0 5px;
        min-height: 0;
        min-width: 0;
        width: auto;
        height: auto;

        &:focus,
        &:hover {
            background-color: $minicart-product__action-button-bg;
        }

        .minicart-product__button-icon {

            height: $minicart-product__action-icon-size;
            width: $minicart-product__action-icon-size;
            padding: $minicart-product__action-icon-padding;
            fill: $minicart-product__action-icon-color;

            &:focus,
            &:hover {
                fill: $minicart-product__action-icon-color--active;
            }

        }*/

    }

}
