.solutions-view-index {
    nav.breadcrumbs {
        display: none;
    }
    .heading--page {
        display: none;
    }

    .hero-slider-container {

        .custom-slider-item {

            min-height: 573px;
            padding: 0;
            display: flex;
            align-items: center;

            .custom-slider-item-inner {

                width: 100%;
                margin: 50px 0;

            }

            .custom-slider-item-title {

            }

            .custom-slider-item-icon {

                max-width: 96px;
                max-height: 96px;
                height: auto;
                width: auto;

            }

            .custom-slider-item-strapline {

                color: $white;

                .button {

                    position: static;
                    display: inline-block;
                    width: auto;
                    padding: 15px 45px;
                    margin-top: 30px;

                    // /position: absolute;
                    //top: 100%;
                    //margin-top: 15px;
                    //left: 50%;
                    //transform: translateX(-50%);
                    //padding: 30px 40px;

                }

            }

        }

    }

    .page-main{
        .solutions-sub-menu {
            background-color: #f6f6f6;
            border-bottom: 1px solid #d3d3d3;
            .solutions-services-sub-menu {

                .solutions-services-sub-menu__list {
                    list-style: none;
                    position: relative;
                    margin: 0 auto;
                    width: 100%;
                    max-width: 1453px;
                    padding: 0;

                    @media all and ( max-width: 550px ) {

                        display: flex;
                        justify-content: space-between;

                    }
                    > .solutions-services-sub-menu__item {

                        display: inline-block;
                        padding: 12px 40px 6px;
                        border-bottom: 3px solid #f6f6f6;
                        font-size: 18px;
                        transition: border-color 200ms ease-in-out;
                        cursor: pointer;

                        @media all and ( max-width: 768px ) {

                            font-size: 14px;
                            padding: 10px 13px 7px;

                        }

                        a {

                            color: #333;

                            &:hover {

                                text-decoration: none;

                            }

                        }

                        &--parent {

                            // Add pseudo dropdown icon here

                            > a {

                                &::after {

                                    content: '';
                                    background-image: url("data:image/svg+xml,%3Csvg height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m7.92285156 6.96830426 7.49903224 7.49903224-7.49903224 7.4983052' fill='none' stroke='%23231f20' stroke-linecap='round' stroke-linejoin='round' transform='matrix(0 1 -1 0 26.524727 .866875)'/%3E%3C/svg%3E");
                                    width: 16px;
                                    height: 16px;
                                    background-size: 100% 100%;
                                    display: inline-block;
                                    margin: 2px 0 0 8px;
                                    transform: translate(2px,3px);

                                }

                            }

                            &.active {

                                > a {

                                    &::after {

                                        transform: translate(2px, 3px) rotate(180deg);

                                    }

                                }

                            }

                        }

                        ul.solutions-services-sub-menu__inner-list {

                            display: none;
                            list-style: none;
                            background-color: rgba(255,255,255,0.9);
                            font-size: 15px;
                            position: absolute;
                            top: 100%;
                            left: 0;
                            right: 0;
                            width: 100%;
                            padding: 15px 15px 30px;
                            z-index: 1;
                            margin: 1px auto 0;

                            .solutions-services-sub-menu__inner-item {

                                padding: 0;

                                > a {

                                    padding: 7px 20px;
                                    display: block;

                                    &:hover {

                                        background-color: #f6f6f6;

                                    }

                                }

                                &.solutions-services-sub-menu__inner-item--all {

                                    display: none;

                                }

                            }

                        }

                        &:hover {

                            border-color: #2ac4f3;

                        }


                        &.active {

                            border-color: #2ac4f3;

                            > ul {

                                display: block;

                            }

                        }

                    }
                }

            }


        }
    }

    .our-solutions-intro-container {

        max-width: 750px;
        margin: 0 auto;
        text-align: center;

    }

    .our-solutions-bg-container {

        background-color: $nearly-white;
        margin: 50px auto;
        padding: 50px 0;

        .our-solutions-bg-info {

            .our-solutions-bg-text {

                max-width: 750px;
                margin: 0 auto;
                text-align: center;

                h3 {

                    font-weight: 600;
                    margin: 30px 0;

                }

                h4 {

                    font-size: 20px;
                    line-height: 1.33;

                }

                .button {
                    margin-top: 15px;
                }

            }

            .our-solutions-bg-widgets {



            }

        }

        hr {

            background: $nearly-white;
            margin: 45px auto;

        }

    }

    .our-certifications-container {

        background-color: $nearly-white;
        margin: 50px auto 0;
        padding: 50px 0;

        .our-certifications-info {

            max-width: 750px;
            margin: 0 auto;
            text-align: center;

            > p {
                margin-bottom: 30px;
            }

        }

    }

    @media all and (min-width: 1024px) {

        .hero-slider-container .custom-slider-item .custom-slider-item-strapline .button {

            //position: absolute;
            //top: 50%;
            //margin-top: 15px;
            //left: 780px;
            //padding: 30px 40px;
            //transform: translateY(-50%);
            //right: auto;
            //bottom: auto;

            position: static;
            display: inline-block;
            width: auto;
            padding: 15px 45px;
            margin-top: 10px;

        }

    }

    @media all and (max-width: 767px) {

        .hero-slider-container {

            .custom-slider-item {

                padding-top: 30px;
                padding-bottom: 100px;

                .custom-slider-item-strapline {

                    .button {

                        left: 30px;
                        right: 30px;
                        transform: none;

                    }

                }

            }

        }

    }

    &.service-page {
        .custom-slider-item {
            padding: 0 !important;
        }
    }

}
