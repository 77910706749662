#cart-totals,
.opc-block-summary .data.cart-totals, .opc-block-summary .data.table-totals {

    border-top: 0;
    padding: 0 5px;

    .cart-totals__row {

        display: flex;
        justify-content: space-between;
        padding: 15px 0;

        .cart-totals__row-label,
        .cart-totals__row-value {

            font-size: 15px;

        }

        &.sub {

            .cart-totals__row-value {

                font-weight: bold;

            }

        }

        .cart-totals__row-label--total {

            font-size: 15px;
            font-weight: bold;

            strong {

                font-weight: bold;

            }

        }

        .cart-totals__row-value--total {

            font-size: 20px;
            font-weight: bold;

        }

    }

    .cart-totals__row--summary {

        margin-bottom: 0;
        padding: 25px 1px 5px;

        + .cart-totals__row--summary {

            border-top: none;
            padding: 5px 0;

        }

    }

}

#payment-request-button-cart {

    margin: 0;
    height: auto;
    line-height: 0;

}


.opc-block-summary {

    .checkout-summary__title {

        color: #333;
        font-size: 18px;

    }

    .place-order {

        margin: 20px 0 0!important;

        button.action.primary.checkout {

            display: inline-flex!important;
            background-color: #32c026!important;
            font-size: 14px!important;
            font-weight: bold!important;
            border-radius: 4px!important;
            padding: 8px 40px!important;
            height: 44px!important;

            &:hover,
            &:focus {

                background: #32c026 !important;
                color: white !important;
                border: none !important;

            }

        }

    }

}
