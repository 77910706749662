/* Related posts specific styling here */
.mfblog-related-posts-block {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 30px 0;


    .block-title {
        font-size: 22px;
        color: $blog-body;
        margin-bottom: 1rem;

        strong {
            font-weight: normal;
        }
    }

    .block-content {
        padding: 0;
        margin: 0;
        display: flex;
        flex-direction: column;
        gap: 1.2rem;

        li.item {
            display: flex;
            list-style: none;
            background-color: white;
            border: 1px solid $blog-grey-lighter;
            border-radius: 5px;
            color: $blog-body;
            overflow: hidden;

            img {
                width: 40%;
                object-fit: cover;
            }

            .related-post-content {
                padding: 1rem;
                width: 60%;

                @media all and (min-width: 1140px) {
                    padding: 1.5rem;
                }

                .post-item-link {
                    font-size: 16px;
                    color: $blog-body;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 1;
                    overflow: hidden;
                    min-height: 48px;

                    @media all and (min-width: 1140px) {
                        -webkit-line-clamp: 2;
                    }
                }

                .related-category-name {
                    color: $blog-body;
                    margin-right: 0.5rem;
                }

                .post-date {
                    color: $blog-body-lighter;
                }
            }
        }
    }
}
