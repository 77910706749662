.brands-page-list {

    #maincontent {

        .title-wrapper {
            display: none;
        }

    }

    .hero-slider-container {

        .custom-slider-item {

            min-height: 1px;

            &:after {
                display: none;
            }

            .custom-slider-item-image:after {
                display: none;
            }

            .custom-slider-item-inner {

                max-width: 550px;
                margin: 0 auto;
                text-align: center;
                min-height: 225px;

            }

            .custom-slider-item-strapline {
                color: $white;
            }

        }

    }

    .attributes-list-items-wrapper {

        .attributepages-title {

            font-size: 16px;
            color: $thunder;

        }

    }

    .attributepages-options-group-title-heading {

        margin: 40px 0;
        text-transform: capitalize;

    }

    .attributepages-options {
        margin-bottom: 350px;
    }

    .grouped-by-first-letter-list {

        margin: 30px auto 45px;

        text-align: center;

        .grouped-by-first-letter-list__link {

            color: $thunder;
            font-size: 20px;
            display: inline-block;
            padding: 0 8px;
            text-transform: capitalize;

            &.letter-invalid {

                opacity: 0.5;
                pointer-events: none;

            }

            &.letter-valid {

            }

        }
    }

}