$catalog-list__margin                 : $spacer--medium 0 0 0 !default;
$catalog-list__sidebar-width\@medium  : 306px !default;
$catalog-list__sidebar-margin\@medium : 0 $spacer--medium 0 0 !default;
$catalog-list__sidebar-border         : 1px solid $gray-lighter !default;
$catalog-list__products-width\@medium : calc(100% - #{$catalog-list__sidebar-width\@medium} - #{$spacer--medium}) !default;
$catalog-list__products-padding       : 0 0 $spacer--large 0 !default;

.catalog-list {

    display: flex;
    flex-direction: column;
    margin: $catalog-list__margin;
    @include mq($screen-l) {
        display: block;
    }
    @include clearfix;

    &__products {

        order: 1;
        padding: $catalog-list__products-padding;

        @include mq($screen-l) {
            float: right;
            width: $catalog-list__products-width\@medium;
        }

    }

    &__sidebar,
    &__sidebar-additional {
        @include mq($screen-l) {
            float: left;
            width: $catalog-list__sidebar-width\@medium;
        }
    }

    &__sidebar {
        order: 0;
        border-bottom: $catalog-list__sidebar-border;
        @include mq($screen-l) {
            margin: $catalog-list__sidebar-margin\@medium;
            border-bottom: none;
        }
    }

    &__sidebar-additional {
        order: 2;
        @include mq($screen-l) {
            clear: left;
        }
    }

}

.catalog-category-view {

    .category-hero-with-img {

        &.slim-header{

            .custom-slider-item {
                padding-top: 10px;
                padding-bottom: 0px;
            }
            h1{
                font-size: 30px;
            }
        }
    }
}



body {

    &.filters-hidden {

        .catalog-list__sidebar {
            display: none;
        }

        .catalog-list {

            &__products {

                @include mq($screen-l) {
                    width: 100%;
                }

            }

        }

    }

}

@media all and (max-width: 767px) {

    .catalog-category-view {

        .category-hero-with-img {

            &.slim-header {

                .custom-slider-item {
                    padding: 20px 0 25px;
                }

            }

        }

    }

}

@-webkit-keyframes slide-in-fwd-center {
    0% {
        -webkit-transform: translateZ(-1400px);
        transform: translateZ(-1400px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        opacity: 1;
    }
}
@keyframes slide-in-fwd-center {
    0% {
        -webkit-transform: translateZ(-1400px);
        transform: translateZ(-1400px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        opacity: 1;
    }
}

/* ----------------------------------------------
 * Generated by Animista on 2019-1-28 20:51:53
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slit-out-vertical
 * ----------------------------------------
 */
@-webkit-keyframes slit-out-vertical {
    0% {
        -webkit-transform: translateZ(0) rotateY(0);
        transform: translateZ(0) rotateY(0);
        opacity: 1;
    }
    54% {
        -webkit-transform: translateZ(-160px) rotateY(87deg);
        transform: translateZ(-160px) rotateY(87deg);
        opacity: 1;
    }
    100% {
        -webkit-transform: translateZ(-800px) rotateY(90deg);
        transform: translateZ(-800px) rotateY(90deg);
        opacity: 0;
    }
}
@keyframes slit-out-vertical {
    0% {
        -webkit-transform: translateZ(0) rotateY(0);
        transform: translateZ(0) rotateY(0);
        opacity: 1;
    }
    54% {
        -webkit-transform: translateZ(-160px) rotateY(87deg);
        transform: translateZ(-160px) rotateY(87deg);
        opacity: 1;
    }
    100% {
        -webkit-transform: translateZ(-800px) rotateY(90deg);
        transform: translateZ(-800px) rotateY(90deg);
        opacity: 0;
    }
}

.catalog-category-view {

    #instant-search-results-container {

        .ais-hits {

            margin: 30px 0;

            @media all and ( max-width: 991px ) {

                margin-top: 0;

            }

            .ais-hits--item {

                margin-bottom: 15px;
                border-top: 1px #ebebeb solid;

                &:last-child {

                    border-bottom: 1px #ebebeb solid;
                    padding-bottom: 15px;

                }

                .catalog-list-item {

                    padding: 0;
                    border: none;

                    .catalog-list-item__brand {

                        top: 10px;
                        left: 10px;
                        right: unset;
                        bottom: unset;
                        z-index: 2;
                        max-width: 37px;
                        width: auto;
                        height: auto;

                    }

                    .row {

                        margin: 0;

                    }

                    .catalog-list-item__img-col {

                        flex-basis: 25%;
                        max-width: 25%;

                    }

                    .catalog-list-item__main-col {

                        flex-basis: 75%;
                        max-width: none;
                        padding: 30px 15px;

                        .catalog-list-item__main {

                            padding-bottom: 34px;

                            .catalog-list-item__details {

                                padding: 15px;

                                @media all and (min-width: 993px) and (max-width: 1250px) {

                                    margin-bottom: 45px;

                                }

                                p {

                                    font-size: 13px;
                                    color: #333;

                                    span {

                                        font-weight: 600;

                                    }

                                    &.catalog-grid-item__sku {

                                        text-transform: uppercase;
                                        color: #949494;
                                        margin-top: 10px;

                                    }

                                }

                                .catalog-list-item__name {

                                    margin-bottom: 0;

                                    a {

                                        font-weight: 500;
                                        font-size: 18px;

                                    }

                                }

                                .catalog-grid-item__sku {

                                    font-size: 14px;

                                }

                            }

                            .catalog-list-item__bottom {

                                display: flex;
                                justify-content: flex-start;
                                align-items: center;
                                padding: 30px 15px 5px;
                                position: absolute;
                                bottom: 0;
                                left: 0;
                                right: 0;

                                @media all and (min-width: 769px) and (max-width: 930px) {

                                    flex-wrap: wrap;

                                }

                                @media all and (min-width: 993px) and (max-width: 1250px) {

                                    flex-wrap: wrap;

                                }

                                .catalog-list-item__price {

                                    margin-right: 20px;
                                    flex-basis: auto;

                                    @media all and ( min-width: 1251px ) and ( max-width: 1300px ) {

                                        margin-right: 10px;

                                    }

                                    @media all and (min-width: 769px) and (max-width: 930px) {

                                        order: 1;

                                    }

                                    @media all and (min-width: 993px) and (max-width: 1250px) {

                                        order: 1;
                                        flex-basis: 100%;

                                    }

                                    .price-wrapper {

                                        > span {

                                            font-size: 26px;
                                            display: inline;
                                            font-weight: bold;
                                            width: 100%;

                                            &::first-letter {

                                                font-size: 16px;
                                                font-weight: normal;

                                            }

                                        }

                                    }

                                }

                                .catalog-list-item__stock-status {

                                    margin-right: 20px;

                                    @media all and (min-width: 1251px) and (max-width: 1300px) {

                                        margin-right: 10px;

                                    }

                                    @media all and (min-width: 769px) and (max-width: 930px) {

                                        order: 4;
                                        flex-basis: 50%;

                                    }

                                    @media all and (min-width: 993px) and (max-width: 1250px) {

                                        order: 2;
                                        margin-bottom: 10px;

                                    }

                                    .stock-status-inner {

                                        display: flex;
                                        align-items: center;
                                        font-size: 13px;
                                        font-weight: 500;
                                        line-height: 1;

                                        img {

                                            width: 18px;
                                            margin-right: 5px;
                                            padding-bottom: 2px;

                                        }

                                        &.stock-qty-oos {

                                            color: $highlight-orange !important;

                                        }

                                        &.stock-qty-in-stock {

                                            color: #32c026;

                                        }

                                    }

                                }

                                .catalog-list-item__actions-secondary {

                                    position: static;
                                    margin-right: 20px;

                                    @media all and (min-width: 769px) and (max-width: 930px) {

                                        order: 2;

                                    }

                                    @media all and (min-width: 993px) and (max-width: 1250px) {

                                        order: 3;
                                        margin-bottom: 5px;

                                    }


                                    .button {

                                        svg use.Heart-border-grey {

                                            stroke: none !important;

                                        }

                                    }

                                }

                                .catalog-list-item__primary-form {

                                    display: flex;


                                    @media all and (min-width: 769px) and (max-width: 930px) {

                                        order: 3;

                                    }

                                    @media all and (min-width: 993px) and (max-width: 1250px) {

                                        order: 4;
                                        flex-basis: 100%;

                                    }

                                    @media all and (min-width: 1251px) {

                                        position: absolute;
                                        right: 0;

                                    }

                                    .qty-selector {

                                        display: flex;
                                        border: 1px solid #e0e0e0;
                                        border-bottom-width: 3px;
                                        margin-right: 15px;
                                        border-radius: 5px;

                                        @media all and (max-width: 450px) {

                                            width: auto;
                                            justify-content: space-around;
                                            margin-right: 0;
                                            margin-bottom: 5px;

                                        }

                                        .quantity-update__input {

                                            font-size: 15px;
                                            height: 35px;

                                        }

                                        .qty-update-btn {

                                            min-width: 25px;
                                            font-size: 20px;
                                            line-height: 1;
                                            background-color: transparent;
                                            color: #e0e0e0;
                                            border: none;
                                            padding: 0 5px;
                                            font-weight: bold;
                                            cursor: pointer;

                                            &.qty-update-decrease {

                                                font-size: 35px;
                                                font-weight: normal;

                                            }

                                        }

                                    }

                                    button.tocart {

                                        background-color: #32c026;
                                        padding: 5px 25px 10px 15px;
                                        height: 37px;

                                        @media all and ( max-width: 992px ) {

                                            min-width: 180px;

                                        }

                                        img {

                                            display: inline-block;
                                            width: 21px;
                                            margin-right: 15px;

                                        }

                                        .catalog-list-item__cart-text {

                                            padding-top: 3px;

                                        }

                                        &.oos {

                                            background-color: #dc2148;

                                        }

                                        &:hover {

                                            color: #fff;
                                            border-color: #32c026;

                                            &.oos {

                                                color: #fff;
                                                border-color: #dc2148;

                                            }

                                        }

                                        &:focus {
                                            color: white;
                                        }

                                    }

                                }

                            }

                        }

                    }

                    .catalog-list-item__actions {

                        .label{
                            display:inline;
                        }

                        .catalog-list-item__primary-action.tocart {

                            font-size: 14px;
                            padding: 0;
                            width: 100%;

                        }

                    }

                    a.price-on-application {

                        height: 37px;
                        text-align: center;

                    }

                }

            }

        }

    }

}
