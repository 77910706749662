.how-can-we-help-container {

    position: relative;
    padding: 50px;
    background: radial-gradient(circle at 65% 30%,#2272a5 0,#2e3687 50%);
    max-width: 1440px;
    margin: 0 auto;

    @media all and (max-width: 1100px) {

        padding: 50px 30px;

    }

    .container {

        margin: 0 auto;

    }

    p {

        color: white;
        font-size: 14px;

        .bold {

            font-weight: bold;

        }

    }

    .top {

        margin-bottom: 20px;

        > .col-lg-4 {

            padding-left: 0;

        }

    }

    .bottom {

        align-items: flex-end;

        .dte-copyright {

            margin: 0;

        }

    }

    .how-we-can-help-col-inner-container {

        padding: 55px 30px;

    }

    .how-we-can-help-col-inner-container-amazon-fc {
        padding: 55px 30px;
        display: inline-block;

    }

    .how-we-can-help-title {

        color: #fff;
        font-size: 17px;
        font-weight: bold;
        margin: 0 0 15px;

        @media all and (min-width: 1024px) {

            margin: 0;

        }

    }

     .contact-details {

        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-left: 15px;
        gap: 0 20px;


        p {

            font-size: 16px;
            font-weight: bold;
            margin-bottom: 0;

        }

        a {

            color: #fff;
            font-size: 16px;

            span {

                color: #fff;
                color: $dte-bright-blue;

            }

        }

        @media all and (min-width: 992px) and (max-width: 1200px) {

            .col-lg-6 {

                flex-basis: 100%;
                max-width: 100%;

                p {

                    margin-bottom: 0;

                }

            }

        }

         .col-lg-6 {

             padding-right: 0;

         }

         @media all and (max-width: 991px) {

            .contact-row:first-child {

                width: 100%;

            }

         }

    }

    .contact-us {

        display: flex;
        align-items: center;
        justify-content: start;


        @media all and (min-width: 992px) {

            justify-content: center;
            position: relative;
            left: -20px;

        }


        .contact-us-button {

            width: 100%;
            max-width: 200px;

        }

    }


    .subscribe-byline {

        font-size: 16px;

    }


    .subscribe-input {

        padding: 0 15px;

        .subscribe-col {
            position: relative;

            input {

                background-color: rgba(white, 0.33);
                border: 0;
                height: 44px;
                color: white;
                font-size: 13px;

                &::placeholder,
                &::-webkit-input-placeholder {

                    color: white;
                    font-size: 13px;

                }

                &:hover {

                    //border: $input__hover-border;
                    box-shadow: none;

                }

                &:focus {

                    background-color: rgba(white, 0.33);
                    outline: none;
                    box-shadow: none;

                }

            }
            
            .mobile-chevron {
                transform: scale(2)
            }

            #newsletter-error {
                position: absolute;
            }

            button {

                height: 44px;
                position: absolute;
                right: 0;
                min-width: 0;
                width: 65px;
                top: 0;

                &:hover {

                    svg {

                        path {

                            stroke: $dte-bright-blue;

                        }

                    }

                }

            }

        }


    }

    .social-row {

        align-items: center;

        @media all and (max-width: 991px) {

            margin-bottom: 15px;

        }

        @media all and (min-width: 992px) and (max-width: 1200px) {

            flex-direction: column-reverse;

        }

        .subscribe-byline {

            p {

                margin: 0;

            }

            @media all and (min-width: 992px) and (max-width: 1200px) {

                flex-basis: 100%;
                max-width: 100%;
                text-align: center;
                margin-top: 10px;

            }

        }


        .social-links {

            display: flex;
            justify-content: space-around;
            gap: 0 20px;

            @media all and (min-width: 992px) and (max-width: 1200px) {

                flex-basis: 100%;
                max-width: 100%;

            }

            @media all and (max-width: 991px) {

                padding-left: 0;

            }


        }

        .social-logo {

            fill: white;
            stroke: white;

        }

    }

    .footer-links {

        padding-left: 15px;
        padding-right: 0;

        a {

            color: white;
            border-right: 1px solid $dte-bright-blue;
            padding: 0 15px;

            &:first-child {

                padding-left: 0;

            }

            &:last-child {

                border: 0;
                padding-right: 0;

            }

        }

    }

    .dte-copyright {

        font-size: 13px;
        text-align: center;

        @media all and (max-width: 991px) {

            text-align: left;
            margin-top: 5px;

        }

    }

    .how-we-can-help-description {

        color: #fff;
        margin: 35px 0;
        font-size: 16px;

    }


    .how-can-we-help-buttons-container {

        .button--sign-up {
            min-width: 150px;
        }

    }

}

@media all and (max-width: 991px) {

    .how-can-we-help-container {

        padding: 40px 15px;

        .top {

            margin-bottom: 20px;

        }

        .contact-us {

            padding: 40px 15px;

            .contact-us-button {

                padding: 11px 40px;

            }

        }

        .subscribe-byline {

            display: none;

        }

        .footer-links {

            a {

                flex: 1;
                text-align: center;
                padding: 0 6px;

            }

        }

        .social-links {

            justify-content: unset !important;

            > svg {

                margin-right: 30px;

            }

        }

        .subscribe-input {

            display: none;

        }

    }

}

/*@media all and (min-width: 992px ) {

    .how-can-we-help-buttons-container {

        .button {

            + .button {
                margin-left: 20px;
            }

        }

    }

}

@media all and (min-width: 768px ) {

    .row-how-can--main-cols {

        position: relative;

        &:before {

            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: 50%;
            width: 1px;
            height: 100%;
            background: #888888;

        }

    }

}

@media all and (max-width: 991px ) {

    .how-can-we-help-buttons-container {

        .button {

            width: 100%;
            text-align: center;

            + .button {
                margin-top: 20px;
            }

        }

    }

}

@media all and (max-width: 767px ) {

    .row-how-can--main-cols {

        .col-md-6 {

            + .col-md-6 {

                position: relative;

                &:before {

                    content: '';
                    position: absolute;
                    top: 0;
                    left: -15px;
                    right: -15px;
                    background: #888888;
                    height: 1px;

                }

            }

        }

    }

}
*/

