// Add Card specific styling here
.post-list-wrapper {

    @media all and (max-width: 1456px) {
        padding: 16px;
    }

    .post-list.grid-view {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 1.2rem;

        @media all and (max-width: 990px) {
            grid-template-columns: repeat(2, 1fr);
        }

        @media all and (max-width: 576px) {
            grid-template-columns: 1fr;
        }

        .post-item {
            background-color: white;
            border: 1px solid $blog-grey-lighter;
            border-radius: 5px;
            transition: ease-in-out 0.2s;
            position: relative;

            &:hover {
                border: 1px solid $blog-body;

                &::after {
                    content: '';
                    display: block;
                    border-radius: 5px;
                    position: absolute;
                    z-index: -1;
                    top: 8px;
                    left: 8px;
                    background-color: $blog-red-dark;
                    width: 100%;
                    height: 100%;
                }
            }

            &::after {
                content: '';
                display: block;
                border-radius: 5px;
                position: absolute;
                z-index: -1;
                top: 0;
                left: 20px;
                background-color: white;
                width: 100%;
                height: 100%;
            }

            .post-container {
                display: flex;
                flex-direction: column;
                height: 100%;

                .post-info {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    height: 100%;
                    padding: 2.5rem;

                    .post-title a {
                        color: $blog-body;
                        font-weight: bold;
                        font-size: 24px;
                    }

                    .post-category .category-name {
                        color: $blog-body;
                        font-size: 20px;
                        font-weight: normal;
                        text-decoration: none;
                        margin-right: 0.5rem;
                    }

                    .post-date {
                        color: $blog-body-lighter;
                        font-size: 20px;
                    }

                    .post-description {
                        flex: 1;

                        p {
                            color: $blog-body-light;
                        }
                    }

                    .post-read-more a {
                        color: $blog-red-dark;
                        font-size: 20px;
                        font-weight: normal;
                        text-decoration: underline;

                        &:hover {
                            color: $blog-red-light;
                        }
                    }
                }

                .post-image {
                    width: 100%;
                    flex: auto;

                    .image-wrap {
                        padding-bottom: 240px;
                        border-radius: 0 0 5px 5px;
                    }
                }
            }
        }
    }
}
