.dte-store--default {
    &.cms-delivery-returns {

        nav.breadcrumbs {
            display: none;
        }
        .delivery-returns-banner {
            .banner-image {
                background-image: url('../images/delivery-returns.png');
                position: relative;
                height: 180px;
                background-position: center bottom;
                background-color: #303030;
                .banner-content {
                    position: absolute;
                    display: flex;
                    align-items: center;
                    height: 100%;
                    width: 100%;
                    top: 0;
                    left: 0;
                    h1 {
                        color: white;
                        font-weight: bold;
                        margin-left: 110px;
                        font-size: 36px;
                        line-height: 50px;
                        margin-bottom: 0;
                    }
                }


            }

        }
        .delivery-returns-main {
            display: flex;
            gap: 80px;
            padding: 58px 67px;

            h3 {
                margin-bottom: 15px;
                line-height: 32px;
                font-weight: bold;
            }
            h5 {
                font-weight: 550;
                line-height: 0.44;
            }
            p {
                font-size: 15px;
                line-height: 26px;
            }
            .text-block-left{
                width: 700px;
                .aim {
                    margin-bottom: 32px;
                    .please-note {
                        margin-top: 26px;
                    }
                }
                .delivery-cost {
                    margin-bottom: 32px;
                }
            }
            .text-block-right {
                width: 700px;
                .submit-returns,
                .returns-request,
                .returns-discrepancies,
                .returns-damaged {
                    margin-bottom: 45px;
                }

            }
        }
    }
}
