$header__border-bottom: 1px solid $gray !default;
$header__background: $white !default;
$header__wrapper-padding: 15px !default;
$header__wrapper-padding\@medium: 15px !default;

$header__logo-padding: 12px 0 12px $spacer !default;
$header__logo-padding\@medium: 0 0 0 $spacer !default;

$header__menu-trigger-size: 48px !default;

$header__buttons-width: calc(100% - 48px) !default;
$header__buttons-width\@medium: calc(4 * 48px + 3 * #{$spacer}) !default;
$header__buttons-width\@extra-large: calc(4 * 48px + 3 * #{$spacer--medium}) !default;

$header__search-wrapper-padding: 0 0 0 24px !default;
$header__search-wrapper-padding\@medium: 0 24px !default;

body {

    #algolia-autocomplete-container .aa-dropdown-menu .aa-dataset-products .aa-suggestion {
        border-color: #eee!important;
    }

  //padding-top: 124px;

  #top-banner-spacer {
    height: 52px;
    background-color: transparent;

    @media all and (max-width: 767px) {
      height: 36px;
    }
  }

  @media all and (max-width: 767px) {
    //padding-top: 127px;
  }

  &.catalog-category-view {

    //padding-top: 178px;

    @media all and (max-width: 767px) {
      //padding-top: 147px;
    }

  }

}

.header {

  position: relative;
  border-bottom: $header__border-bottom;
  background: $header__background_white;

  /* default styles update */
  .header__buttons {
    margin-top: 10px;

    .header-button {
      width: 24px;
    }

    .header-button__icon use[id*="white"] {
      stroke: #000;
    }

  }

  .header__search-wrapper {
    width: 245px;
  }

  .header__islogged_block {

    font-size: 18px;
    font-weight: 300;

    .login-links {
      display: none;

      &.showed {
        display: block;
      }
    }

    /*a {

        color: #231f20;
        text-decoration: none;
        font-weight: 300;
    }*/

  }

  @include mq($screen-l) {
    border-bottom: none;
  }

  .header__menu_wrapper {

    text-align: center;
    margin: 0 auto;

    .header__menu_inner {

      display: inline-block;
      margin: 0 auto;

      .button-header-left-link-container {
        display: inline-block;
      }


      > .button--transparent {
        margin-right: 50px;
      }

      > .button-header-left-link-container + .button-header-left-link-container {
        margin-left: 20px;

        @media all and (max-width: 1300px) {

          margin-left: 10px;

        }
      }

      > .button--transparent,
      > .button-header-left-link-container > .button--link {

        font-weight: 300;
        min-width: 0;
        margin-top: 0;
        margin-bottom: 0;
        padding: 15px 0;
        height: auto;
        border-radius: 0;

        &:before {

          content: '';
          position: absolute;
          height: 0;
          background: transparent;
          left: 0;
          right: 0;
          z-index: 1;
          top: 45px;
          pointer-events: none;
          transition: height 0.35s, background 0.35s;

        }

        &:after {

          content: '';
          width: 16px;
          height: 16px;
          background-size: 100% 100%;
          display: inline-block;
          margin: 0 0 0 4px;
          transition: transform 0.25s;

        }

        &.active {

          &:before {

            //height: 3px;
            //background: $bright-light-blue;

          }

          &:after {
            transform: rotate(180deg);
          }

        }

        &.has-dropdown {

          &:after {

            content: '';
            background-image: url("data:image/svg+xml,%3Csvg height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m7.92285156 6.96830426 7.49903224 7.49903224-7.49903224 7.4983052' fill='none' stroke='%23dc2048' stroke-linecap='round' stroke-linejoin='round' transform='matrix(0 1 -1 0 26.524727 .866875)'/%3E%3C/svg%3E");
            width: 16px;
            height: 16px;
            background-size: 100% 100%;
            display: inline-block;
            margin: 0 0 0 4px;
            transition: transform 0.25s;

          }

        }

      }

      > .button--transparent {

        &:after {

          content: none;

        }

      }

    }

    a {
      color: #2F348A;
      font-weight: bold;
    }

  }

  &__wrapper {

    padding: $header__wrapper-padding;
    position: relative;

    @include mq($screen-m) {
      padding: $header__wrapper-padding\@medium;
    }

    .side-menu__trigger-icon--svg {
      display: none;
    }

    img.side-menu__trigger-icon {

      width: 20px;
      height: auto;
      margin: 10px 0 0;
      padding: 0;

    }

    > .container {

      padding: 17.5px 17px;

      @media all and (max-width: 1039px) {

        padding: 18.83px 17px;

      }

      @media all and (max-width: 350px) {

        padding: 18.83px 0;
        margin: 0;

      }

      .main-header-content {

        padding-left: 29px;

        @media all and (max-width: 767px) {

          padding: 0;

        }

      }

    }

    @media all and (max-width: 450px) {

      > .row {

        .header-primary-grid-col-left,
        .header-primary-grid-col-right {

          padding: 0;

          .header__search-wrapper {

            a.header-button__search {

              margin-left: 0;

            }

          }

        }

      }

    }

  }

  &__logo {
    margin: -2px 25px 0;
  }

  &__search-wrapper {

    .block-title {
      display: none;
    }

  }


  &__minicart {

    position: relative;

    .header-button__minicart {
      margin-left: 26px;
    }

  }

  &__minicart-content {

    top: calc(100% - 1px);

    &.popup {
      position: absolute;
      right: 0;
      width: 320px;
    }

  }

  &__buttons {

    > a,
    > div {

      display: inline-block;
      vertical-align: middle;

    }

    .header-button__account {
      margin: 0 0 0 13px;
    }

  }

  .header-primary-grid-col {

    > * {

      vertical-align: middle;
      display: inline-flex;

      &.side-menu {
        display: inline-block;
      }

    }

  }

  .button-header-left-link {

    position: relative;
    background: transparent;
    color: $white;
    box-shadow: none;
    transition: none;
    height: 75px;
    margin-top: -19px;
    margin-bottom: -19px;
    padding: 0 25px;

    &:hover {

      background: transparent;
      color: $black;
      box-shadow: none;

      /*&:before {

          content: '';
          display: block;
          position: absolute;
          top: 0;
          bottom: 0;
          background: $white;
          left: 0;
          right: 0;
          z-index: -1;

      }*/

    }

  }

  .header-primary-grid-col-right {

    text-align: right;

    .header__buttons {
      margin-top: 12px;
    }

  }

  #search_mini_form {
    position: relative;
  }

  .algolia-search-block {

    .algolia-inline-search-btn {

      position: absolute;
      top: 5px;
      bottom: 5px;
      height: auto;
      right: 5px;
      min-width: 0;
      padding: 7px 12px;
      border: none !important;

    }

  }

  .header-currency-switcher {

    width: auto;
    background: transparent;
    margin: 0 15px 0 0;

    .dropdown-list__label {

      background: transparent;
      text-align: center;
      height: auto;
      min-height: auto;
      padding: 10px 12px 8px;
      border-radius: 50px;
      border: 1px solid $thunder;

      .arrow-state {

        background-image: url("data:image/svg+xml,%3Csvg height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m7.92285156 6.96830426 7.49903224 7.49903224-7.49903224 7.4983052' fill='none' stroke='%23231f20' stroke-linecap='round' stroke-linejoin='round' transform='matrix(0 1 -1 0 26.524727 .866875)'/%3E%3C/svg%3E");
        width: 18px;
        height: 18px;
        background-size: 100% 100%;
        display: inline-block;
          margin: 4px 3px 3px 3px;

      }

      .header-button__icon.currency-flag {
        margin: 0 0 0 -4px;
      }

      &[aria-expanded="true"] {
        .arrow-state {
          transform: rotate(0deg) skew(23deg, 0deg) scaleY(0.5) translateX(-4px) !important
        }
      }
    }


    .dropdown-list__content {

        margin: 0;
        top: 49px;
        position: absolute;
        width: 304px;
        background: #fff;
        text-align: left;
        z-index: 100;
        border: 0;
        right: -17px;
        overflow: visible;
        box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.35);
        display: none;

      &[aria-hidden="false"] {
        height: auto !important;
        display: block;
        padding: 15px 13px;
      }

        &::before {
            content: "";
            display: block;
            position: absolute;
            border-top: 20px solid transparent;
            border-bottom: 20px solid transparent;
            border-right: 20px solid white;
            transform: rotate(90deg);
            bottom: unset;
            top: -21px;
            right: 20px;

        }

      .list {
          display: flex;
          flex-wrap: nowrap;
          align-items: center;
          justify-content: space-between;
      }
      .list__item {

        display: block;
        margin: 0;
        padding: 0;
        text-align: left;

          p {
              margin: 0;
              color: #929292;
          }

      }

      a {

        text-align: left;
        text-decoration: none;
        margin: 0;
        padding: 0;

        &:hover {
          background: #ccc;

          svg,
          use {
            stroke: $color-primary;
          }

        }

        &.active-currency {
          svg use[id*="black"] {
            stroke: #dc2048;
          }
        }

        .dropdown-list__inner-link-icon {

            display: flex;
            flex-wrap: nowrap;
            align-items: center;
            justify-content: space-evenly;
            margin-right: 10px;

            img {
                max-width: 22px;
                max-height: 22px;
            }

            .currency-text {
                margin-left: 10px;
                color: #2E3687;
                font-weight: 600;
            }
        }

        .currency-name {

          display: inline-block;
          vertical-align: top;
          font-size: 15px;
          color: $color-primary;
          margin: 1px 0 1px 6px;
          font-weight: 300;

        }

      }

    }

  }

  .header-button__search {
    display: none;
  }

  @media all and (min-width: 768px) and (max-width: 1024px) {

    .button-header-shop {
      margin-right: 8px;
    }

    .button-header-shop,
    .button-header-quote {

      min-width: 0;
      padding-left: 12px;
      padding-right: 12px;
      font-size: 16px;

    }

    .header-primary-grid-col-left {

      flex-basis: 70%;
      max-width: 70%;

    }

    .header-primary-grid-col-right {

      flex-basis: 30%;
      max-width: 30%;

    }

  }

  @media all and (min-width: 992px) and (max-width: 1024px) {

    .header-primary-grid-col-left {

      flex-basis: 59%;
      max-width: 59%;

    }

    .header-primary-grid-col-right {

      flex-basis: 41%;
      max-width: 41%;

    }

  }

  @media all and (max-width: $screen-m - 1) {
    /*
    > .container {
    margin: 0;
    }

    .button-header-shop,
    .button-header-quote {
    display: none!important;
    }


    .side-menu__trigger-icon--svg {
    display: none;
    }

    .side-menu__trigger-icon {

    margin: 0;
    padding: 0;

    }

    .side-menu__trigger-icon--png,
    .side-menu__trigger {

    width: 30px;
    height: 20px;
    padding: 0;
    margin: 0;
    min-width: 0;

    }

    .header-primary-grid-col-right {

    .header__buttons,
    .header__search-wrapper {
    margin-top: 0;
    }

    }*/

  }

  div.header-account-menu {

    .dropdown-list__label {

      padding: 0;
      background-color: transparent;
    }

    #account-menu-dropdown {

      position: absolute;
      background-color: #fff;
      transform: translateX(-50%);
      @extend %shadow--l1;

      .list__item {

        padding: 5px 15px;
        color: $color-primary;
        text-align: left;
        transition: all 250ms ease-in-out;

        a {
          color: $color-primary;
        }

        &:hover {

          background-color: $color-primary;

          a {
            color: #fff;
            text-decoration: none;
          }

        }

      }

    }

  }

  .header-primary-grid-col-left {

    .button-header-left-link-container {

      .menu-dropdown-child {

        display: none;
        position: absolute;
        top: 123px;
        background-color: rgba(246, 246, 246, 0.98);
        left: 0;
        right: 0;
        height: auto;
        min-height: 400px;
        width: 1460px;
        max-width: 100%;
        margin: 0 auto;

        @media all and (min-width: 768px) {

          .dte-store-top-navigation__mobile {

            display: none;

          }

        }


        nav.dte-store-top-navigation {

          padding: 30px 60px;

          &:before {

            content: '';
            position: absolute;
            background-image: url('../images/about.jpg');
            background-size: 100% auto;
            background-repeat: no-repeat;
            bottom: 0;
            right: 35px;
            top: 35px;
            width: 35%;

          }

          .dte-store-top-navigation__inner-item--all {

            display: none;

          }

          > ul > li.top-navigation-hidden {

            > a {

              display: none;

            }

          }

          > ul > li {

            > a {

              font-weight: 600;
              margin-bottom: 10px;
              display: inline-block;

            }

          }

          ul.dte-store-top-navigation__list,
          ul.dte-store-top-navigation__inner-list {

            list-style: none;
            padding-left: 0;

          }

          ul.dte-store-top-navigation__list {

            display: flex;
            width: 65%;

            > li.dte-store-top-navigation__item {

              flex: 1;
              flex-basis: 33%;

              a {

                font-size: 16px;
                color: $thunder;
                line-height: 2.5;
                font-weight: 300;

              }

            }

            > li.dte-store-top-navigation__item {

              max-width: 33%;

              > .dte-store-top-navigation__link {

                font-size: 17px;
                font-weight: 700;

              }

              &:first-of-type {

                &:last-of-type {

                  justify-content: flex-end;

                }

              }

            }

          }

        }


        &#dte-store-top-solutions-services {

          nav.dte-store-top-navigation {

            &:before {
              background-image: url('../images/solutions.jpg');
            }
          }
        }

        &#dte-store-top-brand-partnerships {

          nav.dte-store-top-navigation {

            &:before {
              background-image: url('../images/brands.jpg');
            }
          }
        }

        &#dte-store-top-about-dte {

          nav.dte-store-top-navigation {

            ul.dte-store-top-navigation__list > li.dte-store-top-navigation__item {
              float: left;
            }

          }
        }

      }

      .button-header-left-link {

        &.active {

          ~ .menu-dropdown-child {

            display: block;

          }

        }

        ~ .menu-dropdown-child {

          &.active {

            display: block;

          }

        }

      }

      &:hover {

        .button-header-left-link {

          background: transparent;
          color: $black;
          box-shadow: none;

          /*&:before {

              content: '';
              display: block;
              position: absolute;
              top: 0;
              bottom: 0;
              background: $white;
              left: 0;
              right: 0;
              z-index: -1;

          }*/

        }

      }

      &:last-of-type {

        // Only to apply to the "About DTE" menu itm

        .menu-dropdown-child nav.dte-store-top-navigation ul.dte-store-top-navigation__list {

          display: block;

          > li.dte-store-top-navigation__item {

            max-width: 33%;
            width: 33%;
            float: right;

          }

        }

      }

    }

  }

  #header-guest-account-dropdown {

    width: auto;

    .dropdown-list__label {

      padding: 0;
      width: auto;

      > * {
        vertical-align: middle;
      }

      .dropdown-list__label-text {

        font-size: 17px;
        line-height: 1.2;
        margin-right: 8px;

        &--active {

          display: none;
          padding-right: 21px;
          position: relative;

          .header-button__icon {

            position: absolute;
            right: 0;
            top: 0;
            width: 22px;
            height: 22px;

          }

        }

        &--inactive {
          display: inline;
        }

      }

      &:hover,
      &:focus {
        background-color: transparent;
      }

    }

    #header-guest-account-dropdown--contents {

      position: absolute;
      background: white;
      height: 450px;
      left: 63px;
      right: unset;
      opacity: 0;
      height: 0;
      display: none;
      top: 44px !important;
      overflow: visible;
      transform: translateX(-50%);
      box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.35);

      @media all and (max-width: 450px) {

        left: 35px;

      }

      &::before {

        content: "";
        display: block;
        position: absolute;
        border-top: 20px solid transparent;
        border-bottom: 20px solid transparent;
        border-right: 20px solid white;
        left: 25%;
        transform: rotate(90deg);
        bottom: unset;
        top: -25px;

        @media all and (max-width: 450px) {

          left: 34%;

        }

      }

      &[aria-hidden="false"] {

        z-index: 999;
        width: 304px !important;
        height: auto !important;
        opacity: 1;
        padding: 20px;
        display: block;

      }

      .list {

        &.show-if-logged {

          .list__item {

            &:first-of-type {

              a {

                padding-top: 0 !important;

              }

            }

            &:last-of-type {

              a {

                padding-bottom: 0 !important;

              }

            }

          }

        }

      }

      .list__item {
        padding: 0;

        &:not(:first-of-type) {

          border-top: 1px solid #e0e0e0;

        }

        a {

          padding: 15px 0 !important;
          display: block;
          text-decoration: none;
          font-weight: 550;

        }

        .list-item-spacer {

          &:not(:first-of-type) {

            margin: 20px 0;

          }

          &:last-of-type {

            margin-bottom: 0;

          }

          a {
            &.button {

              padding: 10px 30px;
              display: flex;

              &:focus {
                background: $dte-bright-blue;
                color: white;
              }

              &:hover {
                background: white;
                color: $dte-bright-blue;
                border: 1px solid $dte-bright-blue;
              }
            }

            &.header-account-dropdown--help-link {

              padding: 0;

            }

          }

        }

        h3 {

          color: #303030;
          font-size: 18px;
          font-weight: bold;
          margin-bottom: 20px;

        }

        &:not(:first-of-type) {

          h3 {

            margin-top: 20px;

          }

        }

      }

      .header-account-dropdown--list {

        margin: 20px 0;
        padding: 0;
        list-style: none;

        li {

          color: #231F20;
          font-size: 14px;
          line-height: 22px;
          margin-bottom: 10px;
          color: #526B79;

          &:before {

            content: '';
            display: inline-block;
            vertical-align: middle;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' id='Complete-green'%3E%3Cg fill='%2332C026' fill-rule='nonzero'%3E%3Cpath d='M10 0C4.486 0 0 4.486 0 10s4.486 10 10 10 10-4.486 10-10S15.514 0 10 0zm0 18.45c-4.66 0-8.45-3.79-8.45-8.45 0-4.66 3.79-8.45 8.45-8.45 4.66 0 8.45 3.79 8.45 8.45 0 4.66-3.79 8.45-8.45 8.45z'%3E%3C/path%3E%3Cpath d='M14.738 7.191a.85.85 0 00-1.13.05l-4.88 4.95-2.357-2.208a.848.848 0 00-1.132-.009.696.696 0 00-.01 1.042l2.952 2.763a.834.834 0 00.586.221.832.832 0 00.576-.242l5.45-5.526a.697.697 0 00-.055-1.041z'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
            width: 24px;
            height: 24px;
            background-size: 100% 100%;
            margin-right: 14px;
            max-width: 20px;

          }

        }

      }

      .button.button--primary {

        width: auto;
        min-width: 130px;
        max-width: 100%;

      }

      .header-account-dropdown--help-link {

        display: block;
        font-size: 13px;
        font-weight: 550;
        margin-top: 20px;
        margin-bottom: 20px;

        &[title="Help"] {

          margin-bottom: 0;

        }

      }

    }

  }

}

body:not( .header-search-visible ) {

  .algolia-inline-search-btn {
    display: none;
  }

  .clear-search {
    display: none;
  }

}

body.header-search-visible {

  overflow: hidden;

  .header {

    .header__wrapper {

      .clear-search {

        position: absolute;
        display: block !important;
        right: 85px;
        top: 53%;
        transform: translateY(-50%);

        @media all and (max-width: 1039px) {
          right: 15%;
        }

      }

    }

    .header-button__search {

      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);


    }

    /* .clear-search {

    visibility: hidden;
    pointer-events: none;
    position: relative;
    z-index: -1;
    opacity: 0;

    }*/

  }


  .header-primary-grid-col-right {

    .header__buttons {
      display: none;
    }

  }

  /* &:not(.amazon-store) { */

  .button-header-left-link-container {
    display: none;
  }

  /* } */

  .header {
    .header-button__search {
      display: none;
    }
  }

}

/* body:not(.amazon-store) {

&.header-menu-open {



overflow: hidden;

header.header {

&:after {

content: '';
background: rgba(0,0,0,0.5);
top: 86px;
bottom: 0;
left: 0;
right: 0;
position: fixed;
pointer-events: none;
z-index: -1;

}

}


}

}
*/

@media all and (min-width: 1040px) {

  /* body:not(.amazon-store) { */

  .header__wrapper {

    .header-primary-grid-col-left {

      padding-right: 0;

      .button-header-left-link {

        //font-size: 17px;

      }

    }

    .header-primary-grid-col-right {

      padding-left: 0;

    }

    .side-menu:not(.side-menu--is-open) {

      display: none !important;

    }

    .side-menu {

      > .side-menu__trigger {
        display: none !important;
      }

    }

  }

  /*  } */

}

@media all and (max-width: 1039px) {

  /*  body:not(.amazon-store) { */

  .header__wrapper {

    .header-primary-grid-col-left {

      .button-header-shopactive {
        display: none;
      }

      .button-header-left-link, .button-header-quote {

        display: none;

      }

    }

  }

  /*  } */

}

@media all and (min-width: 1025px) and (max-width: 1039px) {

  /* body:not(.amazon-store) { */

  .header {

    .header-primary-grid-col-left,
    .header-primary-grid-col-right {

      flex-basis: 50%;
      max-width: 50%;

    }

  }

  /* } */

}


.side-menu__content .fullscreen-menu-wrapper {
  text-align: left;
}

.header__wrapper .header__menu_inner .menu-dropdown-child {
  text-align: left;
}

@media (min-width: 1040px) {

  .header__wrapper {

    .header__menu_inner .side-menu__content .fullscreen-menu-wrapper {
      top: 176px;
    }

  }

}

/* mobile view large */
@media (max-width: 1039px) {
  body .header__wrapper .row .header-section-search-currency {
    position: absolute;
    width: 100%;
    top: 67px;
    background: #F6F6F6;
    flex-basis: 100%;
    max-width: 100%;
    padding: 11px 15px;
  }

  body.header-search-visible {

    .clear-search {
      position: absolute;
      right: 6px;
      top: 18px;
    }
  }

  .header {

    border: none;

    .algolia-search-block .algolia-inline-search-btn {
      padding: 1px 12px;
    }

    .header__islogged_block {
      display: none;
    }

    .header-currency-switcher {
      margin: 0;
    }

    .header-primary-grid-col-left {
      > .container {
        display: block;
      }
    }

    .header-primary-logo {
      width: 100%;
      max-width: 100%;
      flex-basis: 100%;
    }

    .header__logo {

      margin: 12px auto;

      .logo__image {
        max-height: 48px;
        max-width: 85px;
      }

    }

    .headers-buttons-section {
      position: absolute;
      right: 0;
      top: 13px;
    }

    .header__menu_inner {
      position: absolute;
      top: 13px;
      z-index: 101;
      left: 15px;

      .button-header-shop {
        display: none;
      }
    }

    .header__wrapper .side-menu__content {
      top: 179px;
    }

    .header-primary-grid-col-right {

      .header__buttons {

        display: block;
        margin-top: 10px;

        .header-button__account {

          min-width: 0;
          min-height: 0;
          width: 24px;
          height: 24px;
          line-height: 1;
          margin: 0;

          .icon {

            width: 24px;
            height: 24px;

          }
        }

        .header__minicart {

          .header-button__minicart {

            min-width: 0;
            min-height: 0;
            width: 24px;
            height: 24px;
            line-height: 1;
            margin: 0 0 0 8px;

            .icon {

              width: 24px;
              height: 24px;

            }
          }

        }

      }

    }

    #algolia-autocomplete-container .aa-dropdown-menu {
      margin-top: 75px;
    }

    .dropdown-list.header-currency-switcher {

      max-width: 110px;
      width: 100%;
      text-align: center;
      display: inline-block;
      padding-right: 15px;

      .dropdown-list__label {

        padding: 6px 16px 3px;
        width: 100%;

        > .header-button__icon {

          &:first-child {
            display: none;
          }

        }

        .arrow-state {
            margin: 0 0 3px 4px;
        }

      }

      .dropdown-list__item {
        //width:110px;
      }

      .dropdown-list__list {
        display: inline-block;
      }

    }

    .header__search-wrapper {
      width: calc(100% - 135px);

      .block.block-search.algolia-search-block {
        width: 100%;

        .block.block-content {
          width: 100%;
        }
      }
    }

    .algolia-search-block {

      .algolia-search-input {

        height: 38px;
        line-height: 38px;
        padding: 4px 100px 4px 16px;

      }

    }

  }

  body.header-search-visible {
    .header {
      .algolia-search-block {
        position: relative;
        -webkit-transform: none;
        transform: none;
        width: auto;
        top: 0px;
        left: 0px;
      }

      .logo.header__logo {
        visibility: visible;
      }

      .header-primary-grid-col-right {
        .header__buttons {
          display: inline-flex;
        }
      }
    }

    #algolia-autocomplete-container {
      .aa-dropdown-menu {
        margin-top: 150px;
      }
    }
  }

  body.emulate-mobile-scroll {
    .header {
      .header__wrapper {
        .side-menu__content {
          top: 56px;
        }

        .row .header-section-search-currency {
          position: fixed;
          top: 0px;
        }
      }

      .dropdown-list.header-currency-switcher {
        .dropdown-list__label {
          display: none;
        }
      }

      .header-primary-logo {
        display: none;
      }

      .headers-buttons-section {
        display: none;
      }

      .header__menu_inner {
        top: 10px;
      }
    }

    &.header-search-visible {
      #algolia-autocomplete-container .aa-dropdown-menu {
        margin-top: 57px;
      }
    }

    #algolia_instant_selector #instant-search-facets-container.filters-open {
      top: 107px;
    }
  }

  /* content */
  //#maincontent {
  //    margin-top: 66px;
  //}

  body.catalog-category-view {

    #maincontent {
      margin-top: 0;
    }

  }
}

body {

  .page-wrapper {
    .top-navigation-categories-container {
      top: 163px;

      @media all and (min-width: 768px) {

        top: 176px;

      }

      + div.container {
        margin: 0 auto;
      }

      @media all and (max-width: 767px) {
        display: none;
      }
    }
  }

  &.emulate-mobile-scroll,
  &.top-notification-banner-hidden {

    .page-wrapper {
      .top-navigation-categories-container {

        top: 60px;

        @media all and (min-width: 1040px) {

          top: 123px;

        }
      }
    }
  }

  &.category-product-view {
    .page-wrapper {
      .top-navigation-categories-container {
        display: none;
      }
    }
  }

}

@media all and (max-width: $screen-m - 1) {

  /*.header {
  .header__islogged_block{
  display:none;
  }
  .header__menu_inner{
  top:18px;
  }

  .header__wrapper {
  .row{
  .header-section-search-currency{
  top:54px;
  .dropdown-list__label{
  height:35px;
  min-height:35px;
  }
  }
  }
  .side-menu__content{
  top:99px;
  }

  .dropdown-list.header-currency-switcher{
  max-width:23%;
  .arrow-state{
  display:none;
  }

  .dropdown-list__item{
  width:75px;
  }
  }
  }

  .input__field, .catalog-category-view #algolia_instant_selector .sbx-sffv__input{
  height:35px;
  }
  }*/

  //#maincontent {
  //    margin-top: 38px;
  //}
  .algolia-search-block {
    .algolia-inline-search-btn {
      padding: 0 12px;
    }
  }

  body {
    &.emulate-mobile-scroll {
      .header {

        .header__wrapper {

          .side-menu__content {
            top: 60px;
          }

          .dropdown-list.header-currency-switcher {
            margin: 0;
          }
        }
      }

      &.catalog-category-view {
        .top-navigation-categories-container .mobile-button-container {
          top: 35px;
        }

        #algolia_instant_selector #instant-search-facets-container.filters-open {
          top: 96px;
        }
      }

      &.header-search-visible {
        #algolia-autocomplete-container {
          .aa-dropdown-menu {
            //margin-top: -20px;
          }
        }
      }
    }
  }
}


@media all and (max-width: 641px) {

  body {
    .algolia-inline-search-btn {
      display: none;
    }
  }
}


body {

  //.top-navigation-categories-container ~ #maincontent #algolia-autocomplete-container .aa-dropdown-menu {
  //
  //  top: 130px !important;
  //  border-top: none !important;
  //  margin: -1px 0 0 !important;
  //
  //  @media all and (max-width: 1039px) {
  //    top: 165px !important;
  //  }
  //
  //}

  #maincontent #algolia-autocomplete-container .aa-dropdown-menu {

    top: 81px !important;
    border: none;
    margin: 0;

      @media all and (max-width: 1039px) {

          top: 181px !important;

      }

  }

}


body.amazon-store {

  .header {

    .header__menu_wrapper {


      .container {
        padding-right: 28px;
        max-width: 1508px;
        width: 100%;
      }

      .header__menu_inner {

        width: 100%;
        text-align: right;

        .button--transparent {
          font-size: 15px;
          color: #fff;
        }

        > .button--transparent,
        > .button-header-left-link-container > .button--link {

          &:not(.button-header-shop) {

            &:after {

              content: none;

            }

          }

        }

      }

      &.header-primary-grid-col-left {
        a {
          font-size: 15px;
          color: #fff;
          font-weight: 400 !important;
        }

        a:hover {
          color: #fff;
        }

        #Upload-black {
          stroke: #fff !important;
        }
      }

    }
  }

  header {

    .header__wrapper {

      .header-primary-logo  {

      .header-primary-text {

        padding-top: 10px;

        @media all and (max-width: 500px) {

          position: absolute;
          left: 50%;
          transform: translate(-35%, 50%);
          padding: 0;

          img {

            padding: 0 !important;

            }

          }

          @media all and (max-width: 400px) {

            transform: translate(-30%, 50%);

          }

        }

      }

    }

  }

  .minicart-content__edit.minicart-view-basket-btn {

    background-color: #32c026;
    color: white;
    font-size: 14px;
    margin: 0;

    &:hover,
    &:focus {

      background: #32c026;
      border: 1px solid #32c026;
      color: white;

    }

  }

}

#top-notification-banner {

  position: relative;
  padding: 0 90px;
  background-color: #dc1948;

  #top-notification-banner-close-btn {

    position: absolute;
    right: 30px;
    top: 50%;
    transform: translateY(-50%);

  }

  .top-notification-banner-content {

    text-align: center;
    padding: 15px 0;

    p {

      margin: 0;
      color: #fff;
      font-size: 14px;

      a {

        color: #fff;
        text-decoration: underline;

      }

    }

    .mobile {

      display: none;

    }

  }

  @media all and (max-width: 767px) {

    padding: 0 15px;

    .top-notification-banner-close {

      text-align: right;
      padding-top: 5px;

      #top-notification-banner-close-btn {

        position: static;
        transform: none;

      }

    }

    .top-notification-banner-content {

      padding: 7px 0;

      p {

        font-size: 13px;

      }

      .mobile {

        display: block;

      }

      .desktop {

        display: none;

      }

    }

  }
}

.top-navigation-categories-container.overlayvisible:after {
  content: "";
  display: block;
  position: absolute;
  background-color: black;
  top: 129px;
  right: 0;
  left: 0;
  opacity: 0.6;
  bottom: 0;
  transition: opacity 450ms ease-in;
  z-index: 5;
}

.top-notification-banner-hidden .top-navigation-categories-container.overlayvisible:after {
  top: 171px;
}

.call-to-action-container {
  max-width: 1440px;
  margin: 0 auto;

  .banner-item {
    display: none;
  }

  img {
    display: none;
  }
}

.call-to-action-container {
  background-color: #f0f9fb;
  padding: 0 4%;

  @media all and (max-width: 879px) {
    padding: 0 8%;
  }

  .cta-carousel {
    &.owl-loaded .banner-item {
      display: block;
    }

    &.owl-carousel .owl-stage-outer {

      @media all and (max-width: 1023px) {

        padding: 5px 0;

      }

      .owl-stage {
        .owl-item {

          @media all and (max-width: 604px) {
            display: flex;
            justify-content: center;
          }

          .banner-item {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            width: 280px;

            .cta-image-container {
              margin-right: 15px;
              height: 40px;
              display: flex;
              align-items: center;

              @media all and (max-width: 1023px) {

                max-width: 40px;

              }

              img {
                width: 27px;

                &.van {
                  width: 53px;
                }

                &.globe {
                  width: 32px;
                }

                &.hands {
                  width: 40px;
                }
              }
            }

            .text {

              @media all and (max-width: 1023px) {

                font-size: 11px;
                margin-top: 3px;

              }

              p {
                margin: 0;
                  color: #303030;
                  font-family: "Proxima Nova";
                  font-size: 14px;
                  font-weight: 500;
                  letter-spacing: 0;
                  line-height: 17px;

                @media all and (max-width: 1023px) {

                  font-size: 11px;
                  line-height: 1.2;

                }
              }
            }
          }
        }
      }
    }
  }
}

body.emulate-mobile-scroll {

  #top-notification-banner {

    display: none;

  }

}

@media all and (max-width: 767px) {

  .header .header__wrapper .side-menu__content {
    top: 81px;

    &.is-ecom {

      top: 125px;

    }

  }

}

.header-primary-text {

  display: flex;
  align-items: center;
  min-width: 210px;
  color: $light-navy-blue;
  font-size: 13px;
  font-style: italic;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 20px;

}

.header-ecom {

  .header__wrapper {

    .main-header-content {
      height: 51px;
      display: flex;

      > div {

        flex: 1;

        &.header-primary-logo {

          display: flex;
          flex: 10;

          .header__logo {

            margin: 0;
            flex: 1;
            justify-content: start;

            .logo__image {

              width: 85px;
              height: auto;

              @media all and (max-width: 425px) {

                flex: 2;

              }

              @media all and (max-width: 768px) {

                justify-content: center;

              }


            }

          }

          .header-primary-text {

            padding: 0 10px;
            flex: 3;

            img {
              width: auto;
              padding-left: 20px;
              padding-top: 8px;
            }

            span {

              margin-left: 10px;
              text-align: left;
              font-size: 16px;

              @media all and (max-width: 767px) {

                max-width: 150px;
                font-size: 13px;

              }

            }

            @media all and (max-width: 1039px) {

              flex: 5;

            }

          }

          @media all and (min-width: 1040px) {

            flex: 1;

          }


        }

        &.header-search-container {

          flex: 1;
          text-align: center;
          display: block;

          .header__search-wrapper {

            min-width: 300px;
            display: inline-block;
            position: relative;

            #search {

              font-size: 13px;
              height: 39px;
                box-shadow: 0 7px 10px 0 rgba(0,0,0,0.05);
                border: 1px solid #E0E0E0;
                border-radius: 4px;
                background-color: #FFFFFF;
            }

            @media all and (max-width: 1039px) {

              width: 100%;

            }

          }

          @media all and (max-width: 768px) {

            display: none !important;

          }

        }

        &.mobile-menu {

          display: none;
          justify-content: flex-end;
          align-content: center;

          @media all and (max-width: 1039px) {

            display: flex;
            flex: 1;

          }

          @media all and (max-width: 450px) {

            position: absolute;
            right: 25px;
            align-self: center;

          }

          .side-menu {

            align-self: center;
              @media (max-width: 1039px) {
                  .fullscreen-menu-wrapper__list {
                      .mobile-menu-currency-switcher-link {
                        padding-bottom: 60px;

                          #mobile-switcher-currency {

                            padding-top: 15px;
                            padding-bottom: 15px;

                            button.dropdown-list__label + div.dropdown-list__content {

                              width: 330px;

                            }

                          }

                          button {

                            border: none;
                            padding: 0 12px 0 8px!important;
                            min-width: 37px;
                            display: flex;
                            justify-content: space-between;
                            align-items: center;

                            span {

                              font-family: "Proxima Nova", "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
                              position: static !important;
                              margin-right: -8px;
                              font-size: 15px;

                            }

                            i.arrow-state {

                              transform: rotate(180deg) skew(23deg, 0deg) scaleY(0.5) translateX(12px) !important;
                              font-size: 21px;
                              color: #d70000;
                              font-weight: 300;
                              background-image: none;

                            }

                            &[aria-expanded="true"] {
                              .arrow-state {

                                transform: rotate(0deg) skew(23deg, 0deg) scaleY(0.5) translateX(-15px) !important

                              }
                            }

                            svg {

                              width: 13px;
                              height: 13px;
                              margin-right: 6px;

                            }

                            img {

                              width: 22px;

                            }

                          }

                          >.dropdown-list__list {
                              .dropdown-list__label{
                                  padding: 10px 0 0 30px;
                                  font-weight: 100;
                                  color: #2E3687;

                                  .header-button__icon {
                                      margin-bottom:  -2px;

                                  }
                              }
                          }
                      }
                  }
              }
          }

          .side-menu__trigger-icon {

            width: 24px;
            margin: 0;
            padding: 0;

          }

        }

        &.header-actions-container {

          padding-right: 25px;

          @media all and (max-width: 1200px) {

            padding-right: 0;

          }

          > .row {

            height: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-end;

          }


          .col-xs-3 {

            &:last-child {

              margin-left: 10px;

            }

            flex-basis: auto;
            padding: 0 15px;

            //svg {
            //
            //    width: 25px;
            //    height: 25px;
            //
            //}

            img {

              margin: 0 auto;
            }

            .header-account-menu {

              margin-left: 0;

            }

            .header-button__minicart {

              margin: 0 auto;

            }

            .header-currency-switcher {

              margin: 0;

              button {

                border: none;
                padding: 0;
                min-width: 37px;
                display: flex;
                justify-content: space-between;
                align-items: center;

                i.arrow-state {

                    transform: rotate(180deg) skew(23deg, 0deg) scaleY(0.5);
                    font-size: 21px;
                    color: #d70000;
                    font-weight: 300;
                    background-image: none;

                }

                svg {

                  width: 13px;
                  height: 13px;
                  margin-right: 6px;

                }

                img {

                  width: 22px;

                }

              }

              .currency-icon {

                font-family: 'Proxima Nova';
                font-size: 15px;
                color: $dte-blue;
                margin-right: 4px;

              }

            }

            // reset User icon
            #header-guest-account-dropdown .dropdown-list__label .dropdown-list__label-text {

              //font-size: 100%;
              line-height: 1.15;
              margin: 0;

            }


            .header-button__minicart {

              outline: none;

            }

            @media all and (max-width: 1039px) {
              &.account-col {

                button.dropdown-list__label {

                  display: none;

                }

              }

            }

          }

        }

      }

    }

    > .container {

      padding: 15px 17px;

      @media all and (min-width: 1040px) and (max-width: 110px) {

        padding: 15px 0;

      }

      @media all and (max-width: 767px) {

        padding: 15px 10px;

      }

    }

    .header-search-container {

      display: none;
      padding: 0;
      align-self: center;

      .header__search-wrapper {

        width: 100%;

      }

      //@media all and (max-width: 768px) {
      //
      //    display: block;
      //
      //}

    }

    .clear-search {
      display: none !important;
    }

  }

}

.header-search-container {

  .algolia-inline-search-btn {

    background: transparent;
    font-size: 13px;
    border: none;

    .search-icon-mobile {

      display: none;

    }

    // Always show Search btn
    display: inline-flex !important;

    &:hover,
    &:focus {

      background-color: white;

    }

  }

}

.mobile-search-bar {
  position: relative;


  .header-search-container {

    padding: 10px 2% !important;
    display: none;
    background-color: white;
    position: absolute;
    width: 100%;
    z-index: 999;

    .algolia-inline-search-btn {

      background: $dte-bright-blue;
      top: 5px;
      bottom: 0;
      right: 5px;
      margin-top: -5px;
      margin-right: -6px;
      border-radius: 0 5px 5px 0;

      .search-icon-mobile {

        display: inline-block;

      }

      .search-icon-desktop {

        display: none;

      }

    }

  }

}


.mobile-bar-container {

  background-color: $dte-blue;
  padding: 10px 0;


  .mobile-actions {

    display: flex;
    justify-content: space-between;
    padding: 0 30px;

    .dropdown-list {

      background-color: transparent;

      .dropdown-list__label {

        display: flex;
        background-color: $dte-blue;

      }

    }

    .header-button__minicart {

      margin: 0;

    }

    #header-guest-account-dropdown {
      .header-mobile-icon a {
        pointer-events: none;
      }
    }

    .header-mobile-icon {

      height: 23px;
      width: 23px;
      margin-right: 0 !important;

      @media all and (min-width: 1040px) {

        display: none;

      }

    }

    .mobile-faq {

      width: auto;

    }

  }

  @media all and (min-width: 1040px) {

    display: none;

  }

}

.header-desktop-icon {

  @media all and (max-width: 1039px) {

    display: none !important;

  }

}

.header-mobile-icon {

  @media all and (min-width: 1040px) {

    display: none !important;

  }

}

// Normal Header
.header-normal {

  @media all and (max-width: 1039px) {
    padding: 0 10px;
  }

  .header__wrapper {

    .main-header-content {

      .header-primary-group {

        display: flex;
        flex: 1;

        .header-logo {

          display: flex;
          flex: 1;

          @media all and (max-width: 575px) {

            flex: 0.5;

          }

          .header__logo {

            margin: 0 12px 0 0;
            justify-content: flex-start;
          }

        }

        .header-text {

          display: flex;
          flex: 3;
          align-items: center;
          justify-content: flex-start;

          font-size: 16px;
          font-style: italic;
          color: $light-navy-blue;
          letter-spacing: 0;
          line-height: 20px;
          font-weight: bold;

          img {
            width: auto;
          }

          @media all and (max-width: 1039px) {

            flex: 8;

          }

          @media all and (max-width: 991px) {

            flex: 6

          }

          @media all and (max-width: 768px) {

            flex: 4

          }

          @media all and (max-width: 575px) {

            font-size: 14px;
            flex: 1;

            span {

              width: 70%;

            }

          }

          @media all and (max-width: 420px) {

            span {

              width: 80%;

            }

          }

        }

      }

      .header__menu_wrapper {

        position: relative;

        @media all and (max-width: 1039px) {

          display: flex;
          flex: 0;

        }

        .header__menu_inner {

          right: 0;
          top: 0;
          left: unset;

          .side-menu {

            .side-menu__trigger {

              &:focus {

                outline: none;
                -webkit-tap-highlight-color: transparent;

              }

            }

          }

          .button-header-left-link-container {

            position: relative;

            .menu-dropdown-child {

              width: 250px;
              max-width: unset;
              min-height: unset;
              top: 40px;
              background-color: white;
              z-index: 10;
              box-shadow: 2px -10px 10px -10px rgba(#e8e9e9, 15%);

              .header-menu-bar-dropdown {

                ul {

                  list-style: none;
                  padding-left: 20px;
                  margin: 20px 0;

                  li {

                    color: $light-navy-blue;
                    font-size: 14px;
                    font-weight: 600;
                    letter-spacing: 0;
                    line-height: 17px;
                    padding: 8px 0;

                  }

                }

              }

            }

          }

          .button--link {

            color: $light-navy-blue;
            font-size: 14px;
            font-weight: bold;
            letter-spacing: 0;
            border: 0;

          }

          .button-header-shop {

            color: white;
            margin: 0 0 0 40px;
            padding: 10px 38px !important;
            height: 44px;

            @media all and (max-width: 1100px) {

              margin: 0 !important;

            }


            svg {
              transition: all 0.3s ease-in-out;
              margin-bottom: 6px;

              path {
                transition: all 0.3s ease-in-out;
              }

              > g {
                fill: white;

                g line {
                  transition: all 0.3s ease-in-out;
                  stroke: white;

                }

                rect {
                  transition: all 0.3s ease-in-out;
                }

              }

            }

            &:hover,
            &:focus {

              color: $dte-bright-blue;

              svg {

                > g {

                  fill: $dte-bright-blue;

                }

                g line {

                  stroke: $dte-bright-blue

                }

              }

            }

            .button__text {

              margin-left: 10px;

            }

          }

        }

      }

    }

  }


}

// Hide Header Actions, but not the account pullout (fix for mobile account button not working)

.header #header-guest-account-dropdown .dropdown-list__label[aria-expanded="true"] .dropdown-list__label-text--active {
  // Correct spacing so content doesn't shift on change
  &.account-desktop {
    padding-right: 24px;

    @media all and (max-width: 1039px) {
      display: none;
    }
  }

  // Hide mobile vers. for desktop
  &.account-mobile {
    display: none;
  }
}

header #header-guest-account-dropdown #header-guest-account-dropdown--contents {
  top: 81px !important;

  @media all and (max-width: 1039px) {
    top: 125px !important;
  }
    @media (max-width: 768px) {
    top: 125px !important;
  }

  // hide pullout when no acc button is visible
  //@media all and (min-width: 768px) and (max-width: 1039px) {
  //  display: none;
  //}
}

header {
  .header__wrapper {

    @media all and (max-width: 1039px) {

      .header-actions-container {
        flex: 0 !important;
        width: 0 !important;
        padding-right: 0 !important;
        display: none;

        .row {
          .side-menu,
          .col-xs-3:not(.account-col) {
            display: none;
          }

          .account-col {
            button {
              &[data-dropdown="header-guest-account-dropdown--contents"] {
                display: none;
              }
            }
          }
        }
      }

      .mobile-actions {
        .dropdown-list__label-text {
          padding-right: 15px !important;
          top: 0;
          left: 8px;
        }
      }
    }
  }

  .minicart-data-block-wrapper {

    position: relative;

    .popup.header__minicart-content {

      left: -100%;
      right: unset;
      transform: translateX(-50%);
      box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.35);
      top: 50px;
      width: 304px;

      @media all and (max-width: 1039px) {

        left: -110px;

      }

      &::before {

        content: "";
        display: block;
        position: absolute;
        border-top: 20px solid transparent;
        border-bottom: 20px solid transparent;
        border-right: 20px solid #fff;
        left: 58%;
        transform: rotate(-90deg);
        bottom: unset;
        top: -25px;
        transform: rotate(90deg);

        @media all and (max-width: 1039px) {

          left: unset;
          right: 20px;

        }

      }

      .minicart-content__total {

        flex-wrap: wrap;
        padding: 15px 15px 0 15px;

        .basket-summary-title {

          width: 100%;
          font-size: 18px;
          font-weight: bold;
          color: #303030;
          margin-bottom: 30px;

        }

        .minicart-content__total-counter,
        .minicart-content__summary {

          font-size: 15px;
          color: #303030;
          font-weight: 500;

        }

        .minicart-content__summary-label  {

          margin-bottom: 10px;

        }

      }

      .minicart-content__actions {

        padding: 15px;

        .cart.main.actions {
          max-width: 100%;
          flex-basis: 100%;
        }

        #top-cart-btn-checkout {

          background-color: #32c026;
          color: white;

          &:hover,
          &:focus {

            background: #32c026;
            border: 1px solid #32c026;
            color: white;

          }

        }

      }

      .minicart-content__products-list {

        padding: 0 15px;
        margin: 5px 0 0;
        max-height: 45vh;

      }

      .hairline {

        border-bottom: 1px solid #e0e0e0;
        width: 100%;
        max-width: 290px;
        margin-top: 5px;

      }

      #mini-cart {

        border: none;

        .minicart-product {

          border: none;
          flex-wrap: wrap;
          padding-bottom: 0;

          &:first-of-type {

            padding-top: 0;

          }

          &:last-of-type {

            &::after {

              content: '';
              display: block;
              width: 100%;
              max-width: 290px;
              border-bottom: 1px solid #e0e0e0;
              margin-top: 22px;

            }

          }

          &::before {

            content: '';
            display: block;
            width: 100%;
            max-width: 290px;
            border-bottom: 1px solid #e0e0e0;
            margin-bottom: 22px;

          }

          &__info {

            width: 100%;
            padding: 0;

          }

          &__image {

            display: none;

          }

          &__link {

            font-size: 14px;
            color: #303030;
            font-weight: 500;

          }

          &__price {

            font-size: 15px;
            margin-bottom: 15px;

          }

          &__update {

            margin-top: 15px;

          }

          &__actions {

            .action.delete {

              border: none;

              svg {

                stroke-width: 2px;

              }

            }

          }

        }

      }

      .minicart-view-basket-btn {

        margin: 5px 0;
        font-weight: bold !important;
        font-size: 13px;

      }

    }

  }

}

// Fix weird focus bug on sign in button
.header #header-guest-account-dropdown {

    button[title="Sign in"] {
        background: #0AB6FF !important;

        &:hover {

            background: white !important;

        }

    }

}


// Move clear search button to the right (changes from live)
body.header-search-visible .header-ecom .header-search-container .clear-search{
    display:block !important;
    right: 20px;
    top: 51%;
    cursor: pointer;

    @media all and (max-width: 1039px) {

      right: 100px;

    }
}

body.header-search-visible .header-ecom .header-search-container{
    position: relative;

    @media all and (max-width: 1039px) {

      position: absolute;

    }
}

body.header-search-visible span.clear-search svg {
    display: none;
}

body.header-search-visible span.clear-search:after {
    content: 'X';
    font-size: 18px;
    color: #E3051A;
    font-weight: 400;
}

body.header-search-visible {
    #search_mini_form {
        svg.search-icon-desktop {
            display:none;
        }
    }
}
body.dte-store--dte_amazon_en,.dte-store--eu_amazon_store {
    .header {
      #switcher-currency {
        button.dropdown-list__label {
          i.arrow-state {
            display:none;
          }
        }
      }

       #language-EUR {
            display: none;
        }

    }
}

#mobile-switcher-currency{
    padding-top: 10px;
    margin-left: 25px;

    .dropdown-list__label{
        padding:10px 12px 8px;
    }
    .currency-icon{
        top: -6px;
        position: relative;
        left: -5px;
    }
    .dropdown-list__content{
        right: -222px !important;
        top: 38px;
    }
    .dropdown-list__content::before{
        right: 245px !important;
    }
}


