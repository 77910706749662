$file-upload__margin        : $spacer--medium !default;
$file-upload__field-height  : 48px !default;
$file-upload__font-size     : 14px !default;
$file-upload__button-padding: 0 $spacer--large !default;

.file-upload {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: $file-upload__margin;

    &__wrapper {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        width: 100%;
        position: relative;
    }

    &__input {
        @include visually-hidden();
    }

    &__label {
        display: flex;
        flex-basis: 100%;
        margin-bottom: $spacer;
        font-size: $file-upload__font-size;
    }

    &__name {
        font-size: $file-upload__font-size;
        margin-top: $file-upload__margin;

        @include mq($screen-s) {
            margin-left: $file-upload__margin;
            margin-top: 0;
        }
    }

    &__button {
        display: flex;
        align-items: center;
        margin-bottom: 0;
        padding: $file-upload__button-padding;
        width: 100%;

        @include mq($screen-s) {
            width: auto;
        }
    }
}
