.block-owl-carousel-items-list {

    margin-bottom: 45px;

    .block-title,
    .content-heading {

        text-align: center;
        margin-left: auto;
        margin-right: auto;
        padding-bottom: 20px;

    }

    .catalog-grid-item {

        max-width: 366px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;

        .secondary-addto-links {

            .button__text {
                display: none;
            }

        }

        .catalog-grid-item-inner {
            border: none;
        }

        &:after {

            position: absolute;
            right: 0;
            left: 0;
            bottom: 0;
            top: 0;
            content: '';
            border: $catalog-grid-item__border;
            pointer-events: none;
            z-index: -1;

        }

    }

    .button__text-desktop {
        display: none;
    }

    .button__text-mobile {
        display: inline;
    }

}