.list-childs-categories{
  .main-category-title{
    width: 100%;
    padding: 30px 0;
    text-align: center;
    color: #fff;
    font-size: 31px;
    background: #2f348a;
    margin: 0 0 15px
  }

  .grid-container{
        padding:0px 120px;
  }
  .category-item-info {
    text-align:center;



    img{
      border-radius: 7px;
    }

    .category-name{
      margin-top:7px;
    }

    a{
      color: #333;
      font-size:17px;
    }

    margin:8px 0 18px;
  }


  .custom-slider{
    h3{
      font-size: 30px;
      text-align: center;
      display: block;
      width: 100%;
      margin:30px 0 0 ;
    }
    .custom-slider-item
    {
      background-size: auto 100%;
      background-position: center top;
      background-repeat: no-repeat;
    }

    .owl-carousel {
      .owl-item{
          padding:10px;

          .container{
            padding:0px;
            margin:0px;
          }

          img{
            border-radius: 7px;
          }
      }
    }

    .custom-banner-item{

      text-align:center;

      .custom-banner-description{
        padding: 16px;
        font-size: 17px;
      }
      .custom-banner-item-link a{
        font-size:15px;
      }
    }
  }

}

@media all and (max-width: 1024px) {
  .list-childs-categories{
    padding:0px;
    .grid-container{
        padding:0px;
    }
  }
  .main-category-title{
    display: none;
  }
}

.develo-top-level-category-view {
    .page-wrapper {
        div.call-to-action-container{
            display:none;
        }
        .breadcrumbs {
            border-bottom-color: #ffffff;
            .breadcrumbs__list {
                @media all and (min-width: 1300px) {
                    margin: 12px 0 5px;
                    padding-left: 33px;
                }
            }

        }
    }

    .top-level-category-banner {

        margin:auto;

        .banner-inner {

            background-position: center center;
            background-size: cover;
            background-repeat: no-repeat;
            padding: 50px;

            .banner-title {

                h1 {

                    font-size: 28px;
                    font-weight: bold;
                    color: #fff;

                }

            }

            .banner-description {

                width: 100%;
                max-width: 600px;

                p {

                    color: #ececec;

                }

            }

        }

    }

    .algolia-instant-results-wrapper {

        display: none!important;

    }

    .child-category-container {

        padding: 10px 3%;

        > h2 {

            color: #333;
            font-size: 18px;
            font-weight: bold;
            padding: 25px 10px 30px;

        }

        .child-category-grid-container {

            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            &::after {
                content: "";
                flex: auto;
            }

            .child-category-grid-item {

                width: 50%;
                text-align: center;
                margin-bottom: 15px;
                padding: 0 10px;
                position: relative;
                width:20%;

                @media all and (max-width: 1024px) {
                    width: 33%;
                }

                @media all and (max-width: 767px) {
                    width: 100%;
                }

                a {

                    display: block;
                    position: relative;
                    padding-bottom: 50px;
                    -webkit-box-shadow: 0 2px 12px -4px rgba(187,187,187,1);
                    -moz-box-shadow: 0 2px 12px -4px rgba(187,187,187,1);
                    box-shadow: 0 2px 12px -4px rgba(187,187,187,1);
                    height: 100%;

                    &:hover,
                    &:focus {
                      &::after {
                        content: '';
                        background-color: rgb(240, 240, 240);
                        mix-blend-mode: darken;
                        width: 100%;
                        height: 100%;
                        display: block;
                        position: absolute;
                        top: 0;
                        left: 0;
                      }
                    }
                }

                .child-category-image {

                    padding: 15px;
                    height: 100%;
                    display: flex;
                    align-items: center;

                    img {

                        width: 100%;

                    }

                }

                .child-category-name {

                    position: absolute;
                    bottom: 0;
                    padding: 15px;
                    width: 100%;

                    h3 {

                        font-size: 14px;
                        font-weight: bold;
                        text-align: center;
                        margin-bottom: 0;
                        color: #0A246A;

                    }

                    &.no-cat-image {

                      position: absolute;
                      bottom: unset;
                      top: 50%;
                      transform: translateY(-50%);

                    }

                }

            }

        }

    }

}
