.solutions-services-overview-container {

    background-color: $nearly-white;
    padding: 30px 0;

    .overview-title,
    .overview-description {

        max-width: 725px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;

    }

    .tab--services-and-solutions {

        display: flex;
        flex-flow: row wrap;
        justify-content: center;

        .tab__title {

            max-width: 175px;
            background: transparent;
            width: auto;
            flex: 1;
            order: -1;

            &--active {
                background: transparent;
            }

        }

        .tab__content {

            padding: 0;

            &--active {
                padding: 45px 0;
            }

        }

        .grid {

            justify-content: start;

            @media (min-width: 768px) {

                .grid__column--1-4 {

                    flex-basis: calc(25%);
                    max-width: calc(25%);

                }

            }

            .grid__column {

                padding-left: 15px;
                padding-right: 15px;

            }

        }

    }

}

@media all and (max-width: 767px) {

    .solutions-services-overview-container {



    }

}