.page-root-category {

    .catalog-grid-item {
        opacity: 1;
    }

    .catalog-list__products {

        float: none;
        width: auto;
        padding: 0;
        margin-bottom: 0;

    }

    .category-subcategories-list-container {

        .hero-slider-title {
            text-align: center;
        }

    }

    .featured-popular-container {

        background-color: $nearly-white;
        padding: 40px 0;
        display: flex;
        flex-flow: row wrap;
        justify-content: center;

        .tab--popular-and-featured {
            justify-content: center;
        }

        .tab__title {

            background: transparent;
            flex: 1;
            order: -1;

            &--active {
                background: transparent;
            }

            @media (min-width: 992px) {

                width: 150px;
                max-width: 150px;

            }

        }

        .custom-slider {

            padding: 0;

            .content-heading {
                display: none;
            }

            .catalog-grid-item {

                &:after {
                    background-color: $white;
                }

            }

            .owl-stage-outer {
                padding-bottom: 0;
            }

            [class*=owl-carousel-products-].owl-theme .owl-dots {
                bottom: 0;
            }

        }

        .block-owl-carousel-items-list {
            margin-bottom: 0;
        }
    }

    .hero-slider-partners {

        margin: 60px 0 0;

        .custom-slider {
            padding-bottom: 0;
        }

    }

    @media all and (min-width: 768px) {

        .featured-popular-container {

            .custom-slider {

                .catalog-grid-item {

                    margin: 0 15px;
                    width: auto!important;

                }

            }

        }

        .hero-slider-categories {

            .banner-item {

                margin: 0 0 50px;
                vertical-align: top;

            }

        }

    }

}
