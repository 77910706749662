/* Blog Widget banner specific styling here */
.blog-banner {

    margin-bottom: 80px;

    &__image {
        width: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        border-radius: 10px;
    }

    &__content-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        max-width: 500px;
        height: 100%;
        gap: 1rem;
        min-height: 400px;
        padding: 40px 0 40px 125px;

        @media all and (max-width: 768px) {
            padding: 40px 16px;
            text-align: center;
            max-width: 100%;
        }
    }

    &__title {
        color: white;
        font-weight: bold;
        font-size: 50px;
        margin: 0;

        @media all and (max-width: 768px) {
            font-size: 45px;
        }
    }

    &__description {
        color: $blog-grey;
    }

    .button {
        background-color: $blog-red-dark;
        border: none !important;
        color: white !important;

        &:hover,
        &:active,
        &:hover {
            background-color: $blog-red-light !important;
        }
    }

    .form.subscribe {
        display: flex;
        align-items: center;

        input {
            background: transparent;
            border: 1px solid white !important;
            color: white;

            &:hover,
            &:focus {
                box-shadow: none;
            }

            &::placeholder {
                color: white;
                opacity: 1;
            }
        }

        button {
            position: relative;
            height: 44px;
            right: 10px;

            .mobile-chevron {
                &::before {
                    content: 'Subscribe';
                    display: block;
                }
                svg {
                    display: none;
                }
            }
        }
    }
}
