$badge__padding              : 3px 13px !default;
$badge__background           : $color-primary !default;
$badge__border-radius        : $border-radius !default;
$badge__font-size            : $font-size-small !default;
$badge__font-family          : $font-family-base !default;
$badge__font-weight          : $font-weight-bold !default;
$badge__text-transform       : uppercase !default;
$badge__color                : $font-color-base !default;
$badge__color--new           : $color-success !default;
$badge__background--new      : $white !default;
$badge__color--discount      : $color-danger !default;
$badge__background--discount : $white !default;

.badge {
    display: inline-flex;
    padding: $badge__padding;
    font-family: $badge__font-family;
    font-size: $badge__font-size;
    font-weight: $badge__font-weight;
    text-transform: $badge__text-transform;
    color: $badge__color;
    background: $badge__background;
    border-radius: $badge__border-radius;

    &--new {
        color: $badge__color--new;
        background: $badge__background--new;
    }

    &--discount {
        color: $badge__color--discount;
        background: $badge__background--discount;
    }
}
