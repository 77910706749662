.cms-contact-us-communications,
.cms-contact-us-cybersecurity,
.cms-contact-us-data-center,
.cms-contact-us-eprocurement,
.cms-contact-us-financial-services,
.cms-contact-us-international-solutions
{

    .webforms {

        .fieldset {
            margin-bottom: 25px;
        }

        .button.file-upload__button {

            .button__icon {

                stroke: $button__font-color--secondary;

                use {
                    stroke: $button__font-color--secondary;
                }

            }

            &:not(:hover) {

                color: $button__font-color--secondary;
                border: $button__border--secondary;
                background-color: $button__background--secondary;

            }

        }

        .actions-toolbar {

            text-align: center;
            padding: 30px 15px;
            background: $nearly-white;

            .button {

                width: auto;
                margin: 0 auto;
                min-width: 200px;

            }

        }

    }

}
