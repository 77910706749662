$catalog-grid-item__base-spacing              : $spacer !default;
$catalog-grid-item__padding                   : 0 !default;
$catalog-grid-item__border                    : 1px solid $gray-lightest !default;
$catalog-grid-item__font-size                 : $font-size-base !default;
$catalog-grid-item__font-size\@large          : 16px !default;
$catalog-grid-item__line-height               : 1.5 !default;
$catalog-grid-item__font-weight               : $font-weight-bold !default;

$catalog-grid-item__badge-top-position        : $spacer--medium !default;
$catalog-grid-item__badge-left-position       : 0 !default;
$catalog-grid-item__badge-z-index             : 1 !default;
$catalog-grid-item__badge-padding             : 5px 12px !default;
$catalog-grid-item__badge-bg-color            : $color-primary !default;
$catalog-grid-item__badge-font-size           : $font-size-small !default;
$catalog-grid-item__badge-border-radius       : 0 !default;

$catalog-grid-item__image-margin              : 0 auto !default;
$catalog-grid-item__image-width               : auto !default;
$catalog-grid-item__image-width--lazyload     : 100% !default;
$catalog-grid-item__image-height              : auto !default;

$catalog-grid-item__loader-margin-top         : 30% !default;

$catalog-grid-item__link-overflow             : hidden !default;
$catalog-grid-item__link-overflow--visible    : visible !default;
$catalog-grid-item__link-white-space          : nowrap !default;
$catalog-grid-item__link-white-space--visible : normal !default;
$catalog-grid-item__link-text-overflow        : ellipsis !default;

$catalog-grid-item__content-bg-color          : $white !default;
$catalog-grid-item__content-padding           : $spacer !default;
$catalog-grid-item__content-width             : 100% !default;
$catalog-grid-item__content-height            : 67px !default;
$catalog-grid-item__content-height\@large     : 73px !default;
$catalog-grid-item__content-translateY        : translateY(-$catalog-grid-item__content-height) !default;
$catalog-grid-item__content-translateY\@large : translateY(-$catalog-grid-item__content-height\@large) !default;
$catalog-grid-item__content-translateY--active: translateY(-100%) !default;

$catalog-grid-item__name-color                : $font-color-base !default;
$catalog-grid-item__name-font-weight          : $font-weight-normal !default;
$catalog-grid-item__name-margin-botttom       : $spacer !default;
$catalog-grid-item__name-padding--bigger      : $spacer--medium 0 !default;

$catalog-grid-item__rating-margin-bottom      : $spacer !default;
$catalog-grid-item__price-margin-bottom       : $spacer !default;

$catalog-grid-item__primary-padding           : 0 $spacer !default;
$catalog-gird-item__primary-form-margin       : 0 !default;

$catalog-grid-item__action-size               : 48px !default;
$catalog-grid-item__action-padding            : 0 !default;
$catalog-grid-item__action-background         : transparent !default;
$catalog-grid-item__action-border             : none !default;
$catalog-grid-item__action-cursor             : pointer !default;
$catalog-grid-item__action-icon-size          : 18px !default;
$catalog-grid-item__action-icon-transition    : $transition-base !default;
$thunder          : $gray-darker !default;
$thunder-hover    : $green !default;

$catalog-grid-item__IE-margin                 : 0 $spacer $spacer--medium $spacer !default;
$catalog-grid-item__IE-width                  : calc(50% - #{$spacer--medium} - 1px) !default;
$catalog-grid-item__IE-width\@medium          : calc(100% / 3 - #{$spacer--medium} - 1px) !default;

.catalog-grid-item {

    position: relative;
    display: block;
    //border: $catalog-grid-item__border;
    overflow: hidden;


    .catalog-grid-item-inner {

        padding: $catalog-grid-item__padding;
        margin: 0 -1px -1px 0;
        min-height: 100%;
        border: $catalog-grid-item__border;
        border-top: 0;
        //border-bottom: $catalog-grid-item__border;

        // For absolutely positioned bottom product card content
        position: relative;
        padding-bottom: 170px;

        .catalog-grid-item__details {

            .product-card-bottom-container {

                position: absolute;
                bottom: 15px;
                width: calc( 100% - 30px );

            }

            div.wishlist__comment {

                ~ .product-card-bottom-container {

                    .catalog-grid-item__uin {

                        margin-bottom: 0;

                    }

                }

                margin: 10px 0 20px !important;

            }

        }

    }

    &.grid__column {

        flex: 1 0 50%;
        padding: 0;
        border: none;
        max-width: none;
        overflow: visible;

    }

    &__badge {
        position: absolute;
        top: $catalog-grid-item__badge-top-position;
        left: $catalog-grid-item__badge-left-position;
        z-index: $catalog-grid-item__badge-z-index;
        border-radius: $catalog-grid-item__badge-border-radius;
        padding: $catalog-grid-item__badge-padding;
        font-size: $catalog-grid-item__badge-font-size;
        background-color: $catalog-grid-item__badge-bg-color;
    }

    &__link {

        display: block;

        &--name {

            color: $catalog-grid-item__name-color;
            line-height: 24px;
            font-size: 14px;
            font-weight: 600;
            min-height: 72px;

        }

    }

    &__image-wrapper {

        .image {

            margin: $catalog-grid-item__image-margin;
            max-width: $catalog-grid-item__image-width;
            max-height: $catalog-grid-item__image-height;
            height: auto;
            width: auto;

        }

        .lazyload,
        .lazyloading {
            width: $catalog-grid-item__image-width--lazyload;
        }

        .loader__circle {
            margin-top: $catalog-grid-item__loader-margin-top;
        }
    }

    &__name {
        font-weight: $catalog-grid-item__name-font-weight;
        font-size: $catalog-grid-item__font-size;
        line-height: $catalog-grid-item__line-height;
        margin-bottom: $catalog-grid-item__name-margin-botttom;
        min-height: 48px;
    }

    &__rating {
        margin-bottom: $catalog-grid-item__rating-margin-bottom;
    }

    &__sku {
        margin: 0;
    }

    &__subtitle {
        font-size: 12px;
        line-height: 18px;
    }

    &__price,
    .price-from,
    .price-to,
    .minimal-price {

        font-size: 18px;
        line-height: 27px;
        font-weight: 600;
        margin: 0;

    }

    &__details {
        padding: $catalog-grid-item__content-padding;
        background: $catalog-grid-item__content-bg-color;
        width: $catalog-grid-item__content-width;
    }

    &__options {
        .swatch {
            margin: $catalog-grid-item__base-spacing 0;
        }
    }

    &__actions {

        display: block;
        margin: 10px 0 0;

        label {
            color: $font-color-base;
            padding-right: 10px;
            margin: 16px 0 0;
        }

        select.select {
            margin: 10px 10px 0 0;
        }

    }

    &__brand {

        margin: 0;
        height: auto;
        position: absolute;
        right: 0;
        bottom: 0;
        display: block;

        .attributepages-option-title {

            font-size: 12px;
            color: $thunder;

        }

        .attributepages-parent-link {
            display: none;
        }

        .attributepages-option-image {

            display: inline-block;
            max-width: 80px;
            max-height: 45px;
            width: auto;
            height: auto;

        }

    }

    .catalog-grid-item__details-inner {

        position: relative;
        //min-height: 88px;

    }

    &__primary-form {

        display: flex;
        justify-content: space-between;

        .button__icon {
            margin-right: 3px;
        }

        @media all and (max-width: 420px) {

            > * {

                width: 100%;
                margin: 0 0 10px;

            }

        }

        .catalog-grid-item__primary-action {

            @media all and (min-width: 1330px) {
                width: 100%;
            }

        }

    }

    &__primary-action,
    .dte-add-toquote-container {

        display: inline-flex;
        width: auto;

    }

    .dte-add-toquote-container {

        margin-left: 4.5%;

        .button {
            width: 100%;
        }

        .button__icon {
            margin-right: 1px;
        }

    }

    &__cart-icon {

        display: inline-block;
        width: 18px;
        height: 18px;

    }

    &__cart-text {
        display: inline-block;
    }

    &__secondary-action {

        position: absolute;
        top: 0;
        left: 10px;
        right: 10px;
        /*display: flex;
        align-items: center;
        justify-content: center;*/
        display: block;
        text-align: right;

        .button {
            height: $catalog-grid-item__action-size;
            width: auto;
            padding: $catalog-grid-item__action-padding;
            background: $catalog-grid-item__action-background;
            border: $catalog-grid-item__action-border;
            cursor: $catalog-grid-item__action-cursor;
            min-width: 0;

            &:hover,
            &:focus {
                box-shadow: none;
            }

            .button__icon {

                width: $catalog-grid-item__action-icon-size;
                height: $catalog-grid-item__action-icon-size;
                transition: $catalog-grid-item__action-icon-transition;
                margin: 0;
                stroke: $thunder;

                use {
                    stroke: $thunder;
                }

            }

        }

    }

}

@media all and (min-width: 768px) {

    .catalog-grid-item {

        .catalog-grid-item__stock-status {

            display: block;
            margin: 10px 0 1px;
            font-weight: 300;

        }

        .catalog-grid-item__name,
        .catalog-grid-item__details-inner {

            //padding-left: 7px;
            //padding-right: 7px;

        }

    }

}

@media all and (max-width: 1120px) {

    .catalog-grid-item__primary-form {
        .qty-selector {
            flex: 1;
            .quantity-update__input {
                height: 45px;
            }
        }
        .catalog-grid-item__primary-action.tocart {
            height: 47px;
            flex: 1;
        }
    }

    .catalog-grid-item__primary-action,
    .catalog-grid-item .dte-add-toquote-container,
    .catalog-grid-item .dte-add-toquote-container .button {

        width: 100%;
        max-width: 100%;
        margin: 0;

    }

    .catalog-grid-item__primary-action + .dte-add-toquote-container {
        margin-top: 10px;
    }

}

.catalog-grid-item__primary-form {

    .catalog-grid-item__primary-action.tocart,
    a.price-on-application {
        margin-left: 15px!important;
    }


}
