.table {

  background-color: $white;
  width: 100%;
  border-spacing: unset;

  caption {

    background: transparent;
    color: $thunder;
    text-transform: none;
    font-size: 24px;
    font-weight: 300;
    border-bottom: 1px $thunder solid;
    padding: 15px 0;
    margin-bottom: 15px;
    border: none;

  }

  thead {

    th {

      border: none;
      background-color: $white;
      font-weight: normal;
      text-transform: uppercase;
      padding: 7px 0 7px 7px;

      &:first-child {

        padding-left: 10px;

      }

      &.delete {

        text-align: center;

      }

      &.part-number {

        min-width: 150px;
        padding-left: 10px;

      }

    }

  }

  tbody {

    tr[onclick] {

      cursor: pointer;

      &:hover {

        td {

          background-color: #F1F8FB;

          &.part-number {

            min-width: 150px;
            padding-left: 10px;

          }

        }

      }

    }

    tr.totals {

      td {

        text-align: right;
        padding: 24px 0 18px 0;
        font-size: 22px;
        font-weight: bold;
        line-height: 26px;
      }

    }

    td {

      background-color: transparent;
      color: $thunder;
      line-height: 25px;

      &.qty {

        padding-left: 15px;

        .product-view__qty {

          margin: 0 !important;

        }

      }

      &.price {
        .price-including-tax {
          display: none;
        }
      }

      &:first-child {

        border-left: 1px solid transparent;
        padding-left: 10px;

        &.qty {
          ul {
            list-style-type: none;
            padding: 0;
          }
        }

      }

      &:last-child {

        border-right: 1px solid transparent;

      }

      &.col.name{
          min-width:350px;
          strong{
              .product-item-name{
                  margin: 10px;
              }
          }
      }

    }

    tr:last-child td:first-child,
    tr:last-child td:last-child {
      border-radius: 0;
    }

  }

  .delete-row-button {

    svg {

      width: 24px;
      height: 24px;

    }

  }

  .account-table-status {

    vertical-align: super;
    position: relative;
    left: 5px;

  }

  td.actions {

    .action.delete {

      svg {

        width: 25px;
        height: 25px;

      }

    }

    svg {

      width: 24px;
      height: 24px;
      margin-bottom: 6px;
    }

  }

  tfoot {

    font-size: 15px;
    text-transform: uppercase;
    font-weight: bold;
    background-color: $color-primary;

    tr.subtotal {

      font-weight: normal;

      th {

        font-weight: normal;
        background-color: transparent;
        color: $white;
        border-color: $color-primary;

      }

      td.amount {

        border-color: $color-primary;

      }

    }

    tr.grand_total {

      th {

        background-color: transparent;
        color: $white;
        border-color: $color-primary;

      }

      td.amount {

        border-color: $color-primary;

      }

    }

    th.mark {

      border-right: none;
      text-align: right;

    }

    td {

      padding: 20px 16px;
      background-color: transparent;
      color: $white;
      border-color: $color-primary;

      &.amount {

        border-bottom-right-radius: 0;

      }

    }

    span.price {

      font-size: 17px;

    }

  }

  &#my-amazon-quotes-table {

    caption {

      display: none;

    }

  }

  &.quote-list {

    .col.unit,
    .col.total {

      min-width: 120px;

    }

  }

}

.actions-toolbar-bottom {

  text-align: right;

  a {

    width: 30%;

  }

}

.table caption,
.dashboard-table__title {

  font-size: 20px;
  font-weight: 600;
  line-height: 1.33;
  color: #231f20;
  margin-bottom: 15px;
  text-align: left;

}

.admin__page-section-item-title {

  text-align: center;

  .title {

    font-size: 20px;
    font-weight: 600;
    line-height: 1.33;
    color: #231f20;
    margin: 0 0 25px;
    display: inline-block;

  }

}

.dashboard-table {

  overflow: visible;

  .dashboard-table__header {

    border-bottom: none;
    padding: 0;
    text-align: center;
    margin: 0;

    .dashboard-table__title {

      margin: 0 auto 15px;

    }

  }

  .dashboard-table__content {

    overflow: visible;

  }

}

.quotelist-account-view,
.quotelist-cart-index {

  .table.quote-list {

    thead th.col.qty,
    tbody td.col.qty {
    }

    tbody td.col.qty .quantity-update-container {

      display: inline-block;

    }

  }

  .actions-toolbar-bottom {

    .dte-add-toquote-container {

      .save-as-quote-btn {

        float: left;

      }

      .dte-delete-all-button {

        width: auto;
        float: left;
        margin-left: 5px;

      }

    }

  }

}

.quotelist-cart-index {

  .table.quote-list {

    thead th.col.part-number,
    tbody td.col.part-number {
      padding-left: 7px;
    }

  }

}

.develodesign_portalquotes-quote-view {

  #my-amazon-quotes-table {

    font-size: 11px;
    padding: 0 !important;

    tbody td {

      &.qty {
        min-width: 64px;
      }

      &.part-no {
        min-width: 211px;
      }

      &.unit {
        min-width: 120px;
      }

      &.row-total {
        min-width: 124px;
      }

      &.stock {
        min-width: 84px;
      }

    }

  }

}

/* new seach forms */
#quoteSearchForm {
  width: 99%;
  @media all and (min-width: 768px) {
    max-width: 300px;
    float: right;
    display: inline-block;
  }
  padding: 8px 0;

  .dte-search-form-text-container {
    display: inline-block;
    max-width: 210px;

    #quoteSearch {
      height: 44px;
      background: $white;
        border: 1px solid #E0E0E0;
      display: inline-block;
      font-size: 13px;
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;

      &:focus, &:active {
        background: $white;
      }
    }
  }

  .dte-search-form-button-container {
    max-width: 70px;
    display: inline-block;
    position: relative;
    left: -3px;
    top: -2px;

    .orderSearchButton, .quoteSearchButton {
      max-width: 100%;
      padding: 0px;
      height: 44px;
      margin: 0px;
      min-width: 75px;
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
    }
  }
}


#sorttable_sortfwdind,
#sorttable_sortrevind {
  position: relative;
  font-size: 0;
  display: inline-block;

  &:after {
    content: '';
    background-image: url("data:image/svg+xml,%3Csvg height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m11.046082 8.22259504 4.2106126 4.21061266-4.2106126 4.2102043' fill='transparent' stroke='%230AB6FF' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    bottom: -8px;
    left: 10px;
  }
}

#sorttable_sortfwdind {
  &:after {
    transform: rotate(270deg);
    bottom: -10px;
  }
}

#sorttable_sortrevind {
  &:after {
    transform: rotate(90deg);
  }
}

.sales-order-view{

  .heading.heading--page{
    display: none !important;
  }

  .dashboard-table--tab, .dashboard__list .nav.item.current{
    border:0px;
    display: none;
  }

}

.dashboard-table__content{
  margin:30px;
  border:0px;
  background: #fff;
  padding: 10px 10px 30px;

  .dashboard-table__header .dashboard-table__title{
    margin: 18px 10px;
    font-size: 16px;
  }

  .table tfoot{
    top: 30px;
    position: relative;

    .grand_total_incl{
      th{
        padding-right:0px;
      }
      span.total_label{
        display:inline-block;
        width: 200px;
        padding: 12px 0;
        border-top: 1px #dfdada solid;
        padding-right: 10px;
        font-size: 15px;
        font-weight: bold;
      }

      .amount{
        padding-left:0px;

        span{
          display:inline-block;
          border-top: 1px #dfdada solid;
          padding:11px;
        }
      }
    }
  }

  .table tfoot tr.subtotal td.amount,
  .table tfoot td,
  .table tfoot tr.subtotal th,
  .table tfoot th,
  .table tfoot tr.grand_total td.amount{
    background: #fff;
    color: #231f20;
    border-color: transparent;
    text-transform: none;
    padding: 12px;
    font-weight: normal;
    font-size: 15px;
    span {
        &.price{
          font-size: 14px;
        }
    }

    strong {
      span {
          &.price{
            font-size: 16px;
          }
      }
    }

  }
  .table th{
    color:#231f20;
    font-weight: bold;
  }

  .col.subtotal .price-excluding-tax {
    display:none;
  }
}
