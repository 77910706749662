$divider__color--left : $color-primary !default;
$divider__color--right: $gray-lighter !default;
$divider__color--black: #000 !default;
$divider__color--grey: #999 !default;
$divider__color--light-grey: #f5f5f5 !default;
$divider__size        : 8px !default;

.divider {

    display: none;

    @include mq($screen-m) {
        display: flex;
    }

    &__left,
    &__right {
        border-top: $divider__size solid;
        flex: 1;
    }

    &__left {
        border-color: $divider__color--left;
        flex-grow: 1;
    }

    &__right {
        border-color: $divider__color--right;
        flex-grow: 2;
    }

    &__black {
        background: $divider__color--black;
    }

    &__grey {
        background: $divider__color--grey;
    }

    &__light-grey {
        background: $divider__color--light-grey;
    }

    &__simple-grey {

        display: block;
        background: $divider__color--light-grey;
        height: 1px;
        width: 100%;

    }


}
