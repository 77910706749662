$dashborad-form-margin: 30px !default;

.dashboard-form {
    &__title {
        margin-bottom: $dashborad-form-margin;
        padding-bottom: 10px;
        border-bottom: 1px solid $gray;
    }

    &__divider,
    &__select {
        margin-bottom: $dashborad-form-margin;
    }

    &--hidden {
        display: none;
    }
}
