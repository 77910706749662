.catalog-category-view {

    //&:not(.page-root-category) {
    //
    //    #maincontent {
    //        max-width: none;
    //    }
    //
    //}

    .product-added-snackbar {

        position: fixed;
        bottom: 15px;
        left: 10px;
        background-color: #32C015;
        padding: 10px 18px 10px 25px;
        font-size: 13px;
        font-weight: bold;
        color: #fff;
        border-radius: 4px;
        z-index: 9;

        > span {

            display: inline-flex;
            margin-left: 13px;
            border: 1px solid #fff;
            border-radius: 50%;
            font-size: 13px;
            padding: 7px 9px 3px 10px;
            font-weight: bolder;

        }

    }

    #algolia_instant_selector {

        .facet.ais-root:not(.ais-current-refined-values) .ais-body {
            padding-bottom: 15px;
        }

        #instant-search-facets-container {

            a.filter-action--clear-filters {

                display: none;
                position: absolute;
                top: 16px;
                right: 29px;
                font-weight: 600;
                font-size: 13px;

                @media all and ( max-width: 991px ) {

                    right: 20%;

                }

            }

            > div.filter--is-visible,.filter--is-active {

                border-bottom: 1px solid #E0E0E0;

            }

            > * {

                &.is-widget-container-amazon_approved {

                    display: none !important;

                    .ais-toggle--header {
                        display: none;
                    }

                    .ais-toggle--body {

                        display: block !important;

                        .ais-toggle--label {

                            color: $light-navy-blue;
                            font-size: 14px;
                            font-weight: 700;
                            line-height: 1.35;
                            padding: 10px 0 10px 35px;
                            border-bottom: 1px $light-navy-blue solid;
                            text-transform: none;
                            position: relative;
                            min-height: $checkbox__spacer;

                            input[type="checkbox"] {
                                display: none !important;
                            }

                            .ais-toggle--count {
                                display: none !important;
                            }

                            &:before {

                                content: '';
                                position: absolute;
                                top: 50%;
                                transform: translateY(-50%);
                                left: 0;
                                width: $checkbox__spacer;
                                height: $checkbox__spacer;
                                border: 1px $light-navy-blue solid;
                                border-radius: $checkbox__icon-border-radius;
                                transition: $checkbox__icon-transition;
                                background-color: $checkbox__unchecked-background-color;

                            }

                            &:hover {

                                color: $checkbox__label-color;
                                cursor: pointer;

                                &:before {

                                    background-color: $checkbox__hover-background-color;
                                    border-color: $checkbox__hover-border-color;

                                }

                            }


                        }

                        .ais-toggle--item__active {

                            .ais-toggle--label {

                                &:before {

                                    border: $checkbox__border--checked;
                                    background-color: $thunder;
                                    background-image: url("data:image/svg+xml,%3Csvg height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m18.7357432 7.12093254-9.15691975 9.15619256-4.57882345-4.5773691' fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");

                                }

                                &:hover {

                                    &:before {

                                        background-image: none;
                                        background-color: $checkbox__hover-background-color;
                                        border: 1px $checkbox__hover-border-color solid;

                                    }

                                }

                            }

                        }

                    }

                    &.filter--is-active {

                        &.filter--is-top-row .ais-toggle {
                            border-top: 1px $light-navy-blue solid;
                        }

                    }

                }

                //filter--is-active filter--is-visible filter--is-top-row filter--clear-left

                &.filter--is-active {

                }

            }

        }

    }

    .catalog-list__products {

        float: none;
        width: 100%;

    }

    .category-hero-no-img {

        text-align: center;

        .heading--first-level {
            margin: 30px auto 15px;
        }

    }

    .category-hero-with-img {

        background-position: center;
        background-size: cover;
        margin-bottom: 20px;

        .custom-slider {
            padding: 0;
        }

        .custom-slider-item {

            min-height: 1px;

            &:after {
                display: none;
            }

            .custom-slider-item-inner {

                text-align: center;
                min-height: 150px;

                .custom-slider-item-title,
                .custom-slider-item-strapline {

                    color: $white;
                    max-width: 550px;
                    margin-left: auto;
                    margin-right: auto;

                }

                .custom-slider-item-strapline {

                    > * {

                        font-size: inherit;
                        font-weight: inherit;
                        color: inherit;
                        margin: 0;

                    }
                }

            }

        }

    }

    .top-navigation-categories-container {

        &.filters-open {

            .mobile-button-container {

                .button-toggle-filters-mobile {

                    .inactive-text {
                        display: none;
                    }

                    .active-text {
                        display: inline;
                    }

                }

            }

        }

        &:not(.filters-open) {

            .mobile-button-container {

                .button-toggle-filters-mobile {

                    .inactive-text {
                        display: inline;
                    }

                    .active-text {
                        display: none;
                    }

                }

            }

        }

    }

    .category-toolbar-top-parent-container {

        margin-bottom: 30px;

        .toolbar__mode-button {

            padding-left: 0;
            padding-right: 0;

            + .toolbar__mode-button {

                margin-left: 25px;
                margin-right: 5px;

            }

            .button__icon {

                transition: all 0.45s;
                transform: rotate(0deg);

            }

            &:hover {

                .button__icon {
                    transform: rotate(180deg);
                }

            }

        }

    }

    .algolia-category-view-hits-container {

        margin: 0;

        @media all and ( max-width: 991px ) {

            margin: 0!important;

        }

        #algolia-right-container {

            @media all and ( max-width: 991px ) {

                padding: 0!important;

            }

        }

    }

    #algolia-right-container {

        .algolia-instantsearch-full-searchinput-container {

            .toolbar__mode {

                display: flex;
                align-items: center;

            }

            .toolbar__mode-button {

                display: flex;
                border: 1px solid #ccc;
                border-radius: 4px 0 0 4px;
                padding: 7px 5px;
                margin: 0;
                height: auto;

                @media all and (min-width: 992px) {

                    height: 34px;
                    border-bottom-width: 3px;
                    border-color: #e0e0e0;

                }

                + .toolbar__mode-button {

                    border-radius: 0 4px 4px 0;

                }

                .button__text {
                    display: none;
                }

                .button__icon {

                    width: 32px;
                    height: auto;
                    opacity: 0.5;
                    margin: 0;

                }

            }

        }

    }

    .applied-filters-container {

        background-color: $nearly-white;

        .filters__heading {

            border-bottom: 1px $thunder solid;
            margin: 0;
            padding: 8px 0 12px 15px;
            font-size: 24px;

        }

        .active-filters__item {
            font-size: 18px;
        }

        .active-filters__remove-icon {

            width: 24px;
            height: 24px;

        }

    }

    .filters__list {

        .dropdown-list__item {

            .dropdown-list__label {

                border-bottom: 1px $thunder solid;
                text-transform: none;
                font-size: 24px;
                color: $thunder;
                line-height: 32px;
                padding: 5px 0 12px;

                .dropdown-list__icon {

                    fill: $thunder !important;
                    padding: 0;

                }

            }

            .dropdown-list__content.filter__content {

                margin-bottom: 10px;

            }

            .dropdown-list__icon--plus {
                transform-origin: 50% 0%;
            }

            &.dropdown-list__item--open {

                .dropdown-list__icon {
                    transition: all 0.35s;
                }

                .dropdown-list__icon--minus {
                    opacity: 1;
                    transition-delay: 0.075s;

                }

                .dropdown-list__icon--plus {
                    opacity: 0;
                    transform: rotate(90deg) translateY(-50%);
                }

            }

            &:not(.dropdown-list__item--open) {

                .dropdown-list__icon--minus {
                    opacity: 0;
                }

                .dropdown-list__icon--plus {
                    opacity: 1;
                    transform: rotate(0deg) translateY(-50%);
                }

            }


        }


    }

    .algolia-instant-results-wrapper {

        //max-width: 1100px;
        margin-left: auto;
        margin-right: auto;
        //overflow-x: hidden;
        position: relative;

    }

    #instant-search-results-container {

        .ais-infinite-hits--showmoreButton {

            background: none;
            display: block;
            margin: 42px auto 30px;
            justify-content: center;
            cursor: pointer;
            height: 48px;
            min-width: 100px;
            border-radius: 100px;
            outline: none;
            font-size: 18px;
            font-weight: 400;
            line-height: 1;
            vertical-align: middle;
            align-items: center;
            position: static;
            padding: 11px 20px;
            border: 1px $thunder solid;
            background: #fff;
            color: $thunder;

        }

        .ais-hits {

            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: stretch;

            &:after {
                content: '';
                flex: auto;
            }

        }

        .ais-hits--item {

            //flex-basis: 50%;
            //max-width: 50%;
            display: flex;

            /*&:nth-child(1),
            &:nth-child(2)
            {

                .catalog-grid-item {

                    .catalog-grid-item-inner {
                        border-top: 1px $thunder solid;
                    }

                }

            }

            &:nth-child(odd) {

                .catalog-grid-item {

                    .catalog-grid-item-inner {
                        border-right: 0;
                    }

                }

            }

            &:last-child {

                .catalog-grid-item {

                    .catalog-grid-item-inner {
                        border-right: 1px $thunder solid!important;
                    }

                }

            }*/
            .alg-objectid-1281448,
            .alg-objectid-1281449 {
                .row {
                    .catalog-list-item__main-col {
                        .catalog-list-item__main {
                            .catalog-list-item__bottom {
                                .catalog-list-item__primary-form {
                                    button.tocart {
                                        display: none;
                                    }
                                }
                            }
                        }
                    }

                }
                .catalog-grid-item-inner {
                    .product-card-bottom-container {
                        .catalog-grid-item__primary-form {
                            button.tocart {
                                display: none;
                            }
                        }
                    }
                }
            }
            /** This is stagings product Id, should be removed later after DTE-915 testing **/
            .alg-objectid-1280297,
            .alg-objectid-1280298 {
                .row {
                    .catalog-list-item__main-col {
                        .catalog-list-item__main {
                            .catalog-list-item__bottom {
                                .catalog-list-item__primary-form {
                                    button.tocart {
                                        display: none;
                                    }
                                }
                            }
                        }
                    }

                }
                .catalog-grid-item-inner {
                    .product-card-bottom-container {
                        .catalog-grid-item__primary-form {
                            button.tocart {
                                display: none;
                            }
                        }
                    }
                }
            }


        }

        .catalog-grid-item {

            min-height: 100%;
            min-width: 100%;

            .catalog-grid-item-inner {

                min-height: 100%;
                margin: 0;

            }

            .catalog-grid-item__image-wrapper img {

                width: auto;
                max-width: 100%;
                max-height: none;
                height: 150px;

            }

        }

        .catalog-list-item {
            .catalog-list-item__bottom{
                .catalog-list-item__price{
                    min-width: 110px;
                }
            }
            .catalog-list-item__image-wrapper img {
                width: auto;
                max-width: 100%;
                max-height: none;
                height: auto;
            }

            .catalog-grid-item__uin {
                margin: 0 0 30px;
            }

        }

    }

    #algolia_instant_selector {

        margin: 0;
        overflow-x: hidden;
        overflow-y: visible;

        #algolia-left-container {
            padding-right: 0;
            z-index: 2;
            position: relative;

            .help-and-support-container {

                .content-title {

                    font-size: 17px;

                }

                .buttons-group {

                    .button {

                        padding: 0 10px;

                    }

                }

            }
        }

        #algolia-right-container {
            padding-left: 0;
        }

        .button__icon--search-icon {

            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 15px;

            @media all and (max-width:768px) {
                transform: translateY(-57%);
            }

        }

        .filter-actions-container {

            .filter-action--show-more{
                display: none !important;
            }
            margin: 8px 0 !important;

            &:before,
            &:after {

                display: block;
                clear: both;
                content: "";

            }

            .filter-actions--col {

                float: left;
                width: 50%;

                &:first-child {
                    padding-right: 5px;
                }

                &:last-child {
                    padding-left: 5px;
                }

                .button {

                    width: 100%;
                    padding: 3px 6px;
                    font-size: 11px;
                    height: 29px;

                    .button__icon {

                        width: 16px;
                        height: 16px;
                        margin-right: 3px;

                    }

                }

            }

        }

        .facet {

            border: none;
            margin: 0;
            padding: 0;

            .name {

                font-size: 15px;
                font-weight: 600;
                line-height: 1.33;
                color: $thunder;
                text-transform: none;
                padding: 16px 0;
                background-color: #fff;

                a {

                    font-weight: 600;

                }

            }

            .ais-refinement-list--body {

                .ais-refinement-list--list > .searchbox {

                    display: none;

                }
            }


            &.ais-current-refined-values {

                .name {
                    padding: 13px 0 13px 0;
                }

            }

            .ais-current-refined-values--clear-all {
                display: none !important;
            }

        }

        .is-widget-container-supplier_icecat,
        .is-widget-container-Product,
        .is-widget-container-Type{
            .ais-refinement-list--body {
                .ais-refinement-list--list > .searchbox:not(.sbx-sffv-disabled){
                    display: block;
                }
            }
        }

        .is-widget-container-in_stock .ais-refinement-list--body .ais-refinement-list--list {

            /* Hide OOS */
            div.ais-refinement-list--item {
                display: none;
            }

            div.ais-refinement-list--item:nth-of-type(2) {
                display:block;
            }

            /* Set remaining to in stock text */
            div.ais-refinement-list--item {
                .ais-refinement-list--label {
                    font-size: 0;

                    .ais-refinement-list--checkbox::after {
                        top: 6px;
                    }

                    &::after {
                        content: 'In stock';
                        line-height: 1.75;
                        font-weight: 550;
                        font-size: 13px;
                        position: relative;
                        display: inline;
                        top: 3px;

                        &:hover {
                            color: $dte-bright-blue;
                        }
                    }
                }
            }
        }

        .sbx-sffv__wrapper {
            input {
                padding-right: 40px;
            }

            .sbx-sffv__reset {
                cursor: pointer;

                svg {
                    width: 10px;
                    height: 10px;
                }
            }
        }

        .ais-hierearchical-link-wrapper,
        .ais-refinement-list--item {
            padding: 0;
        }

        .ais-refinement-list--label,
        .ais-hierarchical-menu--link {

            font-size: 13px;
            color: $thunder;
            line-height: 1.75;
            padding: 0 30px 0 0;
            position: relative;
            font-weight: 550;
            text-transform: capitalize;

        }

        .ais-hierarchical-menu--item__active {

            .ais-hierarchical-menu--list {

                .ais-hierarchical-menu--link {

                    padding-left: 12px;
                    font-weight: 300;

                }

            }

        }

        .ais-hierarchical-menu--item__active > div > .ais-hierearchical-link-wrapper > .ais-hierarchical-menu--link,
        .ais-refinement-list--item__active > div > .ais-hierarchical-list--link,
        .ais-refinement-list--item__active > div > .ais-refinement-list--label,
        .ais-price-ranges--item__active > .ais-price-ranges--link {

            box-shadow: none;
            position: relative;
        }

        .ais-menu--count,
        .ais-hierarchical-menu--count,
        .ais-refinement-list--count {

            float: none;
            font-size: 12px;
            font-weight: 550;
            color: #949494;
            position: absolute;
            top: 2px;
            right: 10px;
            letter-spacing: -0.3px;

        }

        #instant-search-facets-container {

            .ais-refinement-list--count:before {

                content: '(';
                margin-right: 1px;

            }

            .ais-refinement-list--count:after {

                content: ')';
                margin-left: 1px;

            }

            .ais-refinement-list--header,
            .ais-range-slider--header {

                &:after {

                    content: '';
                    position: absolute;
                    right: 12px;
                    top: 0;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    font-size: 22px;
                    font-weight: 900;
                    pointer-events: none;
                    color: grey;
                    opacity: 0.4;

                }

                &.open {

                    &:after {

                        content: '-';
                        font-size: 37px;
                        font-weight: normal;

                    }

                }

                &.closed {

                    &:after {

                        content: '+';

                    }

                }

            }

        }

        .sbx-sffv__input {
            @extend .input__field;
        }

        .ais-refinement-list--checkbox {

            width: 15px;
            height: 12px;
            vertical-align: middle;
            margin-right: 6px;

            &:after {

                content: '';
                width: 16px;
                height: 16px;
                background-color: #fff;
                border: 1px solid #949494;
                position: absolute;
                z-index: 1;
                top: 3px;
                left: 0;
                pointer-events: none;
                transition: all 0.35s;
                cursor: pointer;

            }

        }

        .ais-refinement-list--label {

            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;

            &:hover {

               color: #0fb6ff;

                .ais-refinement-list--checkbox {

                    &:after {

                        background-color: $white;
                        border-color: #888888;

                    }

                }

            }

        }

        .ais-refinement-list--item {

            padding: 2px 0;

            &:hover {
                text-decoration-color: #0fb6ff;

            }

            &__active {

                .ais-refinement-list--label {

                    .ais-refinement-list--checkbox {


                        &:after {

                            content: '\2713';
                            color: #0fb6ff;
                            background-color: #fff;
                            border-color: #0fb6ff;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            font-size: 13px;
                            font-family: $font-family-proxima-nova;
                            font-weight: 900;
                            padding-top: 3px;

                        }


                    }

                    &:hover {

                        .ais-refinement-list--checkbox {

                            &:after {

                                background-color: $white;
                                background-image: none;
                                border-color: #0fb6ff;
                                cursor: pointer;

                            }

                        }

                    }

                }

            }

        }

        .ais-refinement-list--list {

            overflow: auto;
            max-height: 272px;


            .sbx-sffv__wrapper {

                position: relative;
                width: auto;
                margin: 0 0 15px;

                .sbx-sffv__input {

                    height: 38px;
                    font-size: 14px;

                }

            }

            .sbx-sffv__reset {

                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: 12px;
                margin: 2px 0 0;
                z-index: 1;
                width: auto;
                padding: 0 5px;

            }

            .sbx-sffv-disabled {
                .sbx-sffv__reset {
                    svg {
                        stroke: $gallery-grey;
                    }
                }
            }

        }

        .ais-current-refined-values--list {

            .ais-current-refined-values--item {

                margin: 0 5px 1px;

                .ais-current-refined-values--link {

                    color: $thunder;
                    border: none;
                    padding: 0;
                    height: auto;
                    background: none;

                }

                .cross-wrapper {

                    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAeCAYAAABNChwpAAAABGdBTUEAALGPC/xhBQAAAe5JREFUSA21lz1OwzAYhj+npVKZYACJhVIShiqUO4DEwEZnJG7AzMLGDbgChwAxlDsgUAVKVJiQYGEBgprU5E1xldL82E7iJZFT+3nsOq9jRmGxNto3nPN9RvRAC/Vdx3HeUV922Wq1L8bET4iR12g2NweDwasRQeq1IyJ2z4lsGvm3lmWtVAVnjP0Q1XqAgxEOelIA5aOgT8S3y54JMXLAORmH7rN7LbhTAVRUIZEFB3NGoGyJPHiiQFkSMvBUgaISsvBMAV0JFXiugKqEKlxKQEggH5ATaa+oDlxaIE9CF64kkCSxWK/tffvjM8RrUsigTV6Zy4G8BlFM//0dYYp8EKclXThYygJotGOaq59B8Ah41InBjp3h8BL3qmWyGSm2wrQLeNR0zE8hpdhN9HNlgfiCY+HI8Vbg7fjyg76OhJJAHG5wo4dpx0IsIiG9Bv7Dn17cKzHlGDlmQOQEpO5c9008z7pKCWTBRee6ErkCMvAiEpkCKnBdiVQBHXiaRNaHbqJAEbiqxJxAGXAViRmBMuFCIr53hLC5c8dUoAq4jESUhGarfS62VCRcPGREJ0Wu0UkrPHFhBhBWOH/Ytt1AnyKKD7ClVgEX4pBAQuIEFh5+bM/z1sUz6na7y51OZ21aUeENRm6aplUhQq3rX6og3MKyiFGQAAAAAElFTkSuQmCC');
                    background-size: 18px auto;
                    background-repeat: no-repeat;
                    background-position: center;
                    background-color: transparent;

                }

                .clear-cross {
                    display: none;
                }

            }

        }

        .ais-current-refined-values.facet {

            //background-color: $nearly-white;

            .name {
                //background-color: $nearly-white;
            }

            .ais-current-refined-values--body {

                padding: 17px 0 0 0;

                > div {

                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap-reverse

                }

            }

            .ais-current-refined-values--list {

                display: flex;
                width: 100%;
                flex-wrap: wrap;
                margin: 0 -5px;
                justify-content: space-between;

            }

            .ais-current-refined-values--clear-all {

                display: inline-flex;
                justify-content: center;
                cursor: pointer;
                height: 48px;
                min-width: 100px;
                border-radius: 100px;
                outline: none;
                font-size: 18px;
                font-weight: 400;
                line-height: 1;
                vertical-align: middle;
                align-items: center;
                position: static;
                padding: 11px 20px;
                border: 1px $thunder solid;
                background: #fff;
                color: $thunder;
                margin-top: 15px;

            }

        }

        #instant-search-facets-container {

            > * {

                margin: 0;

            }

        }

    }

    .ais-root:not(.ais-current-refined-values) {

        .ais-body {

            max-height: 270px !important;
        }

        .ais-header {

            position: relative;
            margin: 0 !important;

            .caret-icon {

                width: 24px;
                height: 24px;
                background-size: 100% 100%;
                background-position: center;
                display: inline-block;
                position: absolute;
                right: 10px;
                top: 50%;
                transform: translateY(-50%);
                transition: transform 0.29s, background-size 0.15s, opacity 0.25s;
                background-image: url("data:image/svg+xml,%3Csvg height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m8.93279275 4.54234436 7.49903225 7.49903224-7.49903225 7.4983052' fill='none' stroke='%23231f20' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
                opacity: 0.45;

            }

            .plus-icon {

                &:after {

                    content: "+";
                    width: 20px;
                    text-align: center;
                    height: auto;
                    display: inline-block;
                    position: absolute;
                    right: 10px;
                    top: 50%;
                    transform: translate(0px, -50%);
                    font-size: 22px;
                    color: #d8d8d8;

                }

            }

            &.closed {

                .caret-icon {
                    transform: translateY(-50%) rotate(0deg);
                }

                .plus-icon {
                    &:after {
                        content: none;
                    }
                }

            }

            a {

                display: block;
                color: inherit;
                text-decoration: none;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;

                &:hover {
                    color: inherit;
                }

                &:active {

                    .caret-icon {

                        background-size: 111% 111%;
                        opacity: 1;

                    }

                }

            }

            &.open,
            &:hover {

                .caret-icon {
                    transform: translateY(-50%) rotate(90deg);
                }

            }

            &.open {

                .caret-icon {
                    opacity: 1;
                }

            }

        }

    }

    .algolia-instantsearch-full-searchinput-container {

        background-color: #fff;
        margin: 25px 0 -12px;

        #instant-search-bar-container {

            max-width: 768px;
            margin: 0 auto;
            width: 100%;

        }

        #instant-search-bar {
            padding-left: 45px;
            padding-top: 0;
            padding-bottom: 0;
            height: 34px;
            border-bottom-width: 3px;
            border-color: #e0e0e0;
            line-height: 1;
        }

    }

    .ais-search-box--reset {

        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 10px;

        svg {

            stroke: none;
            fill: $black;

        }

    }

    .button-search-inline-submit {

        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 10px;

    }

    .ais-search-box--magnifier-wrapper {
        display: none;
    }

    .catalog-list-item,
    .catalog-grid-item {

        opacity: 0;
        transition: opacity 0.35s;

    }

    &.instantsearch-view-mode-list {
        #algolia-right-container {
            #mode-list {
                img {
                    opacity: 1;
                }
            }
        }
    }

    &.instantsearch-view-mode-grid {
        #algolia-right-container {
            #mode-grid {
                img {
                    opacity: 1;
                }
            }
        }
    }

    &.instantsearch-view-mode-list {

        #instant-search-results-container {

            .ais-hits--item {

                flex-basis: 100%;
                max-width: 100%;

                .catalog-list-item {

                    display: block !important;
                    width: 100%;
                    opacity: 1;

                    @media all and (max-width: 767px) {

                        display: none !important;

                    }

                }

                .catalog-grid-item {

                    display: none !important;

                    @media all and (max-width: 767px) {

                        display: block !important;

                    }

                }

                + .ais-hits--item {

                    .catalog-list-item {

                        border-top: none;

                    }

                }

            }

        }

    }

    &.instantsearch-view-mode-grid {

        #instant-search-results-container {

            margin-left: 1px;
            margin-top: 1px;

            .ais-hits--item {

                margin-top: -1px;
                margin-left: -1px;
                border: 1px $gallery-grey solid;

                .catalog-list-item {

                    display: none !important;

                }

                .catalog-grid-item {

                    display: block !important;
                    opacity: 1;

                    .catalog-grid-item-inner {
                        border: none;
                    }

                }

            }

        }

    }

    &.filters-hidden {

        &.instantsearch-view-mode-grid {

            #instant-search-results-container {

                .ais-hits--item {


                }

            }

        }

    }

    #current-refinements {

        > div {

            display: block !important;

        }

        .ais-current-refined-values--body {

            padding-top: 17px;

            .current-refinement-wrapper {

                border: 1px solid #e0e0e0;
                border-radius: 14px;
                padding: 5px 27px 5px 9px;
                display: inline-block;
                height: auto;
                width: 100%;
                overflow: visible;
                font-size: 13px;
                line-height: 16px;
                position: relative;

                &.Stock{
                    .current-refinement-name {
                        display:none;
                    }
                }

                &:after {

                    content: '×';
                    position: absolute;
                    top: 6px;
                    right: 10px;
                    font-size: 19px;
                    font-weight: bold;
                    color: #0ab6ff;

                }

                span {

                    color: #959595;

                }

                &.Stock.Status {
                    display: flex;
                    align-items: center;
                    gap: 0 5px;

                    .current-refinement-label.Stock.Status {
                        + .current-refinement-name {
                            font-size: 0;
                            display: flex;

                            &::after {
                                content: 'In Stock';
                                display: inline;
                                font-size: 13px;
                                font-weight: 500;
                                position: relative;
                                top: 1px;
                            }
                        }
                    }
                }

            }

        }

        .ais-current-refined-values--header {

            .name {

                font-size: 18px;

            }

        }

        .ais-current-refined-values,
        .ais-current-refined-values--header .name {
            background-color: #fff;
            padding-left: 0;

            &--body {

                padding-left: 0;

                .cross-wrapper {

                    display: none;

                }

            }
        }

        .ais-current-refined-values {
            margin: 0;
        }

        .ais-current-refined-values--link:hover .current-refinement-wrapper {
            text-decoration: none !important;
            background-color: rgba(10, 182, 255, 0.2);
        }

        @media all and ( max-width: 991px ) {

            width: 85%;

        }

    }

    .catalog-list-item__stock-status {
        font-size: 16px;
    }

    #instant-search-results-container .product-item .catalog-list-item__actions-secondary .secondary-addto-links .button--icon svg.button__icon use {

        stroke: #fff!important;

    }

    .ais-range-slider {

        .ais-range-slider--handle-upper {

            .ais-range-slider--tooltip {

                text-align: right;
                left: auto;
                right: 0;

            }

        }

        .ais-range-slider--handle-lower {

            .ais-range-slider--tooltip {

                margin-left: 10px;

            }

        }

        .rheostat {

            margin-top: 1em;
            margin-bottom: 35px;
            width: 94%;
            margin-left: 9px;

        }

        .rheostat-horizontal {

            .rheostat-progress {

                background-color: #e0e0e0;

            }

        }

        .ais-range-slider--handle {

            border-radius: 2px;
            width: 18px;
            height: 18px;
            top: -6px !important;
            background-color: #0AB6FF;
            border-color: #0AB6FF;

            .ais-range-slider--tooltip {

                top: unset;
                bottom: -2em;
                font-size: 13px;
                font-weight: 600;

            }

            &:after {

                content: "\2039\00a0\203A";
                color: #fff;
                font-size: 13px;
                font-weight: 600;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100%;

            }

        }

        .ais-range-slider--marker-large {

            display: none;

            &:last-child {

                .ais-range-slider--value {

                    text-align: right;
                    right: -7px;

                }

            }

        }

    }

    @media all and (min-width: 1120px) and (max-width: 1330px) {

        &.instantsearch-view-mode-grid {

            #instant-search-results-container {

                .ais-hits--item {

                    width: 50% !important;

                    .catalog-grid-item__primary-action,
                    .catalog-grid-item .dte-add-toquote-container,
                    .catalog-grid-item .dte-add-toquote-container .button {

                        width: 100%;
                        max-width: 100%;
                        margin: 0;

                    }

                    .catalog-grid-item__primary-action + .dte-add-toquote-container {
                        margin-top: 10px;
                    }

                }

            }

        }

    }

    @media all and (min-width: 1130px) {

        .category-toolbar-top-parent-container {

            margin-left: 10px;
            margin-right: 10px;

        }

    }

    @media all and (min-width: 993px) and (max-width: 1120px) {

        &.instantsearch-view-mode-grid {

            #instant-search-results-container {

                .ais-hits--item {

                    width: 50% !important;

                }

            }

        }

    }

    @media all and (min-width: 993px) {

        #algolia_instant_selector {

            max-width: 1508px;
            margin-left: auto;
            margin-right: auto;

            #algolia-left-container {

                flex-basis: auto;
                width: 330px;
                padding: 0 25px 0 30px;

            }

            #algolia-right-container {

                flex-basis: auto;
                max-width: calc(99% - 330px);
                width: 100%;

            }

            > .row {

                margin-left: 0;
                margin-right: 0;

            }

        }

        &.filters-hidden {

            #algolia_instant_selector {

                #algolia-right-container {
                    max-width: 100%;
                }

            }

        }

        &.instantsearch-view-mode-grid {

            #instant-search-results-container {

                .ais-hits--item {

                    flex-basis: auto;
                    width: 300px;
                    max-width: none;

                    .catalog-grid-item-inner {
                    }

                }

            }

        }

    }

    @media all and (min-width: 1063px) {

        #algolia_instant_selector {

            #algolia-left-container {

                width: 400px;

            }

            #algolia-right-container {

                max-width: calc(99% - 400px);

            }

        }

    }

    @media all and (min-width: 931px) and (max-width: 992px) {


        &.instantsearch-view-mode-grid {

            #instant-search-results-container {

                .ais-hits--item {

                    flex-basis: auto;
                    max-width: 33.333333%;

                }

            }

        }

    }

    @media all and (min-width: 768px) {

        &.instantsearch-view-mode-list {

            #instant-search-results-container {

                .ais-hits--item {

                    .catalog-list-item__stock-status {

                        margin: 0;

                    }

                }

            }

        }

    }

    @media all and (max-width: 992px) {

        #maincontent {
            margin: 0;
        }

        #algolia_instant_selector {

            #instant-search-facets-container {

                &.filters-open {

                    display: block !important;
                    position: fixed;
                    top: 215px;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    margin: 0;
                    overflow-y: auto;
                    z-index: 100;
                    background: #fff;

                }

                .filter-actions-container {

                    .filter-actions--col {

                        .button {

                            padding: 3px 6px;
                            font-size: 14px;
                            height: 38px;

                        }

                    }

                }

            }

        }

        .top-navigation-categories-container {

            .mobile-button-container {

                /* margin: 8px 0 !important; */
                padding-left: 0 !important;
                padding-right: 0 !important;

                &:before,
                &:after {

                    display: block;
                    clear: both;
                    content: "";

                }

                .button {

                    float: left;
                    width: 45% !important;
                    margin: 0 2%;

                    &.button-toggle-filters-mobile {

                        float: right;
                    }

                }

            }

        }

        #instant-search-results-container {

            margin: 10px 1px;

            .ais-hits {

                justify-content: center;
                padding: 13px 10px;

            }

        }

        .category-sidebar-toggle {

            &.category-sidebar-toggle-hide {

                z-index: 3;
                position: absolute;
                top: 0;
                left: 0;

            }

        }

        #algolia-left-container {

            display: none;
            position: fixed!important;
            top: 0;
            bottom: 0;
            left: -100%;
            width: 100%;
            min-height: 100vh;
            transition: 200ms left ease-in-out;

            &.mobile-filter-active {

                display: block!important;
                z-index: 999!important;
                margin: 0 !important;
                padding: 0 !important;
                left: 0;

                > #instant-search-facets-container {

                    display: block!important;

                }

            }

            > #current-refinements {

                .ais-current-refined-values {

                    padding-left: 15px;

                }

            }

            > #instant-search-facets-container {

                height: 100vh;
                overflow-y: scroll;
                padding-bottom: 100px;
                padding-left: 15px;
                background-color: #fff;
                width: 85%;
                margin-top: 0;

            }

            &:before {

                content: '';
                background: rgba(68, 68, 68, 0.70);
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                z-index: -1;
                width: 100vw;

            }

        }

        .algolia-left-container-inner {

            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;

        }

        #algolia_instant_selector {

            .facet {

                .name {
                    padding: 13px 10px;
                }

            }

        }

        .hero-slider-container {

            .custom-slider {
                padding: 0;
            }

        }

        .category-hero-with-img {
            margin-bottom: 0;
        }

        .toolbar__limiter {
            display: none;
        }

        .toolbar__amount {

            display: block;
            margin: 0;
            position: absolute;
            top: 55px;
            left: 0;
            right: 0;
            text-align: right;

            .button {

                background-color: transparent;
                border: none;
                padding: 0;
                height: auto;

            }

        }

        .toolbar__sorter {

            position: absolute;
            top: 0;
            right: 0;
            margin: 0;
            padding: 0;
            border: none;
            width: 140px;

        }

        .toolbar__mode {

            display: block;
            position: absolute;
            right: 0;
            top: 0;

            .toolbar__mode-button {

                background-color: transparent;
                padding-left: 0;
                padding-right: 0;

                + .toolbar__mode-button {

                    margin-left: 10px;
                    padding-right: 0;

                }

            }

        }

        .toolbar__sorter-icon-wrapper {
            display: none;
        }

        /*.category-toolbar-top-parent-container {

            background-color: $nearly-white;
            margin: 0 0 0;
            padding: 10px 10px 40px;
            min-height: 98px;

        }*/

        .algolia-instantsearch-full-searchinput-container {

            padding: 15px 10px;

            .toolbar__mode {

                display: none!important;

            }

        }

        .category-sidebar-toggle {

            .category-sidebar-toggle--desktop-title {
                display: none;
            }

            .category-sidebar-toggle--mobile-title {
                display: inline !important;
            }
        }

        .catalog-list__sidebar {

            position: absolute;
            left: 0;
            display: none;
            margin-top: 68px;
            width: 90%;
            z-index: 5;

            .filters__switcher {
                display: none;
            }

            .filters__list {

                padding: 5px 20px;

                &:before {

                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    width: 100vw;
                    bottom: 0;
                    z-index: -1;
                    background: rgba(68, 68, 68, 0.70);

                }

            }

        }

        &.instantsearch-view-mode-list {

            #instant-search-results-container {

                .ais-hits--item {
                    padding: 0;
                }

            }

        }

    }

    @media all and (max-width: 767px) {

        #algolia_instant_selector .filter-actions-container {
            padding: 0 10px;
        }

        .category-hero-with-img {

            .custom-slider-item {

                .custom-slider-item-inner {
                    min-height: 0;
                }

            }

        }

        &.instantsearch-view-mode-list {

            #instant-search-results-container {

                .ais-hits--item {

                    .catalog-list-item__right-col {
                        position: static;
                    }

                    .catalog-list-item__img-col {

                        padding-right: 0;
                        padding-left: 25px;

                    }

                    .catalog-list-item__price .price {

                        bottom: 15px;
                        right: 6px;

                    }

                    .catalog-list-item__primary-form {

                        display: flex;
                        margin: 0 -8px;

                        .button__icon {

                            display: inline-block;
                            width: 18px;
                            height: 18px;
                            margin-right: 1px;

                        }

                    }

                    .catalog-list-item__primary-action,
                    .dte-add-toquote-container {

                        display: inline-flex;
                        margin: 0;
                        width: auto;
                        max-width: none;
                        min-width: 0;

                    }

                    .catalog-list-item__primary-action,
                    .dte-add-toquote-container .button {

                        vertical-align: top;
                        height: 32px;


                    }

                    .catalog-list-item__price {

                        .price-wrapper {

                            font-size: 20px;
                            font-weight: bold;

                        }

                    }

                    .catalog-list-item__stock-status {
                        margin: 0;
                    }

                    .dte-add-toquote-container {

                        margin-left: 2%;

                        .button {

                            width: 100%;
                            min-width: 60px;
                            max-width: 85px;
                            padding-left: 12px;
                            padding-right: 12px;

                        }

                    }


                }

            }

        }

        &:not(.filters-hidden) {

            // Begin new mobile filtering expirement

            &.mobile-filters-ready {

                overflow: hidden;

                .page-wrapper {

                    max-height: 0;
                    overflow: hidden;
                    min-height: 0;

                }

                #algolia-left-container {

                    background: #fff;
                    display: block;
                    height: auto !important;
                    position: fixed !important;
                    top: 66px;
                    bottom: 0;
                    overflow: scroll;
                    z-index: 998;
                    pointer-events: all;

                }

                .category-toolbar-top-parent-container {

                    position: fixed;
                    top: 67px;
                    z-index: 999;
                    left: 0;
                    right: 0;
                    padding: 10px;
                    min-height: 68px;

                    .toolbar__amount,
                    .toolbar__mode {
                        display: none;
                    }

                }

            }

            // End new mobile filtering experiment.

        }

    }

    @media all and (min-width: 628px) and (max-width: 930px) {

        &.instantsearch-view-mode-grid {

            #instant-search-results-container {

                .ais-hits--item {

                    flex-basis: 50% !important;
                    max-width: 50% !important;

                }

            }

        }

    }

    @media all and (max-width: 627px) {

        &.instantsearch-view-mode-grid {

            #instant-search-results-container {

                .ais-hits--item {

                    flex-basis: 100% !important;
                    max-width: 350px !important;

                }

            }

        }

    }

}

.dte-add-toquote-container,
.dte-add-toquote-container .dte-logged-in,
.dte-add-toquote-container .dte-notlogged-in {
    display: none;
}

.category-sidebar-categories-container {

    .category-sidebar-categories--content {

        max-height: 0px;
        overflow-y: scroll;
        transition: max-height 0.75s;

    }

    .category-sidebar-categories--inner {

        > a {

            display: block;
            font-size: 20px;
            font-weight: 600;
            line-height: 1.33;
            color: #231f20;
            text-transform: none;
            padding: 17px 0;
            background-color: #fff;
            border-bottom: 1px #231f20 solid;
            text-decoration: none;
            position: relative;

            &.open,
            &:hover {

                text-decoration: none;

                .caret-icon {
                    transform: translateY(-50%) rotate(90deg);
                }

            }

            &.open {

                .caret-icon {
                    opacity: 1;
                }

                + .category-sidebar-categories--content {
                    max-height: 100%;
                    overflow: visible;
                    -ms-overflow-style: none;
                    scrollbar-width: none;
                }


            }

            &.closed {

                .caret-icon {
                    transform: translateY(-50%) rotate(0deg);
                }

            }

            .caret-icon {

                width: 24px;
                height: 24px;
                background-size: 100% 100%;
                background-position: center;
                display: inline-block;
                position: absolute;
                right: 10px;
                top: 50%;
                transform: translateY(-50%);
                transition: transform 0.29s, background-size 0.15s, opacity 0.25s;
                background-image: url("data:image/svg+xml,%3Csvg height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m8.93279275 4.54234436 7.49903225 7.49903224-7.49903225 7.4983052' fill='none' stroke='%23231f20' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
                opacity: 0.45;

            }

        }

    }

    ul {

        padding: 0;
        margin: 0;
        list-style: none;
        display: none;

        &.level-0--container {

            display: block;
            margin: 10px 0 0;

        }

        li {

            padding: 0 0 0 10px;
            margin: 0;
            position: relative;

            a {

                display: block;
                padding: 0 20px 0 0;
                font-weight: 300 !important;
                font-size: 16px;
                color: #231f20;
                line-height: 1.75;
                position: relative;

            }

            > .expand {

                display: block;
                position: absolute;
                right: 5px;
                top: 4px;
                z-index: 5;

                .button__icon {

                    display: none;
                    width: 22px;
                    height: 22px;

                }

            }

            &.active {

                > a {
                    font-weight: 600 !important;
                }

                > .expand {

                    .button__icon {

                        &.expanded-icon {
                            display: block;
                        }

                    }

                }

            }

            &:not(.active) {

                > .expand {

                    .button__icon {

                        &.expand-icon {
                            display: block;
                        }

                    }

                }

            }

        }

    }

    /*.level-0--container .level1:first-child > a {
        font-weight: 300!important;//reset for inline styling override.
    }*/

}

.catalog-grid-item__custom-label {

    border: 1px solid $dark-sky-blue;
    font-size: 12px;
    color: $dark-sky-blue;
    letter-spacing: 0;
    text-align: center;
    border-radius: 3px;
    display: inline-block;
    padding: 5px 10px 6px;
    line-height: 12px;
    position: absolute;
    top: 10px;
    z-index: 1;

}

body.quote-list-unauthenticated {

    .dte-add-toquote-container,
    .dte-add-toquote-container .dte-notlogged-in {
        display: block;
    }

}

body.quote-list-authenticated {

    .dte-add-toquote-container,
    .dte-add-toquote-container .dte-logged-in {
        display: block;
    }

}


@media all and (min-width: 992px) {

    .catalog-category-view {

        #algolia_instant_selector {

            .is-widget-container-categories {

                .ais-body {
                    display: block;
                    max-width: 280px;
                }
            }

            .ais-hierarchical-menu--count {
                top: 4px;
            }
        }
    }


    //.catalog-category-view {
    //
    //    #algolia_instant_selector {
    //
    //        #instant-search-facets-container {
    //
    //            margin: 20px -10px 0;
    //
    //            &:before,
    //            &:after
    //            {
    //
    //                display: block;
    //                clear: both;
    //                content: "";
    //
    //            }
    //
    //            > * {
    //
    //                margin: 0;
    //
    //                &.filter--is-active {
    //
    //                    float: left;
    //                    width: 20%;
    //                    padding: 0 10px;
    //
    //                    &.filter--is-top-row {
    //
    //                        .facet {
    //
    //                            .name {
    //                                border-top: 1px $thunder solid;
    //                            }
    //
    //                        }
    //
    //                    }
    //
    //                    &:nth-child(6n) {
    //
    //                        .facet.ais-root:not(.ais-current-refined-values) .ais-body {
    //
    //                            left: auto;
    //                            right: 0;
    //
    //                        }
    //
    //                    }
    //
    //                }
    //
    //                &.filter--clear-left {
    //                    clear: left;
    //                }
    //
    //                &:not(.filter--is-active),
    //                &:not(.filter--is-visible)
    //                {
    //                    display: none;
    //                }
    //
    //                &.filter-actions-container {
    //
    //                    float: left;
    //                    width: 20%;
    //                    padding: 0 10px;
    //
    //                }
    //
    //            }
    //
    //        }
    //
    //        .facet {
    //
    //            .name {
    //
    //                color: #231F20;
    //                font-size: 14px;
    //                font-weight: 600;
    //                line-height: 1.35;
    //                padding: 8px 0;
    //                border-bottom: 1px $thunder solid;
    //
    //            }
    //
    //        }
    //
    //        .facet.ais-root:not(.ais-current-refined-values) {
    //
    //            position: relative;
    //
    //            .ais-body {
    //
    //                position: absolute;
    //                left: 0;
    //                right: 0;
    //                top: 100%;
    //                z-index: 5;
    //                padding: 15px;
    //                background-color: #FFFFFF;
    //                width: 400px;
    //                max-height: 500px;
    //                overflow-y: auto;
    //
    //            }
    //
    //            .ais-header {
    //
    //                .caret-icon {
    //
    //                    transform: translateY(-50%);
    //                    background-image: url("data:image/svg+xml,%3Csvg height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m19.99965 12.1428571h-16.49965' fill='none' stroke='%23231f20' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
    //
    //                }
    //
    //                &.closed {
    //
    //                    .caret-icon {
    //                        background-image: url("data:image/svg+xml,%3Csvg height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd' stroke='%23231f20' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='m20.49965 12h-16.49965'/%3E%3Cpath d='m20.49965 12h-16.49965' transform='matrix(0 -1 1 0 .5 24.5)'/%3E%3C/g%3E%3C/svg%3E");
    //                    }
    //
    //                }
    //
    //                &:hover {
    //
    //                    .caret-icon {
    //                        background-image: url("data:image/svg+xml,%3Csvg height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m19.99965 12.1428571h-16.49965' fill='none' stroke='%23231f20' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
    //                    }
    //
    //                }
    //
    //                &.open {
    //
    //                    ~ .ais-refinement-list--body {
    //                        box-shadow: 0 1px 4px 0 rgba(163,163,163,0.5);
    //                    }
    //
    //                }
    //
    //            }
    //
    //
    //        }
    //
    //    }
    //
    //}

}

@media all and (min-width: 992px) and (max-width: 1380px) {

    .catalog-category-view {

        #algolia_instant_selector {

            #instant-search-facets-container {

                > * {

                    &.filter--is-active {

                        .facet.ais-root:not(.ais-current-refined-values) .ais-body {

                            left: auto;
                            right: 0;
                            width: 100%;

                        }

                    }

                    &.filter-actions-container {
                        width: 25%;
                    }

                }

            }

        }

    }

}

@media all and (min-width: 992px) and (max-width: 1250px) {

    .catalog-category-view {

        #algolia_instant_selector {

            #instant-search-facets-container {

                > * {

                    &.filter-actions-container {

                        .filter-actions--col {

                            float: none;
                            width: auto;
                            padding: 0;

                            + .filter-actions--col {
                                margin-top: 7px;
                            }

                        }

                    }

                }

            }

        }

    }

}

@media all and (min-width: 768px) and (max-width: 992px) {

    .catalog-list-item {

        .catalog-grid-item__custom-label {

            top: 7px;
            left: 10px;

        }

    }

}

@media all and (max-width: 767px) {

    .catalog-list-item {

        .catalog-grid-item__custom-label {

            top: 7px;
            left: 88px;

        }

    }

}

// New custom styles on top of new pahse 2 build to fix amazon approved top category filter positioning / styles

.amazon-store {

    #algolia-right-container .algolia-instantsearch-full-searchinput-container #instant-search-bar-container {

        max-width: none;

        .instant-search-bar-wrapper {

            display: flex;
            justify-content: space-between;

            > .ais-search-box {

                width: 100%;

            }

            li.amazon-approved__switcher {

                display: flex;
                align-items: center;
                justify-content: flex-end;

                #item--amazon-approved {

                    background-color: #fff;

                }

                .amazon-approved-img {

                    width: 100%;

                }

                .custom-switch--amazon-approved-toggler {

                    right: 0;

                }

                label.custom-control-label {

                    display: inline-block;

                }

            }

        }

    }
}

.algolia-category-view-hits-container {

    #mobile-filter-button-container {

        @media all and ( min-width: 991px ) {

            display: none;

        }

        button {

            font-family: "Proxima Nova", "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
            color: #333;
            background-color: #fff;
            padding: 15px 50px;
            width: auto;
            font-size: 16px;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 34px;
            margin: 10px 0 -6px 10px;
            box-sizing: border-box;
            border: 1px solid #e0e0e0;
            border-radius: 4px;
            -webkit-appearance: none;
            border-bottom-width: 3px;

        }

    }

    .mobile-filter-close-container {

        position: fixed;
        top: 10px;
        right: 0;
        width: 15%;
        text-align: center;

        @media all and ( min-width: 991px ) {

            display: none;

        }

        .mobile-filter-close-btn {

            font-size: 22px;
            border-radius: 50%;
            padding: 6px 13px 8px 13px;
            background-color: #fff;
            border: none;

        }

    }

}

.stock-qty-in-stock,
.stock-qty-oos {
    display: flex;
    align-items: center;
    line-height: normal; // Fix for Y alignment

    img {
        margin-right: 5px;
        width: 18px !important;
    }

    &.in-cart{
        float: left;
        padding: 11px 20px;
    }
}


.quotelist-account-view {
    table .stock-status-inner {
        padding-left: 3px;
    }
}
.stock-qty-oos {
    color: $highlight-orange !important;

    &.stock-status-inner {
        width: max-content;
    }

    .backorder-stock-dropdown-container {
        position: relative;

             .stock-dropdown-btn {
                display: block;
                cursor: pointer;
                height: 7px;
                width: 7px;
                border-style: solid;
                border-color: inherit;
                color: inherit;
                border-width: 0px 1px 1px 0px;
                transform: rotate(45deg);
                transition: all ease-in-out 0.2s;
                margin-left: 1rem;
                position: relative;
                top: -1px;

                &.open {
                     ~ .dropdown-content {
                        display: block
                    }
                }
            }


        .dropdown-content {
            display: none;
            position: absolute;
            background: white;
            box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.35);
            z-index: 10;
            padding: 2rem;
            width: 330px;
            right: -60px;
            top: 30px;

            &::before {
                content: "";
                display: block;
                position: absolute;
                border-top: 12px solid transparent;
                border-bottom: 12px solid transparent;
                border-right: 12px solid white;
                transform: rotate(90deg);
                bottom: unset;
                top: -18px;
                right: 57.5px;
            }

            &::after {
                content: '';
                position: absolute;
                width: 100%;
                height: 20px;
                left: 0;
                top: -20px;
            }

            h4 {
                font-weight: 600;
                font-size: 17px;
            }

            p {
                font-size: 14px;
                color: #686868;

                &:first-of-type {
                    margin-bottom: 10px;
                }

                &:last-of-type {
                    margin-bottom: 0;
                }

                a {
                    font-weight: 500;
                }
            }
        }
    }
}

/* Quote Page Adjustments */
@media all and (max-width: 767px) {
    .quotelist-account-view .stock-qty-oos .backorder-stock-dropdown-container .dropdown-content {
        width: 280px;
        left: -180px;
        right: unset;

        &::before {
            right: 77px;
        }
    }
}

    /* Category Page Adjustments */
.catalog-category-view {
    #algolia-right-container {
        .product-item {
            overflow: visible;
        }

        .stock-qty-oos {
            .backorder-stock-dropdown-container {
                .dropdown-content {
                    @media all and (max-width: 992px) {
                        right: unset;
                        left: -90px;
                        width: 280px;

                        &::before {
                            right: unset;
                            left: 101px;
                        }
                    }
                }
            }
        }
    }
}

/* Product Page Adjustments */
.catalog-product-view {
    .product-view.container {
        .stock-qty-oos {
            .backorder-stock-dropdown-container {
                .dropdown-content {
                    @media all and (max-width: 450px) {
                        right: unset;
                        left: -90px;
                        width: 280px;

                        &::before {
                            right: unset;
                            left: 101px;
                        }
                    }
                }
            }
        }
    }
}

/* Slider Adjustment Fixes */
.owl-item {
    .product-bottom-section-container {
        .stock-qty-oos {
            .backorder-stock-dropdown-container {
                .stock-dropdown-btn {
                    top: 2px;
                    transform: rotate(-135deg);
                }

                .dropdown-content {
                    top: -230px;
                    right: -100px;

                    &::before {
                        top: unset;
                        bottom: -18px;
                        right: 90px;
                        transform: rotate(270deg);
                    }
                }
            }
        }
    }
}

/* Wishlist Page Adjustments */
.wishlist-index-index {
    .catalog-grid-item {
        overflow: visible;

        .stock-qty-oos {
            .backorder-stock-dropdown-container {
                .dropdown-content {
                    @media all and (max-width: 991px) {
                        right: unset;
                        left: -130px;
                        width: 270px;

                        &::before {
                            right: unset;
                            left: 130px;
                        }
                    }
                }
            }
        }
    }
}
