.cms-page-view {

  h1.heading--page {

    font-size: 30px;
    text-transform: capitalize;
    margin-top: 10px;

  }

  h2 {

    padding-top: 15px;
    margin-top: -15px;
    font-size: 26px;

  }

  p:not(.heading) {

   font-size: 15px;
   line-height: 26px;

  }

}
.cms-delivery {
    .delivery-main {
        font-size: 14px;
        line-height: 22px;
        padding: 20px 80px;

        @media all and (max-width: 991px) {
          padding:10px;
        }

        .aim {
            padding-bottom: 20px;
            .please-note {
                padding-top: 20px;
            }
        }
        .delivery-cost {
            padding-bottom: 20px;
        }


    }

}

.cms-returns {
    .returns-main{
        font-size: 14px;
        line-height: 22px;
        padding: 20px 80px;

      @media all and (max-width: 991px) {
        padding:10px;
      }
        h5 {
            font-weight: 600;
            line-height: 32px;
            margin: 0;
        }
        .submit-returns,
        .returns-request,
        .returns-discrepancies {
            padding-top: 15px;
        }

    }
}

.cms-noroute-index {

  h1.heading--page {
    display: none;
  }

    h1 {
        margin-top: 140px;
        font-size: 160px;
        margin-bottom: 20px;
        color: #fff;
    }

    .main-header {
        margin-top: 112px;
        color: #303030;
        h2 {
            font-size: 40px;
            font-weight: bold;
            line-height: 32px;
        }
        h3 {
            font-size: 28px;
            font-weight: bold;
            line-height: 32px;
        }

    }
    .main-message {
        margin-top: 15px;
       span {
            display: block;
           a {
               font-weight: bold;
           }
        }
    }




  .page-wrapper {
    background-image: url('../images/backgrounds/404-bg.jpg');
    background-size: cover;
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
    opacity: 0.9;
    background-color: #F1F8FB;

  }

  #maincontent {
    max-width: 800px;
    text-align: center;
    margin-bottom: 70px;

    .button--secondary-white {
      margin: 30px 20px 20px 20px;
    }

    .page-logo {
      margin: auto;
      margin-top: 80px;
      width: 100px;
      height: auto;
    }
  }

  .footer {
    margin-top: 0;
  }


}



// Custom CMS Blocks
.cms-page-view,
.catalog-category-view {
  .help-and-support-container {

    .content-container {
      background: #526b79;
      padding: 30px;

      .content-title {
        font-size: 22px;
        line-height: 27px;
        color: white;
        margin-bottom: 10px;
      }

      .content-text {
        margin-right: 15px;
        margin-bottom: 20px;
        font-size: 14px;
        line-height: 23px;
        color: white;
      }

      .buttons-group {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        .button {
          flex-basis: 48%;
          text-align: center;
          padding: 0 10px;

          a {
            color: white;
            font-weight: bold;

            &:hover,
            &:focus {
              color: white;
              text-decoration: none;
            }
          }

          @media all and (max-width: 700px) {
            flex-basis: 100%;

            &:first-of-type {
              margin-bottom: 10px;
            }
          }
        }
      }
    }
  }
}

.contact-banner {
  background: #f1f8fb;
  padding: 70px 107px;
  display: flex;
  flex-wrap: wrap;

  @media all and (max-width: 1023px) {

    padding: 70px 5%;

  }

  .left-block {
    flex-basis: 100%;
    display: flex;
    justify-content: center;

    @media all and (max-width: 1023px) {

      justify-content: flex-start;
      margin-left: 13px;

    }

    .content-block {
      max-width: 450px;

      .content-title {
        font-size: 28px;
        font-weight: bold;
        margin-bottom: 30px;
      }

      .content-text {
        font-size: 15px;
        font-weight: 500;
        margin-bottom: 30px;
      }

      .contact-information {
        .contact-group {
          display: block;
          margin-bottom: 40px;

          .contact-group-icon {
            margin-right: 30px;
            margin-bottom: 8px;

            img {

              width: auto;
              height: auto;

            }

          }

          .contact-group-text {

            .contact-group-top {
              font-size: 18px;
              font-weight: 500;
            }

            .contact-group-bottom {
              a {
                font-size: 20px;
                color: #303030;
                font-weight: bold;
              }
            }
          }
        }
      }
    }
  }

  .right-block {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    @media all and (max-width: 1023px) {

      justify-content: flex-start;

    }

    .form-container {
      margin-top: 70px;

      .form-title {
        font-size: 17px;
        margin-bottom: 16px;
        flex-basis: 100%;
        text-align: left;
        margin-left: 1rem;
      }

      .webforms {
        form {
          .legend {
            display: none;
          }

          .actions-toolbar {
            margin-bottom: 16px;

            button {
              max-width: 170px;
            }
          }

          .recaptcha {
            margin-left: 2rem;
          }
        }
      }
    }
  }
}

@media all and (min-width: 1024px) {
  .contact-banner {

    .left-block {
      flex-basis: 52%;
      display: block;
      padding-right: 8px;

      .content-block {
        .content-title {
          font-size: 36px;
          font-weight: bold;
          margin-bottom: 40px;
        }

        .content-text {
          font-size: 17px;
          margin-bottom: 65px;
        }

        .contact-information {
          .contact-group {
            display: flex;

            .contact-group-icon {
              flex-basis: 50px;
              margin-right: 30px;
              margin-bottom: 0;
            }

            .contact-group-text {
              flex-basis: 70%;

              .contact-group-bottom {
                a {
                  font-size: 20px;
                }
              }
            }
          }
        }
      }

    }

    .right-block {
      width: 48%;
      display: block;

      .form-title {
        text-align: left;
      }
    }
  }
}

.hero-slider-wifi-clients {
    margin: 0 auto !important;
    padding: 0 61px;

    .hero-slider-title {
        font-size: 22px;
        margin-bottom: 22px;
    }

    .owl-stage {
        display: flex;
        align-items: center;

        .owl-item {
            margin-right: 50px;

            .banner-image img {
                width: auto;
                height: 100%;
                max-height: 90px;
                object-fit: scale-down;
                margin: 0 auto;
            }
        }
    }
}
