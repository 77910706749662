$cart__margin         : $spacer--large !default;
$cart__border         : 1px solid $gray-lighter !default;

$cart__bottom-margin  : 0 !default;
$cart__bottom-padding : $spacer 0 0 0 !default;

.cart {
    display: flex;
    margin-bottom: $cart__margin;

    &__bottom {
        display: flex;
        margin: $cart__bottom-margin;
        padding: $cart__bottom-padding;
        border-top: $cart__border;
    }

    &__products-list {
        margin: 0;
        border-top: $cart__border;
    }
}
