.checkout-onepage-success{

  .checkout-success-main-container{
    width:100%;
    background:#f0f9fb;
  }

  .checkout-success-container[data-name^="middle"]{
    padding:22px 10px 0;
    background: #fff;
    width:100%;
    top:0px;
    position: relative;
  }

  .action__handler{
    display: none;
  }

  .dashboard-table{
    margin:30px;
    border:0px;
    background: #fff;
    padding: 10px 10px 30px;

    .dashboard-table__header .dashboard-table__title{
      margin: 18px 10px;
      font-size: 16px;
    }

    .table tfoot{
      top: 30px;
      position: relative;

      .grand_total_incl{
        th{
          padding-right:0px;
        }
        span.total_label{
          display:inline-block;
          width: 150px;
          padding: 12px 0;
          border-top: 1px #dfdada solid;
          padding-right: 10px;
        }

        .amount{
          padding-left:0px;

          span{
            display:inline-block;
            border-top: 1px #dfdada solid;
            padding:11px;
          }
        }
      }
    }

    .table tfoot tr.subtotal td.amount,
    .table tfoot td,
    .table tfoot tr.subtotal th,
    .table tfoot th,
    .table tfoot tr.grand_total td.amount{
      background: #fff;
      color: #231f20;
      border-color: transparent;
      text-transform: none;
      padding: 12px;
      font-weight: normal;
      font-size: 14px;
      span {
          &.price{
            font-size: 14px;
          }
      }

      strong {
        span {
            &.price{
              font-size: 16px;
            }
        }
      }

    }
    .table th{
      color:#231f20;
      font-weight: bold;
    }

    .col.subtotal .price-excluding-tax {
      display:none;
    }
  }
}
.checkout-success{

  .order-details-info-order{
    font-size:20px;
    margin-bottom: 10px;
    font-weight: bold;
  }
  .block-content{
    .order-details{
      float:left;
    }
    .actions-toolbar{
      float: none;
      width: 100%;
      clear: both;
      text-align: center;
    }

    .button--secondary{
      inline-size: auto;
      margin-left:20px;
    }
    .create-acc-button{
      margin-left:23px;
    }
  }
}
