$fullscreen-menu__width: 100% !default;
$fullscreen-menu__min-width: 400px !default;
$fullscreen-menu__bg-color: #191d5d !default;
$fullscreen-menu__bg-color--secondary: $light-navy-blue !default;
$fullscreen-menu__close-bg-color: $white !default;
$fullscreen-menu__close-bg-color--hover: $white !default;
$fullscreen-menu__close-color: $thunder !default;
$fullscreen-menu__close-min-height: 52px !default;

// List item
$fullscreen-menu__item-padding: 15px 30px !default;
$fullscreen-menu__item-transition: $transition-base !default;
$fullscreen-menu__item-font-size: $font-size-large !default;
$fullscreen-menu__item-color: $white !default;
$fullscreen-menu__item-color--open: $white !default;
$fullscreen-menu__item-color--hover: $white !default;
$fullscreen-menu__item-bg-color: transparent !default;
$fullscreen-menu__item-bg-color--open: $dte-bright-blue !default;
$fullscreen-menu__item-bg-color--hover: $dte-bright-blue !default;
$fullscreen-menu__item-bg-color--secondary: $dte-bright-blue !default;
$fullscreen-menu__item-bg-color--open--secondary: #191d5d !default;
$fullscreen-menu__item-bg-color--hover--secondary: #191d5d !default;

// BP Widths
$fullscreen-menu__base-width--desktop: 50%;
$fullscreen-menu__base-width--desktop-remainder: 50%;
$fullscreen-menu__base-width--desktop-xl: 26%;
$fullscreen-menu__base-width--desktop-remainder-xl: 74%;
$fullscreen-menu__base-width--desktop-xxl: 19%;
$fullscreen-menu__base-width--desktop-remainder-xxl: 81%;


.header__wrapper {

  .side-menu__content {

    width: $fullscreen-menu__width;
    min-width: 250px;
    max-width: none;
    box-shadow: none;
    background-color: transparent;
    top: 86px;
    height: auto;
    pointer-events: none;
    border-top: 1px solid lightgrey;

    .side-menu__trigger-wrapper {

      width: 100%;
      background-color: $fullscreen-menu__close-bg-color;
      border: none;
      display: block;
      pointer-events: all;

      .fullscreen-menu-top-btn {

        display: block;
        padding: 15px 30px;
        height: auto;
        max-width: none;
        width: 100%;
        text-align: left;
        flex-direction: initial;
        justify-content: initial;
        align-items: initial;
        transition: $transition-base;
        background-color: $white;
        border: none;
        cursor: pointer;

        > * {

          display: inline-block;
          vertical-align: middle;

        }

        .side-menu__trigger-icon {

          fill: $dusty-grey;
          width: 14px;
          height: 14px;
          margin: -2px;
          padding: 0;

        }

        .side-menu__trigger-text {

          color: $fullscreen-menu__close-color;
          font-size: 18px;
          line-height: 26px;
          font-family: $font-family-montserrat;
          margin-left: 5px;

        }

        &:hover {
          background-color: $fullscreen-menu__close-bg-color--hover;
        }

      }

      .side-menu__trigger-main-menu {

        position: fixed;
        top: 0;
        left: 0;
        z-index: 1;
        padding: 5px 20px;

      }

    }


    .button-header-shop {

      display: inline-flex;
      width: 100%;
      margin: 0 !important;
      border: 0 !important;
      border-radius: 0;
      justify-content: left;
      font-size: 15px;
      padding: 15px 30px;

      &::after {

        content: '';
        background-image: url("data:image/svg+xml,%3Csvg height='16' viewBox='0 0 24 24' width='16' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m7.92285156 6.96830426 7.49903224 7.49903224-7.49903224 7.4983052' fill='none' stroke='%23979797' stroke-linecap='round' stroke-linejoin='round' transform='matrix(0 1 -1 0 26.524727 .866875)'/%3E%3C/svg%3E");
        width: 16px;
        height: 16px;
        display: inline-block;
        background-repeat: no-repeat;
        display: inline-block;
        position: absolute;
        left: 46%;
        transform: rotate(-90deg);
        filter: brightness(100);

        @media all and (max-width: 767px) {

          right: 5%;
          left: unset;

        }

      }

      .button__text {

        margin-left: 10px;

      }

      &:hover {

        &::after {

          filter: invert(38%) sepia(0%) saturate(71%) hue-rotate(151deg) brightness(100%) contrast(80%);

        }

      }

    }

    .fullscreen-menu-wrapper__inner-list {

      .button-header-shop {

        width: 100% !important;

        &::after {

          width: 16px;

        }

      }
    }

  }

}

.fullscreen-menu-wrapper {

  display: block;
  width: 100%;
  height: 100%;
  pointer-events: none;

  .fullscreen-menu-wrapper__inner-item {

    &.show-all-brands {

      .button {

        display: inline-flex !important;
        margin: 25px 40px 0;

        > * {
          vertical-align: middle;
        }

        > span {
          padding-left: 8px;
        }

      }

    }

  }

  .submenu-header-text {

    padding: 17px 40px 10px;
    margin: 0;
    font-weight: 600;

  }

  .fullscreen-menu-wrapper__item {

    pointer-events: all;

    .fullscreen-menu-wrapper__label {

      padding: $fullscreen-menu__item-padding;
      font-size: $fullscreen-menu__item-font-size;
      color: $fullscreen-menu__item-color;
      background-color: $fullscreen-menu__item-bg-color;
      transition: $fullscreen-menu__item-transition;
      display: block;

    }

    .fullscreen-menu-wrapper__icon {
      display: none;
    }

    &:hover {

      .fullscreen-menu-wrapper__label {

        color: $fullscreen-menu__item-color--hover;
        background-color: $fullscreen-menu__item-bg-color--hover;

      }

    }

    .fullscreen-menu-wrapper__inner-list {

      display: none;
      list-style: none;
      padding: 0;
      margin: 0;

      &.fullscreen-menu-wrapper__inner-list--level1,
      &.fullscreen-menu-wrapper__inner-list--level3 {

        > .fullscreen-menu-wrapper__inner-item {

          > .fullscreen-menu-wrapper__inner-label {

            padding: $fullscreen-menu__item-padding;
            font-size: $fullscreen-menu__item-font-size;
            color: $fullscreen-menu__item-color;
            transition: $fullscreen-menu__item-transition;
            display: block;

          }

          &:hover {

            > .fullscreen-menu-wrapper__inner-label {

              color: $fullscreen-menu__item-color--hover;
              background-color: $fullscreen-menu__item-bg-color--hover--secondary;

            }

          }

          &.fullscreen-menu-wrapper__item--open {

            > .fullscreen-menu-wrapper__inner-label {

              color: $fullscreen-menu__item-color--hover;
              background-color: $fullscreen-menu__item-bg-color--open--secondary;

            }

          }

        }

      }


      &.fullscreen-menu-wrapper__inner-list--level2 {

        background-color: $fullscreen-menu__bg-color;

        > .fullscreen-menu-wrapper__inner-item {

          > .fullscreen-menu-wrapper__inner-label {

            padding: $fullscreen-menu__item-padding;
            font-size: $fullscreen-menu__item-font-size;
            color: $fullscreen-menu__item-color;
            transition: $fullscreen-menu__item-transition;
            display: block;

          }

          &:hover {

            > .fullscreen-menu-wrapper__inner-label {

              color: $fullscreen-menu__item-color--hover;
              background-color: $fullscreen-menu__item-bg-color--hover;

            }

          }

          &.fullscreen-menu-wrapper__item--open {

            > .fullscreen-menu-wrapper__inner-label {

              color: $fullscreen-menu__item-color--hover;
              background-color: $fullscreen-menu__item-bg-color--open;

            }

          }

        }

      }

    }

  }

  .fullscreen-menu-wrapper__item--open {

    > .fullscreen-menu-wrapper__inner-list {

      display: block;
      position: fixed;
      top: 36px;
      bottom: 0;
      background-color: $fullscreen-menu__bg-color--secondary;
      overflow-y: auto;
      z-index: 1;

    }

    > .fullscreen-menu-wrapper__label {

      color: $fullscreen-menu__item-color--open;
      background-color: $fullscreen-menu__item-bg-color--open;

    }

  }

  &__item {

    &.visible-mobile-only {

      @media all and (min-width: 768px) {

        display: none;

      }

    }

  }

}

.fullscreen-menu-wrapper__item--parent {

  &.shop-by {

    > .fullscreen-menu-wrapper__label {



      .button__icon {

        vertical-align: top;
        margin-right: 6px;

      }

    }

    > .fullscreen-menu-wrapper__inner-list {
      padding-top: 50px;
    }

    .fullscreen-menu-wrapper__inner-list--level1 {

      > .submenu-header-text {
        padding: 15px 40px;
      }

    }

  }

}

.fullscreen-menu-wrapper__list {

  list-style: none;
  padding: 0;
  margin: 0;
  background-color: $fullscreen-menu__bg-color;
  min-height: 100%;
  pointer-events: all;

}

@media all and (min-width: 768px) {

  .header__wrapper {

    .side-menu__content {

      .side-menu__trigger-wrapper,
      .side-menu__trigger-main-menu.side-menu__trigger-main-menu,
      .side-menu__trigger-back {
        width: $fullscreen-menu__base-width--desktop;
      }

    }

  }


  .side-menu.submenu-opened {


    .side-menu__content {

      .side-menu__trigger-wrapper {

        .side-menu__trigger-close {
          display: none !important;
        }

        .side-menu__trigger-main-menu {
          display: block !important;
        }

      }

    }

  }


  .fullscreen-menu-wrapper__list {
    width: $fullscreen-menu__base-width--desktop;
  }

  .fullscreen-menu-wrapper__item--parent {

    &.overview,
    &.help {

      .fullscreen-menu-wrapper__inner-list.fullscreen-menu-wrapper__inner-list--level1 {
        width: $fullscreen-menu__base-width--desktop;
      }

    }

    &.shop-by {

      .fullscreen-menu-wrapper__inner-list {
        top: 176px;
      }

      .fullscreen-menu-wrapper__inner-list.fullscreen-menu-wrapper__inner-list--level1 {

        width: $fullscreen-menu__base-width--desktop;
        margin-left: 0;

      }

      .fullscreen-menu-wrapper__inner-list.fullscreen-menu-wrapper__inner-list--level2 {

        width: $fullscreen-menu__base-width--desktop;
        margin-left: $fullscreen-menu__base-width--desktop;

      }

      .fullscreen-menu-wrapper__inner-list.fullscreen-menu-wrapper__inner-list--level3 {

        width: $fullscreen-menu__base-width--desktop;
        margin-left: $fullscreen-menu__base-width--desktop;

      }

    }

    &.brand-partnerships {

      li.main-mobile.fullscreen-menu-wrapper__inner-item.main-mobile.fullscreen-menu-wrapper__inner-item--level1 {

        width: 49%;
        display: inline-block;

        &.show-all-brands {
          width: 99%;
        }

      }

      .fullscreen-menu-wrapper__inner-list.fullscreen-menu-wrapper__inner-list--level1 {

        width: $fullscreen-menu__base-width--desktop-remainder;
        left: $fullscreen-menu__base-width--desktop;

      }

    }

  }

}

@media all and (min-width: 1092px) {

  .fullscreen-menu-wrapper__item--parent {

    &.brand-partnerships {

      li.main-mobile.fullscreen-menu-wrapper__inner-item.main-mobile.fullscreen-menu-wrapper__inner-item--level1 {
        width: 33%;
      }

    }

  }

}

@media all and (min-width: 1440px) and (max-width: 1999px) {

  .header__wrapper {

    .side-menu__content {

      .side-menu__trigger-wrapper,
      .side-menu__trigger-main-menu.side-menu__trigger-main-menu,
      .side-menu__trigger-back {
        width: $fullscreen-menu__base-width--desktop-xxl;
      }

    }

  }

  .header__wrapper .side-menu__content {
    left: 21.5% !important;
  }

  .fullscreen-menu-wrapper__list {
    width: $fullscreen-menu__base-width--desktop-xxl;
  }

  .fullscreen-menu-wrapper__item--parent {

    &.overview,
    &.help {

      .fullscreen-menu-wrapper__inner-list.fullscreen-menu-wrapper__inner-list--level1 {

        width: $fullscreen-menu__base-width--desktop-xxl;
        left: $fullscreen-menu__base-width--desktop-xxl;

      }

    }

    &.shop-by {

      .fullscreen-menu-wrapper__inner-list.fullscreen-menu-wrapper__inner-list--level1 {

        width: $fullscreen-menu__base-width--desktop-xxl;
        margin-left: 0;

      }

      .fullscreen-menu-wrapper__inner-list.fullscreen-menu-wrapper__inner-list--level2 {

        width: $fullscreen-menu__base-width--desktop-xxl;
        margin-left: $fullscreen-menu__base-width--desktop-xxl;

      }

      .fullscreen-menu-wrapper__inner-list.fullscreen-menu-wrapper__inner-list--level3 {

        width: $fullscreen-menu__base-width--desktop-xxl;
        margin-left: $fullscreen-menu__base-width--desktop-xxl;

      }

    }

    &.brand-partnerships {

      .fullscreen-menu-wrapper__inner-list.fullscreen-menu-wrapper__inner-list--level1 {

        width: $fullscreen-menu__base-width--desktop-remainder-xl;
        left: $fullscreen-menu__base-width--desktop-xl;

      }

    }

  }

}

@media all and (min-width: 2000px) {

  .header__wrapper {

    .side-menu__content {

      .side-menu__trigger-wrapper,
      .side-menu__trigger-main-menu.side-menu__trigger-main-menu,
      .side-menu__trigger-back {
        width: $fullscreen-menu__base-width--desktop-xxl;
      }

    }

  }

  .header__wrapper .side-menu__content {
    left: 28% !important;
  }

  .fullscreen-menu-wrapper__list {
    width: $fullscreen-menu__base-width--desktop-xxl;
  }

  .fullscreen-menu-wrapper__item--parent {

    &.overview,
    &.help {

      .fullscreen-menu-wrapper__inner-list.fullscreen-menu-wrapper__inner-list--level1 {

        width: $fullscreen-menu__base-width--desktop-xxl;
        left: $fullscreen-menu__base-width--desktop-xxl;

      }

    }

    &.shop-by {

      .fullscreen-menu-wrapper__inner-list.fullscreen-menu-wrapper__inner-list--level1 {

        width: $fullscreen-menu__base-width--desktop-xl;
        margin-left: 0;

      }

      .fullscreen-menu-wrapper__inner-list.fullscreen-menu-wrapper__inner-list--level2 {

        width: $fullscreen-menu__base-width--desktop-xl;
        margin-left: $fullscreen-menu__base-width--desktop-xl;

      }

      .fullscreen-menu-wrapper__inner-list.fullscreen-menu-wrapper__inner-list--level3 {

        width: $fullscreen-menu__base-width--desktop-xl;
        margin-left: $fullscreen-menu__base-width--desktop-xl;

      }

    }

    &.brand-partnerships {

      .fullscreen-menu-wrapper__inner-list.fullscreen-menu-wrapper__inner-list--level1 {

        width: $fullscreen-menu__base-width--desktop-remainder-xxl;
        left: $fullscreen-menu__base-width--desktop-xxl;

      }

    }

  }

}

@media all and (max-width: $screen-m - 1) {

  .header__wrapper {

    .side-menu__content {

      top: 67px;

    }

  }

  .header__wrapper .side-menu__content .side-menu__trigger-wrapper,
  .header__wrapper .fullscreen-menu-wrapper {
    width: 100%;
  }

  .side-menu.submenu-opened {

    .fullscreen-menu-wrapper {

      .fullscreen-menu-wrapper__list {

        &.submenu-opened {
          width: 0;
        }

      }

    }

    .side-menu__content {

      .side-menu__trigger-wrapper {

        .side-menu__trigger-close {
          display: none !important;
        }

        .side-menu__trigger-main-menu {
          display: block !important;
        }

      }

    }

  }

  .fullscreen-menu-wrapper {

    .fullscreen-menu-wrapper__item--open {

      > .fullscreen-menu-wrapper__inner-list {

        top: 36px;
        left: 0;
        width: 100% !important;

      }

    }

    .fullscreen-menu-wrapper__item.fullscreen-menu-wrapper__item--parent.shop-by {

      > .fullscreen-menu-wrapper__inner-list {
        top: 0;
      }

    }

  }


}

@media all and (max-width: 350px) {

  .fullscreen-menu-wrapper {

    .fullscreen-menu-wrapper__inner-item {

      &.show-all-brands {

        .button {

          padding-left: 30px !important;
          padding-right: 30px !important;

        }

      }

    }

  }

}

.header__wrapper .side-menu__content .side-menu__trigger-wrapper .fullscreen-menu-top-btn .side-menu__trigger-text {
  font-size: 13px;
  color: $dusty-grey;
}

.header__wrapper .side-menu__content .side-menu__trigger-wrapper .side-menu__trigger-back {

  display: block;
  height: auto;
  max-width: none;
  text-align: left;
  flex-direction: initial;
  justify-content: initial;
  align-items: initial;
  transition: all 0.3s ease-in-out;
  background-color: $white;
  border: none;
  cursor: pointer;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  padding: 13px 20px;

  > * {

    display: inline-block;
    vertical-align: middle;

  }

  .side-menu__trigger-icon {

    fill: $dusty-grey;
    width: 14px;
    height: 14px;
    margin-top: -2;
    padding: 0;

  }

  .side-menu__trigger-text {

    color: #231f20;
    font-size: 15px;
    line-height: 26px;
    font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
    margin-left: 5px;

  }

}

.side-menu {
  .fullscreen-menu-wrapper {

    .fullscreen-menu-wrapper__list {
      background-color: white
    }

    .fullscreen-menu-wrapper__item {

      .submenu-header-text {

        color: $thunder !important;
        padding: 15px 20px;

        .button {

          border-color: $thunder;
          background-color: transparent;
          color: $thunder;

          .button__icon,
          .button__icon use {

            stroke: $thunder;

          }

        }

      }

      .fullscreen-menu-wrapper__label {

        color: $dte-blue;
        padding: 15px 30px;
        line-height: 1.4;
        font-size: 15px;
        font-weight: 400;


        &::after {

          content: '';
          background-image: url("data:image/svg+xml,%3Csvg height='16' viewBox='0 0 24 24' width='16' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m7.92285156 6.96830426 7.49903224 7.49903224-7.49903224 7.4983052' fill='none' stroke='%23979797' stroke-linecap='round' stroke-linejoin='round' transform='matrix(0 1 -1 0 26.524727 .866875)'/%3E%3C/svg%3E");
          width: 16px;
          height: 16px;
          display: inline-block;
          background-repeat: no-repeat;
          display: inline-block;
          position: absolute;
          left: 46%;
          transform: rotate(-90deg);
          filter: invert(38%) sepia(0%) saturate(71%) hue-rotate(151deg) brightness(100%) contrast(80%);

          @media all and (max-width: 767px) {

            right: 5%;
            left: unset;

          }

        }

        &:hover {

          color: $white !important;
          background-color: $dte-bright-blue;
          text-decoration: none;
          
          &::after {

            filter: brightness(100);

          }

        }

      }

      .fullscreen-menu-wrapper__inner-list {

        //border-right: 1px $light-peach solid;

        > .fullscreen-menu-wrapper__inner-item {

          > .fullscreen-menu-wrapper__inner-label {

            color: $dte-blue;
            padding: 15px 30px;
            line-height: 1.4;
            font-size: 15px;
            font-weight: 400;

            &::after {

              content: '';
              background-image: url("data:image/svg+xml,%3Csvg height='16' viewBox='0 0 24 24' width='16' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m7.92285156 6.96830426 7.49903224 7.49903224-7.49903224 7.4983052' fill='none' stroke='%23979797' stroke-linecap='round' stroke-linejoin='round' transform='matrix(0 1 -1 0 26.524727 .866875)'/%3E%3C/svg%3E");
              width: 16px;
              height: 16px;
              background-size: 100% 100%;
              display: inline-block;
              margin: 0 0 0 4px;
              transition: transform 0.25s;
              transform: rotate(-90deg);
              position: absolute;
              right: 30px;

            }

            &:hover {

              &::after {

                content: '';
                background-image: url("data:image/svg+xml,%3Csvg height='16' viewBox='0 0 24 24' width='16' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m7.92285156 6.96830426 7.49903224 7.49903224-7.49903224 7.4983052' fill='none' stroke='%23FFFFFF' stroke-linecap='round' stroke-linejoin='round' transform='matrix(0 1 -1 0 26.524727 .866875)'/%3E%3C/svg%3E");
                width: 16px;
                height: 16px;
                background-size: 100% 100%;
                display: inline-block;
                margin: 0 0 0 4px;
                transition: transform 0.25s;
                transform: rotate(-90deg);
                position: absolute;
                right: 30px;

              }

            }

          }

          &.fullscreen-menu-wrapper__item--open,
          &:hover {

            > .fullscreen-menu-wrapper__inner-label {

              color: $white;
              background-color: $dte-bright-blue;
              text-decoration: none;

              &::after {

                content: '';
                background-image: url("data:image/svg+xml,%3Csvg height='16' viewBox='0 0 24 24' width='16' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m7.92285156 6.96830426 7.49903224 7.49903224-7.49903224 7.4983052' fill='none' stroke='%23FFFFFF' stroke-linecap='round' stroke-linejoin='round' transform='matrix(0 1 -1 0 26.524727 .866875)'/%3E%3C/svg%3E");
                width: 16px;
                height: 16px;
                background-size: 100% 100%;
                display: inline-block;
                margin: 0 0 0 4px;
                transition: transform 0.25s;
                transform: rotate(-90deg);
                position: absolute;
                right: 30px;

              }

            }

          }

        }

      }

    }

    .fullscreen-menu-wrapper__item--open {

      > .fullscreen-menu-wrapper__inner-list {

        background-color: $white;

      }

      > a {

        &::after {

          content: unset;

        }

      }

    }

  }
}

@media all and (min-width: 1600px) {

  .header__wrapper .side-menu__content {


    width: 100%;
    max-width: 1440px;
    margin: 0 auto;

    .fullscreen-menu-wrapper__list .fullscreen-menu-wrapper__item--parent.shop-by {

      .fullscreen-menu-wrapper__inner-list--level2:before {

        position: absolute;
        top: 0;
        bottom: 0;
        left: 100%;
        width: 100%;

      }

    }

    .fullscreen-menu-wrapper {

      top: 86px;
      left: auto;

    }

  }

}

@media all and (min-width: 1131px) {

  .header__wrapper .side-menu__content {

    .side-menu__trigger-wrapper {
      display: none !important;
    }

  }

}

@media (min-width: 1130px) {

  .header__wrapper .side-menu__content {

    transform: translateX(0);
    left: 0;
    right: auto;

  }

  .header__wrapper .side-menu__content .fullscreen-menu-wrapper {
    left: auto;
  }

  .header__wrapper {

    .side-menu__content {

      top: 0px;

      .side-menu__trigger-wrapper {

        height: 0;
        width: 0;
        max-width: 0;
        max-height: 0;
        opacity: 0;

      }

    }

  }

}

@media all and (max-width: 1129px) and (min-width: 1299px) {

  .header__wrapper .side-menu__content {
    left: 0;
  }

}

@media all and (max-width: 1129px) {

  .header__wrapper {

    .side-menu {

      .fullscreen-menu-wrapper {

        .fullscreen-menu-wrapper__list {

          overflow-y: auto;
          height: 100%;
          max-height: 100%;

          li.level1,
          li.mobile-menu-main-menu-link {

            //position: relative;

            &.mobile-menu-main-menu-link {

              background-color: #f1f8fb;
              color: #303030;
              padding: 15px 30px;
              line-height: 1.4;
              font-size: 15px;
              font-weight: 600;

            }

            a {

              display: block;
              color: #2E3687;
              padding: 15px 30px;
              line-height: 1.4;
              font-size: 15px;
              font-weight: 400;
              position: relative;

              &:hover {

                color: #fff !important;
                background-color: #0AB6FF;
                text-decoration: none;

                &:after {
                  background-image: url("data:image/svg+xml,%3Csvg height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m7.92285156 6.96830426 7.49903224 7.49903224-7.49903224 7.4983052' fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' transform='matrix(0 1 -1 0 26.524727 .866875)'/%3E%3C/svg%3E");
                }

              }

              &:after {

                content: '';
                background-image: url("data:image/svg+xml,%3Csvg height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m7.92285156 6.96830426 7.49903224 7.49903224-7.49903224 7.4983052' fill='none' stroke='%23979797' stroke-linecap='round' stroke-linejoin='round' transform='matrix(0 1 -1 0 26.524727 .866875)'/%3E%3C/svg%3E");
                width: 16px;
                height: 16px;
                background-size: 100% 100%;
                display: inline-block;
                transition: transform 0.25s;
                transform: rotate(-90deg);
                position: absolute;
                right: 30px;
                top: 18px;

              }

            }

            .expand {
              display: none;

              svg {
                display: none;
              }

            }

            &.active {

              > a {

                color: #fff !important;
                background-color: #0AB6FF;
                text-decoration: none;

              }

            }

            &.active {

              a {

                &:after {

                  background-image: url("data:image/svg+xml,%3Csvg height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m7.92285156 6.96830426 7.49903224 7.49903224-7.49903224 7.4983052' fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' transform='matrix(0 1 -1 0 26.524727 .866875)'/%3E%3C/svg%3E");

                }

              }

            }

          }

        }

      }

      &.side-menu--is-open {

        .side-menu__content {

          &:before {

            content: '';
            background: rgba(0, 0, 0, 0.65);
            pointer-events: none;
            z-index: -1;
            position: fixed;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;

          }

        }


      }

    }

  }

}

@media(min-width: 768px) {

  .fullscreen-menu-wrapper {

    .fullscreen-menu-wrapper__item--open {

      > .fullscreen-menu-wrapper__inner-list {

        padding-top: 0;
        width: 50%;

      }

    }

    .fullscreen-menu-wrapper__list {

      .fullscreen-menu-wrapper__item--parent {

        &.shop-by {

          .fullscreen-menu-wrapper__inner-list {
            background: rgba(246, 246, 246, 0.98);
          }

        }

      }

      .fullscreen-menu-wrapper__item--parent {

        &.shop-by {

          .fullscreen-menu-wrapper__inner-list--level2 {

            &:before {

              content: '';
              position: fixed;
              right: 0;
              top: 0;
              bottom: 86px;
              pointer-events: none;
              z-index: -1;
              background-size: 100% auto;
              background-position: center;
              background-repeat: no-repeat;
              max-width: 377px;

            }

          }

        }

      }

    }

  }

}

@media(min-width: 768px) and (max-width: 1129px) {

  .header .header__wrapper .side-menu__content {
    top: 81px;
  }

  .fullscreen-menu-wrapper__item--parent.shop-by .fullscreen-menu-wrapper__inner-list.fullscreen-menu-wrapper__inner-list--level1 {
    top: 36px;
  }

  .fullscreen-menu-wrapper__item--parent.shop-by .fullscreen-menu-wrapper__inner-list.fullscreen-menu-wrapper__inner-list--level2,
  .fullscreen-menu-wrapper__item--parent.shop-by .fullscreen-menu-wrapper__inner-list.fullscreen-menu-wrapper__inner-list--level3 {

    padding-top: 50px;
    top: 0;

  }

}

@media(min-width: 768px) and (max-width: 1440px) {

  .fullscreen-menu-wrapper {

    .fullscreen-menu-wrapper__item--parent {

      &.shop-by {

        .fullscreen-menu-wrapper__inner-list--level2 {

          &:before {

            // background-image: url('https://via.placeholder.com/150/FF0000/FFFFFF');
            width: 28%;
            left: 56%;
            right: auto;

          }

        }

      }

    }

  }

}

@media(min-width: 1440px) {

  .fullscreen-menu-wrapper {

    .fullscreen-menu-wrapper__item--parent {

      &.shop-by {

        .fullscreen-menu-wrapper__inner-list--level2 {

          &:before {

            // background-image: url('https://via.placeholder.com/150/00FF00/FFFFFF');
            width: 26%;
            left: 52%;
            right: auto;

          }

        }

      }

    }

  }

}

@media(min-width: 2000px) {

  .fullscreen-menu-wrapper {

    .fullscreen-menu-wrapper__item--parent {

      &.shop-by {

        .fullscreen-menu-wrapper__inner-list--level2 {

          &:before {

            // background-image: url('https://via.placeholder.com/150/0000FF/FFFFFF');
            width: 19%;
            left: 38%;
            right: auto;

          }

        }

      }

    }

  }

}

@media(max-width: 767px) {

  .header__wrapper {

    .side-menu {

      .side-menu__content {

        .side-menu__trigger-wrapper {

          .side-menu__trigger-main-menu {

            width: 100%;
            padding: 5px 20px;

          }

          .side-menu__trigger-back {
            width: 100%;
          }

        }

      }

    }

  }

  .fullscreen-menu-wrapper {

    .fullscreen-menu-wrapper__item--open {

      > .fullscreen-menu-wrapper__inner-list {
        width: 100%;
      }

    }

  }

}

/* Overiding header button shop copy */
.header-normal .header__wrapper .main-header-content .header__menu_wrapper .header__menu_inner .fullscreen-menu-wrapper__list .button-header-shop {

  height: unset;
  padding: 12px 30px !important;

  span {

    margin-left: 20px;

  }

}

/* Shop Mobile category menu */
.main-mobile.fullscreen-menu-wrapper__list {
  ul {
    
    &.level-2--container {
      display: none;
    }

    &.level-3--container {
      display: none;
    }

    li {
     &::marker {
      content: '';
     }
    }
  }
}