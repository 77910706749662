#minicart-content-wrapper {

  max-height: calc(100vh - 187px);

    .empty{
        padding-bottom: 30px;
    }
  .minicart-content__actions {

    .minicart-content__edit.minicart-view-basket-btn {

      font-weight: normal;

    }

    .cart.main.actions {
      margin: 0;

      button[name="update_cart_action"] {
        margin-top: 5px;
      }
    }

  }

}

.cart-list-item__actions-item {

  &.button--icon {

    padding: 3px;
    height: 32px;
    width: 32px;
    min-width: 32px;

  }

  .button__icon {

    width: 14px;
    height: 14px;

  }

  svg,
  svg use
  {

    stroke: none!important;
    fill: #fff;

  }

}
