.amazon-store {

  .top-banner {
    .owl-carousel-custom-21,
    .owl-carousel-custom-29 {
      > div {
        height: 230px;

        @media all and (max-width: 600px) {
          height: 300px;
        }
      }
    }
  }

  .container {
    .landing-page-links {
      text-align: center;

      > div {
        margin-bottom: 30px;
      }

      .landing-page-item {
        padding: 30px 15px;
        box-shadow: 0 3px 11px 0 rgba(0, 0, 0, 0.13);
        height: 100%;

        h4 {
          color: $color-primary;
          font-weight: bold;
          font-size: 18px;
        }

        .landing-page-img {
          padding: 20px;

          img {
            width: 82px;
            height: 82px;
            margin: auto;
          }
        }

        .landing-page-content {
          padding: 0 35px;
          min-height: 120px;

          @media all and (max-width: 1300px) {
            min-height: 140px;
          }

          @media all and (max-width: 1200px) and (min-width: 1100px) {
            min-height: 170px;
          }

          @media all and (max-width: 1099px) and (min-width: 992px) {
            min-height: 185px;
          }
        }

        .landing-page-action {
          display: flex;
          justify-content: space-evenly;

          @media all and (max-width: 1230px) {
            display: block;
          }

          .button {
            height: 44px;
            font-size: 13px;
            margin-bottom: 10px;

            @media all and (max-width: 1200px) {
              width: 50%;
              max-width: 180px;
            }
          }
        }
      }
    }
  }
}

.amazon-store .top-account-toolbar {
  margin-top: 42px;
}


@media (min-width: 768px) {
  .amazon-store {
    .container {
      .landing-page-item {
        .landing-page-action {
          .button {
            margin-bottom: 10px;
          }
        }
      }
    }
  }
}

@media (min-width: 992px) {

  body.amazon-store.account {

    //padding-top: 86px;

    /*
    > .page-wrapper {
      overflow-x: hidden;
    }
    */

    #maincontent > .row > .dashboard__content > .title-wrapper {
      margin: 0;
    }

  }

}

.account .desk-customer-index{
    .title-wrapper{
	flex-direction: row;
     }
}
.dte-store--default {

  #instant-search-facets-container {

    .is-widget-container-categories {
      display: none;
    }

  }

}

.dte-store--dte_amazon_en {

  #instant-search-facets-container {

    .is-widget-container-categories {

      .ais-hierarchical-menu--body {

        > .ais-hierarchical-menu--list {

          > .ais-hierarchical-menu--item {

            display: none;

            &:first-child {
              display: block;
            }

          }

        }

      }

    }

  }

}

.dte-store--amazon_corp_en {

  #instant-search-facets-container {

    .is-widget-container-categories {

      .ais-hierarchical-menu--body {

        > .ais-hierarchical-menu--list {

          > .ais-hierarchical-menu--item {

            display: none;

            &:nth-child(2) {
              display: block;
            }

          }

        }

      }

    }

  }

}


.categorypath-computers-electronics #instant-search-facets-container {
  display: none;
}

.amazon-store {

  .bom-missing-products-email-link {

    color: #283989;
    text-decoration: underline;
    font-size: 13px;
    font-weight: 700;

  }

  .dte-add-toquote-container {
    display: none !important;
  }

  .ais-infinite-hits--item .action.tocart {

    display: inline-flex;
    width: auto;
    max-width: 100%;
    margin: 0 auto;
    padding-left: 25px;
    padding-right: 25px;

    .button__text-mobile {
      display: none !important;
    }

    .button__text-desktop {
      display: inline !important;
    }

  }

  .catalog-grid-item .dte-add-toquote-container {
    margin-left: 0;
  }

   .catalog-grid-item .catalog-list-item__brand{
       position: relative;
   }
   .catalog-grid-item img.attributepages-option-image {
        top: 10px;
        left: 10px;
        position: relative;
    }

  .catalog-grid-item__image-wrapper {
    position: relative;
  }

  .catalog-grid-item .catalog-grid-item__supplier-orig.supplier-orig--amazon,
  .catalog-grid-item .catalog-grid-item__supplier-orig.supplier-orig--AMAZON,
  .catalog-grid-item .catalog-grid-item__supplier-orig.supplier-orig--Amazon {

    content: '';
    position: absolute;
    bottom: auto;
    width: 65px;
    background-image: url('../images/amazon-approved.png');
    background-size: 100% auto;
    background-position: center;
    background-repeat: no-repeat;
    height: 20px;
    z-index: 1;
    pointer-events: none;
    bottom: 0;
    right: 10px;

  }

  .catalog-list-item__image-wrapper {

    position: relative;

  }

  .catalog-list-item .catalog-grid-item__supplier-orig.supplier-orig--amazon,
  .catalog-list-item .catalog-grid-item__supplier-orig.supplier-orig--AMAZON,
  .catalog-list-item .catalog-grid-item__supplier-orig.supplier-orig--Amazon {

    width: 65px;
    background-image: url('../images/amazon-approved.png');
    background-size: 100% auto;
    background-position: center;
    background-repeat: no-repeat;
    height: 20px;
    z-index: 1;
    pointer-events: none;
    right: -5px;
    position: absolute;
    bottom: 5px;

  }

  .catalog-product-view .product-view__gallery.supplier-orig--Amazon .fotorama__loaded--img::before {

    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 81px;
    background-image: url('../images/amazon-approved.png');
    background-size: 100% auto;
    background-position: center;
    background-repeat: no-repeat;
    height: 25px;
    z-index: 1;
    pointer-events: none;

  }

  &.account.horizontal-nav {
    #maincontent {
      > .row {
        > div {
          .dashboard-nav__content {
            padding: 10px;

            .nav.item {
              strong {
                height: 100%;
                line-height: 21px;
              }
            }
          }
        }
      }
    }
  }

  &.quotelist-account-index {
    table {
      td.actions {
        .button--small {
          display: inline;
          padding: 8px 25px;
        }

        .button--secondary.button--small {
          padding: 8px 12px;
        }
      }
    }
  }

  &.table-order-items {
    table {
      td.actions {
        .button--small {
          display: inline;
          padding: 8px 25px;
        }

        .button--secondary.button--small {
          padding: 8px 12px;
        }
      }
    }
  }

  .dashboard-nav {

    z-index: 29;

    .dashboard-nav__list {

      margin: 0 auto;
      max-width: 1100px;

    }

  }

  .catalog-grid-item__uin {
    display: block !important;
  }

  #ticket_messages_details {

    .message {

      margin: 0 0 10px 0;
      padding: 15px 25px;
      border: none !important;
      background: #F1F1F1 !important;
      border-radius: 4px;

      &:after {
        display: none;
      }

      .title {

        min-width: 130px;

      }

      span.message-name {

        margin-right: 20px;
        display: block;
        width: 100%;
        color: #5d5b61;

      }

      .message-date {
        color: #a6a6a6;
        font-size: 0.8em;
        float: left;
        margin-top: 5px;
      }

      .message-content {

        margin-left: 30px;
        color: #231f20;
        font-size: 14px;

      }

    }

  }

  &.desk-customer-view {


  }

  &.catalog-category-view {

    #instant-search-results-container .ais-infinite-hits .ais-infinite-hits--item .inc-vat-info {
      display: none !important;
    }

  }

  &.catalog-product-view {

    .product-view__secondary-details .product-view__price .price-container.inc-vat-info {
      display: none !important;
    }

    @media (min-width: 768px) {

      &.page-layout-2columns-left {

        .main {

          flex: 1 0 calc(100% - 260px);
          max-width: calc(100% - 260px);

        }

        .sidebar {

          flex: 1 0 260px;
          max-width: 260px;

        }

      }

    }

  }

}

.catalog-grid-item__uin {

  display: none !important;
  font-size: 12px;
  line-height: 1.67;
  color: #808080;

}

.wishlist-index-index {

  button[data-role=all-tocart] {

    display: none;

  }

}


@media all and (max-width: 767px) {

  .amazon-store {

    &.desk-customer-view {

      .customer-ticket {

        > .ticket-messages,
        > .ticket-details {

          width: auto;
          float: none;
          margin: 0 10px;

        }

      }

    }

    .catalog-list-item .catalog-grid-item__supplier-orig.supplier-orig--Amazon {

      top: 17px;
      left: 22px;

    }

  }

}

// Hide the show more button on category pages

#instant-search-results-container {

  .ais-infinite-hits--showmore {

    > .ais-infinite-hits--showmoreButton {

      background-image: url('../images/loader-1.gif');
      background-size: 30px 30px;
      background-position: center;
      background-repeat: no-repeat;
      opacity: 1;
      color: #0000;
      border: none;
      background-color: #0000;
      font-size: 0;
      line-height: 0;
      padding: 0;
      pointer-events: none;

      &[disabled] {
        background-image: none;
      }

    }

  }

}

// Picture Buttons
.picture-buttons-container {

  box-shadow: 0 3px 11px 0 rgba(0, 0, 0, 0.13);
  max-width: 200px;
  padding: 20px;
  border: 1px solid transparent;
  cursor: pointer;

  &:hover {

    box-shadow: 0 3px 11px 0 #B8EAFF;
    border: 1px solid rgba($dte-bright-blue, 0.3);

  }

  &:active {

    outline: none;
    border: 1px solid #B8EAFF;

  }

  &:focus,
  &:active {

    outline: none;
    border: 1px solid #B8EAFF;

  }

}

.pointer {
  cursor: pointer;
}

.page.messages {
  width:100%;
  margin: 0 auto;
}

.loader {
  z-index: 999;
}


.develodesign_product_more_info_popup-container {
  input,
  optgroup,
  select,
  textarea {
    font-family: "Proxima Nova", "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
  }
}
