.amazon-store.customer-account-login {

    .heading--page {

        font-size: 28px;
        margin: 60px 0 30px;
        text-align: center;
        padding: 15px 10px;
        text-transform: none;

    }

    .columns .column.main {

        border: 1px #c9c9c9 solid;
        padding: 45px 30px;
        position: relative;

        &:before {

            content: '';
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            width: 45%;
            background-image: url(/media/portal-banner-image.png);
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
            background-color: #2f3389;

        }

    }

    // Center on page

    #maincontent {
        width: calc(100vw - 30px);
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        position: fixed;
        top: 50%;
        margin: 0;
        left: 50%;
        transform: translate( -50%, -50%);

        @media all and (max-width: 767px) {
            height: auto;
        }

        > .columns {
            width: 100vw;
        }
    }

}

@media all and (min-width: 1030px ) {



}

@media all and (max-width: 767px ) {

    .amazon-store.customer-account-login {

        .heading--page {

            margin: 30px 0;

        }

        .columns .column.main {

            padding: 20px 15px;
            margin-bottom: 100px;

            &:before {

                bottom: 0;
                top: auto;
                width: auto;
                left: 0;
                height: 400px;

            }

        }

        .page-main {
            margin: 15px;
        }

        .login__form-column {
            padding-bottom: 400px;
        }

    }

}

