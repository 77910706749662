$product-view__action-elements-margin-top\@small: 28px !default;
$product-view__action-elements-margin-top\@large: 0 !default;

$product-view__action-icon-color: $gray-darker !default;
$product-view__action-icon-color-hover: $color-primary !default;
$product-view__action-icon-transition: $transition-base !default;
$product-view__action-icon-compare-padding: 16px 16px !default;
$product-view__action-icon-compare-width: 16px !default;
$product-view__action-icon-compare-height: 16px !default;
$product-view__action-icon-emailfriend-padding: 16px 14px !default;
$product-view__action-icon-emailfriend-height: 16px !default;
$product-view__action-icon-emailfriend-width: 20px !default;
$product-view__action-icon-wishlist-padding: 15px 14px !default;
$product-view__action-icon-wishlist-height: 18px !default;
$product-view__action-icon-wishlist-width: 20px !default;
$product-view__action-item-size: 48px !default;
$product-view__action-item-spacing: $spacer !default;

$product-view__add-review-link-font-size: $font-size-base !default;
$product-view__add-review-link-font-size\@medium: $font-size-small !default;

$product-view__add-to-cart-margin-right: $spacer--medium !default;

$product-view__base-elements-vertical-spacing: $spacer !default;

$product-view__title-font-size: $font-size-large !default;

$product-view__col-base-spacing: $spacer--medium !default;
$product-view__col-base-reduce-width: $product-view__col-base-spacing / 2 !default;
$product-view__col-left-base-width: 33% !default;
$product-view__col-left-width: calc(#{$product-view__col-left-base-width} - #{$product-view__col-base-reduce-width}) !default;
$product-view__col-right-base-width: 100% - #{$product-view__col-left-base-width} !default;
$product-view__col-right-width: calc(#{$product-view__col-right-base-width} - #{$product-view__col-base-reduce-width}) !default;

$product-view__description-color: $gray-darker !default;
$product-view__description-margin: 0 $spacer--medium $spacer--medium !default;
$product-view__description-margin\@medium: 0 0 $spacer--large !default;
$product-view__description-padding: $spacer--medium !default;
$product-view__description-padding\@medium: $spacer--medium 0 !default;
$product-view__description-border-top: 1px solid $gray-light !default;
$product-view__description-border-bottom: 1px solid $gray-light !default;
$product-view__description-line-height: 1.7 !default;
$product-view__description-title-font-weight: $font-weight-bold !default;
$product-view__description-bundle-margin: 0 !default;
$product-view__description-bundle-padding: $spacer--medium 0 !default;

$product-view__customization-space: $spacer--medium !default;
$product-view__customization-border: 1px solid $gray-light !default;

$product-view__summary-padding: $spacer--medium 0 !default;
$product-view__summary-border: 1px solid $gray-light !default;
$product-view__summary-margin: 0 0 $spacer--large !default;
$product-view__summary-title-font-weight: $font-weight-bold !default;
$product-view__summary-item-space: $spacer--medium !default;
$product-view__summary-price-margin-bottom: $spacer--medium !default;
$product-view__summary-price-font-size: $font-size-large !default;
$product-view__summary-price-font-weight: $font-weight-bold !default;
$product-view__summary-unit-padding-left: 4px !default;
$product-view__summary-unit-font-size: $font-size-base !default;

$product-view__extra-actions-margin-bottom: $spacer--medium !default;
$product-view__extra-actions-margin-bottom\@medium: $spacer !default;
$product-view__extra-actions-margin-bottom\@large: 0 !default;
$product-view__extra-actions-padding: 0 $spacer !default;

$product-view__add-to-cart-margin: 0 0 $product-view__extra-actions-margin-bottom\@medium 0 !default;
$product-view__add-to-cart-margin\@large: 0 $product-view__add-to-cart-margin-right $product-view__extra-actions-margin-bottom\@large 0 !default;
$product-view__add-to-cart-btn-min-width: 150px !default;
$product-view__add-to-cart-btn-padding\@large: 0 $spacer--large !default;

$product-view__update-wishlist-btn-width: auto !default;

$product-view__gallery-bottom-border: 1px solid $gray !default;
$product-view__gallery-bottom-border\@medium: none !default;
$product-view__gallery-margin-bottom: $spacer--medium !default;
$product-view__gallery-margin-bottom\@medium: 0 !default;
$product-view__gallery-padding-bottom: $spacer !default;
$product-view__gallery-padding-bottom\@medium: 0 !default;

$product-view__information-padding: 0 20px !default;
$product-view__information-padding\@medium: 0 !default;

$product-view__main-actions-margin-bottom: 12px !default;

$product-view__main-details-bottom-spacing: $spacer--medium !default;

$product-view__order-gallery: 2 !default;
$product-view__order-main-details: 1 !default;
$product-view__order-secondary-details: 3 !default;

$product-view__price-bottom-margin: $spacer--medium !default;
$product-view__price-bottom-margin\@medium: 0 !default;
$product-view__price-color: $gray-darker !default;
$product-view__price-color--old: $gray !default;
$product-view__price-font-size: $font-size-large !default;
$product-view__price-font-weight: $font-weight-bold !default;

$product-view__qty-margin-right\@small: $spacer--medium !default;

$product-view__rating-link-color: $gray-dark !default;
$product-view__rating-width: 100% !default;
$product-view__rating-width\@medium: 45% !default;
$product-view__rating-top: 0 !default;
$product-view__rating-right: 0 !default;
$product-view__rating-margin: 0 0 14px 0 !default;
$product-view__rating-margin\@medium: 0 !default;
$product-view__rating-text-color: $gray-dark !default;
$product-view__rating-value-font-size: $font-size-base !default;

$product-view__sku-color: $gray !default;
$product-view__sku-margin-bottom: $spacer--medium !default;
$product-view__sku-margin-bottom\@medium: $spacer !default;
$product-view__sku-padding-right: 0% !default;

$product-view__stock-color: $gray-dark !default;
$product-view__stock-color--out-of-stock: $red !default;
$product-view__stock-color--alert: $red !default;
$product-view__stock-font-weight\@medium: $font-weight-bold !default;

$product-view__swatch-margin-bottom: $spacer--medium !default;
$product-view__swatch-margin-right: $spacer--medium !default;
$product-view__swatch-option-title-font-size: $font-size-base !default;
$product-view__swatch-option-title-font-weight: $font-weight-bold !default;

$product-view__slider-add-to-cart-padding: 0 $spacer--medium !default;
$product-view__slider-margin-bottom: $spacer !default;
$product-view__slider-margin-bottom\@medium: 72px !default;
$product-view__slider-margin-bottom--last\@medium: 56px !default;
$product-view__slider-margin-bottom--last\@large: 64px !default;
$product-view__slider-heading-font-size: 24px !default;
$product-view__slider-heading-font-size-weight: $font-weight-bold;
$product-view__slider-heading-text-transform: uppercase !default;

$product-view__tabs-margin: 0 (-$spacer) 14px (-$spacer) !default;
$product-view__tabs-margin\@medium: 0 0 72px 0 !default;

$product-view__additional-content-margin-bottom\@medium: 72px !default;

$product-view__review-item-padding: $spacer--large 0 !default;
$product-view__review-item-border: 1px solid $gray-light !default;
$product-view__review-details-margin-bottom: $spacer--medium !default;
$product-view__review-author-margin-bottom: $spacer !default;
$product-view__review-date-margin-bottom: $spacer !default;
$product-view__review-date-font-size: $font-size-small !default;
$product-view__review-date-color: $gray-light !default;
$product-view__review-content-title-font-weight: $font-weight-bold !default;
$product-view__review-product-name-font-weight: $font-weight-bold !default;

$product-view__wrapper-margin: $spacer--medium 0 $spacer--large 0 !default;
$product-view__wrapper-margin\@medium: 0 0 40px 0 !default;

$product-view__grouped-qty-margin: 0 !default;
$product-view__grouped-qty-max-width: 100px !default;
$product-view__grouped-qty-width: 120px !default;
$product-view__grouped-product-name-font-weight: $font-weight-bold !default;
$product-view__grouped-product-name-margin: 0 $spacer--medium 0 0 !default;
$product-view__grouped-product-name-margin\@medium: 0 0 $spacer !default;


.product-view__main-details {

  .product-brand-logo-container img {
    max-width: 100%;
    width: auto;
    float: right;
    max-height: 80px;
  }

  .product-view__uin {

    font-size: 14px;
    line-height: 1.5;
    color: #808080;

  }

  .product-view__sku{
    span.bold{ font-weight:600;}
  }
}

.grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: stretch;
}

.grid__column {
  flex: 1 0 100%;
  padding-left: 8px;
  padding-right: 8px;
}

@media all and (min-width: 480px) {
  .grid__column--1-2 {
    flex-basis: calc(50% - 8px);
    max-width: calc(50% - 8px);
  }
}

@media all and (min-width: 480px) {
  .grid__column--1-3 {
    flex-basis: calc(50% - 8px);
    max-width: calc(50% - 8px);
  }
}

@media all and (min-width: 768px) {
  .grid__column--1-3 {
    flex-basis: calc(33.33333% - 10.66667px);
    max-width: calc(33.33333% - 10.66667px);
  }
}

@media all and (min-width: 768px) {
  .grid__column--2-3 {
    flex-basis: calc(66.66667% - 5.33333px);
    max-width: calc(66.66667% - 5.33333px);
  }
}

@media all and (min-width: 768px) {
  .grid__column--1-4 {
    flex-basis: calc(25% - 12px);
    max-width: calc(25% - 12px);
  }
}

@media all and (min-width: 768px) {
  .grid__column--2-4 {
    flex-basis: calc(50% - 8px);
    max-width: calc(50% - 8px);
  }
}

@media all and (min-width: 768px) {
  .grid__column--3-4 {
    flex-basis: calc(75% - 4px);
    max-width: calc(75% - 4px);
  }
}

@media all and (min-width: 768px) {
  .grid__column--1-5 {
    flex-basis: calc(20% - 12.8px);
    max-width: calc(20% - 12.8px);
  }
}

@media all and (min-width: 768px) {
  .grid__column--2-5 {
    flex-basis: calc(40% - 9.6px);
    max-width: calc(40% - 9.6px);
  }
}

@media all and (min-width: 768px) {
  .grid__column--3-5 {
    flex-basis: calc(60% - 6.4px);
    max-width: calc(60% - 6.4px);
  }
}

@media all and (min-width: 768px) {
  .grid__column--4-5 {
    flex-basis: calc(80% - 3.2px);
    max-width: calc(80% - 3.2px);
  }
}

@media all and (min-width: 480px) {
  .grid__column--1-6 {
    flex-basis: calc(33.33333% - 10.66667px);
    max-width: calc(33.33333% - 10.66667px);
  }
}

@media all and (min-width: 768px) {
  .grid__column--1-6 {
    flex-basis: calc(16.66667% - 13.33333px);
    max-width: calc(16.66667% - 13.33333px);
  }
}

@media all and (min-width: 480px) {
  .grid__column--2-6 {
    flex-basis: calc(33.33333% - 10.66667px);
    max-width: calc(33.33333% - 10.66667px);
  }
}

@media all and (min-width: 480px) {
  .grid__column--3-6 {
    flex-basis: calc(50% - 8px);
    max-width: calc(50% - 8px);
  }
}

@media all and (min-width: 480px) {
  .grid__column--4-6 {
    flex-basis: calc(66.66667% - 5.33333px);
    max-width: calc(66.66667% - 5.33333px);
  }
}

@media all and (min-width: 768px) {
  .grid__column--5-6 {
    flex-basis: calc(83.33333% - 2.66667px);
    max-width: calc(83.33333% - 2.66667px);
  }
}

#maincontent .product-view {

  display: flex;
  flex-direction: column;
  margin-bottom: 100px;

  @media all and (max-width: 767px) {

    margin: 0;

  }

    .product-view__qty {

        &.rsa-product .product-view__swatch-option-title {

            display: block;

        }

    }


  .breadcrumbs.container {

    margin: 0;

    @media all and (max-width: 767px) {

      padding: 10px 0;

    }

  }

  &__wrapper {

    display: flex;
    flex-wrap: wrap;
    margin: $product-view__wrapper-margin;
    border-bottom: 1px #D8D8D8 solid;

    .dte-add-toquote-inner {

      .button {

        height: 48px;
        padding: 11px 28px;
        font-size: 18px;
        width: 100%;
        border-color: $white;

      }

    }

    @include mq($screen-m) {
      display: block;
      margin: $product-view__wrapper-margin\@medium;
    }

  }

  .tab__content--inner-title {

    border-bottom: 1px $thunder solid;
    padding: 0 0 8px;
    margin: 5px 0 20px;

    h3 {
      margin: 0;
    }

  }

  .ticket-form-content {
    #send-ticket-button {
      float: left;
      max-width: 400px;
      margin: 20px auto;
    }
  }

  &__form {
    width: 100%;
  }

  &__gallery {

    order: $product-view__order-gallery;
    width: 100%;
    border-bottom: $product-view__gallery-bottom-border;
    padding-bottom: $product-view__gallery-padding-bottom;
    margin: 0 0 15px;

    @include mq($screen-m) {
      border-bottom: $product-view__gallery-bottom-border\@medium;
      padding-bottom: $product-view__gallery-padding-bottom\@medium;
      float: left;
      width: 33%; //$product-view__col-left-width;
    }

    .fotorama__dot {

      width: 10px;
      height: 10px;
      border-radius: 50%;
      border: 1px $thunder solid;
      background: $white;
      transition: all 0.35s;

    }

    .fotorama__active .fotorama__dot,
    .fotorama__dot:hover {

      border: 1px $thunder solid;
      background: $thunder;
      cursor: pointer;

    }

    .gallery__thumb--active,
    .fotorama .fotorama__nav-wrap--vertical .fotorama__active,
    .fotorama .fotorama__nav-wrap--horizontal .fotorama__active {

      border: none;
      width: 22px;

    }

  }

  &__main-details {
    width: 100%;
    //order: $product-view__order-main-details;
    //display: flex;
    //flex-flow: row wrap;
    //align-items: center;
    display: block;
    @include mq($screen-m) {
      display: block;
      position: relative;
      width: calc((99% - 33%) - 285px); //$product-view__col-right-width;
      float: left;
      margin-bottom: 30px;
      min-height: 345px;
    }

    .product-brand-logo-container {
      position: absolute;
      bottom: 15px;
      right: 15px;
      max-width: 50%;
      width: 150px;

      img {

        max-width: 160px;
        max-height: 90px;
        float: right;

      }

    }

    @media all and (max-width: 767px) {

      position: relative;

      .product-brand-logo-container {
        img {
          max-width: 100px;
          float: right;
        }
      }

    }
  }

  &__secondary-details {

    display: flex;
    flex-wrap: wrap;
    order: $product-view__order-secondary-details;
    width: 100%;
    //background: #F6F6F6;
    padding: 30px;
    border: 1px #979797 solid;

    @include mq($screen-m) {
      display: block;
      width: 285px; //$product-view__col-right-width;
      float: right;
    }

    .dte-add-toquote-container {

      .button__text-mobile {
        display: none !important;
      }

      .button__text-desktop {
        display: inline !important;
      }

    }

    //.product-view__qty {
    //
    //  display: inline-block;
    //  width: auto;
    //  max-width: 120px;
    //  margin-bottom: 10px;
    //
    //  @media all and (max-width: 1280px) {
    //
    //    max-width: none;
    //
    //  }
    //
    //  .quantity-update {
    //
    //    display: flex;
    //    align-items: center;
    //
    //    input#qty {
    //
    //      width: 100%;
    //      padding: 0 10px 0 10px;
    //      vertical-align: middle;
    //
    //      @media all and (max-width: 1280px) {
    //
    //        width: 70px;
    //
    //      }
    //
    //    }
    //
    //    .quantity-update__button-container {
    //
    //      display: inline-block;
    //      background: lightpink;
    //
    //      .quantity-update__button {
    //
    //        width: 100%;
    //        height: 30px;
    //        padding: 5px;
    //        border: none;
    //        border-radius: 0;
    //
    //        &--minus {
    //        }
    //
    //        &--plus {
    //        }
    //
    //        .quantity-update__icon {
    //
    //          width: 14px;
    //          height: 14px;
    //
    //        }
    //
    //      }
    //
    //    }
    //
    //  }
    //
    //}

    .delivery-returns-link {
      margin-top: 10px;
      margin-bottom: 10px;

      a {
        font-weight: bold;
      }
    }

  }

  &__title {

    width: 100%;
    margin-bottom: $product-view__base-elements-vertical-spacing;

    .heading--page {

      margin: 15px 0 0;
      font-size: $product-view__title-font-size;
      text-transform: inherit;

    }

  }

  &__price {
    width: 50%;
    font-size: $product-view__price-font-size;
    font-weight: $product-view__price-font-weight;
    margin: 0 0 12px;

    @include mq($screen-m) {
      //float: left;
      width: 100%;
      //padding-right: 55%;
      //margin-bottom: $product-view__price-bottom-margin\@medium;
    }

    .price__value {
      color: $product-view__price-color;

      &--old {
        color: $product-view__price-color--old;
      }
    }
  }

  &__description {
    order: 2;
    margin: $product-view__description-margin;
    color: $product-view__description-color;
    width: 100%;
    padding: $product-view__description-padding;
    border-top: $product-view__description-border-top;
    border-bottom: $product-view__description-border-bottom;
    line-height: $product-view__description-line-height;

    @include mq($screen-m) {
      padding: $product-view__description-padding\@medium;
      margin: $product-view__description-margin\@medium;
    }
  }

  &__description-bundle {
    order: 0;
    margin: $product-view__description-bundle-margin;
    padding: $product-view__description-bundle-padding;
  }

  &__description-title {
    font-weight: $product-view__description-title-font-weight;
  }

  &__customization {
    width: 100%;
    margin-bottom: $product-view__customization-space;
    padding-bottom: $product-view__customization-space;
    border-bottom: $product-view__customization-border;
  }

  &__summary {
    width: 100%;
    padding: $product-view__summary-padding;
    margin: $product-view__summary-margin;
    border-bottom: $product-view__summary-border;
    border-top: $product-view__summary-border;

    @include mq($screen-m) {
      border-bottom: 0;
      margin: 0;
    }
  }

  &__summary-price {
    display: flex;
    align-items: center;
    margin-bottom: $product-view__summary-price-margin-bottom;
    font-weight: $product-view__summary-price-font-weight;
    font-size: $product-view__summary-price-font-size;
  }

  &__summary-unit {
    padding-left: $product-view__summary-unit-padding-left;
    font-size: $product-view__summary-unit-font-size;
  }

  &__summary-title {
    font-weight: $product-view__summary-title-font-weight;
    margin-bottom: $product-view__summary-item-space;
  }

  &__summary-list {
    padding-left: $product-view__summary-item-space;
  }

  &__summary-item-title {
    display: block;
    font-weight: $product-view__summary-title-font-weight;
  }

  &__options {
    width: 100%;
    padding:15px 0;

    .swatch {
      margin-bottom: $product-view__swatch-margin-bottom;

      &__option-container {
        margin-right: $product-view__swatch-margin-right;
      }
    }

    select{
        border-radius: none;
        color: #4c4555;
        -webkit-appearance: none;
        -moz-appearance: none;
        padding-top: 0;
        padding-bottom: 0;
        line-height: 1;
        border: 0.8px solid #ebebeb;
        border-radius: 4px;
        box-shadow: 0 2px 0 0 #ebebeb;
        background: #fff;
        padding: 10px;
    }
  }

  &__swatch-option-title {
    font-size: $product-view__swatch-option-title-font-size;
    font-weight: $product-view__swatch-option-title-font-weight;
  }

  &__tabs {

    line-height: 1.5;
    /*margin: $product-view__tabs-margin;

    @include mq($screen-m) {
        margin: $product-view__tabs-margin\@medium;
    }*/

    .block-owl-carousel-items-list {
      margin-bottom: 0;
    }


  }

  &__tab {

    .tab__content {
      line-height: $product-view__description-line-height;
    }

  }


  .tech-spec-entry__specification {

    margin-bottom: 6px;
    font-size: 14px;
    line-height: 18px;

  }

  .tech-spec-entry__title {
    margin: 30px 0 20px;
  }

  .dte-product-specs {

    > .row > .col-md-12 {

      padding-left: 0;

    }

    .tech-specs-description {

      padding: 15px;
      background-color: #f6f6f6;

    }

    .tab__content--inner-title {

      margin-bottom: 0;

      &:first-of-type {

        margin-bottom: 15px;

      }

    }

    .divider {
      margin-top: 20px;
    }

    .tech-spec-entry__specification {

      > .row {

        padding: 15px 0;

      }

      &:nth-child(even) {

        background-color: #f6f6f6;

      }

    }

    .tech-specs-15-cutoff {

        display: none;


      &.show {

          display: block;

      }

    }

    .show-more-tech-specs-btn {

      margin-top: 30px;

      .more {

        display: inline;

      }

      .less {

        display: none;

      }

      &.less {

        .more {

          display: none;

        }

        .less {

          display: inline;

        }

      }

    }

  }

  &__information {
    order: 3;
    width: 100%;
    padding: $product-view__information-padding;

    @include mq($screen-m) {
      padding: $product-view__information-padding\@medium;
    }
  }

  &__stock-status {
    color: $product-view__stock-color;
    width: 100%;
    text-align: left;
    margin: 12px 0 30px;
    //margin-bottom: $product-view__price-bottom-margin;

    //@include mq($screen-m) {
    //
    //    margin-bottom: 0;
    //    float: right;
    //    font-weight: $product-view__stock-font-weight\@medium;
    //    position: absolute;
    //    right: 0;
    //    width: 45%;
    //    bottom: 0;
    //    text-align: right;
    //
    //}

    &--out-of-stock {
      color: $product-view__stock-color--out-of-stock;
    }

    &--alert {
      color: $product-view__stock-color--alert;
    }
  }

  &__rating {
    color: $product-view__rating-text-color;
    width: $product-view__rating-width;
    display: flex;
    margin: $product-view__rating-margin;
    justify-content: space-between;

    @include mq($screen-m) {
      position: absolute;
      top: $product-view__rating-top;
      right: $product-view__rating-right;
      width: $product-view__rating-width\@medium;
      justify-content: flex-end;
      margin: $product-view__rating-margin\@medium;
    }
  }

  &__rating-rates {
    width: 50%;
    display: flex;
    flex: 1 0 auto;
    align-items: center;
    font-size: $product-view__rating-value-font-size;

    @include mq($screen-m) {
      width: auto;
      justify-content: flex-end;
    }
  }

  &__rating-actions {
    width: 50%;
    flex: 1 0 auto;
    text-align: right;

    @include mq($screen-m) {
      width: auto;
    }
  }

  &__main-actions {
    //display: flex;
    width: 100%;
    //justify-content: space-between;
    //align-items: flex-start;
    margin-bottom: $product-view__main-actions-margin-bottom;
    //flex-wrap: wrap;

    @include mq($screen-l) {
      //flex-wrap: nowrap;
      //align-items: flex-end;
    }
  }

  &__actions {
    clear: both;
  }

  &__extra-actions {
    //display: flex;
    //align-items: center;
    //justify-content: center;
    //flex: 0 0 auto;
    padding: $product-view__extra-actions-padding;
    max-width: 200px;

    .product-view__action--button {

      width: auto;
      margin: 0;
      padding: 8px 0;
      height: auto;

    }

    @include mq($screen-l) {
      margin-bottom: $product-view__extra-actions-margin-bottom\@large;
      padding: 0;
    }

    @include mq($screen-m) {

      position: absolute;
      bottom: 0;
      left: 0;

    }

  }

  &__qty {
    .product-view__swatch-option-title {
      display: none;
    }
  }

  //&__qty {
  //  display: block;
  //  width: 100%;
  //  //flex-basis: 100%;
  //  //margin-bottom: $product-view__extra-actions-margin-bottom;
  //  flex: none;
  //  margin-bottom: 30px;
  //
  //  .product-view__swatch-option-title {
  //    display: none;
  //  }
  //
  //  @include mq($screen-s) {
  //    //flex: 0 0 auto;
  //    //margin-right: $product-view__qty-margin-right\@small;
  //  }
  //
  //  /*@include mq($screen-l) {
  //      margin-bottom: $product-view__extra-actions-margin-bottom\@large;
  //  }*/
  //}

  &__grouped-qty {
    margin: $product-view__grouped-qty-margin;
    max-width: $product-view__grouped-qty-max-width;
  }

  &__grouped-table {
    table-layout: fixed;
  }

  &__grouped-col-qty {
    width: $product-view__grouped-qty-width;
  }

  &__grouped-product-name {
    display: block;
    font-weight: $product-view__grouped-product-name-font-weight;
    margin: $product-view__grouped-product-name-margin;

    @include mq($screen-m) {
      margin: $product-view__grouped-product-name-margin\@medium;
    }
  }

  &__add-to-cart-btn,
  &__update-cart-btn {
    //flex: 1 0 auto;
    //width: 100%;
    //min-width: $product-view__add-to-cart-btn-min-width;
    //min-width: 200px;
    width: 100%;
    background-color: $green;

    &:hover,
    &:focus,
    &:active {
      background-color: $frog-green !important;
      color: white !important;
      border: none !important;
    }

    svg {
      order: -1;
      margin-right: 10px;
    }

    @include mq($screen-l) {
      //padding: $product-view__add-to-cart-btn-padding\@large;
    }
  }

  &__add-to-cart,
  &__update-cart {
    width: 100%;
    //margin: $product-view__add-to-cart-margin;

    @include mq($screen-l) {
      //flex: 0 0 auto;
      //margin: $product-view__add-to-cart-margin\@large;
    }
  }

  &__update-wishlist {
    width: $product-view__update-wishlist-btn-width;
  }

  &__link {
    &--rating {
      text-decoration: none;
      color: $product-view__rating-link-color;

      &:hover {
        cursor: pointer;
      }
    }

    &--add-review {
      text-decoration: none;
      font-size: $product-view__add-review-link-font-size;

      @include mq($screen-m) {
        font-size: $product-view__add-review-link-font-size\@medium;
      }
    }
  }

  &__sku {

    width: 100%;
    color: $product-view__sku-color;
    margin-bottom: $product-view__sku-margin-bottom;
    font-size: 18px;

    @include mq($screen-m) {
      display: block;
      margin-bottom: $product-view__sku-margin-bottom\@medium;
      padding-right: $product-view__sku-padding-right;
    }

  }

  &__review {
    @include mq($screen-m) {
      width: 430px;
    }
  }

  &__review-item {
    width: 100%;
    padding: $product-view__review-item-padding;
    border-top: $product-view__review-item-border;

    &:first-child {
      border-top: 0;
    }

    &:last-child {
      border-bottom: $product-view__review-item-border;
    }
  }

  &__review-details {
    margin-bottom: $product-view__review-details-margin-bottom;

    @include mq($screen-m) {
      margin-bottom: 0;
    }
  }

  &__review-author {
    margin-bottom: $product-view__review-author-margin-bottom;
  }

  &__review-date {
    margin-bottom: $product-view__review-date-margin-bottom;
    font-size: $product-view__review-date-font-size;
    color: $product-view__review-date-color;
  }

  &__review-content-title {
    font-weight: $product-view__review-content-title-font-weight;
  }

  &__review-title {
    margin-bottom: 0;
    text-transform: uppercase;
  }

  &__review-product-name {
    font-weight: $product-view__review-product-name-font-weight;
  }

  &__slider {
    margin-bottom: $product-view__slider-margin-bottom;

    @include mq($screen-m) {
      margin-bottom: $product-view__slider-margin-bottom\@medium;
    }

    &:last-child {
      @include mq($screen-m) {
        margin-bottom: $product-view__slider-margin-bottom--last\@medium;
      }

      @include mq($screen-l) {
        margin-bottom: $product-view__slider-margin-bottom--last\@large;
      }
    }

    .heading {
      font-size: $product-view__slider-heading-font-size;
      font-weight: $product-view__slider-heading-font-size-weight;
      text-transform: $product-view__slider-heading-text-transform;
    }
  }

  &__slider-navigation {
    @include mq($screen-l) {
      display: none;
    }
  }

  &__additional-content {


  }

  &__grid-item {
    .catalog-grid-item__name {
      font-size: 12px;
      text-transform: initial;
    }

    .catalog-grid-item__primary-action {
      padding: $product-view__slider-add-to-cart-padding;
    }
  }

  &__slider {
    overflow: hidden;

    .slider__handler {
      margin-right: -$spacer--medium;
    }
  }

  &__slide-item {
    padding-right: $spacer--medium;
  }

  @media all and (min-width: 768px) {

    .list-attributepages {

      .attributepages-option-image {
        display: inline-block;
      }

    }

  }

  @media all and (min-width: 768px) {

    .list-attributepages {

      position: absolute;
      bottom: 0;
      right: 10px;

    }

  }

}

body.catalog-product-view {
    &.product-dte-wireless-design-eu-estimate,
    &.product-dte-post-install-wireless-survey-estimate-eu {
        .product-view {
            .develo-product-info-container {
                .product-view__secondary-details {
                    .product-secondary-actions {
                        #product_addtocart_form{
                            .product-view__main-actions {
                                display:none;
                            }
                        }
                    }
                }
            }

        }
    }

  #maincontent {
    padding: 0;
  }

    // Amazon Sidebar spacing for product view.
    &.amazon-store  {

        .column.main + .sidebar {

            @media all and (min-width: 1200px) {

                margin-right: 20px;

            }

        }

        #maincontent {

            .column.main:only-child {

                max-width: 100% !important;

            }

        }

    }

  @media (min-width: 768px) and (max-width: 1200px) {

    #maincontent {

      .product-view__title .heading--page {
        margin: 0;
      }

      .product-view {

        margin: 0;
        padding: 0 20px;

      }

      .product-view__main-details {

        .product-brand-logo-container {

          max-width: 100px;
          width: 100px;

          img {
            max-width: 100px;
          }

        }

      }

      .product-view__extra-actions {

        padding: 0;
        max-width: calc(100% - 115px);

        .product-view__action--button + .product-view__action--button {
          margin-left: 15px;
        }

      }

      .product-view__gallery {
        margin: 0 0 5px;
      }

      .product-view__gallery,
      .product-view__main-details,
      .product-view__secondary-details {

        width: 100%;
        float: none;
        min-height: 0;

      }


    }

  }

  @media (min-width: 768px) {

    &.page-layout-2columns-left {

      .main {

        flex: 1 0 100%;
        max-width: 100%;

      }

      .sidebar {

        flex: 1 0 100%;
        max-width: 100%;
        padding: 0;
        box-shadow: 5px 0 5px -5px rgba(178, 176, 176, 0.5);

        .category-sidebar-categories-container {

          .category-sidebar-categories--toggle {
            display: none;
          }

        }

      }

    }

  }

  @media (max-width: 767px) {

    &.page-layout-2columns-left {

      .main {

        flex: 1 0 100%;
        max-width: 100%;

      }

      .sidebar {

        flex: 1 0 100%;
        max-width: 100%;
        padding: 0;
        border: 1px $thunder solid;

      }

    }

  }

}

.catalog-product-view {

  .product-view__price {

    min-height: 32px;
    margin-bottom: 20px;

    .price {

      > .price__value {

        > .price-container {

          display: flex;
          flex-wrap: wrap;

          [class*="price-including-tax"],
          [class*="price-excluding-tax"] {

            flex-basis: 100%;

          }

        }

      }

    }

    .price-box {

      visibility: hidden;

      &.price-final_price {

        &.realtime-info-parsed {

          visibility: visible;
          display: flex;
          flex-wrap: wrap-reverse;

          .price-container {

            flex-basis: 100%;

          }

        }

      }

    }

  }

  #maincontent {

    .product-view__wrapper {

      display: flex;
      justify-content: space-between;
      flex-direction: row-reverse;
      position: relative;
      border: none;
      margin-bottom: 0;

      @media all and (min-width: 768px) {
        padding: 0 35px;
      }

      > .product-view__gallery {

        width: 50%;
        position: relative;
        margin-top: 26px;

        > .gallery {

          display: none;

        }

        .fotorama-item {

          max-width: 600px;
          height: auto;
          margin: 0 auto;

        }

        > .product-brand-logo-container {

          position: absolute;
          top: 24px;
          right: 0;
          width: 75px;

        }

        .develo-product-gallery-main {

          margin-left: 126px;

          > img {

            cursor: pointer;
            max-height: 100%;
            width: auto;
            margin: 0 auto;
            padding-top: 50px;

          }

          &.fullscreen {

            position: fixed;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: #fff;
            z-index: 9999999;
            max-width: none;
            margin: 0;

            > img {

              display: none;

            }

            .develo-product-large-image-container {

              position: fixed;
              bottom: 10%;
              left: 0;
              right: 0;
              background-color: white;
              z-index: 9999999;
              background-repeat: no-repeat;
              background-position: center center;
              background-size: contain;
              width: 100%;
              height: 50%;
              max-width: 1000px;
              margin: 0 auto;

            }

            ~ .sidebar-gallery-container {
              .develo-product-gallery-thumbs-btn {
                position: fixed;
                z-index: 999999999;
                background: rgba(255, 255, 255, 0.2);
                max-width: 90px;
                width: 25%;
                left: 0;
                height: 30px;
                box-shadow: rgba(0, 0, 0, 0.1) 0 1px 3px 0, rgba(0, 0, 0, 0.06) 0 1px 2px 0;

                &-prev {
                  top: 0;
                    background-color: #fff;
                }

                &-next {
                  bottom: 0;
                }
              }

              .develo-product-gallery-thumbs-vertical {

                position: fixed;
                left: 0;
                top: 30px;
                bottom: 0;
                overflow-y: auto;
                background: rgba(255, 255, 255, 0.2);
                width: 25%;
                z-index: 99999999;
                max-height: calc(100% - 60px);
                max-width: 90px;
                padding: 5px;
                box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;

              }

            }

          }

          .develo-product-gallery-fullscreen-close {

            position: fixed;
            border: 1px solid #c7c7c7;
            top: 10px;
            right: 10px;
            border-radius: 50%;
            width: 50px;
            height: 50px;
            padding: 12px;
            z-index: 100000;
            cursor: pointer;
            background: none;
            transition: background-color 200ms ease-in-out;
            box-shadow: rgba(199, 199, 199, 0.4) 0 1px 3px 0, rgba(199, 199, 199, 0.4) 0 1px 2px;

            &:hover {

            }

          }

        }

        .develo-product-gallery-thumbs-vertical {

          position: absolute;
          left: 0;
          top: 0;
          bottom: 0;
          max-height: 100%;
          overflow-y: auto;
          background: white;
          width: 96px;
          z-index: 1;

          .develo-product-gallery-item {

            display: block;
            margin: 12px 0;

            &:first-child {
              margin-top: 23px;
            }

            &:last-child {
              margin-bottom: 23px;
            }

          }

        }

        .develo-product-gallery-thumbs-btn {

          display: block;
          text-align: center;
          line-height: 1;
          position: absolute;
          left: 0;
          width: 96px;
          z-index: 2;

          &-prev {
            top: 0;
          }

          &-next {
            bottom: -30px;
          }

        }

      }

      > .develo-product-info-container {

        width: 50%;
        float: left;
        padding-left: 30px;
        margin-top: 50px;

        @media all and (max-width: 992px) {

          padding: 0;
          margin-top: 40px;

        }

        .product-view__main-details {

          width: 100%;
          min-height: 0;

          .product-view__title {

            h1 {

              font-weight: 500;
              font-size: 24px;
              margin: 0;

            }

          }

          .product-view__sku {

            color: $mid-grey;
            font-size: 13px;
            margin: 15px 0;
            text-transform: uppercase;

          }

          .short-description {

            p {

              display: inline;

            }

            .delivery-returns-link {

              margin-top: 40px;

              a {

                font-weight: bold;

              }

            }

          }

          .see-full-description {

            a {

              font-weight: bold;

            }

          }

        }

        .product-view__secondary-details {

          width: 100%;
          border: none;
          padding: 0px;
          margin-bottom: 50px;

          .product-view__extra-actions {

            position: static;
            display: flex;
            justify-content: space-between;
            max-width: none;
            margin-top: 15px;

            .product-view__action--button {

              background-color: transparent;
              font-size: 14px;
              margin-right: 15%;

              .button__icon {

                width: 15px;

              }

            }

            @media all and (max-width: 768px) {

              width: 100%;
              padding: 0;

              .product-view__action--button {

                font-size: 12px;
                margin-right: 0;

              }

            }

          }

          .dte-add-toquote-container,
          #payment-request-button {

            display: none;

          }

          .product-view__main-actions {

            .product-view__add-to-cart {

              min-width: 140px;
              position: relative;

            }

            display: flex;
            justify-content: space-between;

            #product-addtocart-button {

              font-size: 14px;
              padding: 9px 0;
                height: 44px;
              //margin-bottom: 7px;

              .button__icon {

                width: 20px;

                @media all and (min-width: 992px) and (max-width: 1169px) {

                  display: none;

                }

              }

            }

            //.product-view__qty {
            //
            //  display: inline-block;
            //  width: auto;
            //  max-width: 30%;
            //  margin-bottom: 0;
            //
            //  @media all and (max-width: 1280px) {
            //
            //    max-width: none;
            //
            //  }
            //
            //  .quantity-update {
            //
            //    display: inline-block;
            //
            //    input#qty {
            //
            //      //border: 1px solid #4c4555;
            //      border-right: none;
            //      width: 55px;
            //      padding: 0 10px 0 13px;
            //      //border-radius: 100px 0 0 100px;
            //      vertical-align: top;
            //
            //      @media all and (max-width: 1280px) {
            //
            //        width: 70px;
            //
            //      }
            //
            //    }
            //
            //    .quantity-update__button-container {
            //
            //      display: inline-block;
            //      margin-left: -10px;
            //
            //      .quantity-update__button {
            //
            //        width: 85%;
            //        height: 24px;
            //        padding: 0 5px 0 0;
            //        //border: 1px solid #4c4555;
            //        //border-left: none;
            //
            //        &--minus {
            //
            //          //border-radius: 0 0 100px 0;
            //          //border-top: none;
            //
            //        }
            //
            //        &--plus {
            //
            //          //border-radius: 0 100px 0 0;
            //          //border-bottom: none;
            //
            //        }
            //
            //        .quantity-update__icon {
            //
            //          width: 12px;
            //          height: 12px;
            //
            //        }
            //
            //      }
            //
            //    }
            //
            //  }
            //
            //}

            .product-view__action-elements {

              flex: 1;

            }

          }

          .price-container.price-main {

            margin-right: 10px;

          }

          .product-view__uin {

            display: none;
            font-size: 14px;
            font-weight: normal;

          }

          .price-main + .product-view__uin {

            display: inline;

          }

        }

        .product-view__stock-status {

          color: $alternative-green;
          font-weight: 500;
          margin: 15px 0 4px;
          font-size: 13px;
          display: flex;
          align-items: center;

            img {

                padding-bottom: 2px;

            }

          svg {

            //display: none;
            margin-right: 5px;

          }

          + .delivery-returns-link {

            margin-bottom: 10px;
            display: inline-block;
            font-size: 14px;

          }

        }

        .price-box {

          .price-old_price {

            text-decoration: line-through;
            font-style: italic;
            font-size: 14px;
            color: #2f338a;
            display: block;
            margin-bottom: 5px;

            span.price {

              &:after {

                content: none;

              }

            }

          }

          .price-final_price {

            &.price-main {

              font-style: bold;

            }

            span.price {

              &:after {

                content: none;
                margin: 0;

              }

            }

            span.price-excluding-tax {
            }

            span.price-including-tax {

              font-size: 13px;
              font-style: italic;
              display:none;

            }


            &.inc-vat-info {

              font-size: 1px;
              font-weight: normal;
              display: block;
              margin: 10px 0 0;
              font-style: italic;
              display: none;

            }

          }

        }

        &:not(.inc-vat-info) {

          span.price {

            &:after {

              content: none;
              margin: 0;
              font-size: 15px !important;

            }

          }

        }

      }

      .develo-product-gallery {

        position: relative;

      }

    }

    .product-view__tabs {

      @media all and (min-width: 768px) {
        padding: 0 35px;
      }

      .tab__content--inner-title {

        border: none;
        display:none;
        h3 {

          font-weight: 600;
          font-size: 17px;

        }

      }

        .description {
            .value {
                line-height: 24px;
            }

            ul.reason-to-buy {
                padding: 0px;

                li {
                    display:list-item;
                    align-items: center;
                    padding: 10px 0px;
                    margin-left: 20px;
                    list-style: square;
                    line-height: 21px;
                }

                img {
                    left: 1px;
                    height: auto;
                    display: inline;
                    max-width: 100px;
                }

                &.have-images{
                    li {
                        display:flex;
                        list-style-type: none;
                        padding: 10px  0;
                        margin-left:0px;
                    }

                    span{
                      display:block;
                      float:left;
                      margin-left:10px;
                    }
                }
            }

        }

    }

    @media all and (max-width: 992px) {

      .product-view__wrapper {

        > .develo-product-info-container,
        > .product-view__gallery {

          float: none;
          width: 100%;
          order: unset;

          .product-view__main-details,
          .product-view__secondary-details {

            width: 50%;
            float: left;

          }

        }

      }

    }

    @media all and (max-width: 600px) {

      .product-view__wrapper {

        > .develo-product-info-container {

          .product-view__main-details,
          .product-view__secondary-details {

            width: 100%;
            float: none;

          }

          .product-view__main-details {

            margin-bottom: 20px;

          }

        }

      }

    }

  }

}

.catalog-product-view.amazon-store {

  #maincontent {

    .product-view__wrapper {

      > .develo-product-info-container {

        .product-view__secondary-details {

          .product-view__main-actions {

            .product-view__qty {

              max-width: none;
              margin-bottom: 10px;

              + .product-view__action-elements {

                margin-left: 0;
                width: auto;
                max-width: none;

              }
            }
          }
        }
      }
    }
  }
}

.page-product-configurable #maincontent .product-view__stock-status {
  display: none;
}

#product-options-wrapper .configurable.field {
  margin: 30px 0;
}

.quantity-update {

  .mage-error {

    position: absolute;

  }

}

body.catalog-product-view {

  #mobile-fixed-add-to-cart {

    display: flex;
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 2;
    padding: 15px;
    background-color: #f6f6f6;

    @media all and (min-width: 767px) {
      display: none !important;
    }

    //.product-view__qty {
    //
    //  display: inline-block;
    //  width: auto;
    //  max-width: 30%;
    //  margin-bottom: 0;
    //
    //  @media all and (max-width: 1280px) {
    //
    //    max-width: none;
    //
    //  }
    //
    //  .quantity-update {
    //
    //    display: inline-block;
    //
    //    input.mirror-qty {
    //
    //      border: 1px solid #4c4555;
    //      border-right: none;
    //      width: 55px;
    //      padding: 0 10px 0 13px;
    //      border-radius: 100px 0 0 100px;
    //      vertical-align: top;
    //      height: 42px;
    //
    //      @media all and (max-width: 1280px) {
    //
    //        width: 60px;
    //
    //      }
    //
    //    }
    //
    //    .quantity-update__button-container {
    //
    //      display: inline-block;
    //      margin-left: -10px;
    //
    //      .quantity-update__button {
    //
    //        width: 85%;
    //        height: 21px;
    //        padding: 0 5px 0 0;
    //        border: 1px solid #4c4555;
    //        border-left: none;
    //
    //        &--minus {
    //
    //          border-radius: 0 0 100px 0;
    //          border-top: none;
    //
    //        }
    //
    //        &--plus {
    //
    //          border-radius: 0 100px 0 0;
    //          border-bottom: none;
    //
    //        }
    //
    //        .quantity-update__icon {
    //
    //          width: 12px;
    //          height: 12px;
    //
    //        }
    //
    //      }
    //
    //    }
    //
    //  }
    //
    //}

    .add-to-cart-btn-mirror {

      flex: 1;
      align-self: center;

      .button {

        width: 100%;
        font-size: 15px;
        height: 48px;
        padding: 9px 0;
        color: white;
        border: 0;

        .button__text {

          margin-right: 10px;

        }

        &:hover,
        &:focus {

          background-color: $frog-green !important;

        }

      }

    }

  }

  .product-view__tabs {
    font-size: 15px;

    .dte-folder-pdf-downloads {

      .button {

        &:hover {

          svg {

            use {

              stroke: $dte-bright-blue;

            }

          }

        }
      }

    }

  }

  // New Design Secondary Actions Container

  #maincontent {
    .product-view__wrapper {
      .product-secondary-actions {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: space-between;

        .product-view {

          &__price {
            width: max-content;
            margin: 0;

            .price-box {
                display: grid;

                .price-main {

                    order: 2;

                }
            }

          }

          &__stock-status {
            width: max-content;
            margin: 0 0 0 5px;
            justify-content: center;
          }

          &__main-actions {
            margin: 0;
          }

          &__action--compare {
            display: none;
          }

          &__form {
            flex-basis: 30%;

            #instant-purchase {
              + div {
                position: absolute;
                width: 100%;
                height: 100%;
                margin-top: 10px;
                max-width: 180px;
              }

            }

          }

        }

        .product-wishlist-button {

          flex-basis: 10%;
          display: flex; // Fix odd spacing of SVG WxH
          justify-content: center;

            .button__icon {

                width: 21px;
                height: 21px;

            }

        }

      }

      // @media all and (max-width: 1250px) {
      //   .product-secondary-actions {
      //     justify-content: flex-start;

      //     .product-view {

      //       &__price {
      //         order: 1;
      //         flex-basis: 100%;
      //         margin-bottom: 10px;

      //         .price-box {
      //           margin: 0;
      //         }

      //       }

      //       &__stock-status {
      //         order: 2;
      //         width: max-content;
      //         margin-bottom: 10px !important;
      //         justify-content: flex-start;
      //       }

      //       &__form {
      //         order: 3;
      //         flex-basis: 100%;

      //         .product-view__main-actions {
      //           max-width: 265px;
      //         }
      //       }

      //     }

      //     .product-wishlist-button {
      //       order: 2;
      //       flex-basis: 30%;
      //       margin-bottom: 10px;
      //       justify-content: flex-start;
      //     }

      //   }

      // }

      // @media all and (max-width: 992px) {

      //   .develo-product-info-container {
      //     display: flex;
      //     align-items: center;
      //     justify-content: space-between;
      //     margin: 30px 0 30px 0;

      //     .product-view {

      //       &__main-details {
      //         flex-basis: 45%;
      //       }

      //       &__secondary-details {
      //         flex-basis: 45%;

      //         .product-view__stock-status {
      //           flex-basis: 40%;
      //         }

      //       }

      //     }

      //   }

      // }

      @media all and (max-width: 767px) {

        .product-view__gallery {
          border: none;
        }

        .delivery-returns-link {
          flex-basis: 100%;
        }

      }

      @media all and (max-width: 600px) {

        .develo-product-info-container {
          flex-wrap: wrap;

          .product-view {
            &__main-details {
              flex-basis: 100%;
            }

            &__secondary-details {
              flex-basis: 100%;
            }

          }

        }

      }

    }

    .product-view__qty {
      margin-right: 10px;
    }

    .add-to-cart-btn-mirror {
      align-self: center;

      .button {
        background-color: $green;

        &:hover,
        &:focus,
        &:active {
          background-color: $frog-green !important;
          color: white !important;
          border: none !important;
        }

        svg {
          order: -1;
          margin-right: 10px;
        }

      }

    }

    .product-bottom-info {
      display: flex;
      margin-top: 20px;

      .product-view__tabs {
        flex-basis: 64%;
        max-width: 64%;

        .tab {
          > .tab__title:first-child {
            padding-left: 0;
          }

          .field[class*="ticket-field-"]:not(:last-child) {
            .input__field {
              margin-bottom: 16px;
            }
          }
        }
      }

      .product-related-container {
        flex-basis: 36%;
        max-width: 36%;
        margin: 0 auto;
        padding-right: 35px;
      }

      .popular-products-container {

        position: relative;
        border: 1px solid #e0e0e0;

        .popular-product-container {

          padding: 70px 25px 25px;

          .popular-product-image {

            width: 100%;
            margin: 0 auto;

            img {

              width: auto !important;
                height: 235px;
              margin: 0 auto;

            }

          }

          h2.product-name {

            font-size: 18px;
            font-weight: 500;
            margin-top: 15px;
            padding-top: 0;
            width: 95%;

          }

          .product-sku-container {

            color: #949494;
            margin: 17px 0;
            font-size: 13px;

          }

          .price-box {

            .price-container {

              span.price {

                font-size: 28px;
                display: inline-block;

                &::first-letter {

                  font-size: 16px;
                  font-weight: normal;

                }

              }

            }

          }

          .price-box {

            .price-container {

              span.price {

                font-size: 28px;
                display: inline-block;

                &::first-letter {

                  font-size: 16px;
                  font-weight: normal;

                }

              }

            }

          }

          .product-bottom-section-container {

            display: flex;
            align-items: center;
            flex-wrap: wrap;

            .product-price-container {

              order: 1;
              margin-right: 30px;

            }

            .product-stock-container {

              order: 2;

              @media all and (min-width: 901px) and (max-width: 1280px) {

                order: 3;
                width: 100%;
                margin-top: 5px;

              }

              @media all and (max-width: 460px) {

                order: 3;
                width: 100%;
                margin-top: 5px;

              }

              .stock-status-inner {

                display: flex;
                align-items: center;
                color: #19B823;

                img, svg {

                  width: 20px;
                  margin-right: 5px;

                }

              }

            }

            .product-wishlist-container {

              order: 3;
              margin-left: auto;
              text-align: right;

              @media all and (min-width: 901px) and (max-width: 1280px) {

                order: 2;

              }

              @media all and (max-width: 460px) {

                order: 2;

              }

            }

            @media all and (min-width: 1024px) and (max-width: 1100px) {

              flex-wrap: wrap;

              .product-price-container {

                order: 1;

              }

              .product-stock-container {

                order: 3;

              }

              .product-wishlist-container {

                order: 2;

              }

            }


          }

          .price-wrapper {

            font-size: 26px;
            font-weight: 600;

            span {

              display: block; // required for first letter

              &::first-letter {

                font-size: 16px;
                font-weight: normal;

              }

            }

            ::after {

              content: 'ex VAT';
              display: inline;
              width: 100%;
              height: 100%;
              font-weight: normal;
              font-size: 16px;
              margin-left: 5px;

            }

          }

        }

        .popular-products-title {

          position: absolute;
          top: 0;
          z-index: 1;
          padding: 20px 30px;

          h5 {

            font-size: 14px;
            font-weight: 600;
            color: #949494;

          }

        }

        .owl-dots {

          position: absolute;
          top: 0;
          right: 30px;
          width: auto;

          .owl-dot {

            > span {

              background-color: #efefef;
              border-color: #efefef;
              width: 8px;
              height: 8px;

            }

            &:hover {

              > span {

                background-color: #949494;

              }

            }

            &.active {

              > span {

                background-color: #949494;
                border-color: #949494;

              }

            }

          }

        }

        .owl-stage-outer {

          padding: 0;

        }

      }

      .help-and-support-container {
        margin-top: 20px;

        .content-container {
          background: #526b79;
          padding: 30px;

          .content-title {
            font-size: 22px;
            line-height: 27px;
            color: white;
            margin-bottom: 10px;
          }

          .content-text {
            margin-right: 15px;
            margin-bottom: 20px;
            font-size: 14px;
            line-height: 23px;
            color: white;
          }

          .buttons-group {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;

            .button {
              flex-basis: 48%;
              text-align: center;

              a {
                color: white;
                font-weight: bold;

                &:hover,
                &:focus {
                  color: white;
                  text-decoration: none;
                }
              }

              @media all and (max-width: 1023px) {
                flex-basis: 100%;

                &:first-of-type {
                  margin-bottom: 10px;
                }
              }
            }
          }
        }
      }

      @media all and (max-width: 900px) {
        flex-wrap: wrap;
        justify-content: space-between;

        .product-view__tabs {
          flex-basis: 100%;
          max-width: 100%;
        }

        .product-related-container {
          width: 100%;
          max-width: none;
          padding: 0;

        }

      }

    }

    .amazon-approved-image {

        width: 165px;
      max-width: 175px;
      margin: 20px 0 10px 0;

    }

  }

  .footer {

    #mobile-fixed-add-to-cart {

      .product-view__qty {

        margin-right: 20px;

      }

      .add-to-cart-btn-mirror button {

        svg {

          margin-right: 10px;

        }

        span {

          margin: 0 !important;
          font-size: 14px !important;

        }

      }

    }

  }

  // New ordering of items on product info details


  #maincontent .product-view .product-view__wrapper {

    .develo-product-info-container {

      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      flex-wrap: wrap;

      .product-view__main-details {

        @media all and (max-width: 992px) {

          width: 48%;
          margin-right: 1%;
          margin-bottom: 0;

        }

        @media all and (min-width: 768px) and (max-width: 860px) {

          width: 46%;

        }

        @media all and (max-width: 767px) {

          width: 100%;

        }

      }

      .product-view__secondary-details {

        display: flex;
        align-items: center;


        @media all and (max-width: 992px) {

          width: 48%;
          margin-bottom: 0;

        }

        @media all and (min-width: 768px) and (max-width: 860px) {

          width: 51%;

        }

        @media all and (max-width: 767px) {

          width: 100%;
          margin-top: 30px;

        }

        .product-secondary-actions {

          justify-content: flex-start;
          gap: 0 20px;

        }

        #product_addtocart_form {

          order: 1;
          flex-basis: 100%;

          .product-view__main-actions {

            align-items: end;
            flex-wrap: wrap;

            .product-view__qty {

              order: 1;

            }

            // Add to basket
            .product-view__action-elements {

              order: 2;

              .button {

                height: 48px;
                max-width: 180px;

                  &.price-on-application {

                      max-width: 250px;
                      text-align: center;

                  }

                @media all and (max-width: 500px) {

                  max-width: 100%;

                }

              }

              @media all and (max-width: 340px) {

                flex-basis: 100%;
                margin-top: 10px;

              }

            }

            // Paypal Btn loaded
            &.paypal-button-loaded {

              .delivery-returns-link {

                margin-bottom: 40px;

              }

              .button {

                max-width: 250px;

              }

              #instant-purchase + div {

                max-width: 250px;
                margin-top: 15px;

              }

              .product-view__action-elements {

                @media all and (max-width: 340px) {

                  margin-bottom: 63px;

                }

              }

            }
          }

        }

        .product-view__price {

          order: 2;

        }

        .product-view__stock-status {

          order: 3;
          margin-top: 3px;

          @media all and (max-width: 450px) , all and (min-width: 768px) and (max-width: 950px) {

            order: 4 !important;
            flex-basis: 100%;
            justify-content: flex-start;
            margin: 5px 0 0 0;

          }

        }

        .product-wishlist-button {

          order: 4;

          @media all and (max-width: 450px) , all and (min-width: 768px) and (max-width: 950px) {

            order: 3;

          }

        }

        .product-view__qty {

          order: 1;
          width: max-content;
          margin-right: 20px;

          @media all and (min-width: 768px) and (max-width: 860px) {

            margin-right: 10px;

          }
          .quantity-update__button {

            @media all and (min-width: 768px) and (max-width: 860px) {

              min-width: 30px;

            }


          }

        }

        .product-view__add-to-cart {

          order: 2;
          width: auto;

          .zoid-outlet iframe {
            z-index: 1;
          }

        }

        .delivery-returns-link {

          order: 3;
          width: 100%;

        }

        // Price stock wishlist
        .product-secondary-actions {

          @media all and (min-width: 768px) and (max-width: 790px), all and (max-width: 380px) {

            .product-view__stock-status {

              order: 3;
              margin-left: 0;
              margin-top: 5px;

            }

          }

        }

      }

    }

  }

}


// Some responsive improvements on amazon store when sidebar is present
.amazon-store.page-layout-2columns-left.amazon-store.page-layout-2columns-left .column.main:not(:only-child) {

    .product-secondary-actions {

        .product-view__price {

            flex-basis: 100% !important;

        }

        .product-view__stock-status {

            width: max-content !important;
            justify-content: flex-start !important;
            margin: 0 !important;

            @media all and (max-width: 1250px) {

                flex-basis: 50% !important;
                margin: 0 !important;

            }

        }

        .product-wishlist-button {

            width: max-content !important;
            justify-content: flex-start !important;

            @media all and (max-width: 1250px) {

                flex-basis: 50% !important;

            }

        }

        .product-view__form {

            flex-basis: 35% !important;

            @media all and (max-width: 1250px) {

                flex-basis: 100% !important;
                margin-top: 10px;

            }

        }

    }

    .product-bottom-info {

        .product-related-container {

            .help-and-support-container {

                .buttons-group {

                    button,
                    .button {

                        @media all and (max-width: 1150px) {

                            flex-basis: 100% !important;

                        }

                        &:first-of-type {

                            margin-bottom: 5px;

                        }

                    }

                }

            }

        }

    }

}

.footer {

  .add-to-cart-btn-mirror {

    .trigger-add-to-cart-button {

      background: $green;

        &.oos {

            background: $dte-red;

        }

    }

  }

}

@media all and (max-width: 660px) {
  .product-view__tabs {
    .tab {
      &--inline {
        &::before {
          display: none;
        }
      }

      &__title {
        order: unset;
        color: $dark-grey;
        border: 0;
        padding: 1rem 0;
        margin: 0;
        width: 100%;
        text-align: left;
        font-weight: bold;
        font-size: 14px;
        position: relative;

        &:not(:last-of-type) {
          border-bottom: 1px solid $border-input-grey;
        }

        .tab-arrow-btn {
          display: block;
          cursor: pointer;
          height: 8px;
          width: 8px;
          border-style: solid;
          color: $dte-bright-blue;
          border-width: 0px 1px 1px 0px;
          transform: rotate(-45deg);
          transition: all ease-in-out 0.2s;
          margin-left: 1rem;
          position: absolute;
          top: 20px;
          right: 2px;
      }

        &--active {
          color: $dark-grey;
          font-weight: bold;
        }
      }

      &__content {
        /* Init hidden content */

        &--active {
          opacity: 0;
          height: 0;
          padding: 0;
        }
      }

      &-mobile-active {

        .tab-arrow-btn {
          transform: rotate(45deg);
        }

        + .tab__content {
            height: auto;
            opacity: 1;
            padding: 32px 0;
            flex-basis: 100%;
            z-index: 1;
        }
      }
    }
  }
}


label.product-view__swatch-option-title:after {
    content: ' of licences';
}

.product-view__qty #qty-error {
    position: static;
    display:flex;
    align-items: center;
    margin-right: 8px;
}

@media all and (max-width: 768px) {
    .product-view__main-actions{
        display:none !important;
    }
}

/* custom module for categorized produts */
#products-categorized {

    padding:0 50px;

    .owl-theme .owl-controls .owl-nav [class*=owl-].owl-prev{
        left:-45px;

        @media all and (max-width: 600px) {
            left:-15px;
        }
    }
    .owl-theme .owl-controls .owl-nav [class*=owl-].owl-next{
        right:-45px;
        @media all and (max-width: 600px) {
            right:-15px;
        }
    }

    .category-data{
        display: block;
        padding: 10px;
        width: 100%;
        text-align: center;
        cursor: pointer;
    }

    .owl-stage-outer {
        border-bottom: #ccc 1px solid;
        padding-bottom: 0px;
    }

    .cprice-excluding-tax{
        display: none;
    }

    .owl-carousel .owl-item{
        cursor: pointer;
        a:hover{
            text-decoration: none;
        }

        .categorized-product-img-wrapper {
                max-width: 315px;
                height: 315px;
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 0 auto;
        }
    }

    #products-categorized-categories{
        .owl-item .category-data.active{
            font-weight: bold;
            border-bottom: 2px solid #155195;
        }
    }

    @media all and (max-width: 600px) {
        padding:0px;
    }

    .categorized-product-name{
        text-decoration: none;
        font-weight: 500;
        font-size: 24px;
        color:#303030;

        &:hover{
          text-decoration: none;
        }
    }

    .categorized-product-sku, .categorized-product-ean{
        text-decoration: none;
        color: #949494;
        font-size: 13px;
        margin: 15px 0;
        text-transform: uppercase;
        &:hover{
          text-decoration: none;
        }
    }
    .categorized-product-price {
        text-decoration: none;
        font-size: 26px;
        font-weight: bold;
        &:hover{
          text-decoration: none;
        }
    }

    .products-categorized-products .owl-theme .owl-controls .owl-nav [class*=owl-] {
        transform: translateY(-50%);
    }
}

/* Spec Tab Headings */
.tab.tab--inline {

    @media all and (max-width: 659px) {
        margin: 16px 0;
    }

    &::before {
        display: none;
    }

    .tab__content {
        @media all and (max-width: 659px) {
            padding: 0;
        }
    }

    .tab__title {
        background-color: #f0f9fb;
        padding: 28px 0;
        flex: 1;
        border: 0;
        transition: ease-in-out 0.25s;
        color: #303030;
        font-weight: normal;
        display: flex;
        flex-direction: column;
        gap: 6px;

        @media all and (max-width: 659px) {
            flex-direction: row;
            align-items: center;
            justify-content: start;
        }

        &:hover {
            background-color: #0eb6ff;
            color: white;

            .tab-arrow-btn {
                color: white;
            }
        }

        > svg {
            width: 24px;
            height: 24px;
        }

        &.tab__title--active {
            background-color: #2f3687;
            color: white;

            .tab-arrow-btn {
                color: white;
            }

            &.tab-mobile-active {
                +.tab__content {
                    padding: 16px 0;
                }
            }
        }

        .tab-arrow-btn {
            color: #303030;
            top: 50%;
            right: 20px;
            transform: translateY(-50%) rotate(-45deg);
        }

        @media all and (max-width: 659px) {
            padding-left: 16px;
        }
    }
}
