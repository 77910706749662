.cms-our-partners {
    nav.breadcrumbs {
        display: none;
    }

  .title-wrapper {

    display: none;

  }
}
.our-partners {

  &--banner {

    .banner-image {
      background-size: cover;
      background-position: bottom;
      background-repeat: no-repeat;
      position: relative;
      height: 180px;

      .banner-content {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;

        h1 {
          color: white;
          font-weight: bold;
          padding-left: 110px;
          margin: 0;  
        }
      }
    }

    .banner-footer-text {
      padding: 70px 110px;
      background: #f1f8fb;
      display: grid;
      grid-template-columns: 45% 45%;
      gap: 40px;
      justify-content: space-between;

      p {
        font-size: 17px !important;
        line-height: 26px;
      }

      @media all and (max-width: 767px) {
        padding: 70px 60px;
      }

      @media all and (max-width: 500px) {
        grid-template-columns: 100%;
      }
    }
  }

  &--items-container {
      margin: 80px auto;
      padding: 0 42px;

      a.view-all {
          display: none;
      }
    .section-title {

      h2 {
          font-size: 22px;
          font-weight: bold;
          margin-bottom: 32px
      }

      p {
        font-size: 17px;
      }

    }

    .partners-container {
      display: flex;
      flex-wrap: wrap;

      @media all and (max-width: 1200px) {
        justify-content: center;
      }
      .picture-buttons-container {
        margin: 0 40px 40px 0;
        width: 185px;
        height: 185px;

        .image-container {
          position: relative;
          height: 100%;

          img {
            position: absolute;
            top: 50%;
            transform: translate(-50%, -50%);
            left: 50%;
          }
        }
      }

    }

  }

}

a {

  span.image-container {

    img {

      width: auto;

    }

  }

  span.picture-buttons-container,
  span.image-container {

    display: block;

  }

  &[href="javascript:void(0);"] {

    cursor: unset;

    .picture-buttons-container {
    
      &:hover {
    
        box-shadow: 0 3px 11px 0 rgba(0, 0, 0, 0.13);
        border: 1px solid transparent;
    
      }
    
      &:active {
    
        outline: unset;
        border: unset;
    
      }
    
      &:focus,
      &:active {
    
        outline: unset;
        border: unset;
    
      }
    
    }

  }

}
