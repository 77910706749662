.sidebar {
  padding-right: 30px;

  .cms-glossary {
    h2 {
      font-size: 18px !important;
      font-weight: bold;
      padding-left: 40px;
      margin-bottom: 10px;

      @media all and (max-width: 1024px) {
        padding-left: 5px;
      }
    }

    div[data-role="collapsible"] {
      cursor: pointer;
    }

    .glossary-list {
      padding-left: 0;
      margin: 0;

      .glossary-item {
        padding: 10px 0;
        margin-left: 15px;
        cursor: pointer;

        @media all and (min-width: 1025px) {
          margin-left: 45px;
        }

        a {
          color: #2e3687;
          font-size: 14px;
          font-weight: bold;

          &:hover,
          &:focus {
            color: #526B79 !important;
            text-decoration: none;
          }
        }

        &.current {
          border-left: 2px solid $color-secondary;
          padding-left: 8px;
        }
      }

      ~ h3 {

        padding: 0;

        a {

          padding: 10px 0;

        }

      }

    }

    @media all and (max-width: 767px) {
      position: static !important;
      margin-bottom: 20px;
    }

    @media all and (min-width: 1025px) and (max-width: 1300px) {
      width: 230px !important;
    }

    @media all and (min-width: 768px) and (max-width: 1024px) {
      width: 150px !important;
    }

    a {
      text-decoration: none !important;
      color: #2e3687;
      font-weight: bold;

      &:focus,
      &:hover {
        color: #0ab6ff;
      }
    }

    .help-and-support-container {
      transition: all ease-in-out 0.3s;
      margin: 100px 0 0 15px;

      @media all and (min-width: 1025px) and (max-width: 1310px), all and (min-height: 1080px) and (max-width: 1310px) {
        .content-container {
          padding: 15px;

          .content-text {
            margin-right: 0;
          }

          .button {
            flex-basis: 100% !important;

            &:first-of-type {
              margin-bottom: 10px;
            }
          }
        }
      }
    }

    &.scrolling {
      .help-and-support-container {
        margin: 10px 0 0 15px;
      }
    }
  }

  .glossary-content {
    h3 {
      margin: 0 0 0 40px;
      padding: 10px 0;
      font-size: 15px;
      position: relative;
      color: #2e3687;
      font-weight: bold;
      border-bottom: 1px solid #e0e0e0;
      cursor: pointer;
      transition: ease-in-out 0.2s;

      a {
        text-decoration: none;
        display: block;
      }

      @media all and (max-width: 1024px) {
        margin: 0 5px;
      }

      &:hover {
        color: #526B79;
      }
    }
  }

  div[data-role="collapsible"] {
    h3 {
      &:before,
      &:after {
        content: '';
        width: 15px;
        height: 15px;
        display: block;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        background-size: 100% 100%;
      }

      &:after {
        background-image: url("data:image/svg+xml,%3Csvg width='24px' height='24px' viewBox='0 0 24 24' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3C!-- Generator: Sketch 52.5 (67469) - http://www.bohemiancoding.com/sketch --%3E%3Ctitle%3EIcon / System / 24px / Utility Blue / Chevron Right 3 2 %3C/title%3E%3Cdesc%3ECreated with Sketch.%3C/desc%3E%3Cg id='Icon-/-System-/-24px-/-Utility-Blue-/-Chevron-Right--3--2-' stroke='none' stroke-width='3' fill='none' fill-rule='evenodd' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline id='Chevron' stroke='%232e3687' points='8.93279275 4.54234436 16.431825 12.0413766 8.93279275 19.5396818'%3E%3C/polyline%3E%3C/g%3E%3C/svg%3E");
        right: 10px;
        transition: ease-in-out 0.3s;
      }
    }

    &.active {
      h3 {
        &:after {
          top: 15px;
          transform: rotate(90deg);
        }
      }
    }
  }
}

.faq-sliders {
  @media all and (max-width: 767px) {
    .columns {
      flex-direction: column-reverse;
    }
  }

  .column.main {
    margin-bottom: 200px;


    &:not(.cms-glossary) {

      h2 {
        font-size: 18px;
        font-weight: bold;

        &:not(:first-of-type) {
          margin-top: 30px;
        }

        &:first-child {
          margin-top: 0;
          padding-top: 0;
        }
      }

      div[data-role="collapsible"] {
        background-color: #f1f8fb;
        font-size: 11px;
        margin: 10px 0 0 0;
        transition: background-color 200ms ease-in-out;
        max-width: 850px;
        cursor: pointer;

        &:hover {
          background-color: #e6e6e6;
        }

        h3 {
          padding: 23px 45px;
          margin: 0;
          font-size: 15px;
          position: relative;
          color: #2e3687;
          font-weight: bold;

          &:before,
          &:after {
            content: '';
            width: 15px;
            height: 15px;
            display: block;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            background-size: 100% 100%;
            transition: ease-in-out 0.3s;
          }

          &:after {
            background-image: url("data:image/svg+xml,%3Csvg width='24px' height='24px' viewBox='0 0 24 24' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3C!-- Generator: Sketch 52.5 (67469) - http://www.bohemiancoding.com/sketch --%3E%3Ctitle%3EIcon / System / 24px / Utility Blue / Chevron Right 3 2 %3C/title%3E%3Cdesc%3ECreated with Sketch.%3C/desc%3E%3Cg id='Icon-/-System-/-24px-/-Utility-Blue-/-Chevron-Right--3--2-' stroke='none' stroke-width='3' fill='none' fill-rule='evenodd' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline id='Chevron' stroke='%232e3687' points='8.93279275 4.54234436 16.431825 12.0413766 8.93279275 19.5396818'%3E%3C/polyline%3E%3C/g%3E%3C/svg%3E");
            right: 23px;
          }
        }

        &.active {
          h3 {
            &:after {
              top: 25px;
              transform: rotate(90deg);
            }
          }
        }
      }

      p[data-role="content"] {
        padding: 23px 45px;
        max-width: 850px;
        background: #f1f8fb;
      }

      @media all and (max-width: 1024px), all and (max-height: 1079px) {
        .help-and-support-container {
          max-width: 550px;
          margin: 10px 0;
        }
      }
    }

  }


  @media all and (max-width: 767px) {
    .column.main {
        margin-bottom: 20px;
    }
  }

}

.cms-faq {
    .sidebar{
        h2{
            margin-top: 0;
        }
    }
  .help-and-support-container {
    display: none;
  }
  .faq-sliders .column.main:not(.cms-glossary) h2:first-child{
    margin-top: 15px;
  }
  .faq-intro-text {
    h2 {
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 20px;
    }
  }

  @media all and (max-width: 767px) {
    .sidebar-main {
      padding: 0;

      .cms-glossary {
        //max-width: 100% !important;
        //width: 100% !important;
      }
    }
  }
}

// Adding in QA

.cms-faq {

  .faq-sliders .column.main:not(.cms-glossary) {

    div[data-role="collapsible"] h3 {

      &::before {

        content: 'Q:';
        display: flex;
        align-items: center;
        left: 23px;

      }

    }

    p[data-role="content"] {

      position: relative;

      &::before {

        content: 'A:';
        align-items: center;
        left: 25px;
        display: flex;
        position: absolute;

      }

    }

  }

}

// Show Delivery & Returns only for Amazon FC
.dte-store--dte_amazon_en {
  .sidebar {
    .glossary-content {
      h3:nth-child(1),
      h3:nth-child(5),
      h3:last-child{
        display:none;
      }

    }
  }
}
