$icon__size: 24px !default;
$icon__padding: 2px !default;

.icon {

  display: inline-block;
  width: $icon__size;
  height: $icon__size;
  padding: $icon__padding;

}

svg {

  //stroke: #000; // Fallback color.

  &.trans {
    stroke: transparent;
  }

  use {

    &.stroke {

      &[id*="white"] {
        stroke: #fff;
      }

      &[id*="black"] {
        stroke: #000;
      }

      &[id*="blue"] {
        stroke: $dte-blue;
      }

      &[id*="light-b"] {
        stroke: #0ab6ff;
      }

      &[id*="red"] {
        stroke: #DC2048;
      }

      &[id*="green"] {
        stroke: #6abd0f;
      }

      &[id*="grey"] {
        stroke: #303030;
      }

      &[id*="light-g"] {
        stroke: #AFB9BF;
      }

      &[id*="light-grey"] {
        stroke: #c7c7c7;
      }

    }

  }

  use {

    &.fill {

      &[id*="blue"] {
        fill: $dte-blue;
      }

      &[id*="white"] {
        fill: white;
      }

      &[id*="light-grey"] {
        fill: #c7c7c7;
      }

      &[id*="border-grey"] {
        fill: #E0E0E0;
      }

    }

  }

}

.addthis-smartlayers {

  svg {
    stroke: none;
  }

}
