$paragraph-color      : $color-secondary !default;
$paragraph-font-family: $font-family-base !default;
$paragraph-font-weight: $font-weight-normal !default;
$paragraph-bold-font-weight: $font-weight-bold !default;
$paragraph-line-height: $font-line-height !default;
$paragraph-font-size  : $font-size-base !default;
$paragraph-small-font-size  : $font-size-small !default;
$paragraph-margin     : 0 0 $spacer--medium 0 !default;
$paragraph-transition : none !default;

// sass-lint:disable no-vendor-prefixes
* {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.paragraph {

    color: $paragraph-color;
    font-family: $paragraph-font-family;
    font-weight: $paragraph-font-weight;
    line-height: $paragraph-line-height;
    font-size: $paragraph-font-size;
    margin: $paragraph-margin;
    transition: $paragraph-transition;

    &--bold {
        font-weight: $paragraph-bold-font-weight;
    }

    &--small {
        font-size: $paragraph-small-font-size;
    }

}

p {
    @extend .paragraph;
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

.text-center {
    text-align: center;
}

.text-white {
    color: $white;
}

.text-light-navy-blue {
    color: $light-navy-blue;
}

.text-light-peach {
    color: $light-peach;
}

.text-gallery-grey {
    color: $gallery-grey;
}

.text-greyish-brown {
    color: $greyish-brown;
}

.text-bright-light-blue {
    color: $bright-light-blue;
}

.text-dusty-grey {
    color: $dusty-grey;
}

.text-cloud-grey {
    color: $cloud-grey;
}

.text-lipstick {
    color: $lipstick;
}

.text-nearly-white {
    color: $nearly-white;
}

.text-dark-sky-blue {
    color: $dark-sky-blue;
}

.text-thunder {
    color: $thunder;
}

.text-mandy-red {
    color: $mandy-red;
}

.text-frog-green {
    color: $frog-green;
}

.bg-light-navy-blue {
    background-color: $light-navy-blue;
}
.bg-light-peach {
    background-color: $light-peach;
}
.bg-gallery-grey {
    background-color: $gallery-grey;
}
.bg-greyish-brown {
    background-color: $greyish-brown;
}
.bg-bright-light-blue {
    background-color: $bright-light-blue;
}
.bg-dusty-grey {
    background-color: $dusty-grey;
}
.bg-cloud-grey {
    background-color: $cloud-grey;
}
.bg-lipstick {
    background-color: $lipstick;
}
.bg-nearly-white {
    background-color: $nearly-white;
}
.bg-dark-sky-blue {
    background-color: $dark-sky-blue;
}
.bg-thunder {
    background-color: $thunder;
}
.bg-mandy-red {
    background-color: $mandy-red;
}
.bg-frog-green {
    background-color: $frog-green;
}

.Comments-Grey-2 {
    font-family: $font-family-rounded-m-plus;
    font-size: 24px;
    font-weight: 300;
    line-height: 1.33;
    color: $cloud-grey;
}

.Comments-White {
    font-family: $font-family-rounded-m-plus;
    font-size: 24px;
    font-weight: 300;
    line-height: 1.33;
    color: $white;
}

.Button-Black {
    font-size: 18px;
    line-height: 1.44;
    color: $thunder;
}

.Button-White {
    font-size: 18px;
    line-height: 1.44;
    color: $white;
}


.Extra-Navigation-Menu-Black {
    font-size: 18px;
    line-height: 2.89;
    color: $thunder;
}

.Button-Utility-Green {
    font-size: 18px;
    line-height: 1.44;
    color: $frog-green;
}

.Extra-Navigation-Menu-White {
    font-size: 18px;
    line-height: 2.89;
    color: $white;
}

.Button-Grey {
    font-size: 18px;
    line-height: 1.44;
    color: #9b9b9b;
}

.Button-Red {
    font-size: 18px;
    line-height: 1.44;
    color: $lipstick;
}

.Body-1-Black-Bold {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
    color: $thunder;
}

.Link-White-Active {
    font-size: 16px;
    line-height: 1.5;
    color: $white;
}

.Extra-Input-Error {
    font-size: 16px;
    line-height: 1.5;
    color: $lipstick;
}

.Extra-Input-Success {
    font-size: 16px;
    line-height: 1.5;
    color: $dark-sky-blue;
}

.Body-1-Black-Reg {
    font-size: 16px;
    line-height: 1.5;
    color: $thunder;
}

.Extra-Input-Disabled {
    font-size: 16px;
    line-height: 1.5;
    color: #ebebeb;
}

.Body-1-White-Bold {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
    color: $white;
}

.Label-Black {
    font-size: 12px;
    line-height: 1.67;
    color: #000000;
}

.Label-Utility-Blue {
    font-size: 12px;
    line-height: 1.67;
    color: $dark-sky-blue;
}

.Label-White {
    font-size: 12px;
    line-height: 1.67;
    color: $white;
}

.Label-Grey {
    font-size: 12px;
    line-height: 1.67;
    color: #9b9b9b;
}

.Body-2-White-Bold {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.57;
    color: $white;
}

.Body-2-Black-Reg {
    font-size: 14px;
    line-height: 1.57;
    color: $thunder;
}

.Body-2-Black-Bold {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.57;
    color: $thunder;
}

.Label-Red {
    font-size: 12px;
    line-height: 1.67;
    color: $lipstick;
}

.Label-Blue {
    font-size: 12px;
    line-height: 1.67;
    color: $light-navy-blue;
}

.Button-Utility-Blue {
    font-size: 18px;
    line-height: 1.44;
    color: $dark-sky-blue;
}

.Link-Hover {
    font-size: 16px;
    line-height: 1.5;
    text-align: center;
    color: $light-navy-blue;
}

.Body-1-Grey-2-Reg {
    font-size: 16px;
    line-height: 1.5;
    color: #9b9b9b;
}

.Extra-Input-Placeholder {
    font-size: 16px;
    line-height: 1.5;
    color: $cloud-grey;
}

.Body-2-White-Reg {
    font-size: 14px;
    line-height: 1.57;
    color: $white;
}

// ----- New Design ----- //
.intro-text {
  font-size: 17px;
}

.body-small {
  font-size: 14px;
}

