$footer__padding: $spacer--medium !default;
$footer__padding-sm: $spacer !default;
$footer__background-color: $white !default;
$footer__link-color: black !default;

.footer {

    margin-top: 40px;

    .footer-main-container {

        padding: 45px 30px;
        position: relative;
        background: radial-gradient(circle at 65% 30%,#2272a5 0,#2e3687 50%);
        max-width: 1440px;
        margin: 0 auto;

        @media all and (max-width: 991px) {

            padding: 45px 15px;

        }

        * {
            color: white;
            @media all and (max-width: 991px) {
                padding: 0px;
            }
        }

        h4 {

            font-weight: bold;
            font-size: 14px;

        }

        hr {

            border: 1px solid rgba(255, 255, 255, 0.1);
            border-top: 0;
            margin-bottom: 20px

        }

        svg {

            fill: white;
            stroke: white;

        }

        .get-in-touch {

            display: flex;
            justify-content: flex-end;

            @media all and (max-width: 991px) {

                justify-content: center;
                margin-bottom: 10px;

            }

        }

        .connect-with-us {

            @media all and (max-width: 991px) {

                width: 100%;

            }

        }


        .social-row {

            @media all and (max-width: 991px) {

                margin: 0 auto;
                flex-direction: column-reverse;

                > div:first-of-type {

                    flex-basis: 100%;
                    max-width: 100%;

                }

            }

            @media all and (min-width: 992px) and (max-width: 1199px) {

                .col-lg-8 {

                    flex-basis: 100%;
                    max-width: 100%;

                }

                .social-links {

                    max-width: 200px;
                    margin: 10px auto;

                }

            }

            @media all and (min-width: 1200px) {

                margin-bottom: 20px;

            }

            .social-links {

                display: flex;
                justify-content: space-between;
                gap: 0 20px;

            }

            @media all and (min-width: 992px) {

                align-items: center;

                p {

                    margin: 0;

                }

            }

        }

        .subscribe-input {

            .subscribe-col {

                position: relative;

                input {

                    background-color: rgba(white, 0.33);
                    border: 0;
                    height: 39px;
                    color: white;
                    font-size: 13px;

                    &::placeholder,
                    &::-webkit-input-placeholder {

                        color: white;
                        font-size: 13px;

                    }

                    &:hover {

                        //border: $input__hover-border;
                        box-shadow: none;

                    }

                    &:focus {

                        background-color: rgba(white, 0.33);
                        outline: none;
                        box-shadow: none;

                    }

                }

                .mobile-chevron {
                    transform: scale(2)
                }

                #newsletter-error {
                    position: absolute;
                    color: red;
                }


                button {

                    height: 39px;
                    position: absolute;
                    right: 0;
                    min-width: 0;
                    width: 65px;

                    &:hover {

                        .mobile-chevron {
                            svg {
                                use {
                                    stroke: #0ab6ff;
                                    fill: #0ab6ff;
                                }

                            }

                        }

                    }

                }

            }

            .footer-links {

                position: absolute;
                bottom: -13px;

                a {

                    color: white;
                    border-right: 1px solid $dte-bright-blue;
                    padding: 0 15px;

                    &:first-child {

                        padding-left: 0;

                    }

                    &:last-of-type {

                        border: 0;

                    }

                }

            }

            @media all and (min-width: 992px) {

                position: relative;

                .footer-links {

                    position: absolute;
                    left: -141%;
                    width: max-content;
                    top: 0;

                }

            }

        }


        .list__item {
            line-height: 1;

            h4 {
                font-size: 20px;
            }
        }

        .list--footer-copyright {

            display: block;
            margin: 40px 0 0;
            padding: 0 15px;

            p {

                margin: 0;
                font-weight: 300;
                font-size: 15px;

            }

        }

        .dropdown-list--footer-help {

            background-color: transparent;

            .dropdown-list__label {

                background-color: transparent;
                padding-left: 10px;
                padding-right: 10px;
                text-align: center;

            }

            .dropdown-list__item {
                width: 100%;
            }

            .dropdown-list__content {
                margin: 0;
                height: auto;
            }

            .dropdown-list__item--open .dropdown-list__label,
            .dropdown-list__item.open .dropdown-list__label {
                color: $white;
            }

        }

        .dte-copyright {

            font-size: 13px;

        }

        // mobile

        @media all and (max-width: 991px) {

            .categories-col {

                display: none;

            }

            .shopping-col,
            .about-col {

                flex-basis: 100%;
                max-width: 100%;

            }

            .list {

                .list__item:last-of-type {

                    margin-bottom: 9px;

                }

            }

            .hr-desktop {

                display: none;

            }

            .get-in-touch {

                justify-content: flex-start;

                .button {

                    padding: 11px 70px;

                }

            }

            .connect-with-us {

                margin-bottom: 40px;

                @media all and (max-width: 992px) {

                    margin-bottom: 80px;

                }

                > div:first-of-type {

                    display: none;

                }

                > div:nth-of-type(2) {

                    display: none;

                }

                .social-row {

                    margin: 20px 0;

                    > div:first-of-type {

                        display: none;

                    }

                    .social-links {

                        justify-content: space-between;
                        width: 150px;

                        > svg {

                            margin-right: 30px;

                        }

                    }

                }

            }

            .shopping-col {

                position: static;

                .footer-links {

                    bottom: 0;
                    max-width: 100%;

                    .dte-copyright {

                        margin-bottom: 0;

                    }

                }

            }

            .subscribe-input {

                display: none;

            }

        }

        @media all and (min-width: 992px) {

            .hr-mobile {

                display: none;

            }

        }

    }

    #dropdown-help {

        .row--help {

            .list__item {

                padding: 4px 0;

                a {

                    font-weight: 500;

                }

            }

            .col-lg-4 {

                .col-lg-6 {

                    h4 {

                        margin-bottom: 4px;
                        font-size: 17px;

                    }

                    p {

                        margin-bottom: 30px;

                        @media all and (max-width: 991px) {

                            margin-bottom: 20px;

                        }

                    }

                }

            }

        }

    }

    @media all and (min-width: 768px) {

        .footer-main-container {

            .dropdown-list--footer-help {

                .dropdown-list__label {
                    //display: none;
                }

            }

        }

    }

    @media all and (max-width: 767px) {

        .footer-main-container {

            .dropdown-list--footer-help {

                .dropdown-list__label {

                    font-size: 16px;
                    font-weight: 500;

                    .dropdown-list__icon {

                        width: 20px;
                        height: 20px;

                    }

                }

            }

            .list--footer-copyright {

                text-align: center;
                margin: 20px 0 0;

            }

            .row--help {

                .list {
                    margin-bottom: 30px;
                }

            }

        }

    }

}

@media all and (max-width: 767px) {

    #tawkchat-container {

        &.tawkchat-custom-hidden {

            pointer-events: none !important;
            visibility: hidden !important;
            opacity: 0 !important;

        }

    }

}
