// Add Tag button specific styling here
.blog-post-view .post-view .post-holder .post-tag {
    margin-top: 5rem;

    .post-tags {
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;

        .post-tag-title {
            display: none;
        }

        a {
            background: $blog-grey-lighter;
            color: $blog-body;
            border-radius: 6px;
            padding: 1rem;
            font-weight: normal;
            font-size: 15px;
            border: 0;
            transition: ease-in-out 0.2s;
            margin: 0;
            text-decoration: none;

            &:hover {
                background-color: $blog-body;
                color: white;
            }
        }
    }
}


.sidebar-cta-container {
    margin: 2rem 0;

    .cta-text {
        color: $blog-body;
        font-size: 20px;
        line-height: 1.5;
        margin-bottom: 1.5rem;
    }

    .button {
        background-color: $blog-red-dark;
        border: none !important;

        &:hover {
            background-color: $blog-red-light !important;
            border: none !important;
            color: white !important;
        }
    }
}
