/* Social Link specific styling here */
body.blog-page  {
    .social-title {
        color: black;
        margin-bottom: 1.5rem;
    }

    .post-sharing-top {
        .share-elements {
            gap: 2rem;

            .icon-wrapper {
                background: none;

                .icon {
                    width: 32px;
                    height: 32px;
                    padding: 0;
                }
            }
        }
    }
}
