$input__error-color: $color-danger !default;
$input__error-border-color: $color-danger !default;
$input__error-background-color: $white !default;
$input__error-margin-top: $spacer !default;

div {

    &.mage-error {
        margin-top: $input__error-margin-top;
        color: $input__error-color;
    }

    &.required {
        label {
            &:after {
                content: '*';
                color: $input__error-color;
            }
        }
    }

}

input,
select,
textarea {

    &.mage-error {

        color: $input__error-color;
        border: 1px $input__error-border-color solid;
        background-color: $input__error-background-color;

    }

}

.checkbox {

    &__field {

        &.mage-error {

            ~ .checkbox__label {

                color: $input__error-color;

                &:before {

                    background-color: $input__error-background-color;
                    border-color: $input__error-border-color;

                }

            }

        }

    }

}

.radio {

    &__field {

        &.mage-error {

            + .radio__label {

                color: $input__error-color;

                .radio__icon {

                    background-color: $input__error-background-color;
                    border-color: $input__error-border-color;

                }

            }

        }

    }

}

