.desk-customer-index,
.desk-customer-view,
.wishlist-index-index,
.customer-address-form,
.customer-account-edit,
.wishlist-index-index {
    .page-wrapper {
        .top-navigation-categories-container,
        .call-to-action-container{
            display:none;
        }
    }
}

.quotelist-account-index,
.sales-order-history{
    .heading--page{
        display: none;
    }
    .page-wrapper {
        .top-navigation-categories-container {
            display:none;
        }
    }
    #maincontent.dashboard{
          .title-wrapper {
            display:none;
          }
        .col-xs-12.col-md-8.col-lg-9{

            @media all and (min-width: 992px) and (max-width: 1129px) {
                width: 100%;
                max-width: 100%;
                flex-basis: 100%;
                padding: 0 61px;
            }

            table#savedquotes-results-table,
            #my-quotes-table {
                @media all and (max-width: 534px) {
                    padding-top: 72px;
                }
                caption.draft {
                    display:none;

                }
                tbody {

                    tr {

                        td.col.id {
                            font-weight: bold;
                        }
                        &:hover td.col.delete:after {
                                   content: '\203A';
                                   font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
                                   position: absolute;
                                   margin: 0 -15px;
                                   color: #0AB6FF;
                                   font-size: 45px;
                                   font-weight: 100;
                                   transform: translateY(-66%);
                                   @media all and (max-width: 534px) {
                                       margin-top: 10px;
                                   }


                           }





                    }

                }
            }
        }

    }
}
.develodesign_portalquotes-quote-view {

  .portal-quote-info {

    background-color: $color-primary;
    display: flex;
    justify-content: space-between;
    padding: 12px 25px 0px;

    h2 {

      color: #fff;
      font-size: 22px;

      &:first-child {

        font-weight: 600;
        font-size: 24px;

      }

    }

    > p {

      color: #fff;

    }

  }

  .portal-quote-preview-info {

    display: flex;
    justify-content: space-between;
    background-color: $color-primary;
    padding: 0 25px 0;

    p {

      color: #fff;

    }

  }

  .quote-info {

    display: flex;
    justify-content: space-between;

    h2 {

      font-size: 26px;


    }

      .table-optionals {

          .checkbox-input-container {

              padding: 15px!important;

          }

      }

  }

  .quote-info-container {

    margin: 20px 0;

    > .input {

      @media all and ( min-width: 768px ) {

        padding-right: 0;

        &:first-of-type {

          padding-left: 0;
          padding-right: 15px;

        }

      }

    }

  }

  .actions-container {

    margin-bottom: 15px;

    > .button {

      margin: 15px 10px 15px 0;
      cursor: pointer;
      max-width: 19%;

    }

  }

  .actions-toolbar {

    > .secondary {

      width: auto;
      float: left;

    }

    > a.action.primary {

      width: auto;
      float: right;

    }

  }

  button.update-quote-button {

    margin: 15px 0 45px;
    width: auto;
    float: right;

  }

  .modal-inner-wrap {

    .modal__close-button {

      position: absolute;

    }

    .modal-header {

      text-align: center;
      padding: 60px 0 15px;

      .heading {

        margin: 0;
        font-size: 26px;
        text-transform: none;

      }

    }

    #amazon-quote-share-modal {

      p {

        text-align: center;

      }

    }

    .modal-footer {

      text-align: right;

    }

  }

}

.amazon-store {

  .quotation-quote-index{

    .product-image-photo{
      max-width: 100px;
      margin: auto;
    }

    .button__icon{
      width: 15px;
      height: 15px;
    }

    .button{
      height: 35px;
      min-width: 65px;
      padding: 11px 20px;
    }

  }

  table.table-order-items {

    &#search-results-table {

      td.actions {

        a.button {

          width: auto;

        }

      }

    }

  }

}

.table-optionals {

  float: right;
  width: 100%;


  @media all and (min-width: 576px) {

    width: 70%;

  }

  @media all and (min-width: 768px) {

    width: 50%;

  }

  h3 {

    text-align: right;

  }

  .checkbox-input-container {

    background: #F1F8FB;
    padding: 30px;
    margin-bottom: 10px;

    .checkbox-input {

      display: flex;
      justify-content: center;

    }

  }

}


