// Brand Colors
$light-navy-blue: #2f348a;
$light-peach: #dfdada;
$gallery-grey: #ebebeb;
$greyish-brown: #444444;
$bright-light-blue: #2ac4f3;
$dusty-grey: #979797;
$cloud-grey: #888888;
$lipstick: #dc2048;
$nearly-white: #f6f6f6;
$dark-sky-blue: #4a90e2;
$thunder: #231f20;
$mandy-red: #e85b55;
$frog-green: #6abd0f;
$dte-blue: #2E3687;

$dte-bright-blue: #0AB6FF;
$dte-gradient: #2272A5;
$dark-grey: #303030;
$light-grey: #C7C7C7;
$very-light-blue: #91DEFF;
$border-input-grey: #E0E0E0;

// Secondary Colours
$dark-blue: #526b79;
$mid-grey: #949494;
$hairline-grey: #e0e0e0;
$hover-highlight-blue: #b8eaff;
$light-grey-background: #efefef;
$light-blue-background: #f0f8fb;

$breadcrumbs-bg-colors: #F1F8FB;
$dte-grey: #686868;

// Highlight Colours
$dte-red: $lipstick;
$highlight-orange: #dc800d;
$green: #46c026;
$alternative-green: #32C026;
$higlight-blue: #B8EAFF;

$color-primary: $light-navy-blue;
$color-secondary: $lipstick;
$shadow-color: $higlight-blue;

$box-shadow-defined: 0 3px 11px 0 rgba(184, 234, 255, 0.36);
$box-shadow-defined-right: 2px 1px 9px -1px $shadow-color;
$box-shadow-defined-grey: 5px 5px 15px -2px $mid-grey;

// Blog Colours (Before Redesign)
$blog-body:         #12203F;
$blog-body-light:   #576179;
$blog-body-lighter: #858D9F;
$blog-grey:         #ABB3C3;
$blog-grey-light:   #F0F4FE;
$blog-grey-lighter: #E1E4EC;
$blog-grey-dark:    #2F3C57;
$blog-grey-darker:  #4A4B4D;
$blog-red-dark:     #DA0039;
$blog-red-light:    #E34B74;

// Globals
$grid__gutter-width: 30px;
$grid__gutter-half-width: calc(#{$grid__gutter-width} * 0.5);
$grid__gutter-compensation: calc(#{$grid__gutter-half-width} * -1);


// Typography
$font-size-base: 13px;
$font-size-small: 12px;
$font-family-montserrat: 'Montserrat', "Helvetica Neue", Helvetica, Arial, sans-serif;
$font-family-rounded-m-plus: 'M PLUS Rounded 1c', "Helvetica Neue", Helvetica, Arial, sans-serif;
$font-family-proxima-nova: 'Proxima Nova', $font-family-montserrat;


// Headings
$heading-font-size--first-level-hero: 55px;
$heading-font-size--first-level: 36px;
$heading-font-size--second-level: 25px;
$heading-font-size--third-level: 22px;
$heading-font-size--fourth-level: 18px;
$heading-font-size--fifth-level: 15px;

$catalog-heading-font-size--first-level-hero: 45px;
$catalog-heading-font-size--first-level: 36px;
$catalog-heading-font-size--second-level: 24px;
$catalog-heading-font-size--third-level: 22px;
$catalog-heading-font-size--fourth-level: 18px;

$headings-color: $dark-grey;
$headings-font-weight: 400;
$headings-font-family: $font-family-proxima-nova;
$headings-text-transform--page: none;


// Link
$link__color: $dte-bright-blue;
$link__color-hover: $dark-sky-blue;
$link__decoration: none;
$link__decoration-hover: underline;
$login__forgot-password-color: $link__color;
$login__forgot-password-text-decoration: $link__decoration;
$link__font-family: $font-family-proxima-nova;

// Paragraph
$paragraph-color: $dark-grey;
$paragraph-font-family: $font-family-proxima-nova;
$paragraph-bold-font-weight: 600;
$paragraph-line-height: 26px;
$paragraph-font-size: 15px;


// Icons
$icon__size: 24px;


// Forms / Inputs
$form-elements-border-color: $hairline-grey;
$form-elements-radius: 4px;

$input__field-background-color: $white;
$input__field-border-radius: $form-elements-radius;
$input__field-border-radius--textarea: $form-elements-radius;
$input__field-color: $thunder;
$input__field-font-size: 16px;
$input__field-padding-x: 20px;
$input__field-padding-y: 14px;
$input__field-padding: $input__field-padding-y $input__field-padding-x;
$input__field-padding--with-icon-left: $input__field-padding-y $input__field-padding-x $input__field-padding-y ( $input__field-padding-x ) + ( $icon__size * 1.5 );
$input__field-padding--with-icon-right: $input__field-padding-y ( $input__field-padding-x ) + ( $icon__size * 1.5 ) $input__field-padding-y $input__field-padding-x;
$input__field-padding--textarea: $input__field-padding;
$input__field-spacing: 44px;

$input__placeholder-color: $cloud-grey;

$input__hover-border: 1px solid $hover-highlight-blue;

$input__focus-color: $hover-highlight-blue;
$input__focus-border-color: $hover-highlight-blue;

$input__error-color: $mandy-red;
$input__error-border-color: $input__error-color;

$input__disabled-color: $gallery-grey;
$input__disabled-border-color: $input__disabled-color;

// Select
$select__padding: 0 12px;
$select__spacing: $input__field-spacing;
$select__border-radius: $form-elements-radius;

$select__input-caret-right: $input__field-padding-x;
$select__input-focus-selected-border-color: $input__focus-border-color;
$select__input-focus-selected-color: $input__focus-color;

$select__field-list-border-radius--single: $form-elements-radius;
$select__field-item-padding: 13px 0 12px;
$select__field-item-margin: 0 $input__field-padding-x;
$select__field-item-border-bottom: 1px $dusty-grey solid;
$select__field-item-hover-border-bottom: 1px $input__focus-border-color solid;
$select__field-item-hover-color: $input__focus-color;
$select__field-item-disabled-color: #444;
$choices-bg-color-dropdown: $white;
$choices-bg-color: $white;

// Checkboxes & Radios
$checkbox__line-height: 2;
$checkbox__spacer: 27px;
$checkbox__icon-fill: $thunder;
$checkbox__border: 1px $mid-grey solid;

$checkbox__label-color: $dte-grey;
$checkbox__label-font-size: 15px;
$checkbox__line-height: 1.35;
$checkbox__label-padding: $checkbox__spacer + 3px;

$checkbox__unchecked-background-color: white;
$checkbox__border--checked: 1px solid $dte-bright-blue;
$checkbox__icon-check-mark-color: $dte-bright-blue;
$checkbox__hover-background-color: white;
$checkbox__hover-border-color: $dte-bright-blue;

$checkbox__disabled-background-color: $white;
$checkbox__disabled-border-color: $gallery-grey;
$checkbox__disabled-label-color: #9b9b9b;

$radio__size: 24px;
$radio__dot-size: $radio__size / 2;
$radio__label-font-size: $checkbox__label-font-size;
$radio__label-color: $checkbox__label-color;
$radio__label-color-active: $checkbox__label-color;
$radio__icon-border: $checkbox__border;
$radio__icon-outer-background: $white;
$radio__icon-active-inner-background: $white;
$radio__icon-active-outer-background: $dte-bright-blue;
$radio__icon-hover-inner-background: $white;
$radio__icon-hover-outer-background: $white;
$radio__icon-hover-border-color: $checkbox__hover-border-color;


// Labels
$label-font-size: 12px;
$label-color: #9b9b9b;
$label-text-transform: uppercase;
$label-margin: 0;
$label-focus-color: $input__focus-color;


// Breadcrumbs
$breadcrumbs__color: $thunder;
$breadcrumbs__color-hover: $dark-sky-blue;
$breadcrumbs__color--active: $breadcrumbs__color;


// Tabs
$tab__title-font-size: 16px;
$tab__title-color: $dark-grey;
$tab__title-border-width-active: 3px;
$tab__title-border-color: $border-input-grey;
$tab__title-border-color-active: $dte-bright-blue;
$tab__title-background--active: $white;
$tab__title-background--large-active: $white;
$tab__title-font-weight--large: $font-weight-normal;
$tab__title-font-weight--large-active: bold;
$tab__title-font-weight: $font-weight-normal;
$tab__content-padding: 32px 0;
$tab__content-padding--large: 32px 0;


// Header
$header__wrapper-padding: 0;
$header__logo-padding: 0;
$header__background: linear-gradient(-90deg, #141b76 0%, #2f348a 100%);
$header__background_white: #fff;
$header__border: none;
$header__wrapper-padding\@medium: 0;

$header-button__label-display: none;
$header-button__margin: 0 0 0 15px;
$header-button__margin--extra-large: 0 0 0 15px;
$header-button__background-color: transparent;

$header-button__icon-margin: 0;
$header-button__icon-margin--medium: 0;
$header-button__icon-padding: 0;
$header-button__size: 24px;
$header-button__icon-color: $white;


// Side Menu
$side-menu__label-display: none;


// Divider
$divider__size: 1px;
$divider__color--black: $thunder;
$divider__color--grey: $cloud-grey;
$divider__color--light-grey: $gallery-grey;
$divider__color--left: $cloud-grey;
$divider__color--right: $cloud-grey;


// Global Buttons
$button__height: 48px;
$button__height-small: 32px;
$button__width: 100px;
$button__border-radius: 4px;
$button__font-weight: 400;
$button__font-size: $font-size-large;
$button__font-size-small: 13px; //$font-size-small;
$button__line-height: 1; //1.44;

$button__size--icon: 24px;

// Button Default
$button__padding: 10px 30px;
$button__padding-small: 3px 18px;
$button__border: 1px solid transparent;
$button__background: $dte-bright-blue;
$button__font-color: white;
$button__background-hover: white;
$button__border-hover: 1px solid $dte-bright-blue;
$button__font-color-hover: $dte-bright-blue;
$button__background-active: white;
$button__border-active: 1px solid $dte-bright-blue;
$button__font-color-active: $dte-bright-blue;;
$button__background-focus: $button__background-active;
$button__border-focus: $button__border-active;
$button__font-color-focus: $button__font-color-active;
$button__border-disabled: none;
$button__background-disabled: $light-peach;
$button__font-color-disabled: $white;

$button__font-family: $font-family-proxima-nova;
$button__font-size: 14px;
$button__font-weight: bold;

// Button White
$button__padding--white: $button__padding;
$button__padding-small--white: $button__padding-small;
$button__border--white: none;
$button__background--white: $white;
$button__font-color--white: $thunder;
$button__background-hover--white: $lipstick;
$button__border-hover--white: none;
$button__font-color-hover--white: $white;
$button__background-active--white: $lipstick;
$button__border-active--white: none;
$button__font-color-active--white: #9b9b9b;
$button__background-focus--white: $button__background-active--white;
$button__border-active--white: $button__border-active--white;
$button__font-color-active--white: $button__font-color-active--white;
$button__border-disabled-white: none;
$button__background-disabled-white: $light-peach;
$button__font-color-disabled-white: $white;
$button__border-disabled--white: none;
$button__background-disabled--white: rgba(255, 255, 255, 0.5);
$button__font-color-disabled--white: #9b9b9b;

// Button Secondary
$button__padding--secondary: $button__padding;
$button__padding-small--secondary: $button__padding-small;
$button__border--secondary: 1px solid $dte-bright-blue;
$button__background--secondary: white;
$button__font-color--secondary: $dte-bright-blue;
$button__background-hover--secondary: $dte-bright-blue;
$button__border-hover--secondary: 1px solid $dte-bright-blue;
$button__font-color-hover--secondary: white;
$button__background-active--secondary: $dte-bright-blue;
$button__border-active--secondary: none;
$button__font-color-active--secondary: white;
$button__background-focus--secondary: $button__background-active--secondary;
$button__border-focus--secondary: $button__border-active--secondary;
$button__font-color-focus--secondary: $button__font-color-active--secondary;
$button__border-disabled--secondary: none;
$button__background-disabled--secondary: $white;
$button__font-color-disabled--secondary: #9b9b9b;

// Button Green
$button__padding--green: $button__padding;
$button__padding-small--green: $button__padding-small;
$button__border--green: none;
$button__background--green: $green;
$button__font-color--green: white;
$button__background-hover--green: $mid-grey;
$button__border-hover--green: none;
$button__font-color-hover--green: white;
$button__background-active--green: $mid-grey;
$button__border-active--green: none;
$button__font-color-active--green: white;
$button__background-focus--green: $button__background-active--green;
$button__border-focus--green: $button__border-active--green;
$button__font-color-focus--green: $button__font-color-active--green;
$button__border-disabled--green: none;
$button__background-disabled--green: $white;
$button__font-color-disabled--green: #9b9b9b;


// Button Secondary White
$button__padding--secondary-white: $button__padding;
$button__padding-small--secondary-white: $button__padding-small;
$button__border--secondary-white: 1px $white solid;
$button__background--secondary-white: transparent;
$button__font-color--secondary-white: $white;
$button__background-hover--secondary-white: $white;
$button__border-hover--secondary-white: $button__border--secondary-white;
$button__font-color-hover--secondary-white: $thunder;
$button__background-active--secondary-white: $white;
$button__border-active--secondary-white: $button__border--secondary-white;
$button__font-color-active--secondary-white: #9b9b9b;
$button__background-focus--secondary-white: $button__background-active--secondary-white;
$button__border-focus--secondary-white: $button__border-active--secondary-white;
$button__font-color-focus--secondary-white: $button__font-color-active--secondary-white;
$button__border-disabled--secondary-white: 1px #9b9b9b solid;
$button__background-disabled--secondary-white: transparent;
$button__font-color-disabled--secondary-white: #9b9b9b;

// Button Label
$button__padding--label: 11px 14px;
$button__padding-small--label: $button__padding-small 6px 8px;


// Side Menu
$side-menu__trigger-background-color: transparent;


// Quantity Update
$quantity-update__container-border: 0.8px solid $gallery-grey;
$quantity-update__container-border-radius: 4px;
$quantity-update__container-box-shadow: 0 2px 0 0 $gallery-grey;
$quantity-update__button-background: transparent;
$quantity-update__button-color: $gallery-grey;
$quantity-update__input-width: 40px;


// Footer
$footer__background-color: $white;
$footer__padding: 45px 0;


// Cookie message
$cookie-message__background-color: $white;
$cookie-message__link-color: $bright-light-blue;
$cookie-message__close-color: $white;
$cookie-message__text-padding: 20px 15px;


// Container
$container__max-width: 1100px;
$container__margin: 15px;


// Catalog Grid Item
$catalog-grid-item__image-width: 200px;
$catalog-grid-item__image-height: 200px;
$catalog-grid-item__name-color: $thunder;
$catalog-grid-item__name-font-weight: 600;
$catalog-grid-item__font-size: 22px;
$catalog-grid-item__link-text-overflow: clip;
$catalog-grid-item__link-white-space: normal;
$catalog-grid-item__link-overflow: visible;
$catalog-grid-item__border: 1px #888888 solid;
$catalog-grid-item__padding: 15px;
$catalog-grid-item__line-height: 32px;
$catalog-grid-item__font-weight: 400;
$catalog-grid-item__content-padding: 8px 0;
$catalog-grid-item__name-margin-botttom: 2px;

$catalog-list-item__border: $catalog-grid-item__border;
$catalog-list-item__name-margin: 0 0 8px;
$catalog-list-item__padding: 15px 7px 15px 15px;
$catalog-list-item__font-size--main: 16px;


// Catalog List
$catalog-list__sidebar-width\@medium: 350px;
$catalog-list__sidebar-margin\@medium: 0;
$catalog-list__products-width\@medium: calc(100% - #{$catalog-list__sidebar-width\@medium} - #{$spacer--medium});
$catalog-list__margin: 15px auto 60px;

$catalog-grid-item__image-margin: 27px auto 10px;

$toolbar__sorter-width: 150px;
$toolbar__limiter-width: 150px;
$toolbar__sorter-icon-size: 30px;
$toolbar__sorter-icon-margin: 0 0 0 5px;
$toolbar__border: none;
$toolbar__limiter-margin\@medium: 0;
$catalog-grid-item__action-size: auto;
$catalog-grid-item__action-padding: 5px 7px;
$catalog-grid-item__action-size: 45px;
$toolbar__mode-margin-right: 0;


// Modal
$modal__close-button-top: 18px;
$modal__close-button-right: 6px;


// Minicart
$minicart-product__padding: 20px 0;
$minicart-content__top-bar-background: $white;
$minicart-content__top-bar-active-background: $white;
$minicart-content__top-bar-height: 1px;
$minicart-product__action-icon-size: 16px;
$minicart-product__action: 0;
$minicart-product__action-icon-color: $cloud-grey;


// Product View.
$product-view__price-font-size: 26px;
$product-view__title-font-size: $heading-font-size--third-level;
$product-view__sku-color: $bright-light-blue;
$product-view__price-color: #333333;
$product-view__stock-color: $frog-green;
$product-view__base-elements-vertical-spacing: 15px;
$product-view__wrapper-margin: 0 0 20px 0;


// Pagination
$pager__item-margin: 0 -1px 0 0;
$pager__list-padding: 30px 0;


// Tables
$table__border: 1px $light-peach solid;
$table_tr_box-shadow: $box-shadow-defined;
$table_tr-hovered: $higlight-blue;
