.info-block {

    position: relative;

    &.info-block-half {

        margin-bottom: 30px;

        .col-img,
        .col-info {

            background-position: center;
            background-size: cover;
            min-height: 430px;

            @media all and (max-width: 767px) {

                min-height: 300px !important;

            }

        }

        .col-info-inner {

            position: relative;
            min-height: 100%;
            padding: 30px 20px 83px;

        }

        .info-block-half--title {

            margin: 0 0 20px;

            * {

                color: $white;
                line-height: inherit;

            }

        }

        .info-block-half--description {

            color: $white;
            margin-bottom: 25px;

        }

        .info-block-half--link {

            position: absolute;
            bottom: 35px;

        }

    }

    &.info-block-certificate {

        max-width: 165px;
        margin-left: auto;
        margin-right: auto;

        .info-block-inner {

            background-color: $white;
            padding: 20px 10px;
            min-height: 100px;
            transition: box-shadow 0.35s;

        }

        a {

            display: block;

            &.no-url {

                cursor: default;
                text-decoration: none;

            }

        }

        img {

            max-width: 44px;
            width: auto;
            height: auto;
            display: inline-block;
            margin-left: auto;
            margin-right: auto;

        }

        .title {
            margin-top: 10px;
        }

        p {

            font-weight: 700;
            margin-bottom: 0;
            font-size: 14px;

        }

        a {
            color: inherit;
        }

        &:hover {

            .info-block-inner {
                @extend %shadow--l4;
            }

        }

        &.no-title {

            img {
                max-width: 65px;
            }

        }

    }

    &.info-block-small-right {

        margin-bottom: 25px;

        h3 {
            margin: 0 0 20px;
        }

        p {
            margin-bottom: 0;
        }

        .button-container {

            margin-top: 15px;
            text-align: center;

        }

    }

    &.info-block-overlay-right {

        background-position: center;
        background-size: cover;
        margin-bottom: 25px;

        .info-block-inner {

            > .row {

                .col-md-7 {
                    padding-right: 0;
                }

            }

        }

        &:before {

            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
            z-index: 0;
            content: '';
            background-color: #444;
            opacity: 0.3;

        }

        .info-block-inner-text-col {

            padding: 30px 45px;
            background-color: rgba(255,255,255,.85);
            position: relative;
            z-index: 1;
            min-height: 300px;

            * {
                color: $black;
            }

            h3 {
                margin-bottom: 15px;
            }

            p,
            .body
            {

                line-height: 1.5;
                font-size: 15px;
                margin-bottom: 0;

            }

            .button-container {

                margin: 20px 0 0;
                text-align: center;

                .button {
                    border-color: $black;
                }

            }

        }

    }

    @media all and (min-width: 768px) {

        &.info-block-overlay-right {

            &:before {
                display: none;
            }

        }

    }

    @media all and (max-width: 767px) {

        &.info-block-small-right {

            margin-bottom: 45px;

            img {
                margin-bottom: 15px;
            }

        }

        &.info-block-half {

            margin-bottom: 45px;

            .col-info-inner {
                padding: 30px 5px 35px;
            }

            .col-img,
            .col-info {
                min-height: 175px;
            }

            .col-img {
                order: -1;
            }

            .col-info {
                text-align: center;
            }

            .info-block-half--link {
                position: static;
            }

        }

        &.info-block-overlay-right {

            background-size: auto 250px;
            background-repeat: no-repeat;
            background-position: top center;
            padding-top: 249px;

            .info-block-inner-text-col {
                background-color: rgba(0,0,0,1);
            }

        }

    }

    &.reverse {

        .row {

            > div:first-of-type {

                order: 1;

            }

        }

    }

}

// New brand page widget styles

.info-block-brand-left-logo-banner {

    min-height: 290px;
    margin-bottom: 30px;

    .col-img {

        min-height: 290px;
        position: relative;

        img {

            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate( -50%, -50% );
            max-width: 170px;

        }

        @media all and ( max-width: 768px ) {

            display: none;

            //min-height: 0;
            //padding: 0;
            //
            //img {
            //
            //    transform: none;
            //    position: static;
            //    max-width: none;
            //
            //}

        }

    }

    .col-info {

        background-color: rgba(185, 217, 235, 0.17);

        .col-info-inner {


            padding: 50px 75px;

            @media all and ( max-width: 768px ) {

                padding: 40px 12px;
                text-align: center;

            }

            .info-block--title {

                font-size: 35px;

                @media all and ( max-width: 768px ) {

                    font-size: 24px;
                    line-height: 1.3;

                }

            }

            .info-block--description {

                font-size: 19px;

                @media all and ( max-width: 550px ) {

                    font-size: 14px;

                }

            }

        }

    }

}

.info-block-video-right,
.info-block-video-left,
.info-block-video-half {

    min-height: 380px;
    margin-bottom: 50px;
    padding: 70px;

    @media all and (max-width: 767px) {

        padding: 70px 30px;

    }

    .col-info {

        text-align: left;
        padding: 0;
        background-color: rgba(185, 217, 235, 0.17);

        &.no-image {

            .top-image {

                display: none;

            }

            .col-info-inner {

                top: 50%;

                @media all and (max-width: 767px) {

                    padding: 0 !important;
        
                }

            }

        }

        .top-image {

            min-height: 140px;
            background-position: center center;
            background-size: cover;

        }

        .col-info-inner {

            padding: 50px 80px;
            position: relative;
            top: calc(50% - 80px);
            min-height: unset;
            transform: translateY(-50%);
            text-align: left;

            @media all and ( max-width: 768px ) {

                padding: 30px;

            }

            .info-block--subtitle {

                font-size: 15px;
                color: #008eaa;
                font-weight: 550;

            }

            .info-block--title {

                font-size: 25px;
                font-weight: 550;
                color: #002A3A

            }

            .info-block--description {

                font-size: 15px;
                margin: 25px 0px 30px;
                color: #002a3a;

            }

            .button {

                display: inline-block;
                margin-bottom: 20px;
                padding: 15px 30px !important;

            }

        }

    }

    .col-video {

        min-height: 380px;

        .col-video-inner {

            position: relative;
            padding-bottom: 56.25%; /* 16:9 */
            padding-top: 25px;
            height: 100%;

            iframe {

                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;

            }

        }

        @media all and ( max-width: 768px ) {

            padding: 0;
            margin-top: 30px;

        }

    }

}

.info-block-video-half {

    .col-info {

        padding-left: 15px;
        background-color: #fff;

        .col-info-inner {

            background-color: #f6f6f6;

        }

        @media all and ( max-width: 768px ) {

            padding-left: 0;

        }

    }

    .col-video {

        padding-left: 0;

        @media all and ( max-width: 768px ) {

            margin-bottom: 30px;
            order: 2;

        }

    }

}

.info-block-video-right {

    .col-video {

        padding-right: 0;
        padding-left: 0;

        .col-video-inner {

            padding: 0 !important;

            iframe {

                max-width: 670px;
                max-height: 400px;
                left: unset;
                right: 0;

            }

        }

    }

    .col-info-inner {

        padding: 0 50px 0 50px !important;

    }

}

.info-block-video-left {

    .col-video {

        padding-left: 0;
        padding-right: 0;

        @media all and ( max-width: 768px ) {

            order: 2;

        }

        .col-video-inner {

            padding: 0 !important;

            iframe {

                max-width: 670px;
                max-height: 400px;
                right: unset;
                left: 0;

            }

        }

    }

    .col-info-inner {

        padding: 0 0 0 50px !important;

    }

}

.info-block-three-columns {

    visibility: hidden;

}

.container.info-block-three-columns {

    display: flex;
    justify-content: space-between;
    margin-bottom: 50px;
    flex-wrap: wrap;

    .info-block-three-columns {

        visibility: visible;
        flex: 1;

        &:not(:last-of-type) {

            margin-right: 30px;

            @media all and ( max-width: 768px ) {

                margin-right: 0;

            }

        }

        .top-image {

            height: 300px;
            width: 100%;
            background-position: center center;
            background-size: contain;
            background-repeat: no-repeat;

        }

        .col-info-inner {

            background-color: rgba(185, 217, 235, 0.17);

            .info-block--description {

                padding: 50px;
                text-align: center;

                @media all and ( max-width: 550px ) {

                    padding: 30px;
                    font-size: 14px;

                }

            }

        }

        @media all and ( max-width: 768px ) {

            flex-basis: 100%;

        }

    }

}

.brands-featured-product-slider-container {

    max-width: 1508px;
    margin: 0 auto 50px;

}

.info-block-featured-product-slider {

    text-align: center;

    .col-info-inner {

        padding: 0 50px;

        .info-block-featured-product-slider--title {

            font-size: 24px;
            margin: 15px 0 30px;

        }

    }

}

.info-block-white-paper-download {

    visibility: hidden;
    max-width: 100%;

}


.info-block-white-paper-container {

    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 50px;

    .info-block-white-paper-download {

        visibility: visible;
        flex: 1;
        background-color: rgba(185, 217, 235, 0.17);

        .col-info-inner {

            padding: 40px 100px;
            text-align: center;

            @media all and ( max-width: 768px ) {

                padding: 40px 15px;

            }

            .top-flag-outline {

                width: auto;
                border: 1px solid #4a90e2;
                padding: 9px 15px;
                display: inline-block;
                text-transform: uppercase;
                font-size: 12px;
                color: #4a90e2;
                border-radius: 3px;

            }

            .info-block--title {

                font-size: 30px;
                margin: 15px 0 30px;
                color: #4a90e2;
                line-height: 1.3;

            }

            .info-block--subtitle {

                font-size: 24px;
                margin: 0 0 20px 0;

            }

            .info-block--description {

                font-size: 15px;
                line-height: 21px;
                margin: 0 0 30px 0;

            }

            .download-white-paper-button {

                font-size: 18px;
                margin-bottom: 22px;
                display: inline-block;
                padding: 14px 20px;

            }

        }

        &:not(:last-of-type) {

            margin-right: 30px;

            @media all and ( max-width: 768px ) {

                margin-right: 0;
                margin-bottom: 30px;

            }

        }

    }

}

.info-block-half {

    .col-info {

        background-color: rgba(185, 217, 235, 0.17);

        .col-info-inner {

            padding: 50px 80px;
            position: relative;
            top: 50%;
            min-height: unset;
            transform: translateY(-50%);
            text-align: left;

            @media all and (max-width: 767px) {

                padding: 30px;

            }

            .info-block-half--title,
            .info-block-half--description {

                text-align: left;
                color: #002A3A;

                * {

                    color: #002A3A;

                }

                a {

                    font-weight: bold;

                }

            }

            .info-block-half--title {

                @media all and ( max-width: 768px ) {

                    font-size: 24px;

                }

            }

            .info-block-half--description {

                font-size: 15px;
                color: #303030;

                @media all and ( max-width: 768px ) {

                    font-size: 16px;

                }

            }

            .info-block-half--link {

                position: static;
                
                @media all and ( max-width: 768px ) {

                    font-size: 16px;

                }

            }

        }

    }

}

.summary-section {

    padding: 50px 100px 90px;
    text-align: center;
    margin-bottom: 50px;

    @media all and ( max-width: 768px ) {

        padding: 40px 15px;
        margin-bottom: 30px;

    }

    .summary-section-title {

        margin: 30px 0 40px;
        font-size: 38px;

    }

    .summary-section-subtitle {

        margin: 30px 0 40px;
        font-size: 25px;
        margin-bottom: 75px;

    }

    .summary-items {

        display: flex;
        flex-wrap: wrap;

        .info-block-summary-item {

            flex: 1;
            background-color: #fff;

            @media all and ( max-width: 768px ) {

                margin-bottom: 30px;
                flex-basis: 100%;

            }

            .summary-item-image {

                height: 175px;
                position: relative;
                padding: 25px 25px 0;
                box-sizing: content-box;

                img {

                    width: 50%;
                    max-width: 125px;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%,-50%);

                }

            }

            &:not(:last-of-type) {

                margin-right: 30px;

                @media all and ( max-width: 768px ) {

                    margin-right: 0;

                }

            }

            .summary-item-title {

                font-size: 23px;
                margin: 5px 0 15px;

            }

            .summary-item-description {

                padding: 20px 15px 30px;

            }

        }

    }

}

.info-block-full-width-banner {

    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;

    text-align: center;
    margin-bottom: 50px;

    .info-block-inner {

        height: 100%;

    }

    a > * {

        //-webkit-box-shadow: -1px -1px 28px 4px rgba(255,255,255,0.3);
        //-moz-box-shadow: -1px -1px 28px 4px rgba(255,255,255,0.3);
        //box-shadow: -1px -1px 28px 4px rgba(255,255,255,0.3);
        display: inline-block;
        width: auto;
        //background-color: rgba(255,255,255,0.2);
        //border-radius: 9px;

    }

    a {

        display: block;
        width: 100%;
        padding: 80px 150px 120px;
        height: 100%;

        &:hover {

            text-decoration: none;

        }

    }

    h2 {

        font-size: 30px;
        display: block;
        margin: 30px auto 0;

    }

    p {

        font-size: 17px;
        display: block;
        margin: 25px auto 0;

    }

    &.white-text {

        h2 {

            color: #fff;

        }

        p {

            color: #fff;

        }

        //a > * {
        //
        //    -webkit-box-shadow: -1px -1px 28px 4px rgba(0, 0, 0, 0.3);
        //    -moz-box-shadow: -1px -1px 28px 4px rgba(0, 0, 0, 0.3);
        //    box-shadow: -1px -1px 28px 4px rgba(0, 0, 0, 0.3);
        //    background-color: rgba(0, 0, 0, 0.1);
        //
        //}

    }

    @media all and ( max-width: 767px ) {

        background-image: none!important;
        background-color: rgba(185, 217, 235, 0.17);

        a {

            h2 {

                color: #333;
                font-size: 24px;

            }

            p {

                color: #333;
                font-size: 16px;

            }

        }

    }

    @media all and ( min-width: 768px ) {

        a {

            padding-top: 110px;
            padding-bottom: 110px;

            h2 {

                display: none;

            }

            p {

                display: none;

            }

        }

    }

    @media all and ( max-width: 767px ) {

        height: auto;

        .info-block-inner {

            a {

                padding: 50px 100px 30px;

            }

        }

    }

    @media all and ( max-width: 650px ) {

        margin-bottom: 30px;

        .info-block-inner {

            a {

                padding: 30px 15px 60px;

                > * {

                    padding: 0 15px;

                }

                h2 {

                    font-size: 22px;

                }

                p {

                    font-size: 14px;

                }

            }

        }

    }

    &.thin-banner {

        a {

            padding-top: 30px;
            padding-bottom: 55px;

        }

        &-white-text {

            a {

                h2 {

                    color: #fff;

                }

                p {

                    color: #fff;

                }

            }

        }

    }

}

.half-width-banner-shared-container {

    display: flex;
    margin-bottom: 50px;
    justify-content: space-between;

    @media all and ( max-width: 767px ) {

        flex-direction: column;
        margin-bottom: 0;

    }

}

.info-block-half-width-banner-container {

    width: 50%;
    display: inline-block;

    @media all and ( max-width: 767px ) {

        margin-bottom: 30px;

    }

    .info-block-half-width-banner {

        height: 100%;
        text-align: center;

        .info-block-inner {

            height: 100%;

        }

        a > * {

            //-webkit-box-shadow: -1px -1px 28px 4px rgba(255,255,255,0.4);
            //-moz-box-shadow: -1px -1px 28px 4px rgba(255,255,255,0.4);
            //box-shadow: -1px -1px 28px 4px rgba(255,255,255,0.4);
            display: inline-block;
            width: auto;
            //background-color: rgba(255,255,255,0.2);
            //border-radius: 9px;

        }

        a {

            display: block;
            width: 100%;
            padding: 40px 100px;
            height: 100%;
            min-height: 400px;
            background-size: cover;
            background-position: center center;
            background-repeat: no-repeat;

            &:hover {

                text-decoration: none;

            }

        }

        h2 {

            font-size: 30px;
            margin-top: 15px;
            max-width: 450px;

        }

        p {

            font-size: 17px;
            margin: 25px 0 30px;
            max-width: 450px;

        }

        &.white-text {

            h2 {

                color: #fff;

            }

            p {

                color: #fff;

            }

            //a > * {
            //
            //    -webkit-box-shadow: -1px -1px 28px 4px rgba(0, 0, 0, 0.3);
            //    -moz-box-shadow: -1px -1px 28px 4px rgba(0, 0, 0, 0.3);
            //    box-shadow: -1px -1px 28px 4px rgba(0, 0, 0, 0.3);
            //    background-color: rgba(0, 0, 0, 0.1);
            //
            //}

        }

    }

    @media all and ( min-width: 768px ) and ( max-width: 1128px ) {

        width: 47%;

        &:nth-of-type(odd) {

            // The left hand side

            padding-right: 0;
            margin-right: 0;

        }

        &:nth-of-type(even) {

            // The right hand side

            padding-left: 0;
            margin-left: 0;

        }

        .info-block-half-width-banner {

            a {

                padding: 30px 45px;
                min-height: 300px;

            }

        }

    }

    @media all and ( max-width: 767px ) {

        display: block;
        width: auto;

        .info-block-half-width-banner {

            a {

                padding: 40px 100px;
                min-height: 300px;

            }

        }

    }

    @media all and ( max-width: 650px ) {

        .info-block-half-width-banner {

            a {

                padding: 30px 15px;

                > * {

                    padding: 0 15px;

                }

                h2 {

                    font-size: 22px;

                }

                p {

                    font-size: 14px;

                }

            }

        }

    }

}
