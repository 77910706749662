body.account {

  //.account-action-tooltip {
  //  .lib-tooltip(bottom);
  //}

  &.desk-customer-new,
  &.desk-customer-view {

    #maincontent {

      margin-top: 0;



      .top-account-toolbar {

        .actions-toolbar {

          padding-bottom: 6px;

        }

      }

    }

  }

  &.desk-customer-view, &.desk-customer-new {

    #maincontent {
      .top-account-toolbar {
        margin: 0;
      }
    }

    .ticket-go-back {

      display: none;

    }

    .customer-ticket {
      display: flex;
      flex-wrap: wrap;

      .ticket-messages {

        .message {
          color: #000000;
          border: none;
          padding: 30px 48px;
          border-radius: 0;
          font-size: 16px;
          flex-wrap: wrap;

          &.owner-customer {
            background-color: #f1f8fb;
            margin-top: 0;
          }

          .title {
            width: 100%;
          }
          .message-content {
            width: 100%;
          }
          &::after{
            top: 35px;
            bottom: unset;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15' height='15'%3E%3Cpath fill='%2310a9ec' d='M7 .333A6.67 6.67 0 0 0 .333 7 6.67 6.67 0 0 0 7 13.667 6.67 6.67 0 0 0 13.667 7 6.67 6.67 0 0 0 7 .333zm.667 10H6.333V9h1.334v1.333zm0-2.666H6.333v-4h1.334v4z'/%3E%3C/svg%3E")
          }
        }

        .actions-toolbar {

          .primary {
            margin-bottom: 0;

          }

        }

      }
      @media all and (max-width: 767px) {
        .ticket-messages {
           width: 100%;
           order: 2;
           margin: 30px 0 0 0;

          #desk_message_form {
            margin-top: 30px;
          }

        }
        .ticket-details {
          width: 100%;
          order: 1;
        }

      }

    }

  }

  .ticket-go-back {
    padding-left: 0;
    margin-bottom: 20px;
  }

  .dte-quote-form {

    position: relative;
    width: 80%;
    margin: 0 auto;

    .grid__column--3-4 {

      flex-basis: calc(100% - 4px);
      max-width: calc(100% - 4px);

      .input__field {

        height: 60px;
        border-radius: 30px;

      }

    }

  }

  .dashboard-nav {

    .dashboard-nav__content {

      .nav.item a {

        &:hover,
        &:focus {

          color: #526B79;

        }

      }

      .current,
      .nav.item.current {

        border-color: $color-primary;
        color: $color-primary;
        font-size: 15px;
        border: 0;
        height: 44px;
        margin: 0;

        > a {

          color: $color-primary;

        }

        strong {

          font-size: 15px;
          color: #526B79;

        }

      }

      .dashboard-nav__title {
	    border-bottom: none !important;
	    padding-bottom:20px;
        font-size: 18px;
        font-weight: bold;
        margin: 0 0 10px 10px;
        height: 40px;
        padding-bottom: 0;
        display: flex;
        align-items: center;
      }

    }

  }

  address {
    font-style: normal;
  }

  table {

    thead th.col.qty {

      min-width: 125px;

    }

  }

  &.quotelist-account-view {
    .bom-btn-search-form-container {
      text-align: right;
      padding: 0 20px;

      .dte-bom-button-container {
        float: none;

        a.button {
          width: auto;
          margin: 0;
        }

      }

      #quoteSearchForm {
        float: none;
        margin-right: 30px;

        #quoteSearch {
          width: 200px;
        }
      }
    }
  }

  &.quotelist-cart-index {


    @media all and (max-width: 767px) {

      .quote-info {

        h2 {

          margin-left: 0;
          display: block;

        }

      }

      .actions-container {

        display: block;

        > .button {

          width: 80%;
          box-sizing: border-box;
          max-width: 250px;
          margin: 8px auto;
          display: block;
          text-align: center;
          vertical-align: middle;

        }

        a.button {

          padding-top: 8px;

        }

      }

    }

    .quantity-update {

      button.quantity-update__button,
      input.quantity-update__input {

        height: 35px;
        padding: 9px 5px 10px;
        font-size: 14px;

        svg {

          height: 16px;
          width: 16px;

        }

      }

    }

    .actions-toolbar-bottom {

      button[type=submit] {

        width: auto;

      }

    }

  }

  .top-account-toolbar {
    margin-bottom: 30px;

    .actions-toolbar {

      display: inline-block;
      width: 49%;
      vertical-align: top;
      padding-top: 7px;

      a.back {

        margin-top: 0;
        height: 40px;

      }

    }

    .actions-container {

      display: inline-block;
      width: 50%;
      text-align: right;
      margin-bottom: 0;

      .quote-action-icons {

        display: flex;
        justify-content: flex-end;

        > button,
        > a {

          border: none;
          margin: 0 5px;
          padding: 15px;
          cursor: pointer;
          background-color: transparent;

          svg {

            width: 25px;
            height: 25px;
            transition: all 200ms ease-in-out;

          }

        }

        &:hover {

          > button,
          > a {

            svg {

              opacity: 0.5;

            }

            &:hover {

              svg {

                opacity: 1;
                transform: scale(1.1);

              }

            }

          }

        }

        a[title],
        button[title] {

          position: relative;

          &:hover {

            &::before {

              content: attr(title);
              padding: 10px;
              background-color: $color-primary;
              position: absolute;
              bottom: -33px;
              left: 50%;
              transform: translateX(-50%);
              color: #fff;
              min-height: 41px;
              line-height: 20px;
              font-family: $font-family-sans-serif;
              @extend %shadow--l1;

            }

            &::after {

              content: '';
              padding: 5px;
              background-color: $color-primary;
              position: absolute;
              bottom: -1px;
              left: 50%;
              color: #fff;
              transform: rotate(45deg) translateX(-50%);

            }

          }

        }

      }

    }

  }

  .title-wrapper {

    padding-top: 10px;


    .heading {

      margin: 0;
      font-size: 18px;
      font-weight: bold;
      display: flex;
      align-items: center;
      height: 40px;

    }


  }

  .dashboard-form__title {

    font-size: 15px;
    font-weight: bold;
    border-bottom: 0;

  }

  .dashboard-form__divider {

    max-width: 685px;

    .input.newPassword {

      position: relative;

      @media all and (min-width: 1025px) {

        max-width: 472px;

      }

    }

    .newPassword input:not(.mage-error) {

      @media all and (max-width: 1024px) {

        margin-bottom: 25px;

      }

    }

    .newPassword #password-error:not([style="display: none;"]) {

      @media all and (max-width: 1024px) {

        margin-bottom: 40px;

        & + [data-role="password-strength-meter"] {

          bottom: -20px;

        }

      }

    }

  }

  &.customer-account-edit .action {

    max-width: 680px;

  }

  &.customer-account-edit {

    // Updating password strength design
    .mage-error[style="display: block;"] + [data-role="password-strength-meter"] {

      top: calc(50% - 22px);

    }


    [data-role="password-strength-meter"] {

      position: absolute;
      right: -120px;
      top: calc(50% - 4px);
      margin: 0;
      width: 100px;
      font-size: 13px;

      @media all and (max-width: 1024px) {

        right: unset;
        left: 0;
        bottom: 0;
        top: unset !important;

      }

      &::before,
      &::after {

        display: inline-block;
        content: '';
        height: 6px;
        width: 100px;
        border-radius: 5px;
        position: absolute;
        left: 0;
        top: 0;

      }

      &::before {

        background-color: #EFEFEF;

      }

      &::after {

        width: 0;

      }

      &.password-weak {

        [data-role="password-strength-meter-label"] {

          color: #DC2048;

        }

        &::after {

          background-color: #DC2048;
          width: 25px;
          transition: width 0.2s ease-in-out;

        }

      }

      &.password-medium {

        [data-role="password-strength-meter-label"] {

          color: #DC800D;

        }

        &::after {

          background-color: #DC800D;
          width: 50px;
          transition: width 0.2s ease-in-out;

        }

      }

      &.password-strong {

        [data-role="password-strength-meter-label"] {

          color: #32C026;

        }

        &::after {

          background-color: #32C026;
          width: 75px;
          transition: width 0.2s ease-in-out;

        }

      }

      &.password-very-strong {

        [data-role="password-strength-meter-label"] {

          color: #32C026;

        }

        &::after {

          background-color: #32C026;
          width: 100px;
          transition: width 0.2s ease-in-out;

        }

      }

      .password-strength__progress {

        position: relative;
        left: 90px;
        display: flex;
        margin: 0;
        align-items: center;
        height: 6px;
        width: 200px;

        [data-role="password-strength-meter-label"] {

          margin-left: 25px;

        }

      }

    }

    label[for="subscription"] {

      font-size: 13px;
      color: #686868;
      font-weight: 500;

      a {

        font-weight: bold;

      }

    }

  }

  &.customer-address-form {
      .form-address-edit {
          .label {
              display:none;
          }
      }
  }

}

body.wishlist-index-index {

  .wishlist__grid {

    .wishlist__item {

      padding: 0;

      @media all and (min-width: 1300px) {

        flex-basis: 33.33333333%;
        max-width: 33.33333333%;

      }

      @media all and (min-width: 768px) and (max-width: 991px) {

        flex-basis: 70%;
        max-width: 70%;
        margin: 16px auto;

      }

      // Product item container.
      > div {

        border: thin #dedede solid;
        border-radius: 5px;
        margin: 0 9px 15px;
        padding: 17px;

        // Product image container
        > a {

          display: block;
          position: relative;
          z-index: -1;

        }

      }

      .wishlist__item-name {

        padding: 0;

        a {

          font-size: 14px;
          font-weight: 600;
          line-height: 24px;
          min-height: 72px;

        }

      }

      .product__price {

        font-size: 18px;
        line-height: 27px;
        font-weight: 600;
        margin-top: 7px;

      }

      .wishlist__comment {
        margin: 20px 0;
      }

      .price-box {
        padding: 0;
      }

      .wishlist__item-inner {
        padding: 0;
      }

      .wishlist__qty {

        max-width: 100%;

        .quantity-update__button {
          width: 25%;
        }

      }

      .wishlist__item-actions {

        width: 100%;

        .button--icon {

          width: auto;
          min-width: 0;
          height: auto;
          padding: 11px;

          .button__icon {

            stroke: none;
            fill: #fff;
            width: 13px;
            height: 13px;

          }

        }

      }

      .wishlist__field-qty {

        margin: 0;
        text-align: center;
        width: 48%;

      }

      .wishlist__label {
        margin: 0 0 6px 0;
      }

    }

  }

}

#msp-recaptcha-container {
  margin: 15px 0;
}

.wishlist-index-index {

  .catalog-grid-item {

    &__primary-form {

      flex-wrap: wrap;

      .quantity-update-container {

        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        flex-basis: 100%;
        align-items: center;
        margin: 10px 0;
        justify-content: space-between;

        button {

          margin: 0 !important;
          height: 34px !important;

        }

      }

      > button {

        width: 100%;
        height: 48px;

      }

    }

  }

  .catalog-grid-item__primary-action.tocart {
    margin: 0 !important;
  }

}

.paypal-billing_agreement-index {

  .field.payment.method {

    .control {

      select {

        background: white;
        width: 100%;
        height: 44px;
        padding: 0 12px;
        border: 1px solid #e0e0e0;
        border-radius: 4px;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        font-family: inherit;
        position: relative;
        z-index: 4;

      }

    }

  }

  .actions-toolbar {

    .primary {

      button.primary.action.create {

        display: inline-flex;
        justify-content: center;
        cursor: pointer;
        height: 48px;
        min-width: 100px;
        padding: 10px 30px;
        border: 1px solid transparent;
        border-radius: 5px;
        outline: none;
        background: #0AB6FF;
        font-size: 14px;
        color: white;
        transition: all 0.3s ease-in-out, outline-offset 1ms;
        font-weight: bold;
        line-height: 1;
        vertical-align: middle;
        align-items: center;
        margin: 10px 0;

        &:focus,
        &:hover {

          background: white;
          border: 1px solid #0AB6FF;
          color: #0AB6FF;

        }

      }

    }

  }

}

body.customer-account-logoutsuccess {

  #maincontent {

    padding: 0 60px;

  }

}

// Manually fixing sort order of account nav items to match design
body.account {

  .list.dashboard-nav__list {

    display: flex;
    flex-wrap: wrap;

    .nav.item {

      width: 100%;

      // My Orders
      &:nth-of-type(4) {

        order: 1;

      }

      // Account details
      &:nth-of-type(2) {

        order: 2;

        &::after {

          transform: translate(0%, -55%) scale(65%);

        }

      }

      // Addresses
      &:nth-of-type(6) {

        order: 3;

      }

      // Wishlist
      &:nth-of-type(5) {


        order: 4

      }


      // Tickets
      &:nth-of-type(8) {

        order: 5;

      }

      // Logout
      &:nth-of-type(7) {

        order: 10;

      }

    }

  }

  &.amazon-store {

    .list.dashboard-nav__list {

      .nav.item {

        // Orders
        &:nth-of-type(1) {

          order: 1;

        }

        // Account Details
        &:nth-of-type(2) {

          order: 2;
          display: none;

        }

        // Quotes
        &:nth-of-type(5) {

          order: 3;

        }

        // Addresses
        &:nth-of-type(3) {

          display: none;
          order: 4;

        }

        // Wishlist
        &:nth-of-type(4) {

          order: 5;

        }

        // Tickets
        &:nth-of-type(7) {

          order: 6;


        }

        // Logout
        &:nth-of-type(6) {

          order: 7;

        }

      }

    }

  }

}


.customer-account-create {

    .swissup-customer-fields-container {

        .field {

            .control {

                .input-text {

                    margin-bottom: $input__margin-bottom;
                    background-color: $input__field-background-color;
                    color: $input__field-color;
                    width: 100%;
                    height: $input__field-spacing;
                    line-height: $input__field-spacing;
                    padding: $input__field-padding;
                    border: $input__field-border;
                    border-radius: $input__field-border-radius;
                    font-size: $input__field-font-size;
                    font-family: $input__field-font-family;

                }

                .select {

                    position: relative;
                    z-index: 4;
                    width: 100%;
                    height: $select__spacing;
                    padding: $select__padding;
                    border: $select__border;
                    border-radius: $select__border-radius;
                    appearance: none;
                    font-family: inherit;

                }
            }

        }

    }

}
