$message_success_color: #90d9fb;
$message_warning_color: #eec18f;
$message_notice_color: #2962ff;
$message_alert_color: #e98ea0;

$message_border_radius: 4px;

$message_background_color: #fff;
$message_padding: 20px;


.message-container {

  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 15px;

  .message {
      border: 1px;
      border-radius: $message_border_radius;
      font-size: 15px;
      font-weight: bold;

      div{

        &:before, &:after{
          position: absolute;
          content: ' ';
          height: 15px;
          width: 2px;
          right: 20px;
          top: 21px;
          cursor: pointer;
        }

        &:after{
          transform: rotate(-45deg);
        }
        &:before{
          transform: rotate(45deg);
        }
      }


      &.message-success{
        border: 1px solid $message_success_color;
        padding: $message_padding;
        color: $message_success_color;
        background: $message_background_color;

        div:after{
          background-color: $message_success_color;
        }

        div:before{
          background-color: $message_success_color;
        }
      }

      &.message-notice{
        padding: $message_padding;
        background: $message_background_color;
        border: 1px solid $message_notice_color;

        div:after{
          background-color: $message_notice_color;
        }

        div:before{
          background-color: $message_notice_color;
        }
      }

      &.message-warning{
        border: 1px solid $message_warning_color;
        padding: $message_padding;
        color: $message_warning_color;
        background: $message_background_color;
        div:after{
          background-color: $message_warning_color;
        }

        div:before{
          background-color: $message_warning_color;
        }
      }

      &.message-error{
        border: 1px solid $message_alert_color;
        padding: $message_padding;
        color: $message_alert_color;
        background: $message_background_color;
        div:after{
          background-color: $message_alert_color;
        }

        div:before{
          background-color: $message_alert_color;
        }
      }

      &:after{
        display: none;
      }
  }

}

.page {
  &.messages {

    max-width: 1440px;
    padding: 0 15px;

    .message {
      border: 1px solid #90d9fb;
      padding: 20px 35px;
      color: #90d9fb;
      background: #fff;
      border-radius: 4px;
      font-size: 15px;
      font-weight: bold;
    }

  }
}
