$breadcrumbs__margin             : $spacer !default;
$breadcrumbs__padding            : $spacer--medium $spacer !default;
$breadcrumbs__color              : $gray-dark !default;
$breadcrumbs__color-hover        : $gray-dark !default;
$breadcrumbs__font-family        : $font-family-proxima-nova !default;
$breadcrumbs__color--active      : $blue !default;
$breadcrumbs__font-family--active: $font-family-proxima-nova !default;
$breadcrumbs__font-size          : $font-size-small !default;

.breadcrumbs {
    display: none;

    @include mq($screen-m) {

        display: flex;
        align-items: center;
        width: 100%;
        margin: 0 auto;
        padding: 0;

    }

    &__list {
        @include mq($screen-m) {
            display: flex;
            flex-wrap: wrap;
            margin: 0;
            padding: 0;
	        margin-left: 13px !important;
        }
    }

    &__item {

        display: flex;
        align-items: center;
        color: $breadcrumbs__color;
        font-size: $breadcrumbs__font-size;

        &:after {

            content: '\203A';
            display: flex;
            align-items: center;
            margin: 0 $breadcrumbs__margin;
            color: $breadcrumbs__color;
            font-size: 30px;
            font-weight: 300;
            margin-top: -8px;

        }

        &:last-child:after{
            content: '';
        }

        a {
            color: $breadcrumbs__color;
        }

        &:first-of-type {
            .breadcrumbs__link {
                padding-left: 0;
            }
        }

        &:last-child {
            &:not(:first-child) {
                align-items: center;
                padding: $breadcrumbs__padding;
                font-family: $breadcrumbs__font-family--active;
                color: $breadcrumbs__color--active;
            }

            &:after {
                display: none;
            }
        }
    }

    &__link {
        display: flex;
        align-items: center;
        padding: $breadcrumbs__padding;
        font-family: $breadcrumbs__font-family;
        text-decoration: none;
    }

    & + .page-main {
        .heading--page {
            margin-top: 0;
            display:none;
        }
    }

    // Product page specific?
    > ul.items {

        @include mq($screen-m) {

            display: block;
            margin: 0;
            padding: 0;

        }

        .item {

            display: inline-block;
            color: $breadcrumbs__color;
            font-size: $breadcrumbs__font-size;

            &:after {

                content: '\203A';
                display: inline-block;
                margin: 0 $breadcrumbs__margin;
                color: $breadcrumbs__color;
                font-size: 28px;
                font-weight: 300;

            }

            /*a {
                color: $breadcrumbs__color-hover;
            }*/

            a {

                display: inline-block;
                padding: $breadcrumbs__padding;
                font-family: $breadcrumbs__font-family;
                text-decoration: none;

            }

            strong {
                font-weight: normal;
            }

        }

    }
}

.amazon-store.page-products {

    nav.breadcrumbs.container {

        @media all and ( min-width: 768px ) {

            margin-top: 0;
            height: 48px;

        }

    }

}

.catalog-product-view {

    .breadcrumbs {

        &__list {
            @include mq($screen-m) {
                flex-wrap: nowrap;
            }
        }

    }

}
