body.paypal-express-review {

  background-color: #eff2f7;

  .title-wrapper {

    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;

  }

  .paypal-review.view {

    > * {

      box-sizing: border-box;
      padding: 25px 13px;
      background: #fff;
      border: 0;
      border-radius: 10px;
      box-shadow: 0 3px 23px -3px rgba(0,0,0,.1);
      margin: 0 0 30px;

    }

    .box {

      margin: 0;

      .box-title {

        display: block;
        margin: 0 0 15px;
        padding: 0 0 15px;
        font-weight: 600;
        font-size: 1.5em;
        color: #2f348a;
        border-bottom: 1px #888 solid;

      }

    }

    .paypal-review-discount {
      display: none!important;
    }

    .action.edit {

      padding: 9px 18px;
      border: 1px #231f20 solid;
      background: #fff;
      color: #231f20;
      font-size: 13px;
      cursor: pointer;
      border-radius: 100px;
      outline: none;
      font-family: montserrat, sans-serif;
      transition: all 0.3s ease-in-out;
      font-weight: 400;
      line-height: 1;
      vertical-align: middle;
      width: auto;
      margin-top: 10px;
      text-decoration: none;
      display: inline-block;
      height: auto;
      text-align: center;

      &:hover {

        background: #dc2048;
        border: none;
        color: #fff;
        box-shadow: 0 3px 9px 1px rgba(65,64,64,.32);

      }

    }

    .block-order-details-view {

      .box {

        margin-bottom: 30px;

        &:last-child {
          margin-bottom: 0;
        }

      }

      .box-order-billing-address {

        .box-content {

          line-height: 1.35;

          img {

            display: inline-block;
            width: auto;
            max-width: 68px;
            margin: 15px 0 0;

          }

        }

      }

    }

    .paypal-review-items {

      .paypal-review-title {

        display: block;
        margin: 0 0 15px;
        padding: 0 0 15px;
        font-weight: 600;
        font-size: 1.5em;
        color: #2f348a;

        strong {
          font-weight: 600;
        }

        a {
          display: none;
        }

      }

      #details-reload {

        display: block;
        padding: 0;
        margin-bottom: 0;

        .table-caption {
          display: none;
        }

        #details-table {

          display: table;
          margin-bottom: 0;

          thead {

            display: table-header-group;

            tr {

              th {

                color: #8f8f8f;

                &:first-child {
                  padding-left: 0;
                }

              }

            }

          }

          tbody {

            display: table-row-group;

            tr {

              td {

                &:first-child {
                  padding-left: 0;
                }

                &.item {

                  .product-image-photo {

                    width: 72px;
                    max-width: 72px;

                  }

                }

                &.subtotal {
                  text-align: right;
                }

              }

            }

          }

          tfoot {

            display: table-footer-group;

            tr {

              th,
              td,
              td .price
              {

                padding: 0 0 15px;
                font-size: 13px;
                font-weight: 400;
                text-transform: none;
                background: #fff;
                color: #333;
                border: none;

              }

              th {
                text-align: left;
              }

              td {
                text-align: right;
              }

              &.totals.sub{

                th,
                td
                {
                  padding-top: 60px;
                }

              }

              &.totals.grand {

                strong,
                .price
                {
                  font-weight: 700;
                }

              }

            }

          }

        }

      }

      #order-review-form {

        .action.checkout.primary {

          cursor: pointer;
          height: auto;
          padding: 15px 20px;
          border: none;
          border-radius: 100px;
          outline: none;
          background: #dc2048;
          font-family: montserrat,sans-serif;
          font-size: 18px;
          color: #fff;
          transition: all .3s ease-in-out;
          font-weight: 400;
          line-height: 1;
          vertical-align: middle;
          box-shadow: none;
          width: 100%;

          span {

            text-align: center;
            width: 100%;

          }

        }

      }

    }

  }

}

@media all and (min-width: 768px) {

  body.paypal-express-review {

    background-color: #eff2f7;

    .title-wrapper {

      max-width: 1200px;
      margin-left: auto;
      margin-right: auto;

    }

    .paypal-review.view {

      max-width: 1200px;
      margin-left: auto;
      margin-right: auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: start;

      > * {
        flex: 1 0 100%;
      }

      .block-order-details-view {

        flex-basis: calc(63% - 13px);
        max-width: calc(63% - 13px);

        > * {
          padding: 13px;
        }

      }

      .paypal-review-items {

        flex-basis: calc(37% - 13px);
        max-width: calc(37% - 13px);

        > * {
          padding: 13px;
        }

      }

      .paypal-review-items {

        #details-reload {

          #details-table {

            tbody {

              tr {

                td {

                  &.item {

                    flex-direction: row-reverse;
                    display: flex;
                    flex-wrap: wrap-reverse;
                    justify-content: flex-end;

                  }

                }

              }

            }

          }

        }

      }

    }

  }

}

@media all and (max-width: 767px) {

  body.paypal-express-review {

    #maincontent {
      margin-top: 0;
    }

    .paypal-review.view {

      .paypal-review-items {

        .paypal-review-title {
          padding-bottom: 0;
        }

        #details-reload {

          #details-table {

            tbody {

              tr {

                border: none;
                border-bottom: 1px #d9d9d9 solid;
                padding: 0 0 10px;
                margin: 0 0 10px;

                &:first-child {

                  border-top: 1px #d9d9d9 solid;
                  padding-top: 10px;

                }

                td {

                  padding: 1px 7px!important;
                  border: none;

                  > * {

                    display: inline-block;
                    width: 85%;
                    text-align: right;

                  }

                  &:before {

                    display: inline-block;
                    font-size: 13px;
                    font-weight: 700;
                    background: #fff;
                    color: #333;
                    text-align: left;
                    line-height: 1.35;
                    max-width: 50%;
                    width: 100%;

                  }

                }

              }

            }

            tfoot {

              background: #fff;

              tr {

                padding: 7px;
                border: none;

                th,
                td
                {

                  padding: 0;
                  text-align: left;

                }

                td {

                  &:before {

                    display: inline-block;
                    font-size: 13px;
                    font-weight: 700;
                    background: #fff;
                    color: #333;
                    line-height: 1.35;
                    max-width: 50%;
                    width: 100%;
                    margin: 0;

                  }

                  > * {

                    display: inline-block;
                    width: 50%;
                    text-align: right;
                    float: right;

                  }

                }

                &.totals.sub {

                  th,
                  td
                  {
                    padding-top: 0;
                  }

                }

              }

            }

          }

        }

      }

      #order-review-form {

        .action.checkout.primary {
          margin-top: 20px;
        }

      }

    }

  }

}
