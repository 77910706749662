$fotorama-arw-size: 80px !default;
$fotorama-duration-time: 0.3s !default;
$fotorama-fullscreen-zoom-time: 0.3s !default;
$fotorama-inner-box-shadow: 3px !default;
$fotorama-spinner-size: 64px !default;
$fotorama-thumb-arrow: 30px !default;
$fotorama_close_button: 80px !default;
$size-fotorama-block: 80px !default;
$focus__color: $black !default;
$focus__box-shadow: 0 0 3px 1px $focus__color !default;

// z-index
$z-index-1: 100 !default;
$z-index-8: 800 !default;
$z-index-9: 900 !default;
$z-index-10: 1000 !default;
