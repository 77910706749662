// Quote list view

$quote-slate-grey: #4f5c67;

.quotelist-account-view,
.develodesign_portalquotes-quote-view,
.develodesign_portalorders-orders-view{
  table {
    margin: 10px 0;
    caption {
        #country-filter {

            float: right;
            margin-right: 10px;
            position: relative;

            @media all and (max-width: 534px) {
                float: left;
                margin-top: 34px;
                margin-left: -4px;

            }

            select.table-filter {

                font-family: $font-family-proxima-nova;
                color: #333;
                background-color: #fff;
                -webkit-appearance: none;
                padding: 15px 40px 15px 19px;

            }

            &:after {

                content: '\203A';
                position: absolute;
                right: 15px;
                top: 50%;
                transform: translateY(-50%) rotate(90deg);
                font-size: 22px;

            }

        }

    }
    thead th {
      padding: 10px 0 20px 10px;
    }

    tbody tr td {
      padding: 10px 0 10px 10px;
      font-weight: 500;

      .mobile-chevron {
        display: none;
      }

        .row-actions {
            display: flex;

            .transfer-items-to-cart,
            .transfer-cart-items-to-quotes,
            .dte-delete-all-button {
                width: 130px;
                height: 44px;
                margin-left: 10px;
            }

        }

      @media all and (max-width: 534px) {
        border: none;
      }
    }

    tbody tr td.id {
      color: #0AB6FF;
      text-decoration: none;
      font-weight: bold;
    }

    // Chevrons for hover
    @media all and (max-width: 534px) {
      tbody {
        tr {
          position: relative;

          td[data-th="Quote No"] {
            .mobile-chevron {
              display: block;
              position: absolute;
              right: 0;
              top: 50%;
              transform: translateY(-50%);

              svg {
                width: 50px;
                height: 50px;
                max-width: 50px;
                max-height: 50px;
              }
            }
          }
        }
      }
    }
  }

  .order-products-toolbar {

    .toolbar {

      width: 100%;

      .select__field-list {

        background-color: $white;

      }

    }

  }

  #my-quotes-history-table {

    margin: 40px 0 30px;

  }

  #maincontent {
    @media all and (max-width: 534px) {
      background: #f1f8fb !important;
      padding: 0 25px;
      margin: 0 auto;

      table {
        border-radius: 0;
        background: none;

        tbody {

          tr {
            border: 1px solid transparent;
            transition: all ease-in-out 0.2s;

            &:hover:not(.totals):not(.quote-totals):not(.total-tr) {
              border: 1px solid #0ab6ff;
            }
          }

          tr:not(.totals):not(.quote-totals):not(.total-tr) {
            background: white;
            padding: 15px;
            margin-bottom: 15px;

            td {
              font-size: 13px;

              &.product-name {
                font-size: 14px;

                &:before {
                  display: none;
                }
              }

              &.stock-desktop {
                display: none;
              }

              &.stock-mobile {
                display: flex;
              }
            }
          }

          tr {
            &.totals,
            &.quote-totals {
              td {
                font-size: 14px;
                font-weight: bold;

                span {
                  font-size: 24px;
                  margin-left: 10px;
                }
              }
            }
          }
        }
      }
    }
  }
}

.quote-info {
  .row-inner {
    margin-left: 0;
    margin-right: 0;
  }
}

.quote-info-items-container {

  background: $breadcrumbs-bg-colors;
  position: relative;

  @media all and (min-width: 1040px) {

    padding-bottom: 10px;

  }

  @media all and (max-width: 534px) {
    text-align: left;
    display: flex;

    .quote-items-title {
      flex: 1;
    }
    .order-dropdown-menu-control {
      display: flex;
      justify-content: flex-end;
      margin-top: 0;
      right: 0;
      float: none;
    }

    .order-dropdown-menu-container {
      right: -2px;
      top: 30px;
      margin-top: 0;
    }
  }

  .quote-items-title {
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 15px;
  }

}

.quote-info-items {

  padding: 10px 0px 0px 0px;
  width: 100%;
  background: $breadcrumbs-bg-colors;
  margin-bottom: 25px;
  margin-top: 20px;

    @media all and (max-width: 534px) {
    padding: 15px;
    border: 1px solid #0ab6ff;
    background: white;
    margin: 20px 0;
  }

  .col-xs-12 {

    @media all and (max-width: 1200px) {


      &:first-of-type {

        padding: 0;

      }

      &:last-of-type {

        padding: 0;

      }


    }

    @media all and (max-width: 991px) {

      padding: 0;

    }

  }


  .quote-info-item {

    box-shadow: $box-shadow-defined;
    background: $white;
    border: 1px solid $higlight-blue;
    padding: 30px;
    margin-bottom: 30px;
    display: flex;
    align-items: center;

    @media all and (min-width: 992px) {

      height: 100%;

    }

    @media all and (max-width: 534px) {
      text-align: left;
      padding: 0;
      margin: 0;
      border: 0;
      box-shadow: none;
    }

    p {

      margin: 0 0 6px 0;
      color: $dark-grey;
      font-weight: 600;
      font-size: 16px;
      display: flex;
      flex-wrap: wrap;

      @media all and (max-width: 534px) {
        display: flex;
      }

      span {

        color: #565656;
        font-weight: 500;
        margin-right: 10px;

        @media all and (max-width: 534px) {
          width: 130px;
        }

      }

    }

  }

}

// Individual quote view
.sales-order-history,
.quotelist-account-view,
.quotelist-cart-index,
.sales-order-history,
.develodesign_portalorders-orders-view {
  table {
    margin: 10px 0;

    thead th {
      padding: 10px 0 20px 10px;
    }

    tbody tr td {
      padding: 10px 0 10px 10px;
      .icon-order-status{
        position: relative;
        text-align: center;
        cursor: pointer;
          svg.header__icon {
              margin-left: 0;
              max-width: 20px;
              padding-top: 4px;

          }
       &:hover {
              span.tooltiptext {
                  visibility: visible;
              }
          }
      }
      svg.header__icon {
        margin-left: 13px;
        max-width: 28px;;
      }
      .tooltiptext {
            visibility: hidden;
            width: max-content;
            color: #32C026;
            background-color: #fff;
            margin:0;
            border-radius: 0;
            padding: 20px;
            position: absolute;
            top: -80px;
            left: 50%;
            transform: translateX(-50%);
            font-size: 15px;
            font-weight: bold;
            border: 1px solid transparent;
            box-shadow: 0 2px 10px 2px rgba(204,204,204,0.58);
            &::before {
                content:"";
                display:block;
                position:absolute;
                border-top: 8px solid transparent;
                border-bottom: 8px solid transparent;
                border-right:8px solid #fff;
                left: 47%;
                bottom:  -13px;
                transform: rotate(-90deg);

            }

        }
    }
  }

  .quote-totals {
    border-bottom-color: transparent;
    text-align: right;
    font-weight: bold;
    font-size: 22px;
    color: #303030;

    td {
      border-bottom-color: transparent;
      padding-top: 2.5rem;
    }
  }

  .quote-extra-help-action-text {
    text-align: right;
    position: relative;
    font-size: 130%;
  }

  .quote-extra-help-action-checkbox {
    text-align: center;
    position: relative;
  }
}

.quotation-quote-view,
.quotation-quote-index,
.quotation-quote-history {

  .title-wrapper {

    div.date-zone {

      text-align: left;

    }

    .order-status {

      margin: 28px 0;
      font-size: 14px;
      text-transform: uppercase;
      border-radius: 21px;
      display: inline-block;
      float: left;

    }

    form.form-quote {

      padding: 10px;

    }

    .block-order-details-view {

      display: block;
      width: 100%;
      margin: 30px 0 20px;

    }

  }

  table.table-order-items {

    caption {

      background: transparent;
      color: $thunder;
      text-transform: none;
      font-size: 24px;
      font-weight: 300;
      border-bottom: 1px $thunder solid;
      text-align: left;
      padding: 15px 0;
      margin-bottom: 15px;

    }

    border: none;
    border-radius: 0;

    th.col {

      background-color: transparent;
      text-transform: uppercase;
      font-weight: 600;
      font-size: 13px;
      text-align: left;
      border-bottom: 1px solid $thunder;

    }

    td.col {

      border-right: none;
      background-color: transparent;
      font-size: 15px;
      padding: 5px 16px 5px 0;


      span {

        font-size: 15px;

      }

      &.actions {

        text-align: right;
        padding: 0 16px;

        a {

          span {

            display: inline-block;
            padding: 5px;
            color: #6c727f;
            font-weight: 600;

          }

          &:hover {

            background-color: $color-secondary;
            border-color: $color-secondary;

            span {

              color: #fff;

            }

          }

        }

      }

      &.qty {

        ul {

          padding-left: 0;

          li {

            list-style: none;

          }

        }

      }

    }

    tfoot {

      font-size: 15px;
      text-transform: uppercase;
      font-weight: bold;
      border: none;

      tr.subtotal {

        font-weight: normal;

        th.mark {

          font-weight: normal;

        }

      }

      th.mark {

        border-right: none;
        text-align: right;

      }

      td {

        padding: 20px 16px;

        &.amount {

          border-bottom: 1px solid #c2c1df;
          border-bottom-right-radius: 0;

        }

      }

      span.price {

        font-size: 17px;

      }

    }

    .table tbody tr:last-child td:first-child {

      border-bottom-left-radius: 0;

    }

    .table tbody tr:last-child td:last-child {

      border-bottom-right-radius: 0;

    }
  }

  .message-container {

    width: 100%;

    div.message {

      > div {

        margin-left: 20px;

      }

    }

  }

  &.account {

    .button.back {

      width: auto;
      float: left;
      margin-top: 0;

    }

  }

  .form-quote {

    .quote.main {

      max-width: 100%;

      .cart2quote-accept-checkout-btn {

        width: auto;

        @media all and (max-width: 500px) {

          width: 100%;
          margin-top: 15px;
          float: none;

        }

      }

    }

  }

  .customer-ticket {

    .ticket-messages {

      width: 100%;

    }

    button.submit {

      margin-top: 15px;

    }

  }

}

.quotation-quote-index {

  .main .form-cart {

    max-width: none !important;

  }

  div.cart {

    display: block;

  }

  table.cart {

    display: table;

  }

  tbody.cart {

    display: table-row-group;

  }

  #shopping-cart-table {

    margin-bottom: 0;

    td {

      border-bottom: 1px $dusty-grey solid;

    }

    .cart.item {

      > tr:not( .item-info ) {

        width: 100%;

        td {

          width: 100%;
          border: none;

        }

      }

    }

    .product-item-photo {

      display: block;
      max-width: 125px;

    }

    td.col.item {

      width: 200px;
      max-width: 100%;
      text-transform: none;

      .product-item-name {

        display: block;
        margin-bottom: 15px;

      }

    }

    td.comment {

      border-right: none;
      background-color: #f7f7f7;
      vertical-align: top;

    }

    td.col.qty {

      width: 120px;
      vertical-align: top;

    }

    .input__label {
      margin: 0 0 12px;
    }

    td.col.price {

      vertical-align: top;
      width: 150px;

      .price {
        font-size: 19px;
      }

    }

    tr.item-actions {

      td {

        border-bottom: none;

      }

    }

    tbody tr:last-child td:last-child,
    tbody tr:last-child td:first-child {

      border-radius: 0;

    }


    .item-info {

      .product-item-name {

        a {
          color: $thunder;
        }

      }

      > .col {

        padding-bottom: 20px;
        padding-top: 15px;

      }

    }

  }

  #co-add-to-quote-form {

    ul {

      list-style: none;
      padding-left: 0;

    }

  }

  #quotation-fields {

    .admin__control-textarea {

      // Code reviewers - ignore this file...

      display: block;
      border-radius: 25px;
      min-width: 100%;
      max-width: 100%;
      min-height: 100px;
      padding: 8px 16px;
      line-height: 1.5;
      background-color: #fff;
      color: #231f20;
      width: 100%;
      height: 48px;
      line-height: 48px;
      padding: 8px 16px;
      border: 1px solid #888888;
      border-radius: 25px;
      font-size: 16px;
      font-family: inherit;
      margin-bottom: 15px;

    }

  }

  #checkoutSteps {

    padding: 0;

  }

  .cart.table-wrapper {
    margin-bottom: 0;
  }

  .actions-toolbar {

    margin-bottom: 30px;
    text-align: right;

    > * {
      width: auto;
    }

    #empty_quote_button {
      float: left;
    }

  }

  .authentication-wrapper {

  }

}

.account {

  .button.back {

    margin-top: 15px;

  }

}

.button--transfer-to-coupa {
  margin-top: 10px;
}

.quote-popup-modal-content {
  text-align: center;
}

@media all and (max-width: 767px) {

  .quotation-quote-index {

    #shopping-cart-table {

      .cart.item {

        .actions-toolbar {

          margin-bottom: 0;
          text-align: left;

        }

        .item-info {

          td {

            display: block;
            max-width: 100%;
            border: none;
            width: 100%;
            padding: 10px 15px;
            margin: 0;

            &:last-child {
              padding-bottom: 15px;
            }

          }

        }

      }

    }

  }

}

.quotation-quote-view {

  .title-wrapper {

    div.date-zone {

      text-align: left;

    }

    .actions-toolbar {

      margin-top: 10px;

    }

  }

  table.table-order-items {

    caption {

      padding: 15px;
      margin-bottom: 3px;
      color: #fff;
      text-transform: uppercase;
      background: $header__background;
      font-weight: bold;

    }

    border: none;
    border-radius: 0;

    th.col {

      background-color: transparent;
      text-transform: uppercase;
      font-weight: 600;
      font-size: 13px;
      text-align: left;

    }

    td.col {

      border-right: none;
      background-color: transparent;
      font-size: 15px;
      padding: 5px 16px 5px 0;

      &:first-child {

        padding-left: 30px;

      }

      span {

        font-size: 15px;

      }

      &.actions {

        text-align: right;
        padding: 0 16px;

        a {

          span {

            display: inline-block;
            padding: 5px;
            color: #6c727f;
            font-weight: 600;

          }

          &:hover {

            background-color: $color-secondary;
            border-color: $color-secondary;

            span {

              color: #fff;

            }

          }

        }

      }

      &.qty {

        ul {

          padding-left: 0;

          li {

            list-style: none;

          }

        }

      }

    }

    tfoot {

      font-size: 15px;
      text-transform: uppercase;
      font-weight: bold;
      background-color: $color-primary;

      tr.subtotal {

        font-weight: normal;

        th {

          font-weight: normal;
          background-color: transparent;
          color: $white;
          border-color: $color-primary;

        }

      }

      tr.grand_total {

        th {

          background-color: transparent;
          color: $white;
          border-color: $color-primary;

        }

      }

      th.mark {

        border-right: none;
        text-align: right;

      }

      td {

        padding: 20px 16px;
        background-color: transparent;
        color: $white;
        border-color: $color-primary;

        &.amount {

          border-bottom-right-radius: 0;

        }

      }

      span.price {

        font-size: 17px;

      }

    }

    .table tbody tr:last-child td:first-child {

      border-bottom-left-radius: 0;

    }

    .table tbody tr:last-child td:last-child {

      border-bottom-right-radius: 0;

    }

  }

  .message-container {

    width: 100%;

    div.message {

      > div {

        margin-left: 20px;

      }

    }

  }

}

@media all and (max-width: 500px) {

  #quote-popup-modal-element {

    .quote-added-action-btn + .quote-added-action-btn {

      width: 100%;
      margin: 10px 0 0;

    }

  }

}

table {
  tr {
    td {
      // Hiding mobile rows by default that need to be moved after
      &.product-name-mobile {
        display: none;
      }

      // Order Page hide desktop icon and show mobile text vers. after
      &.order-status-mobile {
        display: none;
      }

      &.stock-mobile {
        display: none;
      }
    }
  }
}

//.checkbox__field:not(:checked) ~ .checkbox__label:before {
//  background-color: lightgrey;
//}

@media all and (max-width: 534px) {
  .quote-list,
  #savedorders-table,
  #my-amazon-orders-table {
    tr {
      position: relative;

      td {
        padding: 5px 0 !important;
        border: none;
        color: #303030;

        &[data-th="Qty"] {
          display: flex;
          align-items: center;

          &:before {
            text-align: left;
          }

          .quantity-update {
            button,
            input {
              padding: 0;
              height: 100%;
            }
          }
        }

        &.product-name-desktop {
          display: none;
        }

        &.product-name-mobile {
          display: flex;
        }
      }
    }
  }

  .quote-list {
    tr {
      td {
        font-weight: bold;

        &:before {
          font-weight: 400;
        }

        &:last-of-type {
          &:before {
            width: 50%;
          }
        }

        &[data-th="Stock"] {
          position: absolute;
          bottom: 0;
          right: 0;
          width: 200px;
          color: transparent;
          display: flex;
          justify-content: flex-end;
          margin-right: 20px;
        }
      }
    }
  }
  #savedorders-table {
    tr {
      td {
        font-weight: 400;

        &:before {
          font-weight: bold;
        }

        &.order-status-desktop {
          display: none;
        }

        &.order-status-mobile {
          display: flex;

          &[data-th="Status"] {
            text-transform: capitalize;
            font-weight: bold;

            &.dispatched {
              color: #32c036;

              &:before {
                color: #303030;
              }
            }

            &.processing {
              color: $highlight-orange;

              &:before {
                color: #303030;
              }
            }
          }
        }
      }
    }
  }
}

.quotelist-account-view {

  .title-wrapper {

    display: none;

  }

  .quote-info-items-container {

    @media all and (max-width: 991px) {

      margin-top: 80px;

    }

  }

  .table.quote-list{

    th.col.part-number {

      min-width: unset;

    }

  }

}

.develodesign_portalquotes-quote-view {
  .row.row--no-margins {
    margin: 0 auto;

    .col-lg-2 {
      display: none;
    }

    .col-lg-10 {

      flex-basis: 100%;
      max-width: 100%;
      padding: 20px 60px;

      @media all and (min-width: 1040px) and (max-width: 1200px) {

        padding: 20px 30px;

      }

      @media all and (max-width: 1039px) {

        padding: 20px 15px;

      }

    }

  }

}

.table.quote-list {

  .totals.quote-totals {

    td.total-qty {

      text-align: right;

      @media all and (max-width: 767px) {

        &::before {

          display: none;

        }

        span {

          margin-left: auto;

        }

      }

    }

    .dte-add-toquote-container {

      @media all and (max-width: 767px) {

        width: 70%;
        margin-left: auto;

      }

      .dte-add-toquote-inner {

        padding-left: 15px;

        @media all and (max-width: 767px) {

          padding-left: 0;

        }

        .button {

          width: 100%;

        }

      }

    }

  }

}

// New Design Changes
.amazon-store.account.quotelist-cart-index,
.quotelist-account-view  {

  #maincontent {

    margin: 0 auto;
    padding: 0;

    .grid__column.dashboard__content {

      padding: 0;

    }

  }

  .title-wrapper {

    display: none;

  }

  .call-to-action-container {

    display: none;

  }

  .accounts-breadcrumbs-container {

    background-color: $breadcrumbs-bg-colors;

    .breadcrumbs.dte-amazon.container {

      display: flex;
      align-items: center;
      width: 100%;
      margin: 0 auto;


      .items {

        border-bottom: 0;
        padding: 50px 60px 25px 60px;

        @media all and (max-width: 1100px) {

          padding: 50px 40px 25px 40px;

        }

        @media all and (max-width: 1039px) {

          padding: 50px 30px 25px 30px;

        }

        .item a {

          color: $dte-blue;
          font-size: 18px;
          font-weight: bold;

        }


      }

    }

  }

  // Title and top table actions
  .quote-info-items-container {

    .table-top-header {

      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      align-items: flex-end;
      padding: 0 60px 10px 60px;
      gap: 10px 60px;

      @media all and (max-width: 1100px) {

        padding: 0 40px 10px 40px;

      }

      @media all and (max-width: 1039px) {

        padding: 0 30px 10px 30px;

      }

      .page-title {

        font-size: 18px;
        color: $dark-grey;
        font-weight: bold;

      }

      .row-actions {

        display: flex;
        align-items: center;
        gap: 12px;
        flex-wrap: wrap;
        margin-top: 20px;
        height: 44px;

        .button {

          height: 44px;
          min-width: 167px;
          padding: 10px;

        }

        @media all and (max-width: 768px) {
            .button {
                min-width:auto;
                float:left;
                z-index:100;
            }
        }

        // Save as quote btn
        .dte-add-toquote-container {



        }

      }

    }

  }

  // Tables and below
  .quote-tables,
  .actions-toolbar-bottom,
  #my-amazon-quotes-table {

    padding: 0 60px;

    @media all and (max-width: 1100px) {

      padding: 0 45px;

    }

    @media all and (max-width: 1039px) {

      padding: 0 30px;

    }

  }

  .quote-list {

    padding: 30px 0;

    thead tr {

      th {

        padding: 0 36px 18px 0 !important;

        &:last-of-type {

          padding: 0 18px 18px 0 !important;

        }

        @media all and (max-width: 1200px) {

          padding: 0 18px 18px 0 !important;

        }


        &.part-number {

          min-width: unset;

        }


        &.name {

          min-width: 40%;

        }

        &.qty {

          min-width: 30%;

        }

        &.stock {

          min-width: 125px;

        }

        &.delete {

          text-align: left;

          @media all and (min-width: 992px) {

            min-width: 100px;

          }

        }

        &.unity,
        &.total {

          min-width: unset;

        }

      }

    }

    tbody,
    tfoot {
      background-color: white;

      td {

        color: $dark-grey;
        font-size: 13px;
        text-transform: capitalize;
        padding: 5px 36px 5px 0 !important;

        &:not(.total-qty) {

          font-weight: 500;

        }

        @media all and (max-width: 1200px) {

          padding: 13px 18px 13px 0 !important;

        }

        &:last-of-type {

          padding: 13px 18px 13px 0 !important;
          font-weight: bold;

          @media all and (max-width: 1200px) {

            padding: 13px 5px 13px 0 !important;

          }

        }

        .quantity-update {

          .quantity-update__button {

            max-height: 30px;

            @media all and (max-width: 1200px) {

              min-width: 20px !important;

            }

          }

          .quantity-update__input.qty {

            max-height: 30px;

          }

          .quantity-update__button--minus {

              bottom: 0px;

          }

        }

        &.col[data-th="Stock"] {

          position: relative;

          svg:not(.Call-orange) {

            max-height: 24px;
            max-width: 24px;


          }

          span {

            @media all and (min-width: 768px) {

              position: absolute;
              left: 30px;
              top: 50%;
              vertical-align: unset;
              transform: translateY(-64%);
              font-weight: 500;

            }

          }

        }

        .col.actions a.delete {

          svg {

            max-height: 24px !important;
            max-width: 24px !important;

          }

        }

        &.total-qty {

          border-bottom: 1px #dfdada solid !important;
          padding: 19.5px 0 19.5px 0 !important;

          @media all and (max-width: 767px) {

            padding-top: 0 !important;

          }

        }

        .total-qty {

          text-align: left;

          .total-title-text {

            font-size: 22px;
            display: inline-block;

          }

          .total-value {

            display: inline-block;
            font-size: 20px;

            &::first-letter {

              font-size: 16px;

            }
          }

        }

        &[class^="blank"] {

          border-bottom: 0;
          border: 0;

          @media all and (max-width: 767px) {

            display: none;

          }

        }

        &.shipping-col {

          border-bottom: 0;
          padding: 0 !important;

          .shipping-col-details {

            display: flex;
            flex-wrap: wrap;
            gap: 0 5px;
            font-size: 15px;
            font-weight: 500;

            .shipping-name {

              font-weight: 500px;

            }

            .shipping-value {

              font-weight: 600;

            }

          }

        }

      }

      tr[class^="shipping-row-"] {

        @media all and (max-width: 767px) {

          padding: 0 !important;

        }

        td.shipping-col {

          padding-bottom: 13px !important;

          &.spacing-top {

            padding-top: 24px !important;

            @media all and (max-width: 767px) {

              padding-top: 8px !important;

            }

          }

          &.spacing-bottom {

            padding-bottom: 24px !important;

            @media all and (max-width: 767px) {

              padding-bottom: 8px !important;

            }

          }

          &.first-last-item {

            @media all and (max-width: 767px) {

              padding-top: 8px !important;

            }

          }

        }

      }

      tr.totals.show-border-top {

        td.total-qty {

          border-top: 1px solid $border-input-grey;

          @media all and (max-width: 767px) {

            padding-top: 19.5px !important;

          }

        }

      }

    }

  }

  tr.table-optionals {

    width: 100% !important;
    float: unset !important;

    td::before {

      display: none;

    }

    .option-item {

      .checkbox-input-container {

        background: transparent;
        padding: 24px 0 0 0 !important;
        margin: 0;

        @media all and (max-width: 767px) {

          padding: 0 !important;

        }

      }

    }

    .checkbox-input {

      justify-content: flex-start;

      input {

        + label {

          font-size: 13px;
          font-weight: 550;
          color: $dark-grey;
          padding: 0 0 0 35px !important;
          margin: 0 !important;
          display: flex;
          flex-wrap: wrap;

          &:before {

            width: 17px;
            height: 17px;
            top: 4px;

          }

          &:after {

            background-color: transparent;
            background-image: url("data:image/svg+xml,%3Csvg  viewBox='0 0 24 24' width='24' height='24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m18.7357432 7.12093254-9.15691975 9.15619256-4.57882345-4.5773691' fill='none' stroke='%230AB6FF' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
            transform: scale(60%);
            top: 0px;
            left: -4px;
            border-radius: 0;

          }

          > div {

            flex-basis: 100%;
            font-weight: bold;

          }

        }

      }
    }

      &.extra-row {

        td {

          border-bottom: 0;
          padding: 0 !important;

      }

    }

    &.punchout-row {

      hr {

        border-top: 2px solid $border-input-grey;
        color: transparent;
        margin-bottom: 10px;
        margin-top: 11px;

      }

      @media all and (max-width: 767px) {

        padding-top: 0;

        td {

          padding: 0 !important;
          flex-wrap: wrap;

          hr {

            width: 100%;

          }

        }

      }

      .button--transfer-to-coupa {

        background-color: $alternative-green;
        color: white;

      }

      td {

        border-bottom: 0;
        padding-right: 0 !important;

      }

      .dte-add-toquote-button  {

        min-width: 100%;
        margin-top: 10px;

        @media all and (min-width: 768px) {

          display: none;

        }

      }

    }

  }

}

.quotelist-account-view {

  .quote-info .quote-info-items-container {

    margin-top: 0;

  }

}
